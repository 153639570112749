import { Component, OnInit, ViewEncapsulation, ViewChild, Input, EventEmitter } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { HistoryService } from 'app/services';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Subject, merge } from 'rxjs';
import { startWith, tap, takeUntil, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { GlobalFuntions } from 'app/_helpers';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-activities-history',
  templateUrl: './activities-history.component.html',
  styleUrls: ['./activities-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ActivitiesHistoryComponent implements OnInit {

  @Input() id: any;
  @Input() relatedTo: any;
  displayedColumns = ['subject', 'type', 'status', 'priority', 'start_date', 'end_date', 'start_time', 'end_time', 'action'];
  activities = [] as any;
  loadingResults: boolean;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  reloadProperties: EventEmitter<any>;

  private _unsubscribeAll: Subject<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private _historyService: HistoryService,
    private router: Router,
    public _globalFunction: GlobalFuntions,
    private _matDialog: MatDialog
  ) {
    this.reloadProperties = new EventEmitter();
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    merge(this.sort.sortChange, this.paginator.page, this.reloadProperties)
      .pipe(
        startWith(''),
        tap(() => this.loadingResults = true),
        takeUntil(this._unsubscribeAll),
        switchMap(() => {
          if (this.relatedTo === 'accounts') {
            return this._historyService.getAccountsActivities(this.id, this.paginator.pageIndex + 1);
          } else if (this.relatedTo === 'owners') {
            return this._historyService.getOwnersActivities(this.id, this.paginator.pageIndex + 1);
          } else if (this.relatedTo === 'companies') {
            return this._historyService.getCompaniesActivities(this.id, this.paginator.pageIndex + 1);
          }
        })
      )
      .subscribe((data: any) => {
        this.loadingResults = false;
        this.activities.data = data.body;
        this.activities.total = data.headers.get('x-pagination-total-count');
        this.activities.limit = data.headers.get('x-pagination-per-page');
      }, () => this.loadingResults = false);
  }

  deleteData(_id: any, createdBy: any, agency: any): void {
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });
    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';
    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._historyService.deleteActivity(_id, createdBy, agency).subscribe(() => {
          this.reloadProperties.next(true);
        });
      }
      this.confirmDialogRef = null;
    });
  }
  ViewTask(_id: any): void {
    this.router.navigate(['/activities/tasks/view/' + _id]);
  }
  EditTask(_id: any): void {
    this.router.navigate(['/activities/tasks/update/' + _id]);
  }
  ViewMeeting(_id: any): void {
    this.router.navigate(['/activities/meetings/view/' + _id]);
  }
  EditMeeting(_id: any): void {
    this.router.navigate(['/activities/meetings/update/' + _id]);
  }
  ViewViewing(_id: any): void {
    this.router.navigate(['/activities/viewings/view/' + _id]);
  }
  EditViewing(_id: any): void {
    this.router.navigate(['/activities/viewings/edit/' + _id]);
  }
}
