import { NgModule } from '@angular/core';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BoxLoaderComponent } from './box/box-loader.component';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  imports: [
    MatProgressSpinnerModule,
    MatInputModule
  ],
  declarations: [BoxLoaderComponent],
  exports: [BoxLoaderComponent]
})
export class LoadersModule {

}
