import { Router } from '@angular/router';
import { AuthenticationService } from 'app/services/authentication.service';
import { Injectable, NgZone } from '@angular/core';
import * as store from 'store';

const MINUTES_UNITL_AUTO_LOGOUT = 180; // in Minutes
const CHECK_INTERVALL = 1000; // in ms
const STORE_KEY = 'lastAction';

@Injectable({
  providedIn: 'root'
})
export class AutoLogoutService {

  date = new Date();

  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private ngZone: NgZone
  ) {
    this.check();
    this.initListener();
    this.initInterval();
  }

  get lastAction(): any {
    // tslint:disable-next-line: radix
    return parseInt(store.get(STORE_KEY));
  }
  set lastAction(value) {
    store.set(STORE_KEY, value);
  }

  initListener(): void {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener('click', () => this.reset());
    });
  }

  initInterval(): void {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.check();
      }, CHECK_INTERVALL);
    });
  }

  reset(): void {
    this.lastAction = Date.now();
  }

  check(): void {
    const now = Date.now();
    const timeleft = this.lastAction + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    this.ngZone.run(() => {
      if (isTimeout && this.auth.currentUserValue || this.date.getDate() === this.date.getDate() + 1) {
        this.auth.logout();
      }
    });
  }
}
