import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TasksCreateFormComponent } from './tasks-create-form.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { TimePickerModule } from 'app/components/time-picker/time-picker.module';
import { TooltipModule } from 'ng2-tooltip-directive-ngfix';
import { CommonDialogModule } from 'app/components/common-dialog/common-dialog.module';

@NgModule({
    declarations: [
        TasksCreateFormComponent
    ],
    imports: [
        CommonModule,
        FuseSharedModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MatMenuModule,
        MatToolbarModule,
        MatChipsModule,
        MatExpansionModule,
        MatRippleModule,
        MatSnackBarModule,
        NgxMatSelectSearchModule,
        TimePickerModule,
        TooltipModule,
        CommonDialogModule
    ],
    exports: [
        TasksCreateFormComponent
    ]
})
export class TasksCreateFormModule { }
