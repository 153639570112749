import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-edit-input',
  templateUrl: './edit-input.component.html',
  styleUrls: ['./edit-input.component.scss']
})
export class EditInputComponent implements OnInit {

  @Input() data: any;
  @Input() view: any;
  @Input() cardName: any;
  @Output() focusOut: EventEmitter<any> = new EventEmitter<any>();
  editMode = false;
  constructor() { }

  ngOnInit(): void { }

  onFocusOut(): void {
    this.focusOut.emit(this.data);
  }

  updateCard(): void {
    const data = [
      this.data, this.cardName
    ];
    this.focusOut.emit(data);
  }

}
