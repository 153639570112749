import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { CurrencyPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class PropertyModelsService {

    constructor(
        private http: HttpClient,
        private _authenticationService: AuthenticationService,
        private _currencyPipe: CurrencyPipe,
        private _translateService: TranslateService,
    ) { }

    getData(filter: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}property_models`, filter);
    }

    createData(data: any): Observable<object> {
        return this.http.post(`${environment.apiUrl}property_models/create`, data);
    }

    updateData(data: any): Observable<object> {
        return this.http.put(`${environment.apiUrl}property_models/update/${data._id}`, data);
    }

    deleteData(data: any): Observable<any> {
        return this.http.delete(`${environment.apiUrl}property_models/delete/${data._id}`, data);
    }
}
