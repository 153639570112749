import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DocumentTypesService {

  constructor(private http: HttpClient) { }

  getData(postData: any, page: any, perPage?: number): Observable<any[]> {
// tslint:disable-next-line: max-line-length
    return this.http.post<any[]>(`${environment.yiiUrl}documents-types/search?expand=agency_data&get_all=true&page=` + page + `&per-page=${perPage}` , postData, { observe: 'response' as 'body' })
    .pipe(map(data => {
      return data;
  }));
  }

  createData(data: any): Observable<object> {
    return this.http.post(`${environment.yiiUrl}documents-types`, data);
  }

  updateData(data: any): Observable<object> {
    return this.http.put(`${environment.yiiUrl}documents-types/${data._id}`, data);
  }

  deleteData(_id: any, createdBy: any, agency: any): Observable<any> {
    return this.http.delete(`${environment.yiiUrl}documents-types/${_id}?created_by=${createdBy}&agency=${agency}`);
  }

  getModel(): Observable<object> {
    return this.http.get<any>(`${environment.yiiUrl}agencies/get-exclusions?model=documents_types`);
  }

  updateModel(data: any): Observable<object> {
    return this.http.post<any>(`${environment.yiiUrl}agencies/model-exclusions`, data);
  }

  getAllModel(): Observable<object> {
    return this.http.get<any>(`${environment.yiiUrl}agencies/is-all-check?model=documents_types`);
  }

  updateAllModel(data: any): Observable<object> {
    return this.http.post<any>(`${environment.yiiUrl}agencies/model-all-exclusions`, data);
  }
}
