// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cell {
  border: 2px solid transparent;
  padding: 11px 20px;
  width: 100%;
  height: 100%;
}
.cell:hover {
  cursor: pointer;
  border: 2px dashed #c8c8c8;
}

.cellInput {
  border: 2px solid transparent;
  padding: 11px 20px;
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/edit-input/edit-input.component.scss"],"names":[],"mappings":"AAEA;EACE,6BAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AADF;AAGE;EACE,eAAA;EACA,0BAAA;AADJ;;AAKA;EACE,6BAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AAFF","sourcesContent":["$lightGray: #c8c8c8;\n\n.cell {\n  border: 2px solid transparent;\n  padding: 11px 20px;\n  width: 100%;\n  height: 100%;\n\n  &:hover {\n    cursor: pointer;\n    border: 2px dashed $lightGray;\n  }\n}\n\n.cellInput {\n  border: 2px solid transparent;\n  padding: 11px 20px;\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
