import { NgModule } from '@angular/core';
import { MatPasswordStrengthInfoComponent } from './mat-password-strength-info.component';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [MatPasswordStrengthInfoComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatCardModule,
        MatPasswordStrengthModule,
        MatCardModule,
    ],
    exports: [MatPasswordStrengthInfoComponent],
})
export class CustomMatPasswordStrengthModule {}
