import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { AddressMapComponent } from './address-map/address-map.component';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import * as _ from 'lodash';
import { CountriesService, CitiesService, ProvincesService, RegionsService, DropdownsService } from 'app/services';

@Directive({
  selector: '[appAddressMap]'
})
export class AddressMapDirective {

  @Input() address: UntypedFormControl = new UntypedFormControl('');
  @Input() country: UntypedFormControl;
  @Input() region: UntypedFormControl;
  @Input() city: UntypedFormControl;
  @Input() province: UntypedFormControl;
  @Input() postcode: UntypedFormControl;
  @Input() street: UntypedFormControl;
  // tslint:disable-next-line: variable-name
  @Input() street_number: UntypedFormControl;
  @Input() latitude: UntypedFormControl;
  @Input() longitude: UntypedFormControl;
  @Output() changeLat: EventEmitter<any> = new EventEmitter();
  @Output() changeLng: EventEmitter<any> = new EventEmitter();
  filter: any;
  countryData: any;
  provinceData: any;
  cityData: any;
  regionData: any;

  constructor(
    public _matDialog: MatDialog,
    private _countriesService: CountriesService,
    private _citiesService: CitiesService,
    private _provincesService: ProvincesService,
    private _regionService: RegionsService,
    private _dropdownService: DropdownsService,
  ) {
    this.filter = {
      options: { page: 1, limit: 30, sort: {} }
    };
  }

  @HostListener('click') onClick(): void {
    const dialogRef = this._matDialog.open(AddressMapComponent, {
      panelClass: 'map-dialog',
      data: {
        address: this.address.value,
        latitude: this.latitude,
        longitude: this.longitude,
      },
    });
    dialogRef.afterClosed()
      .subscribe((response: any) => {
        if (response) {
          const address = response[0];
          const compAddress = response[1];
          const coordinates = response[2];
          // this.country.setValue(1);
          let countryName: any;
          let cityName: any;
          let ProvinceName: any;
          let postalCode: any;
          let streetName: any;
          let streetNumber: any;
          let regionName: any;

          postalCode = _.find(address, (component: any) => component.types.indexOf('postal_code') > -1);
          streetName = _.find(address, (component: any) => component.types.indexOf('route') > -1);
          streetNumber = _.find(address, (component: any) => component.types.indexOf('street_number') > -1);
          if (postalCode && this.postcode) { this.postcode.setValue(postalCode.long_name); }
          if (streetName && this.street) { this.street.setValue(streetName.long_name); }
          if (streetNumber && this.street_number) { this.street_number.setValue(streetNumber.long_name); }
          if (compAddress && this.address) { this.address.setValue(compAddress); }
          if (this.latitude) { this.latitude.setValue(coordinates['lat']); }
          if (this.longitude) { this.longitude.setValue(coordinates['lng']); }
          this.changeLat.next(coordinates['lat']);
          this.changeLng.next(coordinates['lng']);

          countryName = _.find(address, (component: any) => component.types.indexOf('country') > -1);
          cityName = _.find(address, (component: any) => component.types.indexOf('locality') > -1);
          ProvinceName = _.find(address, (component: any) => component.types.indexOf('administrative_area_level_2') > -1);
          regionName = _.find(address, (component: any) => component.types.indexOf('administrative_area_level_1') > -1);

          /** Calling APi's */
          if (countryName && this.country) {
            this.filter.query = { 'value.en': { $regex: '.*' + countryName.long_name + '.*', $options: 'i' } };
            this._countriesService.getData(this.filter)
              .subscribe((data: any) => {
                if (data.docs[0]) {
                  this.countryData = data.docs[0];
                  this.country.setValue(this.countryData.key);

                  // Get region data
                  if (regionName && this.region) {
                    this.filter.query = { 'value.en': { $regex: '.*' + regionName.long_name + '.*', $options: 'i' } };
                    this._dropdownService.getRegions(regionName.long_name)
                      .subscribe((data: any) => {
                        if (data.docs.length) {
                          this.regionData = data.docs.filter(x => x.country == this.countryData.key);
                          this.region.setValue(this.regionData[0].key);

                          // Get province data
                          if (ProvinceName && this.province) {
                            this.filter.query = { 'value.en': { $regex: '.*' + ProvinceName.long_name + '.*', $options: 'i' } };
                            this._dropdownService.getProvinces(ProvinceName.long_name)
                              .subscribe((data: any) => {
                                if (data.docs.length) {
                                  this.provinceData = data.docs.filter(x => x.region == this.regionData[0].key);
                                  this.province.setValue(this.provinceData[0].key);

                                  // Get city data
                                  if (cityName && this.city) {
                                    this._dropdownService.getCities(cityName.long_name, false, this.region.value, this.province.value)
                                      .subscribe((data: any) => {
                                        if (data.docs.length) {
                                          this.cityData = data.docs.filter(x => x.province == this.provinceData[0].key);
                                          if (cityName.long_name === 'Ronda') {
                                            _.forEach(data.docs, (content: any) => {
                                              if ((content.value.en === cityName.long_name || content.value.es_AR === cityName.long_name)
                                                && content.country === this.country.value) {
                                                this.city.setValue(content.key);
                                              }
                                            });
                                          }
                                          else if (this.cityData.length) {
                                            this.city.setValue(this.cityData[0].key);
                                          }
                                        }
                                      });
                                  }

                                }
                              });
                          }
                        }
                      });
                  }
                }
              });
          }
        }
      });
  }
}
