import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/services';
@Component({
  selector: 'windowcards-list-dialog',
  templateUrl: './windowcards-list-dialog.component.html',
  styleUrls: ['./windowcards-list-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WindowcardsListDialogComponent implements OnInit {
  currentAgency: any;

  constructor(
    public matDialogRef: MatDialogRef<WindowcardsListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _authenticationService: AuthenticationService,
    private router: Router,
  ) {
    this.currentAgency = this._authenticationService.currentAgencyValue;
  }

  ngOnInit(): void {
    // this.islocalRoute();
  }

  islocalRoute(): boolean {
    let getURL = window.location.href;
    let otherURL = getURL.slice(8);
    let myArr = otherURL.split("/");
    let checkURL = myArr[0];

    if (checkURL == 'ocalhost:4200' || checkURL == 'dev2.optima-crm.com' ) {
      return true;
    } else {
      return false;
    }

  }

}
