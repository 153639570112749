import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicInfoComponent } from './basic-info.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { TimePickerModule } from 'app/components/time-picker/time-picker.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRippleModule } from '@angular/material/core';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {  NgxMaskDirective, NgxMaskPipe, IConfig, provideNgxMask } from 'ngx-mask';

const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
    declarations: [BasicInfoComponent],
    imports: [
        CommonModule,
        FuseSharedModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MatMenuModule,
        MatToolbarModule,
        MatChipsModule,
        MatExpansionModule,
        MatRippleModule,
        MatSnackBarModule,
        NgxMatSelectSearchModule,
        TimePickerModule,
        NgxMaskDirective,
        NgxMaskPipe,
        // NgxMaskModule.forRoot(maskConfig),
    ],
    exports: [BasicInfoComponent],
    providers: [provideNgxMask(maskConfig)]
})
export class BasicInfoModule { }
