import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { DropdownsService } from 'app/services';
import { SequencesService } from 'app/services/sequences.service';
import { BaseService } from 'app/_helpers/base/base.service';
import * as _ from 'lodash';
import { merge, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

export interface PeriodicElement {
    name: string;
    position: number;
    weight: number;
    symbol: string;
}

@Component({
    selector: 'app-sequences',
    templateUrl: './sequences.component.html',
    styleUrls: ['./sequences.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SequencesComponent implements OnInit, OnDestroy {

    private _unsubscribeAll: Subject<any>;
    displayedColumns: string[] = ['title', 'activity_group', 'offices'];
    searchColumns: string[] = ['search_title', 'search_activity_group', 'search_offices'];
    dialogTitle: string;
    filter: UntypedFormGroup;
    sequences: any;
    currentLang: string;
    searchActivityGroup$ = new Subject<string>();
    searchOffices$ = new Subject<string>();
    activityGroups: any[];
    offices: any[];

    dataReload: UntypedFormControl = new UntypedFormControl(null);
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    loadingResults: boolean;

    constructor(
        private _formBuilder: UntypedFormBuilder,
        public matDialogRef: MatDialogRef<SequencesComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _sequencesService: SequencesService,
        private _translateService: TranslateService,
        private _gService: BaseService,
        private _dropdownsService: DropdownsService
    ) {
        this.currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
        // this.filter = { for: this._data.relatedTo };
        this.filter = this._formBuilder.group({
            for: [],
            title: [],
            activity_group: [],
            offices: [],
        });
        this.sequences = {};
        this.dialogTitle = 'Select sequence';
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this._translateService.onLangChange
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
            });

        merge(this.paginator.page, this.filter.valueChanges, this.dataReload.valueChanges)
            .pipe(
                tap(() => this.loadingResults = true),
                takeUntil(this._unsubscribeAll),
                switchMap(() => {
                    return this._sequencesService.getAll(this.filter.getRawValue(), this.paginator.pageIndex + 1);
                })
            )
            .subscribe((data: any) => {
                this.loadingResults = false;
                this.sequences.data = data.body;
                this.sequences.total = data.headers.get('x-pagination-total-count');
                this.sequences.limit = data.headers.get('x-pagination-per-page');
            }, () => this.loadingResults = false);
        this.filter.get('for').setValue(this._data.relatedTo);

        this.searchActivityGroup$
            .pipe(
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    const filter = {
                        query: {} as any,
                        options: {
                            page: 1,
                            limit: 30,
                            sort: {} as any
                        }
                    };
                    filter.options.sort[`value.${this.currentLang}`] = 1;
                    if (search) {
                        filter.query.$or = [
                            { 'value.en': { $regex: `.*${search}.*`, $options: 'i' } },
                            { 'value.es_AR': { $regex: `.*${search}.*`, $options: 'i' } }
                        ];
                    }
                    return this._gService.post('task_groups', filter, 'nodejs');
                }),
            )
            .subscribe((data: any) => {
                const activityGroups = _.map(data.docs, (body) => {
                    body.label = body.value[this.currentLang];
                    return body;
                });
                this.activityGroups = activityGroups;
            });

        this.searchOffices$
            .pipe(
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    return this._dropdownsService.getOffices(search);
                }),
            )
            .subscribe((data: any) => {
                this.offices = data.body;
            });
        // this.getEventsSequence();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    onSelectEvent(data: any): void {
        this.matDialogRef.close(data);
    }

}
