import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Component({
  selector: 'archive-search',
  templateUrl: './archive-search.component.html',
  styleUrls: ['./archive-search.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class ArchiveSearchComponent implements OnInit {

  form: UntypedFormGroup;
  systemLang: any;
  searching: boolean;
  currentLang: string;
  viewRecord: boolean;
  filter: any = {};
  constructor(
    public dialogRef: MatDialogRef<ArchiveSearchComponent>,
    private _formBuilder: UntypedFormBuilder,
    private _translateService: TranslateService,
  ) {
    this.systemLang = this._translateService.currentLang;
    this.form = this._formBuilder.group({

      id: [{ value: '', disabled: true }],
      type: [{ value: '', disabled: this.viewRecord, }],
      forename: [{ value: '', disabled: this.viewRecord, }],
      surname: [{ value: '', disabled: this.viewRecord }],
      title: [{ value: '', disabled: this.viewRecord, }],
      company_name: [{ value: '', disabled: this.viewRecord, }],
      email: [{ value: '', disabled: this.viewRecord, }],
      mobile_phone: [{ value: '', disabled: this.viewRecord, }],
      account_updated_from: [{ value: '', disabled: this.viewRecord, }],
      account_updated_to: [{ value: '', disabled: this.viewRecord, }],
    });
  }

  ngOnInit(): void { }

  onClickClose(): void {
    this.dialogRef.close();

  }
}
