import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, ChangeDetectorRef, AfterViewChecked, Injector } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { merge, Subject } from 'rxjs';
import { DropdownsService } from 'app/services';
import { startWith, switchMap, tap, takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AccountsCreateComponent } from '../../accounts-create.component';
import { AttachmentsService } from 'app/services/attachments.service';
import * as _ from 'lodash';
@Component({
    selector: 'tab-id',
    templateUrl: './tab-id.component.html',
    styleUrls: ['./tab-id.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class TabIdComponent implements OnInit, AfterViewChecked {

    viewRecord: boolean;
    nationalities: any;
    systemLang: any;
    classifications: any;
    currentLang: string;
    partners: UntypedFormArray;
    @Input() form: UntypedFormGroup;
    @Input() popup: boolean;
    searching = false as boolean;
    public companies: any = [];
    public companiesSearch: UntypedFormControl = new UntypedFormControl();
    public linkedUsersSearch: UntypedFormControl = new UntypedFormControl();
    _accountsCreateComponent: AccountsCreateComponent;
    private _unsubscribeAll: Subject<any>;
    agencyUsers: any;
    constructor(
        private _dropdownsService: DropdownsService,
        private formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService,
        private cdref: ChangeDetectorRef,
        private injector: Injector,
        private _attachmentsService: AttachmentsService,
    ) {
        this.systemLang = this._translateService.currentLang;
        this._unsubscribeAll = new Subject();
        this.currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
    }

    ngOnInit(): void {
        this._translateService.onLangChange
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
                this.systemLang = this._translateService.currentLang;
                this.nationalities = _.orderBy(this.nationalities, [`value.${this.systemLang}`], ['asc']);
            });

        if (!this.form) {
            this._accountsCreateComponent = this.injector.get(AccountsCreateComponent) as AccountsCreateComponent;
            this.form = this._accountsCreateComponent.form;
        }
        this.partners = this.form.get('partners') as UntypedFormArray;

        this._dropdownsService.getNationalities().subscribe((data: any) => {
            data.body = _.orderBy(data.body, [`value.${this.systemLang}`], ['asc']);
            this.nationalities = data.body;
        });

        this._dropdownsService.getAccountClassification().subscribe((data: any) => {
            this.classifications = data;
        });

        merge(this.companiesSearch.valueChanges, this.form.get('linked_company').valueChanges)
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    const search = this.companiesSearch.value ? this.companiesSearch.value : '';
                    return this._dropdownsService.getCompanies(search, this.form.get('linked_company').value);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.companies = data.body;
            });

        merge(this.linkedUsersSearch.valueChanges, this.form.get('linked_users').valueChanges)
            .pipe(startWith(''), debounceTime(300),distinctUntilChanged(),
                tap(() => this.searching = true),
                switchMap(() => {
                    let search = this.linkedUsersSearch.value ? this.linkedUsersSearch.value : '';
                    return this._dropdownsService.getUsersByAgencies(search, "");
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.agencyUsers = data.body;
            });

            
        if (!this.form.get('date_of_birth').value) {
            this.form.controls['birthday_greetings'].disable();
        }
        this.form.get('date_of_birth').valueChanges.subscribe(() => {
            if (this.form.get('date_of_birth').value) {
                this.form.controls['birthday_greetings'].enable();
            }
        });
    }

    addPartners(): UntypedFormGroup {
        return this.formBuilder.group({
            title: { value: '', disabled: false },
            forename: { value: '', disabled: false },
            surname: { value: '', disabled: false },
            gender: { value: '', disabled: false },
            date_of_birth: { value: '', disabled: false },
            birthday_greetings: {value: '', disabled: this.viewRecord},
            nationality: { value: '', disabled: false },
            id_type: { value: '', disabled: false },
            id_number: { value: '', disabled: false },
            id_type_two: { value: '', disabled: false },
            id_number_two: { value: '', disabled: false },
            id_expiration: { value: '', disabled: false },
            phone: { value: '', disabled: false },
            email: { value: '', disabled: false },
            last_time_contacted: { value: '', disabled: false }
        });
    }

    onChangetitle(): void {
        const title = this.form.get('title').value;
        if (title === 'Mr.') {
            this.form.get('gender').setValue('Male');
        }
        if (title === 'Ms.' || title === 'Mrs.' || title === 'Miss.') {
            this.form.get('gender').setValue('Female');
        }
    }

    onChangePartnertitle(index: any): void {
        const title = this.form.get(`partners.${index}.title`).value;
        if (title === 'Mr.') {
            this.form.get(`partners.${index}.gender`).setValue('Male');
        }
        if (title === 'Ms.' || title === 'Mrs.' || title === 'Miss.') {
            this.form.get(`partners.${index}.gender`).setValue('Female');
        }
    }

    onSelectFile(files: FileList): void {
        this._attachmentsService.uploadSingle(this.form.get('_id').value, 'account', files[0])
            .subscribe(() => {
                this.form.get('dp').setValue(files[0].name);
            });
    }

    ngAfterViewChecked(): void {
        this.cdref.detectChanges();
    }


}
