import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService, DropdownsService } from 'app/services';
import { PropertiesService } from 'app/services/properties.service';
import { GlobalFuntions } from 'app/_helpers';
import { forEach, orderBy, uniq } from 'lodash';
import { merge, Subject } from 'rxjs';
import { startWith, switchMap, tap } from 'rxjs/operators';
@Component({
    selector: 'quick-create',
    templateUrl: './quick-create.component.html',
    styleUrls: ['./quick-create.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class QuickCreateComponent implements OnInit {
    form: UntypedFormGroup;
    systemlang: any;
    searching: boolean;
    currentLang: string;
    viewRecord: boolean;
    user: any;
    filter: any = {};
    type1Data: any;
    type2Data: any;
    type1Search: UntypedFormControl = new UntypedFormControl('');
    type2Search: UntypedFormControl = new UntypedFormControl('');
    currentAgency: any;
    sale: boolean;
    transfer: boolean;
    featured: boolean;
    nudeSale: boolean;
    CountriesSearch: UntypedFormControl = new UntypedFormControl('');
    locationSearch: UntypedFormControl = new UntypedFormControl('');
    locations: any;
    citySearch: UntypedFormControl = new UntypedFormControl('');
    cities: any;
    provinceSearch: UntypedFormControl = new UntypedFormControl('');
    provinces: any;
    regionSearch: UntypedFormControl = new UntypedFormControl('');
    regions: any;
    countriesData: any;
    currentUser: any;
    currencies: any;
    nudeSales: UntypedFormArray;
    periodSeasons: UntypedFormArray;
    systemLanguages: any;
    icalUrls: UntypedFormArray;
    rentalSeasons: UntypedFormArray;
    addDiscountsDialogRef: any;
    private _unsubscribeAll: Subject<any>;
    public currentOffice: any;
    defaultCurrency: any;
    public offices: any = [];
    searchOffices$ = new Subject<string>();
    officeRegion = '' as any;
    officeGroups = [] as any;
    isClick = false as boolean;

    constructor(
        public dialogRef: MatDialogRef<QuickCreateComponent>,
        private _formBuilder: UntypedFormBuilder,
        private _dropdownsService: DropdownsService,
        private _authenticationService: AuthenticationService,
        private _translateService: TranslateService,
        private _globalFuntions: GlobalFuntions,
        private _propertiesService: PropertiesService,
        private route: ActivatedRoute,
        private _matDialog: MatDialog
    ) {
        this.user = this._authenticationService.currentUserValue;
        this.currentAgency = this._authenticationService.currentAgencyValue;
        this.currentOffice = this._authenticationService.currentOfficeValue;
        if (this.currentAgency.currency) {
            this.defaultCurrency = this.currentAgency.currency;
        }
        if (this.currentOffice.currency) {
            this.defaultCurrency = this.currentOffice.currency;
        }
        if (this.currentOffice.region) {
            this.officeRegion = this.currentOffice.region;
        }
        this._translateService.onLangChange.subscribe((data: any) => {
            this.systemlang = data.lang;
            this.loadType1Data();
            this.loadType2Data();
        });
        this._unsubscribeAll = new Subject();
        this.systemlang = this._translateService.currentLang;
        this.systemLanguages = this.route.snapshot.data.systemLanguages;

        let officeRecord = false;
        if (this._globalFuntions.getUserRole(this.user.user_role)) {
            officeRecord = true;
        }

        this.form = this._formBuilder.group({
            id: [{ value: '', disabled: true }],
            reference: new UntypedFormControl({ value: '', disabled: true }),
            own: [
                { value: true, disabled: this.viewRecord },
                Validators.required,
            ],
            status: [
                { value: 'Valuation', disabled: this.viewRecord },
                Validators.required,
            ],
            type_one: [{ value: '', disabled: this.viewRecord }],
            type_two: [{ value: '', disabled: this.viewRecord }],
            sale: [{ value: '', disabled: this.viewRecord }],
            transfer: [{ value: '', disabled: this.viewRecord }],
            rent: [{ value: '', disabled: this.viewRecord }],
            nude_sale: [{ value: '', disabled: this.viewRecord }],
            offices: [
                { value: [this.currentOffice._id], disabled: officeRecord },
            ],
            private_info_object: this._formBuilder.group({
                [this.currentAgency._id]: this._formBuilder.group({
                    address: this._formBuilder.group({
                        formatted_address: [],
                    }),
                    address_postal_code: [],
                    address_street: [],
                    address_street_number: [],
                    apartment_no: [],
                    latitude: [],
                    longitude: [],
                    address_comments: [],
                }),
            }),
            address_country: [
                {
                    value: this.currentOffice.country,
                    disabled: this.viewRecord,
                },
            ],
            region: [{ value: this.officeRegion, disabled: this.viewRecord }],
            address_province: [{ value: '', disabled: this.viewRecord }],
            address_city: [{ value: '', disabled: this.viewRecord }],
            location: [{ value: '', disabled: this.viewRecord }],
            bedrooms: [{ value: '', disabled: this.viewRecord }],
            bathrooms: [{ value: '', disabled: this.viewRecord }],
            currency: [
                { value: this.defaultCurrency, disabled: this.viewRecord },
            ],
            currentprice: [{ value: '', disabled: this.viewRecord }],
            nude_sales: this._formBuilder.array([this.addNudeSales()]),
            lt_rental: [{ value: '', disabled: this.viewRecord }],
            ltr: this._formBuilder.group({
                commission: [],
                management_commission: [],
                rental_deposit: [],
                security_deposit: [],
                minimum_stay: [],
                stay_type: [],
            }),
            period_seasons: this._formBuilder.array([this.addPeriodSeasons()]),
            period_seasons_dc: this._formBuilder.group({}),
            st_rental: [{ value: '', disabled: this.viewRecord }],
            toilets: [{ value: '', disabled: this.viewRecord }],
            external_reference: [{ value: '', disabled: this.viewRecord }],
            agency_reference: [{ value: '', disabled: this.viewRecord }],
            ical_urls: this._formBuilder.array([this.addicalUrl()]),
            license_number: [{ value: '', disabled: this.viewRecord }],
            rental_commission: [{ value: '', disabled: this.viewRecord }],
            security_deposit: [{ value: '', disabled: this.viewRecord }],
            common_periods: [{ value: '', disabled: this.viewRecord }],
            saty_number: [{ value: '', disabled: this.viewRecord }],
            stay_value: [{ value: '', disabled: this.viewRecord }],
            rental_seasons: this._formBuilder.array([this.addRentalSeasons()]),
            commission: [{ value: '', disabled: this.viewRecord }],
            management_commission: [{ value: '', disabled: this.viewRecord }],
            rental_deposit: [{ value: '', disabled: this.viewRecord }],
            stay_type: [{ value: '', disabled: this.viewRecord }],
            minimum_stay: this._formBuilder.group({
                saty_number: [],
                stay_value: [],
            }),
        });
    }

    ngOnInit(): void {
        this.nudeSales = this.form.get('nude_sales') as UntypedFormArray;
        if (this.nudeSales.length === 0) {
            this.nudeSales.push(this.addNudeSales());
        }
        this.periodSeasons = this.form.get('period_seasons') as UntypedFormArray;
        this.icalUrls = this.form.get('ical_urls') as UntypedFormArray;
        this.rentalSeasons = this.form.get('rental_seasons') as UntypedFormArray;

        this._dropdownsService.getCurrencies().subscribe((data: any) => {
            this.currencies = data;
        });

        merge(
            this.CountriesSearch.valueChanges,
            this.form.get('address_country').valueChanges
        )
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap(() => {
                    let search = this.form.get('address_country').value;
                    if (this.CountriesSearch.value) {
                        search = this.CountriesSearch.value;
                    }
                    return this._dropdownsService.getAllCountries(search);
                })
            )
            .subscribe((data: any) => {
                this.countriesData = data.docs;
                this.searching = false;
            });
        this.searchOffices$
            .pipe(
                startWith(''),
                switchMap((search: string) => {
                    return this._dropdownsService.getOffices(search);
                })
            )
            .subscribe((data: any) => {
                this.offices = data.body;
                forEach(this.offices, (value) => {
                    if (value.office_groups) {
                        if (typeof value.office_groups == 'object') {
                            value.office_groups.forEach((group) => {
                                this.officeGroups.push(group);
                            });
                        } else {
                            this.officeGroups.push(value.office_groups);
                        }
                    }
                });
                this.officeGroups = uniq(this.officeGroups);
                this.officeGroups.sort();
            });
        merge(
            this.regionSearch.valueChanges,
            this.form.get('region').valueChanges,
            this.form.get('address_country').valueChanges
        )
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap(() => {
                    let search = this.form.get('region').value;
                    if (this.regionSearch.value) {
                        search = this.regionSearch.value;
                    }
                    return this._dropdownsService.getRegions(
                        search,
                        this.form.get('address_country').value
                    );
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.regions = data.docs;
            });

        merge(
            this.provinceSearch.valueChanges,
            this.form.get('address_province').valueChanges,
            this.form.get('region').valueChanges
        )
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap(() => {
                    let search = this.form.get('address_province').value;
                    if (this.provinceSearch.value) {
                        search = this.provinceSearch.value;
                    }
                    return this._dropdownsService.getProvinces(
                        search,
                        false,
                        this.form.get('region').value
                    );
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.provinces = data.docs;
            });

        merge(
            this.citySearch.valueChanges,
            this.form.get('address_city').valueChanges,
            this.form.get('address_province').valueChanges
        )
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap(() => {
                    let search = this.form.get('address_city').value;
                    if (this.citySearch.value) {
                        search = this.citySearch.value;
                    }
                    return this._dropdownsService.getCities(
                        search,
                        false,
                        false,
                        this.form.get('address_province').value
                    );
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.cities = data.docs;
            });

        merge(
            this.locationSearch.valueChanges,
            this.form.get('location').valueChanges,
            this.form.get('address_city').valueChanges
        )
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap(() => {
                    let search = this.form.get('location').value;
                    if (this.locationSearch.value) {
                        search = this.locationSearch.value;
                    }
                    return this._dropdownsService.getLocations(
                        search,
                        false,
                        false,
                        false,
                        this.form.get('address_city').value
                    );
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.locations = data.docs;
            });

        merge(this.type1Search.valueChanges)
            .pipe(
                startWith(''),
                tap(() => (this.searching = true))
            )
            .subscribe((data: any) => {
                this.loadType1Data();
            });

        merge(this.form.get('type_one').valueChanges)
            .pipe(
                startWith(''),
                tap(() => (this.searching = true))
            )
            .subscribe((data: any) => {
                this.loadType2Data();
            });
    }

    submitForm(): void {
        this.isClick = true;
        const formData = this._globalFuntions.cleanObject(this.form.value);
        formData.created_at = new Date();
        formData.updated_at = new Date();
        formData.created_by = this.user.user_id;

        this._propertiesService.createProperty(formData).subscribe(
            (data: any) => {
                this.dialogRef.close(data);
            },
            (err: any) => {
                this.isClick = false;
            }
        );
    }

    loadType1Data(): any {
        let search = '';
        if (this.type1Search.value) {
            search = this.type1Search.value;
        }
        return this._dropdownsService
            .getPropertyType(search)
            .subscribe((data: any) => {
                this.searching = false;
                data.body = orderBy(data.body, ['value'], ['asc']);
                this.type1Data = data.body;
            });
    }
    loadType2Data(): any {
        return this._dropdownsService
            .getPropertyTypeByParent(
                this.systemlang,
                this.form.get('type_one').value
            )
            .subscribe((data: any) => {
                data.body = orderBy(data.body, ['value'], ['asc']);
                this.type2Data = data.body;
                this.searching = false;
            });
    }

    onChange(event): void {
        if (event === 'sale') {
            if (this.sale === true) {
                this.sale = false;
            } else {
                this.sale = true;
            }
            this.transfer = false;
            this.nudeSale = false;
            this.form.get('transfer').setValue(false);
            this.form.get('nude_sale').setValue(false);
        } else {
            if (event === 'transfer') {
                if (this.transfer === true) {
                    this.transfer = false;
                } else {
                    this.transfer = true;
                }
                this.sale = false;
                this.nudeSale = false;
                this.form.get('sale').setValue(false);
                this.form.get('nude_sale').setValue(false);
            } else {
                if (this.nudeSale === true) {
                    this.nudeSale = false;
                } else {
                    this.nudeSale = true;
                }
                this.sale = false;
                this.transfer = false;
                this.form.get('transfer').setValue(false);
                this.form.get('sale').setValue(false);
            }
        }
    }

    checkTransfer(): any {
        let status = true;
        if (
            this.form.get('type_one').value === 1 ||
            this.form.get('type_one').value === 13 ||
            this.form.get('type_one').value === 40 ||
            this.form.get('type_one').value === 42 ||
            this.form.get('type_one').value === 45 ||
            this.form.get('type_one').value === 49 ||
            this.form.get('type_one').value === 53 ||
            this.form.get('type_one').value === 67 ||
            this.form.get('type_one').value === 74 ||
            this.form.get('type_one').value === 82 ||
            this.form.get('type_one').value === 88 ||
            this.form.get('type_one').value === 89 ||
            this.form.get('type_one').value === 104
        ) {
            status = false;
        }
        return status;
    }
    checkNudes(): any {
        let show = true;
        if (
            this.form.get('type_one').value === 9 ||
            this.form.get('type_one').value === 12 ||
            this.form.get('type_one').value === 16 ||
            this.form.get('type_one').value === 64 ||
            this.form.get('type_one').value === 67 ||
            this.form.get('type_one').value === 89 ||
            this.form.get('type_one').value === 91 ||
            this.form.get('type_one').value === 104
        ) {
            show = false;
        }
        return show;
    }

    addNudeSales(): UntypedFormGroup {
        return this._formBuilder.group({
            initial_amount: { value: '', disabled: false },
            lft_monthly_rent: { value: '', disabled: false },
            temporary_monthly_rent: { value: '', disabled: false },
            comentarios: { value: '', disabled: false },
        });
    }

    addPeriodSeasons(): UntypedFormGroup {
        return this._formBuilder.group({
            seasons: [],
            period_from: [],
            period_to: [],
            new_price: [0],
            old_price: [0],
            commission: [0],
            management: [0],
        });
    }

    addicalUrl(): UntypedFormGroup {
        return this._formBuilder.group({
            value: [],
        });
    }

    addRentalSeasons(): UntypedFormGroup {
        return this._formBuilder.group({
            seasons: [],
            period_from: [],
            period_to: [],
            period: [],
            new_price: [0],
            old_price: [0],
            commission_included: [],
            gross_price: [0],
            gross_day_price: [0],
            price_per_day: [0],
            discounts: this._formBuilder.array([this.addDiscounts()]),
        });
    }

    addDiscounts(): UntypedFormGroup {
        return new UntypedFormGroup({
            number_days: new UntypedFormControl(),
            discount_percent: new UntypedFormControl(),
            discount_price: new UntypedFormControl(),
        });
    }
}
