import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PdfTemplatesService {

  constructor(private http: HttpClient) { }

  getData(filter: any): Observable<any[]> {
    return this.http.post<any[]>(`${environment.apiUrl}pdf_templates`, filter);
  }

  getById(id: string): Observable<object> {
    return this.http.post(`${environment.apiUrl}pdf_templates/view/${id}`, '');
  }

  createData(data: any): Observable<object> {
    return this.http.post(`${environment.apiUrl}pdf_templates/create`, data);
  }

  updateData(data: any): Observable<object> {
    return this.http.put(`${environment.apiUrl}pdf_templates/update/${data._id}`, data);
  }

  deleteData(data: any): Observable<any> {
    return this.http.delete(`${environment.apiUrl}pdf_templates/delete/${data._id}`, data);
  }

  getListData(filter: any): Observable<object> {
    return this.http.post<any[]>(`${environment.apiUrl}pdf_templates`, filter);
  }
  
  getDecodedData(filter: any): Observable<object> {
    return this.http.post<any[]>(`${environment.yiiUrl}pdf-template/template-data`, filter);
  }

  getWindowsCardTemplates(filter: any): Observable<any> {
    // const filter = {
    //   lang: lang,
    //   cards: data,
    //   properties: properties,
    //   agency: agencyId
    // };
    return this.http.post<any>(`${environment.yiiUrl}pdf/windowcards-attachment`, filter);
  }
}
