import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from 'app/services';
import { forEach, isString, isArray, startsWith, split } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private _toastr: ToastrService,
        private _tranlateService: TranslateService,
        private router: Router
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 502 && request.url.indexOf('app/search') > -1) { }
            else if (err.status === 401 || err.status === 403) {
                // auto logout if 401 response returned from api
                // console.log('error interceptor logout ===>>>', err.status);
                this.authenticationService.logout();
                // location.reload(true);
            } else if (err.status === 400 && err?.error?.errors?.private_info_object?.message) {
                const splitMessage = err?.error?.errors?.private_info_object?.message.split(' ');
                let title = 'private_info_object';
                if (splitMessage.length > 0) {
                    if (splitMessage[0]  === 'duplicated_cadastral_number') {
                        title = 'Duplicated cadastral number';
                        err.error.message = this._tranlateService.instant('property')
                        + ' ' + splitMessage[1]
                        + ' ' + this._tranlateService.instant('already has been assigned cadastral number')
                        + ' ' + splitMessage[2]
                        + ' ' + this._tranlateService.instant('Update the cadastral number in order to save.');
                    } else if (splitMessage[0]  === 'cadastral_not_valid' || splitMessage[0]  === 'cadastral_number') {
                        const firstSpaceIndex = err?.error?.errors?.private_info_object?.message.indexOf(' ');
                        err.error.message = err?.error?.errors?.private_info_object?.message.substring(firstSpaceIndex + 1);
                    }
                    const translatedErrorMessage = this._tranlateService.instant(err.error.message);
                    const translatedErrorField = this._tranlateService.instant(title);
                    this._toastr.error(translatedErrorMessage, translatedErrorField);
                }
            } else {
                if (err.error.name && err.error.name === 'Method Not Allowed' && request.url.includes('properties')) {
                    const splitMessage = err.error.message.split(' ');
                    let translatedErrorMessage = this._tranlateService.instant(err.error.message);
                    if (splitMessage.length === 2) {
                        translatedErrorMessage = this._tranlateService.instant('updated_property_message',
                            { reference: this.authenticationService.currentUserValue.user_agency_data.agency_reference + '-' + splitMessage[1] });
                    }

                    this._toastr.success(translatedErrorMessage);
                    this.router.navigate(['/properties/index']);
                }
                else if (err.error.message && err.error.message.includes('Object not found')) {

                }
                else if (err.error.message && request.url.includes('users/get-url')) { }
                else if (err.error.message && !request.url.includes('users/get-url')) {
                    const translatedErrorMessage = this._tranlateService.instant(err.error.message);
                    const translatedErrorField = this._tranlateService.instant('Error!');
                    this._toastr.error(translatedErrorMessage, translatedErrorField);
                } else if (!isString(err.error)) {
                    forEach(err.error, (e, k) => {
                        if (isString(e.message) && (e.field || e.path)) {
                            if (e.field === 'Email Already Exists') {
                                const splitMessage = JSON.parse(e.message);
                                console.log(splitMessage);
                                e.message = this._tranlateService.instant(splitMessage.key) + ' ' +
                                    splitMessage.email + ' ' +
                                    this._tranlateService.instant(splitMessage.assigned_to_key) + ' ' +
                                    splitMessage.assigned_to + ' ' +
                                    this._tranlateService.instant(splitMessage.offices_key) + ' ' +
                                    splitMessage.offices;
                            }
                            if (e.field === 'Duplicated cadastral number') {
                                const splitMessage = split(e.message, ' ');
                                e.message = this._tranlateService.instant('property')
                                    + ' ' + splitMessage[0]
                                    + ' ' + this._tranlateService.instant('already has been assigned cadastral number')
                                    + ' ' + splitMessage[1]
                                    + ' ' + this._tranlateService.instant('Update the cadastral number in order to save.');
                            }
                            if (startsWith(e.message, 'You can book minimun')) {
                                const splitMessage = split(e.message, ' ');
                                e.message = this._tranlateService.instant('You can book minimun') + ' ' + splitMessage[4] + ' ' + this._tranlateService.instant('days');
                            }
                            const translatedErrorMessage = this._tranlateService.instant(e.message);
                            const translatedErrorField = this._tranlateService.instant(e.field || e.path);
                            this._toastr.error(translatedErrorMessage, translatedErrorField);
                        }
                        if (isArray(e)) {
                            const translatedErrorMessage = this._tranlateService.instant(e[0]);
                            const translatedErrorField = this._tranlateService.instant(k);
                            this._toastr.error(translatedErrorMessage, translatedErrorField);
                        }
                    });
                } else if (isString(err.error)) {
                    const translatedErrorMessage = this._tranlateService.instant(err.error);
                    const translatedErrorField = this._tranlateService.instant('Error!');
                    this._toastr.error(translatedErrorMessage, translatedErrorField);
                }
            }
            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }
}
