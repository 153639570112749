import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from 'app/services';

@Component({
  selector: 'sharing-warning',
  templateUrl: './sharing-warning.component.html',
  styleUrls: ['./sharing-warning.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SharingWarningComponent implements OnInit {
  currentUser: any;

  constructor(
    public matDialogRef: MatDialogRef<SharingWarningComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _authenticationService: AuthenticationService
  ) {
    this.currentUser = this._authenticationService.currentUserValue;
  }

  ngOnInit(): void {

  }

}
