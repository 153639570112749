import { Pipe, PipeTransform } from '@angular/core';
import { toUpper, isEmpty, isString } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'getCommercialAddress'
})
export class GetCommercialAddressPipe implements PipeTransform {

    constructor(
        private _translateService: TranslateService,
    ) { }

    transform(property: any): any {
        const currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
        let address = '';
        if (!isEmpty(property.address) && !isEmpty(property.address.formatted_address)) {
            address = property.address.formatted_address;
        }
        if (isEmpty(address) && !isEmpty(property.address) && isString(property.address)) {
            address = property.address;
        }
        if (isEmpty(address)) {
            let location = '';
            let city = '';
            if (!isEmpty(property.property_location) && !isEmpty(property.property_location.value) && isString(property.property_location.value[currentLang])) {
                location = property.property_location.value[currentLang];
            } else if (!isEmpty(property.property_location) && isString(property.property_location[currentLang])) {
                location = property.property_location[currentLang];
            }
            if (!isEmpty(property.property_city) && !isEmpty(property.property_city.value) && isString(property.property_city.value[currentLang])) {
                city = property.property_city.value[currentLang];
            } else if (!isEmpty(property.property_city) && isString(property.property_city[currentLang])) {
                city = property.property_city[currentLang];
            }
            address = location + ' - ' + city;
        }
        return address;
    }

}
