import { Component, OnInit, ViewEncapsulation, AfterViewChecked, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DropdownsService } from 'app/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'owners-advance-search',
    templateUrl: './advance-search.component.html',
    styleUrls: ['./advance-search.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AdvanceSearchComponent implements OnInit, AfterViewChecked {

    currentLang: any;
    searchForm: UntypedFormGroup;

    constructor(
        public matDialogRef: MatDialogRef<AdvanceSearchComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: UntypedFormBuilder,
        private _dropdownsService: DropdownsService,
        private _translateService: TranslateService,
        private cdRef: ChangeDetectorRef
    ) {
        this.currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
        this.searchForm = this.createEventForm();
    }

    ngOnInit(): void {

    }

    createEventForm(): UntypedFormGroup {
        return new UntypedFormGroup({
            id: new UntypedFormControl(),
            status: new UntypedFormControl(),
            home_phone: new UntypedFormControl(),
            work_phone: new UntypedFormControl(),
            mobile: new UntypedFormControl(),
            email: new UntypedFormControl(),
            skype_id: new UntypedFormControl(),
            country: new UntypedFormControl(),
            province: new UntypedFormControl(),
            city: new UntypedFormControl(),
            postcode: new UntypedFormControl(),
            street: new UntypedFormControl(),
            streetnumber: new UntypedFormControl(),
            gdpr_status: new UntypedFormControl(),
            automated_email: new UntypedFormControl(),
            created_date_from: new UntypedFormControl(),
            created_date_to: new UntypedFormControl(),
            last_updated_from: new UntypedFormControl(),
            last_updated_to:  new UntypedFormControl(),
            newsletter:  new UntypedFormControl(),
        });
    }

    search(): void {
        this.matDialogRef.close(this.searchForm);
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }
}
