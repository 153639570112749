import { Directive, Input, ElementRef, Renderer2, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WindowcardsListDialogComponent } from './windowcards-list-dialog/windowcards-list-dialog.component';
import { WindowcardsViewDialogComponent } from './windowcards-view-dialog/windowcards-view-dialog.component';

@Directive({
    selector: '[windowCards]'
})
export class WindowCardsDirective {

    @Input() propertyId: string;
    @Input() type: string;
    @Input() requestFrom: string;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        public _matDialog: MatDialog
    ) {
        this.renderer.setStyle(this.el.nativeElement, 'cursor', 'pointer');
    }

    @HostListener('click') onClick(): void {
        const dialogRef = this._matDialog.open(WindowcardsListDialogComponent, {
            panelClass: 'windowcards-list-dialog',
            data: {},
        });
        dialogRef.afterClosed()
            .subscribe(response => {
                if (response) {
                    this.viewWindowCard(response);
                }
            });
    }

    viewWindowCard(template: any): void {
        const dialogRef = this._matDialog.open(WindowcardsViewDialogComponent, {
            panelClass: 'windowcards-view-dialog',
            data: {
                property_id: this.propertyId,
                template_id: template,
                type: this.type,
                requestFrom: this.requestFrom
            },
        });
        dialogRef.afterClosed()
            .subscribe(response => {
                // console.log(response);
            });
    }

}
