// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-events app-time-picker mat-form-field {
  width: 65px;
}

app-time-picker ngx-material-timepicker .timepicker-overlay {
  z-index: 999999 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/time-picker/time-picker.component.scss"],"names":[],"mappings":"AAEQ;EACI,WAAA;AADZ;;AAQQ;EACI,0BAAA;AALZ","sourcesContent":["app-events {\n    app-time-picker {\n        mat-form-field {\n            width: 65px;\n        }\n    }\n}\n\napp-time-picker {\n    ngx-material-timepicker {\n        .timepicker-overlay {\n            z-index: 999999 !important;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
