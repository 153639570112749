export class CountryDialCodesFakeDb {
    public static dialCodes = [
        {
            name: 'Afghanistan',
            dail: '93',
            code: 'AF'
        },
        {
            name: 'Albania',
            dail: '355',
            code: 'AL'
        },
        {
            name: 'Algeria',
            dail: '213',
            code: 'DZ'
        },
        {
            name: 'American Samoa',
            dail: '1-684',
            code: 'AS'
        },
        {
            name: 'Andorra',
            dail: '376',
            code: 'AD'
        },
        {
            name: 'Angola',
            dail: '244',
            code: 'AO'
        },
        {
            name: 'Anguilla',
            dail: '1-264',
            code: 'AI'
        },
        {
            name: 'Antigua & Barbuda',
            dail: '1-268',
            code: 'AG'
        },
        {
            name: 'Argentina',
            dail: '54',
            code: 'AR'
        },
        {
            name: 'Armenia',
            dail: '374',
            code: 'AM'
        },
        {
            name: 'Aruba',
            dail: '297',
            code: 'AW'
        },
        {
            name: 'Australia',
            dail: '61',
            code: 'AU'
        },
        {
            name: 'Austria',
            dail: '43',
            code: 'AT'
        },
        {
            name: 'Azerbaijan',
            dail: '994',
            code: 'AZ'
        },
        {
            name: 'Bahamas',
            dail: '1-242',
            code: 'BS'
        },
        {
            name: 'Bahrain',
            dail: '973',
            code: 'BH'
        },
        {
            name: 'Bangladesh',
            dail: '880',
            code: 'BD'
        },
        {
            name: 'Barbados',
            dail: '1-246',
            code: 'BB'
        },
        {
            name: 'Belarus',
            dail: '375',
            code: 'BY'
        },
        {
            name: 'Belgium',
            dail: '32',
            code: 'BE'
        },
        {
            name: 'Belize',
            dail: '501',
            code: 'BZ'
        },
        {
            name: 'Benin',
            dail: '229',
            code: 'BJ'
        },
        {
            name: 'Bermuda',
            dail: '1-441',
            code: 'BM'
        },
        {
            name: 'Bhutan',
            dail: '975',
            code: 'BT'
        },
        {
            name: 'Bolivia',
            dail: '591',
            code: 'BO'
        },
        {
            name: 'Bosnia',
            dail: '387',
            code: 'BA'
        },
        {
            name: 'Botswana',
            dail: '267',
            code: 'BW'
        },
        {
            name: 'Brazil',
            dail: '55',
            code: 'BR'
        },
        {
            name: 'British Virgin Islands',
            dail: '1-284',
            code: 'VG'
        },
        {
            name: 'Brunei',
            dail: '673',
            code: 'BN'
        },
        {
            name: 'Bulgaria',
            dail: '359',
            code: 'BG'
        },
        {
            name: 'Burkina Faso',
            dail: '226',
            code: 'BF'
        },
        {
            name: 'Burundi',
            dail: '257',
            code: 'BI'
        },
        {
            name: 'Cambodia',
            dail: '855',
            code: 'KH'
        },
        {
            name: 'Cameroon',
            dail: '237',
            code: 'CM'
        },
        {
            name: 'Canada',
            dail: '1',
            code: 'CA'
        },
        {
            name: 'Cape Verde',
            dail: '238',
            code: 'CV'
        },
        {
            name: 'Caribbean Netherlands',
            dail: '599',
            code: 'BQ'
        },
        {
            name: 'Cayman Islands',
            dail: '1-345',
            code: 'KY'
        },
        {
            name: 'Central African Republic',
            dail: '236',
            code: 'CF'
        },
        {
            name: 'Chad',
            dail: '235',
            code: 'TD'
        },
        {
            name: 'Chile',
            dail: '56',
            code: 'CL'
        },
        {
            name: 'China',
            dail: '86',
            code: 'CN'
        },
        {
            name: 'Colombia',
            dail: '57',
            code: 'CO'
        },
        {
            name: 'Comoros',
            dail: '269',
            code: 'KM'
        },
        {
            name: 'Congo - Brazzaville',
            dail: '242',
            code: 'CG'
        },
        {
            name: 'Congo - Kinshasa',
            dail: '243',
            code: 'CD'
        },
        {
            name: 'Cook Islands',
            dail: '682',
            code: 'CK'
        },
        {
            name: 'Costa Rica',
            dail: '506',
            code: 'CR'
        },
        {
            name: 'Croatia',
            dail: '385',
            code: 'HR'
        },
        {
            name: 'Cuba',
            dail: '53',
            code: 'CU'
        },
        {
            name: 'Curaçao',
            dail: '599',
            code: 'CW'
        },
        {
            name: 'Cyprus',
            dail: '357',
            code: 'CY'
        },
        {
            name: 'Czech Republic',
            dail: '420',
            code: 'CZ'
        },
        {
            name: 'Côte d\'Ivoire',
            dail: '225',
            code: 'CI'
        },
        {
            name: 'Denmark',
            dail: '45',
            code: 'DK'
        },
        {
            name: 'Djibouti',
            dail: '253',
            code: 'DJ'
        },
        {
            name: 'Dominica',
            dail: '1-767',
            code: 'DM'
        },
        {
            name: 'Dominican Republic',
            dail: '1-809,1-829,1-849',
            code: 'DO'
        },
        {
            name: 'Ecuador',
            dail: '593',
            code: 'EC'
        },
        {
            name: 'Egypt',
            dail: '20',
            code: 'EG'
        },
        {
            name: 'El Salvador',
            dail: '503',
            code: 'SV'
        },
        {
            name: 'Equatorial Guinea',
            dail: '240',
            code: 'GQ'
        },
        {
            name: 'Eritrea',
            dail: '291',
            code: 'ER'
        },
        {
            name: 'Estonia',
            dail: '372',
            code: 'EE'
        },
        {
            name: 'Ethiopia',
            dail: '251',
            code: 'ET'
        },
        {
            name: 'Falkland Islands',
            dail: '500',
            code: 'FK'
        },
        {
            name: 'Faroe Islands',
            dail: '298',
            code: 'FO'
        },
        {
            name: 'Fiji',
            dail: '679',
            code: 'FJ'
        },
        {
            name: 'Finland',
            dail: '358',
            code: 'FI'
        },
        {
            name: 'France',
            dail: '33',
            code: 'FR'
        },
        {
            name: 'French Guiana',
            dail: '594',
            code: 'GF'
        },
        {
            name: 'French Polynesia',
            dail: '689',
            code: 'PF'
        },
        {
            name: 'Gabon',
            dail: '241',
            code: 'GA'
        },
        {
            name: 'Gambia',
            dail: '220',
            code: 'GM'
        },
        {
            name: 'Georgia',
            dail: '995',
            code: 'GE'
        },
        {
            name: 'Germany',
            dail: '49',
            code: 'DE'
        },
        {
            name: 'Ghana',
            dail: '233',
            code: 'GH'
        },
        {
            name: 'Gibraltar',
            dail: '350',
            code: 'GI'
        },
        {
            name: 'Greece',
            dail: '30',
            code: 'GR'
        },
        {
            name: 'Greenland',
            dail: '299',
            code: 'GL'
        },
        {
            name: 'Grenada',
            dail: '1-473',
            code: 'GD'
        },
        {
            name: 'Guadeloupe',
            dail: '590',
            code: 'GP'
        },
        {
            name: 'Guam',
            dail: '1-671',
            code: 'GU'
        },
        {
            name: 'Guatemala',
            dail: '502',
            code: 'GT'
        },
        {
            name: 'Guernsey',
            dail: '44',
            code: 'GG'
        },
        {
            name: 'Guinea',
            dail: '224',
            code: 'GN'
        },
        {
            name: 'Guinea-Bissau',
            dail: '245',
            code: 'GW'
        },
        {
            name: 'Guyana',
            dail: '592',
            code: 'GY'
        },
        {
            name: 'Haiti',
            dail: '509',
            code: 'HT'
        },
        {
            name: 'Honduras',
            dail: '504',
            code: 'HN'
        },
        {
            name: 'Hong Kong',
            dail: '852',
            code: 'HK'
        },
        {
            name: 'Hungary',
            dail: '36',
            code: 'HU'
        },
        {
            name: 'Iceland',
            dail: '354',
            code: 'IS'
        },
        {
            name: 'India',
            dail: '91',
            code: 'IN'
        },
        {
            name: 'Indonesia',
            dail: '62',
            code: 'ID'
        },
        {
            name: 'Iran',
            dail: '98',
            code: 'IR'
        },
        {
            name: 'Iraq',
            dail: '964',
            code: 'IQ'
        },
        {
            name: 'Ireland',
            dail: '353',
            code: 'IE'
        },
        {
            name: 'Isle of Man',
            dail: '44',
            code: 'IM'
        },
        {
            name: 'Israel',
            dail: '972',
            code: 'IL'
        },
        {
            name: 'Italy',
            dail: '39',
            code: 'IT'
        },
        {
            name: 'Jamaica',
            dail: '1-876',
            code: 'JM'
        },
        {
            name: 'Japan',
            dail: '81',
            code: 'JP'
        },
        {
            name: 'Jersey',
            dail: '44',
            code: 'JE'
        },
        {
            name: 'Jordan',
            dail: '962',
            code: 'JO'
        },
        {
            name: 'Kazakhstan',
            dail: '7',
            code: 'KZ'
        },
        {
            name: 'Kenya',
            dail: '254',
            code: 'KE'
        },
        {
            name: 'Kiribati',
            dail: '686',
            code: 'KI'
        },
        {
            name: 'Kuwait',
            dail: '965',
            code: 'KW'
        },
        {
            name: 'Kyrgyzstan',
            dail: '996',
            code: 'KG'
        },
        {
            name: 'Laos',
            dail: '856',
            code: 'LA'
        },
        {
            name: 'Latvia',
            dail: '371',
            code: 'LV'
        },
        {
            name: 'Lebanon',
            dail: '961',
            code: 'LB'
        },
        {
            name: 'Lesotho',
            dail: '266',
            code: 'LS'
        },
        {
            name: 'Liberia',
            dail: '231',
            code: 'LR'
        },
        {
            name: 'Libya',
            dail: '218',
            code: 'LY'
        },
        {
            name: 'Liechtenstein',
            dail: '423',
            code: 'LI'
        },
        {
            name: 'Lithuania',
            dail: '370',
            code: 'LT'
        },
        {
            name: 'Luxembourg',
            dail: '352',
            code: 'LU'
        },
        {
            name: 'Macau',
            dail: '853',
            code: 'MO'
        },
        {
            name: 'Macedonia',
            dail: '389',
            code: 'MK'
        },
        {
            name: 'Madagascar',
            dail: '261',
            code: 'MG'
        },
        {
            name: 'Malawi',
            dail: '265',
            code: 'MW'
        },
        {
            name: 'Malaysia',
            dail: '60',
            code: 'MY'
        },
        {
            name: 'Maldives',
            dail: '960',
            code: 'MV'
        },
        {
            name: 'Mali',
            dail: '223',
            code: 'ML'
        },
        {
            name: 'Malta',
            dail: '356',
            code: 'MT'
        },
        {
            name: 'Marshall Islands',
            dail: '692',
            code: 'MH'
        },
        {
            name: 'Martinique',
            dail: '596',
            code: 'MQ'
        },
        {
            name: 'Mauritania',
            dail: '222',
            code: 'MR'
        },
        {
            name: 'Mauritius',
            dail: '230',
            code: 'MU'
        },
        {
            name: 'Mayotte',
            dail: '262',
            code: 'YT'
        },
        {
            name: 'Mexico',
            dail: '52',
            code: 'MX'
        },
        {
            name: 'Micronesia',
            dail: '691',
            code: 'FM'
        },
        {
            name: 'Moldova',
            dail: '373',
            code: 'MD'
        },
        {
            name: 'Monaco',
            dail: '377',
            code: 'MC'
        },
        {
            name: 'Mongolia',
            dail: '976',
            code: 'MN'
        },
        {
            name: 'Montenegro',
            dail: '382',
            code: 'ME'
        },
        {
            name: 'Montserrat',
            dail: '1-664',
            code: 'MS'
        },
        {
            name: 'Morocco',
            dail: '212',
            code: 'MA'
        },
        {
            name: 'Mozambique',
            dail: '258',
            code: 'MZ'
        },
        {
            name: 'Myanmar',
            dail: '95',
            code: 'MM'
        },
        {
            name: 'Namibia',
            dail: '264',
            code: 'NA'
        },
        {
            name: 'Nauru',
            dail: '674',
            code: 'NR'
        },
        {
            name: 'Nepal',
            dail: '977',
            code: 'NP'
        },
        {
            name: 'Netherlands',
            dail: '31',
            code: 'NL'
        },
        {
            name: 'New Caledonia',
            dail: '687',
            code: 'NC'
        },
        {
            name: 'New Zealand',
            dail: '64',
            code: 'NZ'
        },
        {
            name: 'Nicaragua',
            dail: '505',
            code: 'NI'
        },
        {
            name: 'Niger',
            dail: '227',
            code: 'NE'
        },
        {
            name: 'Nigeria',
            dail: '234',
            code: 'NG'
        },
        {
            name: 'Niue',
            dail: '683',
            code: 'NU'
        },
        {
            name: 'Norfolk Island',
            dail: '672',
            code: 'NF'
        },
        {
            name: 'North Korea',
            dail: '850',
            code: 'KP'
        },
        {
            name: 'Northern Mariana Islands',
            dail: '1-670',
            code: 'MP'
        },
        {
            name: 'Norway',
            dail: '47',
            code: 'NO'
        },
        {
            name: 'Oman',
            dail: '968',
            code: 'OM'
        },
        {
            name: 'Pakistan',
            dail: '92',
            code: 'PK'
        },
        {
            name: 'Palau',
            dail: '680',
            code: 'PW'
        },
        {
            name: 'Palestine',
            dail: '970',
            code: 'PS'
        },
        {
            name: 'Panama',
            dail: '507',
            code: 'PA'
        },
        {
            name: 'Papua New Guinea',
            dail: '675',
            code: 'PG'
        },
        {
            name: 'Paraguay',
            dail: '595',
            code: 'PY'
        },
        {
            name: 'Peru',
            dail: '51',
            code: 'PE'
        },
        {
            name: 'Philippines',
            dail: '63',
            code: 'PH'
        },
        {
            name: 'Pitcairn Islands',
            dail: '870',
            code: 'PN'
        },
        {
            name: 'Poland',
            dail: '48',
            code: 'PL'
        },
        {
            name: 'Portugal',
            dail: '351',
            code: 'PT'
        },
        {
            name: 'Puerto Rico',
            dail: '1',
            code: 'PR'
        },
        {
            name: 'Qatar',
            dail: '974',
            code: 'QA'
        },
        {
            name: 'Romania',
            dail: '40',
            code: 'RO'
        },
        {
            name: 'Russia',
            dail: '7',
            code: 'RU'
        },
        {
            name: 'Rwanda',
            dail: '250',
            code: 'RW'
        },
        {
            name: 'Réunion',
            dail: '262',
            code: 'RE'
        },
        {
            name: 'Samoa',
            dail: '685',
            code: 'WS'
        },
        {
            name: 'San Marino',
            dail: '378',
            code: 'SM'
        },
        {
            name: 'Saudi Arabia',
            dail: '966',
            code: 'SA'
        },
        {
            name: 'Senegal',
            dail: '221',
            code: 'SN'
        },
        {
            name: 'Serbia',
            dail: '381 p',
            code: 'RS'
        },
        {
            name: 'Seychelles',
            dail: '248',
            code: 'SC'
        },
        {
            name: 'Sierra Leone',
            dail: '232',
            code: 'SL'
        },
        {
            name: 'Singapore',
            dail: '65',
            code: 'SG'
        },
        {
            name: 'Sint Maarten',
            dail: '1-721',
            code: 'SX'
        },
        {
            name: 'Slovakia',
            dail: '421',
            code: 'SK'
        },
        {
            name: 'Slovenia',
            dail: '386',
            code: 'SI'
        },
        {
            name: 'Solomon Islands',
            dail: '677',
            code: 'SB'
        },
        {
            name: 'Somalia',
            dail: '252',
            code: 'SO'
        },
        {
            name: 'South Africa',
            dail: '27',
            code: 'ZA'
        },
        {
            name: 'South Korea',
            dail: '82',
            code: 'KR'
        },
        {
            name: 'South Sudan',
            dail: '211',
            code: 'SS'
        },
        {
            name: 'Spain',
            dail: '34',
            code: 'ES'
        },
        {
            name: 'Sri Lanka',
            dail: '94',
            code: 'LK'
        },
        {
            name: 'Saint Barthélemy',
            dail: '590',
            code: 'BL'
        },
        {
            name: 'St. Helena',
            dail: '290 n',
            code: 'SH'
        },
        {
            name: 'St. Kitts & Nevis',
            dail: '1-869',
            code: 'KN'
        },
        {
            name: 'St. Lucia',
            dail: '1-758',
            code: 'LC'
        },
        {
            name: 'St. Martin',
            dail: '590',
            code: 'MF'
        },
        {
            name: 'St. Pierre & Miquelon',
            dail: '508',
            code: 'PM'
        },
        {
            name: 'St. Vincent & Grenadines',
            dail: '1-784',
            code: 'VC'
        },
        {
            name: 'Sudan',
            dail: '249',
            code: 'SD'
        },
        {
            name: 'Suriname',
            dail: '597',
            code: 'SR'
        },
        {
            name: 'Svalbard & Jan Mayen',
            dail: '47',
            code: 'SJ'
        },
        {
            name: 'Swaziland',
            dail: '268',
            code: 'SZ'
        },
        {
            name: 'Sweden',
            dail: '46',
            code: 'SE'
        },
        {
            name: 'Switzerland',
            dail: '41',
            code: 'CH'
        },
        {
            name: 'Syria',
            dail: '963',
            code: 'SY'
        },
        {
            name: 'Sao Tome and Principe',
            dail: '239',
            code: 'ST'
        },
        {
            name: 'Tajikistan',
            dail: '992',
            code: 'TJ'
        },
        {
            name: 'Tanzania',
            dail: '255',
            code: 'TZ'
        },
        {
            name: 'Thailand',
            dail: '66',
            code: 'TH'
        },
        {
            name: 'Timor-Leste',
            dail: '670',
            code: 'TL'
        },
        {
            name: 'Togo',
            dail: '228',
            code: 'TG'
        },
        {
            name: 'Tokelau',
            dail: '690',
            code: 'TK'
        },
        {
            name: 'Tonga',
            dail: '676',
            code: 'TO'
        },
        {
            name: 'Trinidad & Tobago',
            dail: '1-868',
            code: 'TT'
        },
        {
            name: 'Tunisia',
            dail: '216',
            code: 'TN'
        },
        {
            name: 'Turkey',
            dail: '90',
            code: 'TR'
        },
        {
            name: 'Turkmenistan',
            dail: '993',
            code: 'TM'
        },
        {
            name: 'Turks & Caicos Islands',
            dail: '1-649',
            code: 'TC'
        },
        {
            name: 'Tuvalu',
            dail: '688',
            code: 'TV'
        },
        {
            name: 'U.S. Virgin Islands',
            dail: '1-340',
            code: 'VI'
        },
        {
            name: 'United Kingdom',
            dail: '44',
            code: 'GB'
        },
        {
            name: 'US',
            dail: '1',
            code: 'US'
        },
        {
            name: 'Uganda',
            dail: '256',
            code: 'UG'
        },
        {
            name: 'Ukraine',
            dail: '380',
            code: 'UA'
        },
        {
            name: 'United Arab Emirates',
            dail: '971',
            code: 'AE'
        },
        {
            name: 'Uruguay',
            dail: '598',
            code: 'UY'
        },
        {
            name: 'Uzbekistan',
            dail: '998',
            code: 'UZ'
        },
        {
            name: 'Vanuatu',
            dail: '678',
            code: 'VU'
        },
        {
            name: 'Vatican City',
            dail: '39-06',
            code: 'VA'
        },
        {
            name: 'Venezuela',
            dail: '58',
            code: 'VE'
        },
        {
            name: 'Vietnam',
            dail: '84',
            code: 'VN'
        },
        {
            name: 'Wallis & Futuna',
            dail: '681',
            code: 'WF'
        },
        {
            name: 'Western Sahara',
            dail: '212',
            code: 'EH'
        },
        {
            name: 'Yemen',
            dail: '967',
            code: 'YE'
        },
        {
            name: 'Zambia',
            dail: '260',
            code: 'ZM'
        },
        {
            name: 'Zimbabwe',
            dail: '263',
            code: 'ZW'
        },
        {
            name: 'Ãland Islands',
            dail: '358',
            code: 'AX'
        }
    ];
}
