import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenerateRegisterTokenComponent } from './generate-register-token.component';

import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { FuseSharedModule } from '@fuse/shared.module';
import { FormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [GenerateRegisterTokenComponent],
  imports: [
    CommonModule,
    FuseSharedModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatSnackBarModule
  ],
  exports: [GenerateRegisterTokenComponent]
})
export class GenerateRegisterTokenModule { }
