import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { FuseSharedModule } from '@fuse/shared.module';
import { TimelineComponent } from './timeline.component';
import { PostComponent } from './post/post.component';
import { CommentComponent } from './comment/comment.component';
import { LoadersModule } from '../../../../shared/modules/loaders/loaders.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ReportComponent } from './report/report.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LightboxModule } from 'ngx-lightbox';
import { NgImageSliderModule } from 'ng-image-slider-v16';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ShareModalComponent } from './share-modal/share-modal.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
    declarations: [TimelineComponent, PostComponent, CommentComponent, ReportComponent, ShareModalComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatTabsModule,
        FuseSharedModule,
        MatMenuModule,
        LoadersModule,
        MatDialogModule,
        MatChipsModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatToolbarModule,
        MatInputModule,
        MatTooltipModule,
        LightboxModule,
        NgImageSliderModule,
        InfiniteScrollModule,
        ColorPickerModule,
        MatSelectModule
    ],
    exports: [TimelineComponent, PostComponent, CommentComponent, ReportComponent]
})
export class TimeLineModule { }
