import { Component } from '@angular/core';
import { startWith } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    date: Date;
    systemLang: any;
    /**
     * Constructor
     */
    constructor(
        private _translateService: TranslateService,

    ) {
        this.date = new Date();
        _translateService.onLangChange.pipe(startWith('')).subscribe(() => {
            if (this._translateService.currentLang === 'es_AR'){
                this.systemLang = 'es';
            } else if (this._translateService.currentLang === 'en_US'){
                this.systemLang = 'en';
            } else {
                this.systemLang = this._translateService.currentLang;
            }
        });
    }
}
