import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(private http: HttpClient) { }

  getAccountsProperties(id: any, page: any): Observable<any[]> {
    // tslint:disable-next-line: max-line-length
    return this.http.get<any[]>(`${environment.yiiUrl}accounts/get-properties?account_id=${id}&expand=images,property_type,property_location&page=${page}`, { observe: 'response' as 'body' });
  }

  getOwnersProperties(id: any, page: any): Observable<any[]> {
    // tslint:disable-next-line: max-line-length
    return this.http.get<any[]>(`${environment.yiiUrl}owners/get-properties?owner_id=${id}&expand=images,property_type,property_location&page=${page}`, { observe: 'response' as 'body' });
  }

  getCompaniesProperties(id: any, page: any): Observable<any[]> {
    // tslint:disable-next-line: max-line-length
    return this.http.get<any[]>(`${environment.yiiUrl}companies/get-properties?company_id=${id}&expand=images,property_type,property_location&page=${page}`, { observe: 'response' as 'body' });
  }

  updateProperty(data: any): Observable<any[]> {
    return this.http.put<any[]>(`${environment.yiiUrl}properties/${data._id}`, data, { observe: 'response' as 'body' });
  }

  getAccountsActivities(id: any, page: any): Observable<any[]> {
    return this.http.get<any[]>(`${environment.yiiUrl}accounts/get-activities?account_id=${id}&expand=users&page=${page}`, { observe: 'response' as 'body' });
  }

  getOwnersActivities(id: any, page: any): Observable<any[]> {
    return this.http.get<any[]>(`${environment.yiiUrl}owners/get-activities?owner_id=${id}&expand=users&page=${page}`, { observe: 'response' as 'body' });
  }

  getCompaniesActivities(id: any, page: any): Observable<any[]> {
    return this.http.get<any[]>(`${environment.yiiUrl}companies/get-activities?company_id=${id}&expand=users&page=${page}`, { observe: 'response' as 'body' });
  }

  deleteActivity(id: any, createdBy: any, agency: any): Observable<any[]> {
    return this.http.delete<any[]>(`${environment.yiiUrl}activities/${id}?created_by=${createdBy}&agency=${agency}`);
  }

  getOffers(id: any, page: any, field: any, order: any): Observable<any[]> {
    // tslint:disable-next-line: max-line-length
    return this.http.get<any[]>(`${environment.yiiUrl}property-offers?account_id=${id}&page=${page}&sort[]=${field}&sort[]=${order}&expand=property_data,account_data,agency_data,offers,c_offers`,
      { observe: 'response' as 'body' });
  }

  getAccountBookings(id: any, page: any): Observable<any[]> {
    return this.http.get<any[]>(`${environment.yiiUrl}accounts/get-account-bookings?page=${page}&id=${id}`, { observe: 'response' as 'body' });
  }

  getAccountsSales(id: any, page: any): Observable<any[]> {
    return this.http.get<any[]>(`${environment.yiiUrl}accounts/get-sales?account_id=${id}&expand=property_data,listing_agency&page=${page}`, { observe: 'response' as 'body' });
  }

  getOwnersSales(id: any, page: any): Observable<any[]> {
    return this.http.get<any[]>(`${environment.yiiUrl}owners/get-sales?owner_id=${id}&expand=property_data,listing_agency&page=${page}`, { observe: 'response' as 'body' });
  }

  getCompaniesSales(id: any, page: any): Observable<any[]> {
    return this.http.get<any[]>(`${environment.yiiUrl}companies/get-sales?company_id=${id}&expand=property_data,listing_agency&page=${page}`, { observe: 'response' as 'body' });
  }

  deleteSales(id: any, createdBy: any): Observable<any[]> {
    return this.http.delete<any[]>(`${environment.yiiUrl}sales/${id}?created_by=${createdBy}`);
  }

  getRelatedEmails(id: any, page: any, type: string): Observable<any[]> {
    return this.http.get<any[]>(`${environment.yiiUrl}${type}/get-related-emails?id=${id}&page=${page}&expand=properties_data`, { observe: 'response' as 'body' });
  }

  getAccountsAccounts(id: any, page: any): Observable<any[]> {
    return this.http.get<any[]>(`${environment.yiiUrl}owners/get-accounts?owner_id=${id}&expand=users&page=${page}`, { observe: 'response' as 'body' });
  }

  getCompaniesAccounts(id: any, page: any): Observable<any[]> {
    return this.http.get<any[]>(`${environment.yiiUrl}companies/get-accounts?company_id=${id}&expand=users&page=${page}`, { observe: 'response' as 'body' });
  }

  deleteAccount(id: string, createdBy: string): Observable<any[]> {
    return this.http.delete<any[]>(`${environment.yiiUrl}/accounts/${id}?created_by=${createdBy}`);
  }

  getMailboxEmails(type: string, id: string): Observable<any[]> {
    return this.http.get<any[]>(`${environment.yiiUrl}${type}/mail-box-emails?id=${id}`);
  }
}
