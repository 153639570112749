import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { Owners } from 'app/models/owners';

@Injectable({
  providedIn: 'root'
})
export class OwnersService {

  constructor(
    private http: HttpClient,
  ) { }

  getAll(data: any, page: any, perPage?: any, module=''): Observable<object> {
    page = page ? page : 1;
    perPage = perPage ? perPage : 10;
    // tslint:disable-next-line: max-line-length 
    if(module !== 'commercial_properties'){
      module = 'properties_data';
    }else{
      module = 'commercial_properties_data';
    }
    return this.http.post<object>(`${environment.yiiUrl}owners/search?expand=account,${module},moorings_data,boats_data,u_agency_data&page=${page}&per-page=${perPage}`, data, { observe: 'response' as 'body' })
      .pipe(map(owners => {
        return owners;
      }));
  }
  getById(_id: string): Observable<object> {
    return this.http.get(`${environment.yiiUrl}owners/${_id}?expand=account,linked_users_data`);
  }

  create(owner: Owners): Observable<object> {
    return this.http.post(`${environment.yiiUrl}owners`, owner);
  }

  update(owner: any): Observable<object> {
    return this.http.put(`${environment.yiiUrl}owners/${owner._id}`, owner);
  }

  delete(_id: number): Observable<object> {
    return this.http.delete(`${environment.yiiUrl}owners/${_id}`);
  }

  archiveOwner(_id: number): Observable<object> {
    return this.http.get(`${environment.yiiUrl}owners/delete-owner?owner_id=${_id}`);
  }

  searchAgencies(page: string, data: any): Observable<Owners[]> {
    return this.http.post<Owners[]>(`${environment.yiiUrl}owners/search?page=` + page, data, { observe: 'response' as 'body' })
      .pipe(map(owners => {
        return owners;
      }));
  }

  exportCSV(data: any): Observable<any> {
    return this.http.post(`${environment.yiiUrl}xml-export-formats/get-owners-export-csv`, data);
  }

  exportTimelineCSV(data: any): Observable<any> {
    data = { ...data, model: 'Owners' };
    return this.http.post(`${environment.yiiUrl}xml-export-formats/export-timeline-csv`, data);
  }

  getOwnerFields(): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}get-schema-field`, { schema_name: 'owners' });
  }

  exportXLS(selectedOwners: any, selectedTabs: any): Observable<any> {
    const filter = {
      field: selectedTabs,
      ids: selectedOwners,
      schema_name: 'owners'
    };
    return this.http.post<any>(`${environment.apiUrl}exports_xls`, filter, { observe: 'response' as 'body' });
  }

  updateOwnersStatus(selectedOwners: any): Observable<any> {
    return this.http.post<any[]>(`${environment.yiiUrl}owners/update-all-owners-status`, selectedOwners);
  }

  getArchiveOwners(filter: any, page?: any, perPage?: any): Observable<any> {
    page = page ? page : 1;
    perPage = perPage ? perPage : 10;
    return this.http.post<any>(`${environment.yiiUrl}owners/deleted-owners?page=${page}&per-page=${perPage}`, filter, { observe: 'response' as 'body' });
  }

  restoreArchiveOwner(_id: number, data: any): Observable<object> {
    return this.http.put(`${environment.yiiUrl}owners/${_id}`, data);
  }

  deleteArchiveOwner(_id: number, createdby: any, agency: any): Observable<object> {
    return this.http.delete(`${environment.yiiUrl}owners/${_id}?created_by=${createdby}&agency=${agency}`);
  }

  restoreSelectedOwners(filter: any): Observable<any> {
    return this.http.post<any>(`${environment.yiiUrl}owners/restore-selected`, filter);
  }

  deleteSeletedArchiveOwners(filter: any): Observable<object> {
    return this.http.post(`${environment.yiiUrl}owners/delete-selection`, filter);
  }

  restoreAll(agency: any): Observable<object> {
    return this.http.get(`${environment.yiiUrl}owners/restore-all?agency=${agency}`);
  }

  deleteAll(agency: any): Observable<object> {
    return this.http.delete(`${environment.yiiUrl}owners/delete-all?agency=${agency}`);
  }

  getPropertiesOwner(data: any): Observable<object> {
    // tslint:disable-next-line: max-line-length
    return this.http.get(`${environment.yiiUrl}properties/load-propertiesbyowner?expand=agency_logo,listing_agent_name,property_location,property_type&owner=${data._id}&properties=${data.properties}`);
  }
  insertCoustomAccount(data: any): Observable<object> {
    return this.http.post(`${environment.yiiUrl}accounts/insert`, data);
  }

  exportAttachments(data: any): Observable<any> {
    return this.http.post(`${environment.yiiUrl}owners/export-attachments-xml`, data);
  }

  massUpdate(data: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}owners/mass-update`, data);
  }
}

