import { Component, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';

/**
 * Overlay Component
 *
 * How to use?
 *
 * <shared-box-loader *ngIf="!loaded"> </shared-box-loader>
 */
@Component({
  selector: 'shared-box-loader',
  templateUrl: './box-loader.component.html',
  styleUrls: ['./box-loader.component.scss'],
  animations: fuseAnimations
})
export class BoxLoaderComponent {

  // @Input() show: boolean = true;
  @Input() diameter = 50;
  
  constructor() { }
}
