import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'create-list',
    templateUrl: './create-list.component.html',
    styleUrls: ['./create-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CreateListComponent implements OnInit {

    title: string;

    constructor(
        public matDialogRef: MatDialogRef<CreateListComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
    ) { }

    ngOnInit(): void {
    }

}
