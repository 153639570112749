import {
    Directive,
    Input,
    ElementRef,
    Renderer2,
    HostListener,
} from '@angular/core';
import { isEmpty, indexOf } from 'lodash';
import { AuthenticationService } from 'app/services';
import { MatDialog } from '@angular/material/dialog';
import { FormDialogComponent } from './form-dialog/form-dialog.component';

@Directive({
    selector: '[editable-content]',
})
export class EditableContentDirective {
    editMode: boolean = false;

    constructor(
        private _eRef: ElementRef,
        private _renderer: Renderer2,
        public _matDialog: MatDialog
    ) {}

    @HostListener('mouseover') onMouseOver(): void {
        this._renderer.setStyle(
            this._eRef.nativeElement,
            'border',
            '1px dashed'
        );
    }
    @HostListener('mouseout') onMouseOut(): void {
        this._renderer.setStyle(this._eRef.nativeElement, 'border', 'unset');
    }
    @HostListener('click') onClick(): void {
        this.editMode = true;
        const dialogRef = this._matDialog.open(FormDialogComponent, {
            disableClose: true,
            panelClass: 'custom-formly-dialog',
            data: this._eRef.nativeElement.innerHTML,
        });
        dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                this._renderer.setProperty(
                    this._eRef.nativeElement,
                    'innerHTML',
                    response
                );
            }
        });
    }
}
