import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrawMapComponent } from './draw-map.component';
// import { AgmCoreModule } from '@agm/core';
import { GoogleMapsModule } from '@angular/google-maps'
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';



@NgModule({
    declarations: [DrawMapComponent],
    imports: [
        CommonModule,
        GoogleMapsModule,
        // AgmCoreModule.forRoot({
        //     apiKey: 'AIzaSyB9C0iVNLS5_igud0vkq-_7wQTtMbmRgeI',
        //     libraries: ['places', 'drawing', 'geometry'],
        // }),
        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        TranslateModule,
        FlexLayoutModule,
        FuseDirectivesModule
    ],
    exports: [DrawMapComponent]
})
export class DrawMapModule { }
