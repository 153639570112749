import { Component, OnInit, Inject, ViewEncapsulation, ElementRef, ViewChild, OnDestroy, SecurityContext } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable, Subject, forkJoin } from 'rxjs';
import { startWith, takeUntil, switchMap, tap, filter, map } from 'rxjs/operators';
import { DropdownsService, AuthenticationService, UserService } from 'app/services';
import * as _ from 'lodash';
import { AttachmentsService } from 'app/services/attachments.service';
import { TranslateService } from '@ngx-translate/core';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { EmailsService } from 'app/services/emails.service';
import { PreviewEmailComponent } from '../preview-email/preview-email.component';
import { EmailDraftsComponent } from '../email-drafts/email-drafts.component';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatChipInputEvent } from '@angular/material/chips';
import { MailBoxService } from 'app/services/mail-box.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AccountsService } from 'app/services/accounts.service';
import { forEach, isArray, includes, isEmpty } from 'lodash';
import { BaseService } from 'app/_helpers/base/base.service';
import { environment } from 'environments/environment';
import { GLOBALS } from 'app/config/globals';
import { ViewingToursService } from 'app/services/viewing-tours.service';
import { ComposeEmailsService } from 'app/services/compose-emails.service';


@Component({
    selector: 'compose-dialog',
    templateUrl: './compose-dialog.component.html',
    styleUrls: ['./compose-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ComposeDialogComponent implements OnInit, OnDestroy {
    showExtraToFields: boolean;
    composeForm: UntypedFormGroup;

    separatorKeysCodes: number[] = [ENTER, COMMA];
    emailsCtrl = new UntypedFormControl('');
    filteredEmials: Observable<string[]>;
    templatesCtrl = new UntypedFormControl('');
    filteredTemplates: Observable<any>;
    emails: string[] = [];
    attachments: string[] = [];
    viewRecord = false as boolean;
    data: any;
    cardData: any;
    loadingResults = false as boolean;
    headerInfo: any;
    activeEditor: any;
    filter: any = {};
    currentUser: any;
    templateId: any;
    isDataloaded = false as boolean;

    @ViewChild('emailInput', { static: false }) emailInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

    public tinyMc = GLOBALS.tinyMce; // To use centralized tinyMce
    private _unsubscribeAll: Subject<any>;
    previewData: {};
    sending: boolean;
    constructor(
        public matDialogRef: MatDialogRef<ComposeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _dropdownsService: DropdownsService,
        private _authenticationService: AuthenticationService,
        private _attachmentsService: AttachmentsService,
        private _emailsService: EmailsService,
        private _translateService: TranslateService,
        public _mailBoxService: MailBoxService,
        public _matDialog: MatDialog,
        private _userService: UserService,
        public sanitizer: DomSanitizer,
        private _snackBar: MatSnackBar,
        private _accountsService: AccountsService,
        private _gService: BaseService,
        private _viewingToursService: ViewingToursService,
        public _composeEmailsService: ComposeEmailsService,
    ) {
        this.tinyMc.setup = (editor) => {
            editor.on('init', (e) => {
                this.activeEditor = editor;
            });
        };
        this._unsubscribeAll = new Subject();
        this.viewRecord = true;
        this.currentUser = this._authenticationService.currentUserValue;
        if (this._data && this._data.to) {
            // this.emails = this._data.to;
            if (isArray(this._data.to)) {
                let dataObj = {} as any;
                forEach(this._data.to, (val: any) => {
                    if (val) {
                        dataObj.org_email = val;
                        if (this.currentUser.user_role === 'agent' || this.currentUser.user_role === 'agent_medium' || this.currentUser.user_role === 'admin_agent_light') {
                            if (includes(this._data.assigned_emails, val)) {
                                dataObj.masked_email = val;
                            } else {
                                const hide = val.split('@')[0].length - 2;
                                const replaced = new RegExp('.{' + hide + '}@', 'g');
                                dataObj.masked_email = val.replace(replaced, '***@');
                            }
                        } else {
                            dataObj.masked_email = val;
                        }
                        this.emails.push(dataObj);
                        dataObj = {};
                    }
                });
            }

        }
        if (this._data) {
            this.data = this._data;
            this.cardData = this._data;
        }
        if (this._data.content) {
            this._data.content = this.sanitizer.sanitize(SecurityContext.HTML, this._data.content);
        }
        this.showExtraToFields = false;

        this.filteredEmials = this.emailsCtrl.valueChanges.pipe(
            startWith(''),
            switchMap(() => {
                return this._dropdownsService.getUserEmails(this.emailsCtrl.value);
            }),
        );
        this.filteredTemplates = this.templatesCtrl.valueChanges.pipe(
            startWith(''),
            switchMap(() => {
                return this._dropdownsService.getEmailsTemplates(this.templatesCtrl.value, this._data.relatedTo);
            }),
        );
    }

    ngOnInit(): void {

        if (this.data.view) {
            this.viewRecord = this.data.view;
            this.composeForm = this.createComposeForm();
            this.composeForm.patchValue(this.data);
        } else {
            this.viewRecord = false;
            this.composeForm = this.createComposeForm();
        }
        if (this._data.content && this._data.content !== '' && this._data.content !== undefined && this._data.relatedTo === 'timeline_emails') {
            this.composeForm.get('content').setValue(this._data.content);
        }
        if (this._data.subject && this._data.subject !== '' && this._data.subject !== undefined && this._data.relatedTo === 'timeline_emails') {
            this.composeForm.get('subject').setValue(this._data.subject);
        }
        if (this._data.content && this._data.content !== '' && this._data.content !== undefined && this._data.relatedTo === 'reply_email') {
            if (this._data.id.from.name && this._data.id.time) {
                this.headerInfo = 'On ' + this._data.id.time + ', ' + this._data.id.from.name + ' wrote: <br/>';
                this._data.content = this.headerInfo + this._data.content;
            }
            this.composeForm.get('content').setValue(this._data.content);
        }
        if (this._data.selectedIds && this._data.selectedIds.length === 0 && this._data.id && this._data.id !== undefined) {
            this._data.selectedIds = [this._data.id];
        }
        if (this._data.subject && this._data.subject !== '' && this._data.subject !== undefined && this._data.relatedTo === 'reply_email') {
            this.composeForm.get('subject').setValue('Re: ' + this._data.subject);
        }
        if (this._data.content && this._data.content !== '' && this._data.content !== undefined && this._data.relatedTo === 'forword_email') {
            this.composeForm.get('content').setValue(this._data.content);
        }
        if (this._data.subject && this._data.subject !== '' && this._data.subject !== undefined && this._data.relatedTo === 'forword_email') {
            this.composeForm.get('subject').setValue('Fwd: ' + this._data.subject);
        }
        if (this._data.relatedTo === 'viewing_tour' && this._data.mailAttachment && this._data.mailAttachment !== '') {
            this._viewingToursService.getSheetPdf(this._data.mailAttachment , true)
                .subscribe((data: any) => {
                    this.attachments = [data.file_name];
                });
        }
        if (this._data.relatedTo === 'offer_download' && this._data.mailAttachment && this._data.mailAttachment !== '') {
            let offerType: any;
            if (this._data.mailAttachment.status === 'Accepted') {
                offerType = 'accepted';
            }
            if (this._data.mailAttachment.status === 'Rejected') {
                offerType = 'rejected';
            }
            if (this._data.mailAttachment.status === 'Countered') {
                offerType = 'generated';
            }
            if (typeof this._data.mailAttachment.status === 'undefined') {
                offerType = 'generated';
            }
            this._data.mailAttachment.send_pdf = true;
            this._gService.post(`pdf-offer/mail?offer_id=${this._data.mailAttachment._id}&offer_type=${offerType}`, this._data.mailAttachment, 'Yii')
                .subscribe((data: any) => {
                this.attachments = [data.file_name];
        });
        }
        this.isDataloaded = true;
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    createComposeForm(): UntypedFormGroup {
        const currentUser = this._authenticationService.currentUserValue;
        let from = currentUser.user_email;
        if (this._data.from) {
            from = this._data.from;
        }
        return new UntypedFormGroup({
            from: new UntypedFormControl({
                value: from,
                disabled: true
            }),
            to: new UntypedFormControl({ value: '', disabled: this.viewRecord }),
            cc: new UntypedFormControl({ value: '', disabled: this.viewRecord }),
            bcc: new UntypedFormControl({ value: '', disabled: this.viewRecord }),
            model: new UntypedFormControl({ value: '', disabled: this.viewRecord }),
            subject: new UntypedFormControl({ value: '', disabled: false }),
            content: new UntypedFormControl({ value: '', disabled: this.viewRecord }),
            files: new UntypedFormControl({ value: '', disabled: this.viewRecord }),
        });
    }

    toggleExtraToFields(): void {
        this.showExtraToFields = !this.showExtraToFields;
    }

    add(event: MatChipInputEvent): void {
        const value = event.value;
        if ((value || '').trim()) {
            if (_.includes(value, '@')) {
                const obj = {} as any;
                obj.org_email = value.trim();
                obj.masked_email = value.trim();
                this.emails.push(obj);
            }
        }
        // Clear the input value
        event.chipInput!.clear();
        
        this.emailsCtrl.setValue('');
    }

    focusOutFunction(): void {
        if (this.emailsCtrl.value) {
            // this.emails.push(this.emailsCtrl.value);
            const obj = {} as any;
            obj.org_email = this.emailsCtrl.value;
            obj.masked_email = this.emailsCtrl.value;
            this.emails.push(obj);
            this.emailInput.nativeElement.value = '';
            this.emailsCtrl.setValue('');
        }
    }

    onSelectEmail(event: MatAutocompleteSelectedEvent): void {
        const obj = {} as any;
        obj.org_email = event.option.value;
        obj.masked_email = event.option.viewValue;
        this.emails.push(obj);
        // this.emails.push(event.option.viewValue);
        this.emailInput.nativeElement.value = '';
        this.emailsCtrl.setValue('');
    }

    remove(fruit: string): void {
        const index = this.emails.indexOf(fruit);
        if (index >= 0) {
            this.emails.splice(index, 1);
        }
    }

    onSelectTemplate(event: any): void {
        this.templateId = event._id;
        this.composeForm.get('subject').setValue(event.subject);
        this.composeForm.get('content').setValue(event.content);
    }

    initializeLink(controls: any, formGroup: any): void {
        controls.initialize();
        const editor = controls.getEditor();
        setTimeout(() => {
            editor.events.on('focus', () => {
                this.activeEditor = editor;
            });
        });
    }
    addSignature(): void {
        this._userService.getById(this._authenticationService.currentUserValue._id).pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data: any) => {
                if (this.activeEditor) {
                    // this.activeEditor.html.insert(data.signature);
                    this.activeEditor.insertContent(data.signature);
                    this._snackBar.open(this._translateService.instant('Signature Added'), this._translateService.instant('Close'), {
                        duration: 2000,
                    });
                }
            });
    }


    // addSignature(): void {
    //     let signature = '';
    //     let content = '';
    //     this._userService.getById(this._authenticationService.currentUserValue._id).pipe(takeUntil(this._unsubscribeAll))
    //         .subscribe((data: any) => {
    //             signature = data.signature;
    //             content = this.composeForm.get('content').value;
    //             content = content + signature;
    //             this.composeForm.get('content').setValue(content);
    //         });
    // }

    closeDialog(action: string): void {
        // if (this.emailsCtrl.value) {
        //     const email = this.emailsCtrl.value;
        //     this.emailsCtrl.setValue('');
        //     this.emails.push(email);
        // }
        // const emails = _.map(this.emails, (e: any) => ({ email: e.org_email }));
        const emails = _.map(this.emails, (e: any) => {
            const data = {} as any;
            if (e.org_email) {
                data.email = e.org_email.toString();
            } else {
                data.email = e.toString();
            }
            return data;
        });

        this.composeForm.get('to').setValue(emails);
        this.composeForm.get('files').setValue(this.attachments);
        if (action === 'draft') {
            const dialog = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: false
            });
            dialog.componentInstance.confirmMessage = this._translateService.instant('Are you sure you want to save mail to draft?');
            dialog.afterClosed()
                .subscribe(result => {
                    if (result) {
                        this._emailsService.saveAsDraft(this.composeForm.getRawValue()).subscribe(() => {
                            this._snackBar.open(this._translateService.instant('Saved to draft'), this._translateService.instant('Close'), {
                                duration: 2000,
                            });
                        });
                    }
                    this.matDialogRef.close();
                });
        }
        else if (action === 'send') {
            this.loadingResults = true;
            this.sending = true;
            this.previewEmail(true);
            let postData = this.composeForm.getRawValue();
            if(this?._data && this._data?.relatedTo && this._data?.relatedTo === 'properties_to_accounts') {
                postData.model =  'properties';
            }
            if(this?._data && this._data?.relatedTo && this._data?.relatedTo === 'commercials_to_accounts') {
                postData.model = 'commercials';
            }

            if(this?._data && this._data?.id) {
                postData.property_id = this._data?.id;
            }

            postData.useAttachedPath = true;

            if(!_.isEmpty(this.previewData)) {
                this._emailsService.previewEmail(this.previewData).pipe(takeUntil(this._unsubscribeAll)).subscribe((content: any) => {
                    postData.content = content.email_content ? content.email_content : content;
                    this._composeEmailsService.sendMailToMailBox(postData).subscribe((data) => {
                        this.sending = false;
                        this._snackBar.open(this._translateService.instant('Send successfully'), this._translateService.instant('Close'), {
                            duration: 2000,
                        });
                        this.loadingResults = false;
                        this.sending = false;
                        this.matDialogRef.close();
                        if (postData && postData.to) {
                            forEach(postData.to, (value) => {
                                this.filter = {};
                                this.filter = { grid: false, status_not: 'inactive' };
                                if (value.email) {
                                    this.filter.all_emails = value.email;
                                }
                                // tslint:disable-next-line: no-shadowed-variable
                                this._accountsService.getAll(this.filter, 1).subscribe((data: any) => {
                                    if (data.body) {
                                        forEach(data.body, (account) => {
                                            if (account) {
                                                this._gService.get(`accounts/updated-at?id=${account._id}`, 'Yii').subscribe();
                                            }
                                        });
                                    }
                                });
                            });
                        }
                    }, () => {
                        this.sending = false;
                        this.loadingResults = false;
                    });
                })
            }else {
                this._composeEmailsService.sendMailToMailBox(postData).subscribe((data) => {
                    this.sending = false;
                    this._snackBar.open(this._translateService.instant('Send successfully'), this._translateService.instant('Close'), {
                        duration: 2000,
                    });
                    this.loadingResults = false;
                    this.matDialogRef.close();
                    if (postData && postData.to) {
                        forEach(postData.to, (value) => {
                            this.filter = {};
                            this.filter = { grid: false, status_not: 'inactive' };
                            if (value.email) {
                                this.filter.all_emails = value.email;
                                this._gService.post(`companies/save-email-logs?email=${value.email}`, postData, '').subscribe();
                            }
                            // tslint:disable-next-line: no-shadowed-variable
                            this._accountsService.getAll(this.filter, 1).subscribe((data: any) => {
                                if (data.body) {
                                    forEach(data.body, (account) => {
                                        if (account) {
                                            this._gService.get(`accounts/updated-at?id=${account._id}`, 'Yii').subscribe();
                                        }
                                    });
                                }
                            });
                        });
                    }
                }, (err: any) => {
                    this.loadingResults = false;
                    this.sending = false;
                });
            }

            /**
             * ********************************************************
             * commenting old logic
             * ********************************************************
             */
            // if (!_.isEmpty(this.previewData)) {
            //     this._emailsService.previewEmail(this.previewData)
            //         .pipe(takeUntil(this._unsubscribeAll))
            //         .subscribe((content: any) => {
            //             /**
            //              * this update referes to https://gitlab.optimasit.com/arsl/optima-crm-v2/-/issues/1070
            //              * content.email_content is object that fix the issue for sending emails to multi accounts
            //              */
            //             postData.content = content.email_content ? content.email_content : content;
            //             this._mailBoxService.sendEmail(postData)
            //                 .subscribe((data) => {
            //                     this.sending = false;
            //                     this._snackBar.open(this._translateService.instant('Send successfully'), this._translateService.instant('Close'), {
            //                         duration: 2000,
            //                     });
            //                     this.loadingResults = false;
            //                     this.sending = false;
            //                     this.matDialogRef.close();
            //                     if (postData && postData.to) {
            //                         forEach(postData.to, (value) => {
            //                             this.filter = {};
            //                             this.filter = { grid: false, status_not: 'inactive' };
            //                             if (value.email) {
            //                                 this.filter.all_emails = value.email;
            //                             }
            //                             // tslint:disable-next-line: no-shadowed-variable
            //                             this._accountsService.getAll(this.filter, 1).subscribe((data: any) => {
            //                                 if (data.body) {
            //                                     forEach(data.body, (account) => {
            //                                         if (account) {
            //                                             this._gService.get(`accounts/updated-at?id=${account._id}`, 'Yii').subscribe();
            //                                         }
            //                                     });
            //                                 }
            //                             });
            //                         });
            //                     }
            //                 }, () => {
            //                     this.sending = false;
            //                     this.loadingResults = false;
            //                 });
            //         }, (err: any) => {
            //             this.loadingResults = false;
            //             this.sending = false;
            //         });
            // } else {
            //     this._mailBoxService.sendEmail(postData)
            //         .subscribe((data) => {
            //             this.sending = false;
            //             this._snackBar.open(this._translateService.instant('Send successfully'), this._translateService.instant('Close'), {
            //                 duration: 2000,
            //             });
            //             this.loadingResults = false;
            //             this.matDialogRef.close();
            //             if (postData && postData.to) {
            //                 forEach(postData.to, (value) => {
            //                     this.filter = {};
            //                     this.filter = { grid: false, status_not: 'inactive' };
            //                     if (value.email) {
            //                         this.filter.all_emails = value.email;
            //                         this._gService.post(`companies/save-email-logs?email=${value.email}`, postData, '').subscribe();
            //                     }
            //                     // tslint:disable-next-line: no-shadowed-variable
            //                     this._accountsService.getAll(this.filter, 1).subscribe((data: any) => {
            //                         if (data.body) {
            //                             forEach(data.body, (account) => {
            //                                 if (account) {
            //                                     this._gService.get(`accounts/updated-at?id=${account._id}`, 'Yii').subscribe();
            //                                 }
            //                             });
            //                         }
            //                     });
            //                 });
            //             }
            //         }, (err: any) => {
            //             this.loadingResults = false;
            //             this.sending = false;
            //         });
            // }

        } else {
            this.matDialogRef.close();
        }
    }

    onSelectFile(files: FileList): void {
        let size = 0;
        _.forEach(files, (file: File) => {
            size = file.size + size;
        });

        if (size <= 5242880) {
            const uploader = [];
            _.forEach(files, (file: File) => {
                uploader.push(this._attachmentsService.mailAttachment(file));
            });
            forkJoin(uploader)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((data: any) => {
                    const attachments = _.map(data, (url) => {
                        let rootUrl = '';
                        if(url.indexOf('my.optima-crm.com') > -1){
                            rootUrl = 'https://my.optima-crm.com';
                        }else if(url.indexOf('dev1.immosurance.net') > -1){
                            rootUrl = 'https://dev1.immosurance.net';
                        }
                        return rootUrl+'/uploads/mail_attachment/'+this.currentDate()+'/'+_.last(_.split(url, '/'));
                    });
                    this.attachments = _.concat(this.attachments, attachments);
                }, () => { });
        } else {
            this._snackBar.open(this._translateService.instant('File size must be less than 5MB'), this._translateService.instant('Close'), {
                duration: 3000,
            });
            _.forEach(files, (file: File) => {
                this.removeAttachment(file);
            });
        }
    }

    removeAttachment(file: any): void {
        _.remove(this.attachments, (el: any) => {
            return el === file;
        });
    }

    selectEmailsFromList(event: any): void {
        this.emails = event;
    }

    previewEmail(getContent?: boolean): void {
        let data = {};
        const emails = _.map(this.emails, (e: any) => {
            let res = {} as any;
            if (e.org_email) {
                res = e.org_email.toString();
            } else {
                res = e.toString();
            }
            return res;
        });
        if (this._data.relatedTo === 'account') {
            data = {
                to: emails,
                from: this.composeForm.get('from').value,
                account: this._data.relatedTo,
                subject: this.composeForm.get('subject').value,
                property_ids: this._data.properties,
                content: this.composeForm.get('content').value
            };
        }

        if (this._data.relatedTo === 'owner') {
            data = {
                to: this.cardData.to,
                to_id: [{ id: this._data.id, email: this.cardData.to }],
                from: this.composeForm.get('from').value,
                owner: this._data.relatedTo,
                subject: this.composeForm.get('subject').value,
                property_ids: this._data.properties,
                files: this.attachments ? this.attachments : [],
                content: this.composeForm.get('content').value
            };
        }
        if (this._data.relatedTo === 'booking') {
            data = {
                to: emails,
                from: this.composeForm.get('from').value,
                booking_id: this._data.id._id,
                account: this._data.id.client,
                subject: this.composeForm.get('subject').value,
                property_id: this._data.id.property,
                content: this.composeForm.get('content').value,
                template_id: this.templateId ? this.templateId : ''
            };
        }

        if (this._data.relatedTo === 'properties_to_accounts' || this._data.relatedTo === 'commercials_to_accounts') {
            data = {
                to: emails,
                from: this.composeForm.get('from').value,
                subject: this.composeForm.get('subject').value,
                property_ids: [this.cardData.id],
                htmlContent: this.composeForm.get('content').value,
                model: this._data?.relatedTo === 'properties_to_accounts' ? 'properties' : 'commercials'
            };
        }
        if (this._data.relatedTo === 'standard') {
            data = {
                to: emails,
                content: this.composeForm.get('content').value
            };
        }
        if (this._data.relatedTo === 'timeline_emails') {
            data = {
                to: emails,
                from: this.composeForm.get('from').value,
                account: this._data.relatedTo,
                subject: this.composeForm.get('subject').value,
                property_ids: this._data.properties,
                content: this.composeForm.get('content').value
            };
        }
        if (this._data.relatedTo === 'reply_email') {
            data = {
                to: emails,
                from: this.composeForm.get('from').value,
                subject: this.composeForm.get('subject').value,
                content: this.composeForm.get('content').value
            };
        }
        if (this._data.relatedTo === 'forword_email') {
            data = {
                to: emails,
                from: this.composeForm.get('from').value,
                account: this._data.relatedTo,
                subject: this.composeForm.get('subject').value,
                content: this.composeForm.get('content').value
            };
        }
        if (getContent) {
            this.previewData = data;
        } else {
            this._matDialog.open(PreviewEmailComponent, {
                disableClose: true,
                panelClass: 'mail-preview-dialog',
                data: data
            });
        }
    }
    viewDrafts(): void {
        const draftsDialog = this._matDialog.open(EmailDraftsComponent, {
            disableClose: true,
            panelClass: 'mail-drafts-dialog',
        });
        draftsDialog.afterClosed().subscribe(result => {
            if (result) {
                this.emails = result.to;
                this.composeForm.get('from').setValue(result.from);
                this.composeForm.get('cc').setValue(result.cc);
                this.composeForm.get('bcc').setValue(result.bcc);
                this.composeForm.get('subject').setValue(result.subject);
                this.composeForm.get('content').setValue(result.content);
                this.composeForm.get('files').setValue(result.files);
                this.attachments = result.files;
            }
        });
    }
    currentDate(){
        var now = new Date();
        var day = ("0" + now.getDate()).slice(-2);
        var month = ("0" + (now.getMonth() + 1)).slice(-2);
        return month + "-" + day + "-" + now.getFullYear();
    }

}
