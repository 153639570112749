import { Pipe, PipeTransform } from '@angular/core';
import { forEach } from 'lodash';

@Pipe({
  name: 'getCommercialPropertyTerraces'
})
export class GetCommercialPropertyTerracesPipe implements PipeTransform {

  transform(property: any, args?: any): any {
    if (property?.terraces && property.terraces.length) {
      let terraces = 0;
      forEach(property.terraces, (data: any) => {
        if (data?.terrace && typeof(data?.terrace) === 'string') {
          var result = data.terrace.replace(",", ".");
          terraces += parseFloat(result);
        }
      });
      return terraces;
    }
  }

}
