import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BaseService {
    private endPoint: string = environment.apiUrl;
    private endPointYii: string = environment.yiiUrl;
    constructor(
        private _http: HttpClient,
        private _toaster: ToastrService,
        private _translateService: TranslateService,
    ) {
    }

    get(modelUrl: string, remote?: any, options?: any): Observable<any> {
        let server: string;
        if (remote === 'nodejs') {
            server = this.endPoint;
        } else {
            server = this.endPointYii;
        }

        options = options ? options : {};

        return this._http.get<any>(`${server}${modelUrl}`, options);
    }

    post(modelUrl: string, form: any, remote?: any, options?: any): Observable<any> {
        let server: string;
        if (remote === 'nodejs') {
            server = this.endPoint;
        } else {
            server = this.endPointYii;
        }

        options = options ? options : {};

        return this._http.post<any>(`${server}${modelUrl}`, form, options);
    }
    uploadCsv(data, modelType): Observable<any> {
        return this._http.post(
            `${environment.apiUrl}${modelType}/import-data`,
            { importData: data, modelType }
        );
    }
    put(modelUrl: string, form: any, remote?: any): Observable<any> {
        let server: string;
        if (remote === 'nodejs') {
            server = this.endPoint;
        } else {
            server = this.endPointYii;
        }
        return this._http.put<any>(`${server}${modelUrl}`, form);
    }

    delete(modelUrl: string, data?, remote?: any): Observable<any> {
        let server: string;
        if (remote === 'nodejs') {
            server = this.endPoint;
        } else {
            server = this.endPointYii;
        }
        return this._http.delete<any>(`${server}${modelUrl}`);
    }

    uploadImage(modelUrl: string, data?: any, file?: File, remote?: any, fieldName: any = 'photo'): Observable<any> {
        let server: string;
        if (remote === 'nodejs') {
            server = this.endPoint;
        } else {
            server = this.endPointYii;
        }
        const formData: FormData = new FormData();
        formData.append(fieldName, file);
        formData.append('id', data.id);
        formData.append('model', data.model);
        return this._http.post(`${server}${modelUrl}`, formData, {
            reportProgress: true,
            observe: 'events'
        });
    }

    succussMessage(message, title): ActiveToast<any> {
        return this._toaster.success(this._translateService.instant(message), this._translateService.instant(title), {
            timeOut: 2000,
            easing: 'ease-in',
            progressBar: true,
            progressAnimation: 'decreasing',
            tapToDismiss: true
        });
    }

    deleteMessage(message: any, title: any): ActiveToast<any> {
        return this._toaster.error(this._translateService.instant(message), this._translateService.instant(title), {
            timeOut: 2000,
            easing: 'ease-in',
            progressBar: true,
            progressAnimation: 'decreasing',
            tapToDismiss: true,
        });
    }
    errorMessage(message: any, title: any): ActiveToast<any> {
        return this._toaster.error(this._translateService.instant(message), this._translateService.instant(title), {
            timeOut: 2000,
            easing: 'ease-in',
            progressBar: true,
            progressAnimation: 'decreasing',
            tapToDismiss: true,
        });
    }
    download(url: string): Observable<Blob> {
        return this._http.get(url, {
            responseType: 'blob'
        })
    }
    
}
