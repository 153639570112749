import { Injectable } from '@angular/core';
import {io} from 'socket.io-client';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class WebsocketService {

    private socket: any;
    private url = environment.socketUrl;

    constructor() {
        this.socket = io(this.url);
    }

    public listen(event: string): any {
        return new Observable((subscriber) => {
                this.socket.on(event, (data) => {
                    subscriber.next(data);
                });
        });
    }

    public emit(event: string, data: any): any {
        // console.log(event,data);
        if (this.socket) {
            this.socket.emit(event, data);
        }
    }

}
