import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from 'app/services';
import { BaseService } from '../../../../../_helpers/base/base.service';
import { environment } from 'environments/environment';
import { GLOBALS } from '../../../../../config/globals';
import { startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { WebsocketService } from '../../../../../services/websocket.service';


@Component({
  selector: 'app-notifications',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationComponent implements OnInit, OnDestroy {

  public currentUser: any;
  public notificationData: any;
  public post: any;
  public report = false;
  public blockByAdmin = false;
  // private postId: any;

  // public comments: any;
  // public comment: any;
  // public commentBox = true;


  // public env = environment;
  public currentLang: any;
  // public board: any;

  // public loaders = { ...GLOBALS.loaders }; // To display/hide loading spinner
  // public animations = { ...GLOBALS.animations }; // To use centralized animations
  // public pageActions = GLOBALS.pageActions; // To use same naming conventions for opening one form for multiple purposes

  // public postLikes = [];
  // public likesCount;

  private _unsubscribeAll: Subject<any> = new Subject();

  constructor(
    public matDialogRef: MatDialogRef<NotificationComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    public _authenticationService: AuthenticationService,
    private _globalService: BaseService,
    private socket: WebsocketService,
    private _translateService: TranslateService,
  ) {
    if (this.data.report) {
      this.report = true;
      if(this.data.blockByAdmin === 'block' ){
        this.blockByAdmin = true;
      }
    }
    this.currentUser = this._authenticationService.currentUserValue;
    this.notificationData = data;
    this.post = this.data;
    this._translateService.onLangChange
      .pipe(startWith(''))
      .subscribe(() => {
        this.currentLang = this._translateService.currentLang;
      });

  }

  ngOnInit(): void {
    // this.post = this.data;
    // if (this.data.type === 'post') {

    //   if (this.data.status === 'create' || this.data.status === 'updated' || this.data.status === 'deleted' || this.data.status === 'like'
    //     || this.data.status === 'unlike') {
    //     this.postId = this.data.model_id;
    //     this.getPost();
    //   }

    //   if (this.data.status === 'comment') {
    //     this._globalService.get('mp/boardmessage/comments/get/' + this.data.model_id, 'nodejs')
    //       .pipe(takeUntil(this._unsubscribeAll))
    //       .subscribe(res => {
    //         console.log('comment res', res);
    //         this.postId = res.data.message;
    //         // this.comment = res.data;
    //         this.getPost();
    //       });
    //   }
    // }
  }

  public reloadData(event): void {
    if (event === true) {
      // this.ngOnInit();
      console.log(event);
    }
  }

  public block(setBlocked) {
    this.matDialogRef.close({ status: true ,data:{messageId:this.data._id,setBlocked:setBlocked}});
  }

  // private scrollToComment(): void {
  //   console.log('Comment Here', this.comment._id)
  //   const ele = document.getElementById('c' + this.comment._id)
  //   console.log(ele)
  //   // .scrollIntoView({ behavior: 'smooth', block: 'start' });

  // }

  // private getPost(): void {
  //   console.log(this.postId);
  //   this._globalService.get('mp/board/message/get/' + this.postId, 'nodejs')
  //     .pipe(takeUntil(this._unsubscribeAll))
  //     .subscribe(res => {
  //       console.log('sending post',res);
  //       this.post = res.data;
  //     //   this.board = (this.data.board) ? this.data.board : (this.post) ? this.post.board : '';

  //     //   this._globalService.post('mp/boardmessage/comments/get/all/' + this.postId, {}, 'nodejs')
  //     //     .pipe(takeUntil(this._unsubscribeAll))
  //     //     .subscribe(resp => {
  //     //       if (resp.status === true) {
  //     //         console.log(resp);
  //     //         this.comments = resp.data;
  //     //         this.scrollToComment();
  //     //       }
  //     //     });

  //     //   this._globalService.post('mp/boardmessage/likes/get/all/' + this.board._id, { message: this.data.model_id }, 'nodejs')
  //     //     .pipe(takeUntil(this._unsubscribeAll))
  //     //     .subscribe(response => {
  //     //       this.postLikes = response.likes;
  //     //       this.likesCount = response.count;
  //     //     });
  //     });

  // }

  // public likeUnlikePost(postId, str): void {
  //   let url: string;
  //   let payload = {} as any;
  //   this.loaders.page = true;

  //   if (str === 'like') {
  //     url = 'mp/boardmessage/like/create';
  //     payload = { board: this.post.board._id, message: postId, like: true };
  //   } else {
  //     url = 'mp/boardmessage/unlike/' + this.post.board._id;
  //     payload = { message: postId };
  //   }
  //   this._globalService.post(url, payload, 'nodejs')
  //     .pipe(takeUntil(this._unsubscribeAll))
  //     .subscribe(res => {
  //       if (res.status === true) {
  //         this.loaders.page = false;
  //         this.ngOnInit();
  //         this.socket.emit('postLike', {
  //           created_by: this._authenticationService.currentUserValue._id, updated_by: this._authenticationService.currentUserValue._id,
  //           agency: this._authenticationService.currentUserValue.agency, board: this.post.board,
  //           created_name: this._authenticationService.currentUserValue.full_name, model_id: this.post._id, status: (str === 'like') ? 'like' : 'unlike'
  //         });
  //       }
  //     });

  // }

  // public toggleCommentDiv(): void {
  //   if (this.commentBox === true) {
  //     this.commentBox = false;
  //   } else {
  //     this.commentBox = true;
  //   }

  // }


  /**
   * On destroy
   */

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
