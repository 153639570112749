import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuAccessComponent } from './menu-access.component';

import { FuseSharedModule } from '@fuse/shared.module';
import { MatTreeModule } from '@angular/material/tree';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [MenuAccessComponent],
  imports: [
    CommonModule,
    FuseSharedModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSnackBarModule
  ],
  exports: [MenuAccessComponent]
})
export class MenuAccessModule { }
