import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'getRentalPropertyTitle'
})
export class GetRentalPropertyTitlePipe implements PipeTransform {

  transform(property: any, locale?: any): any {
    const lang = locale === 'es_AR' ? 'ES' : 'EN';
    let title = '';
    if (property.rental_title && property.rental_title[lang]) {
      title = property.rental_title[lang];
    }
    if (!title && property.title && property.title.EN) {
      title = property.title.EN;
    }
    return title;
  }

}
