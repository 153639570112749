import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getPropertyDescription'
})
export class GetPropertyDescriptionPipe implements PipeTransform {

  transform(property: any, locale?: any): any {
    const lang = locale === 'es_AR' ? 'ES' : 'EN';
    let description = '';
    if (property.description && property.description[lang]) {
      description = property.description[lang];
    }
    if (!description && property.description && property.description.EN) {
      description = property.description.EN;
    }
    return description;
  }
}
