import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TasksCreateFormModule } from '../tasks-create/tasks-create-form/tasks-create-form.module';
import { TasksCreateModalComponent } from './tasks-create-modal.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatRippleModule } from '@angular/material/core';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ColorPickerModule } from 'ngx-color-picker';
import { FuseSidebarModule } from '@fuse/components';

@NgModule({
    declarations: [
        TasksCreateModalComponent
    ],
    imports: [
        CommonModule,
        TasksCreateFormModule,
        FuseSharedModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MatMenuModule,
        MatToolbarModule,
        MatChipsModule,
        MatExpansionModule,
        MatRippleModule,
        MatSnackBarModule,
        NgxMatSelectSearchModule,
        ColorPickerModule,
        FuseSidebarModule
    ]
})
export class TasksCreateModalModule { }
