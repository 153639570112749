import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UrbanisationsService {

  constructor(private http: HttpClient) { }

  getData(filter: any): Observable<any[]> {
    return this.http.post<any[]>(`${environment.apiUrl}urbanisations`, filter);
  }

  getById(id: any): Observable<object> {
    return this.http.post<any[]>(`${environment.apiUrl}urbanisations/view/${id}`, '');
  }

  createData(data: any): Observable<object> {
    return this.http.post(`${environment.apiUrl}urbanisations/create`, data);
  }

  updateData(data: any): Observable<object> {
    return this.http.put(`${environment.apiUrl}urbanisations/update/${data._id}`, data);
  }

  deleteData(id: any): Observable<any> {
    return this.http.delete(`${environment.apiUrl}urbanisations/delete/${id}`);
  }

  ModelExclusions(data: any): Observable<any> {
    return this.http.post(`${environment.yiiUrl}agencies/model-exclusions`, data);
  }
  ModelAllExclusions(data: any): Observable<any> {
    return this.http.post(`${environment.yiiUrl}agencies/model-all-exclusions`, data);
  }
  // this is use for commerical properties in offer create
  getSearchByKey(filter: any): Observable<object> {
    return this.http.post<any[]>(`${environment.apiUrl}urbanisations/search-by-key`, filter);
  }

}
