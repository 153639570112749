import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MeetingsService {

  constructor(
    private http: HttpClient,
  ) { }

  getPast(filter: any, page: any, perPage?: any, value?: any): Observable<object> {
    return this.http.post<object>(`${environment.yiiUrl}activities/get-meetings?active_past=true&page=`
      + page + `&expand=agency_users&filter_own=` + value + `&per-page=${perPage}`, filter, { observe: 'response' as 'body' })
      .pipe(map(data => {
        return data;
      }));
  }

  getArchived(filter: any, page: any, perPage?: any, value?: any): Observable<object> {
    return this.http.post<object>(`${environment.yiiUrl}activities/get-meetings?archived=true&page=`
      + page + `&expand=agency_users&filter_own=` + value + `&per-page=${perPage}`, filter, { observe: 'response' as 'body' })
      .pipe(map(data => {
        return data;
      }));
  }

  getActive(filter: any, page: any, perPage?: any, value?: any): Observable<object> {
    return this.http.post<object>(`${environment.yiiUrl}activities/get-meetings?active=true&page=`
      + page + `&expand=agency_users&filter_own=` + value + `&per-page=${perPage}`, filter, { observe: 'response' as 'body' })
      .pipe(map(data => {
        return data;
      }));
  }

  getById(_id: string): Observable<object> {
    return this.http.get(`${environment.yiiUrl}activities/${_id}?expand=a_assignee`);
  }

  create(data: any): Observable<any> {
    return this.http.post(`${environment.yiiUrl}activities`, data);
  }

  update(data: any): Observable<any> {
    return this.http.put(`${environment.yiiUrl}activities/${data._id}`, data);
  }

  delete(id: any, create: any, agency: any): Observable<any> {
    return this.http.delete(`${environment.yiiUrl}activities/${id}?created_by=${create}&agency=${agency}`);
  }
}
