import { Component, OnInit, ViewEncapsulation, Inject, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SequencesService } from 'app/services/sequences.service';
import * as _ from 'lodash';
import { AuthenticationService, DropdownsService } from 'app/services';
// import * as moment from 'moment';
// import 'moment-timezone';
import moment from 'moment-timezone';
import { startWith, tap, switchMap } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EventsComponent implements OnInit, OnDestroy {

    private _unsubscribeAll: Subject<any>;
    displayedColumns: string[] = ['status', 'type', 'steps', 'title', 'start_time', 'start_date', 'end_time', 'end_date', 'dependent_step', 'to', 'time', 'assignee'];
    // searchColumns: string[] = ['search_status', 'search_type', 'search_steps', 'search_title', 
    // 'search_start_time', 'search_start_date', 'search_end_time', 'search_end_date', 'search_to', 'search_time', 'search_assignee'];
    currentLang: string;
    dialogTitle: string;
    loadingResults: boolean;
    events: any;
    currentUser: any;
    participants: any;
    nameSearch$ = new Subject<string>();
    users: any;
    usersSearch$ = new Subject<string>();
    public searching: boolean;

    constructor(
        public matDialogRef: MatDialogRef<EventsComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _translateService: TranslateService,
        private _sequencesService: SequencesService,
        private _authenticationService: AuthenticationService,
        private _dropdownsService: DropdownsService,
        private _snackBar: MatSnackBar
    ) {
        this.events = {};
        this.currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
        this.currentUser = this._authenticationService.currentUserValue;
        this.dialogTitle = 'Please select which activity would you like to activate from the sequence:';
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {

        this.nameSearch$
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap((searchValue: string) => {
                    let search = '';
                    if (searchValue) {
                        search = searchValue;
                    }
                    return this._dropdownsService.getParticipants(search);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.participants = data.body;
            });

        this.usersSearch$
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap((searchValue: string) => {
                    let search = '';
                    if (searchValue) {
                        search = searchValue;
                    }
                    return this._dropdownsService.getByAgency(search);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.users = data.body;
            });

        const filter = {
            sequence_id: this._data.sequence._id
        };
        this._sequencesService.getEvents(filter, 1)
            .subscribe((data: any) => {
                this.loadingResults = false;
                _.forEach(data.body, (value: any) => { value.status = true; });
                this.events.data = data.body;
                this.events.total = data.headers.get('x-pagination-total-count');
                this.events.limit = data.headers.get('x-pagination-per-page');
                this.calculateEvents();
            }, () => this.loadingResults = false);

    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    onChangeDates(index: any): void {
        let endDate = this.events.data[index].end_date;
        const endTime = this.events.data[index].end_time;
        if (typeof this.events.data[index].end_date === 'object') {
            endDate = this.events.data[index].end_date.toString();
        }
        this.calculateEvents(endDate, endTime, index);
    }
    calculateEvents(dayDiff?: any, timeDiff?: any, index?: any): void {
        _.forEach(this.events.data, (value: any, key: any) => {
            if (this._data.data && !dayDiff && !value.tnt_update) {
                value.tnt_update = true;
                value.title += ' ';
                if(this._data?.data?.account_data?.type === 'company') {
                    value.title += this._data.data.account_data?.company_name;
                }else {
                    value.title += this._data.data?.forename + ' ' + this._data.data?.surname;
                }
            }
            let timeFrom = new Date();
            let timeTo = null;
            let previousData = this.getPreviousObject(this.events.data, key);
            //                        if (!dayDiff) {
            const relatedStep = _.find(this.events.data, { steps: (value.dependent_step ? value.dependent_step : null) });
            // let relatedStep = $filter('filter')(this.events.data, {
            //     steps: (value.dependent_step ? value.dependent_step : null)
            // }, true);
            // relatedStep = relatedStep.length ? relatedStep[0] : null;
            if (relatedStep) {
                previousData = relatedStep;
            }
            //                        }
            if (!timeDiff) {
                timeTo = new Date();
            }
            if (key > 0 && !timeDiff) {
                timeTo = new Date(previousData.end_time);
            }
            let dateFrom = new Date();
            let dateTo = null;
            if (!dayDiff) {
                dateTo = new Date();
            }
            if (dayDiff && key <= index) {
                dateTo = new Date(this.events.data[key].end_date);
                dateFrom = new Date(this.events.data[key].start_date);
            }
            if (key > 0 && !dayDiff) {
                dateTo = new Date(previousData.end_date);
                dateFrom = new Date(previousData.start_date);
            }
            if (key > index && dayDiff) {
                dateTo = new Date(previousData.end_date);
                dateFrom = new Date(previousData.dateFrom);
            }

            if (!value.dependent_step) {
                value.dependent_step = previousData.steps;
            }

            timeFrom = this.getRoundedDate(15, timeFrom);
            timeTo = this.getRoundedDate(15, timeTo);

            let daysFrom = 0;
            let daysTo = 0;
            let minutesFrom = 0;
            let minutesTo = 0;
            if (!value.timing.from && value.type !== 'task') {
                value.timing.from = value.timing.to;
            }
            if (value.type === 'viewing') {
                value.timing.from = value.timing.to;
            }

            if (value.time === 'minutes' && value.timing) {
                if (value.timing.from) {
                    minutesFrom = value.timing.from;
                }
                if (value.timing.to) {
                    minutesTo = value.timing.to;
                }
            }
            if (value.time === 'hours' && value.timing) {
                if (value.timing.from) {
                    minutesFrom = value.timing.from * 60;
                }
                if (value.timing.to) {
                    minutesTo = value.timing.to * 60;
                }
            }
            if (value.time === 'days' && value.timing && !dayDiff) {
                if (value.timing.from) {
                    daysFrom = value.timing.from;
                }
                if (value.timing.to) {
                    daysTo = value.timing.to;
                }
            } else if (value.time === 'days' && value.timing && dayDiff && key > index) {
                if (value.timing.from) {
                    daysFrom = value.timing.from;
                }
                if (value.timing.to) {
                    daysTo = value.timing.to;
                }
            }
            if (value.time === 'weeks' && value.timing) {
                if (value.timing.from) {
                    daysFrom = value.timing.from * 7;
                }
                if (value.timing.to) {
                    daysTo = value.timing.to * 7;
                }
            }
            if (!minutesFrom && !minutesTo && !daysFrom && !daysTo) {
                minutesTo = 60;
            }
            if (dayDiff && key === index) {
                daysTo = 0;
            }
            if (!dayDiff) {
                dateFrom.setDate((dateFrom.getDate() + daysFrom));
                value.start_date = dateFrom;
                // value.start_date = $filter('date')(dateFrom.setDate((dateFrom.getDate() + daysFrom)), 'yyyy-MM-dd');
            }
            dateTo.setDate((dateTo.getDate() + daysTo));
            value.end_date = dateTo;
            // value.end_date = $filter('date')(dateTo.setDate((dateTo.getDate() + daysTo)), 'yyyy-MM-dd');
            timeFrom.setMinutes((timeFrom.getMinutes() + minutesFrom));
            value.start_time = timeFrom;
            if (!timeDiff) {
                timeTo.setMinutes((timeTo.getMinutes() + minutesTo));
                value.end_time = timeTo;
            }
            if (value.type === 'task' && this._data.data && this._data.data.assignedto_obj) {
                value.assignee = [this._data.data.assignedto_obj._id];
            }
            else if (value.type === 'task') {
                value.assignee = [this.currentUser.user_id];
            }
            else if (value.type === 'meeting' && this._data.data && this._data.data.assignedto_obj) {
                value.participants = [
                    {
                        name: this._data.data.assignedto_obj.firstname,
                        email: this._data.data.assignedto_obj.email
                    },
                    {
                        name: this.currentUser.first_name,
                        email: this.currentUser.user_email
                    }
                ];
                // $scope.Participants = value.participants;
            } else if (value.type === 'meeting') {
                value.participants = [{
                    name: this.currentUser.full_name,
                    email: this.currentUser.user_email
                }];
            } else if (value.type === 'email') {
                value.to_email = this._data.data.email;
            }
            // this.events.data[key] = value;
        });
    }

    getPreviousObject(data: any, key: any): any {
        if (!data[key - 1]) {
            return {};
        }
        if (data[key - 1].status) {
            return data[key - 1];
        }
        if (!data[key - 1].status) {
            return this.getPreviousObject(data, key - 1);
        }
    }

    getRoundedDate(minutes: any, d: any): any {
        d = d ? d : new Date();
        const ms = 1000 * 60 * minutes; // convert minutes to ms
        const roundedDate = new Date(Math.round(d.getTime() / ms) * ms);
        return roundedDate;
    }

    activateCron(events: any): void {
        const tz = moment.tz.guess();
        _.forEach(events, (event) => {
            const startTime = moment.tz(event.start_time, tz);
            const europeParisStart = startTime.clone().tz('Europe/Paris');
            event.start_time = europeParisStart.format();

            const enTime = moment.tz(event.end_time, tz);
            const europeParisEnd = enTime.clone().tz('Europe/Paris');
            event.end_time = europeParisEnd.format();
        });

        const data = {} as any;
        data.events = events;
        data.sequence_id = this._data.sequence._id;
        if (this._data.relatedTo === 'Accounts') {
            data.account_id = this._data.data._id;
        }
        if (this._data.relatedTo === 'Owners') {
            data.owner_id = this._data.data._id;
        }
        if (this._data.relatedTo === 'Properties') {
            data.property_id = this._data.data._id;
        }
        if (this._data.relatedTo === 'Sales Stages') {
            data.sale_id = this._data.data._id;
        }
        if (this._data.relatedTo === 'Urbanisations') {
            data.urbanisation_id = this._data.data._id;
        }
        if (this._data.relatedTo === 'Bookings') {
            data.booking_id = this._data.data._id;
        }
        if (this._data.relatedTo === 'Commercial Properties') {
            data.commercial_id = this._data.data._id;
        }
        if (this._data.relatedTo === 'Rental Contracts') {
            data.contract_id = this._data.data._id;
        }
        this._sequencesService.activateCron(data)
            .subscribe((response: any) => {
                if (response) {
                    this._snackBar.open(this._translateService.instant('Events will be apear within 5-10 min'), this._translateService.instant('Close'), {
                        duration: 2000,
                    });
                }
                this.matDialogRef.close(true);
            });
    }
}
