import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'app/_guards';
import { UserFaqsComponent } from './user-faqs.component';

const routes: Routes = [
  {
    path: 'user-faqs',
    component: UserFaqsComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserFaqsRoutingModule { }
