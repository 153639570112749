import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isArray, isEmpty } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { GlobalFuntions } from 'app/_helpers';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'common-dialog',
  templateUrl: './common-dialog.component.html',
  styleUrls: ['./common-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CommonDialogComponent implements OnInit {

  public htmlMobile = '' as string;
  public htmlHome = '' as string;
  public htmlWork = '' as string;
  public htmlWhatsApp = '' as string;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

  constructor(
    public dialogRef: MatDialogRef<CommonDialogComponent>,
    private _globalFuntions: GlobalFuntions,
    public _translateService: TranslateService,
    private _matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public _data: any,
  ) {
    if (this._data && this._data.type === 'account') {
      this._data.model = 'Accounts';
      this._data.activity_type = 'Call to client';
    } else if (this._data && this._data.type === 'owner') {
      this._data.model = 'Owners';
      this._data.activity_type = 'Call to owner';
    } else if (this._data && this._data.type === 'company') {
      this._data.model = 'Company';
      this._data.activity_type = 'Call to company';
    }
  }

  ngOnInit(): void {
    if (isArray(this._data.mobile) && this._data.mobile[0] !== '') {
      this.htmlMobile += `${this._translateService.instant('mobile_phone')}: <span class="blue-500-fg">${this.formatPhoneNumber(this._data.mobile)}</span>`;
      this.htmlWhatsApp += `<a class="font-size-20 green-500-fg"><span class="fab fa-whatsapp"></span></a> <br>`;
    } else if (!isArray(this._data.mobile) && this._data.mobile !== '') {
      this.htmlMobile += `${this._translateService.instant('mobile_phone')}: <span class="blue-500-fg">${this.formatPhoneNumber(this._data.mobile)}</span>`;
      this.htmlWhatsApp += `<a class="font-size-20 green-500-fg"><span class="fab fa-whatsapp"></span></a> <br>`;
    }

    if (this._data.homePhone && typeof this._data.homePhone !== undefined) {
      this.htmlHome += `${this._translateService.instant('home_phone')}: <span class="blue-500-fg">${this.formatPhoneNumber(this._data.homePhone)}</a> `;
    }
    if (this._data.workPhone && typeof this._data.workPhone !== undefined) {
      this.htmlWork += `${this._translateService.instant('work_phone')}: <span class="blue-500-fg">${this.formatPhoneNumber(this._data.workPhone)}</a> `;
    }
  }

  formatPhoneNumber(phone: string | string[]) {
    if (isArray(phone) && phone[0] !== '') {
      let html = '';
      phone.forEach((p: string) => {
        const phoneNumber = this.getPhoneNumber(p);
        if (html) {
          html += `, ${phoneNumber}`;
        } else {
          html = phoneNumber;
        }
      });
      return html;
    } else {
      return this.getPhoneNumber(phone as string);
    }
  }

  getPhoneNumber(number: string) {
    const mobile = number.trim();
    if (mobile.charAt(0) !== '+') {
      return `+${mobile}`;
    } else {
      return mobile;
    }
  }

  openWhatsapp(): void {
    if (this._data._id) {
      this.dialogRef.close(true);
    } else {
      if (this._data.url) {
        window.open(`https://api.whatsapp.com/send?phone=${this._data.mobile}&text=${this._data.url}&source=&data=`, `_blank`);
      } else {
        window.open(`https://api.whatsapp.com/send?phone=${this._data.mobile}&text=&source=&data=`, `_blank`);
      }

    }
  }

  mobile(callPerson: any): void {
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });
    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to make call?';
    this.confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (callPerson === 'home') {
          this._globalFuntions.onCallMobile(this._data, this._data.homePhone);
        } else if (callPerson === 'work') {
          this._globalFuntions.onCallMobile(this._data, this._data.workPhone);
        } else if (callPerson === 'mobile') {
          this._globalFuntions.onCallMobile(this._data, this._data.mobile);
        }
      }
    });
    this.dialogRef.close(false);
  }

}
