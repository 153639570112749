import { Directive, ElementRef, Self, OnInit, OnDestroy } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isMoment } from 'moment';
// import * as moment from 'moment';
import moment from 'moment-timezone';
import { format } from 'date-fns';

@Directive({
    selector: '[formateDatepicker]'
})
export class FormateDatepickerDirective implements OnInit, OnDestroy {

    private _unsubscribeAll: Subject<any>;
    constructor(
        public el: ElementRef,
        @Self() public ngControl: NgControl
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.ngControl.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(v => {
            if (v instanceof Date) {
                v = moment(v.toISOString());
            }
            if (isMoment(v)) {
                v.set({
                    hour: 12,
                    minute: 0,
                    second: 0,
                    millisecond: 0
                });
                this.ngControl.control.setValue(v.format());
            }
        });
    }


    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
