import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getPropertyTerraces'
})
export class GetPropertyTerracesPipe implements PipeTransform {

  transform(property: any, args?: any): any {
    const terraces = property?.terrace[0]?.terrace;
    return terraces;
  }

}
