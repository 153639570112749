export class WebSitesFakeDb {
    public static sites = [
        {
            thumbnail: 'assets/images/base2.png',
            siteId: 155,
            title: 'Sales, Long Term Rentals, New Construction Project',
            link: 'base2.optimasit.com'
        },
        {
            thumbnail: 'assets/images/base3.png',
            siteId: 156,
            title: 'Sales, Long Term & Holiday Rentals',
            link: 'base3.optimasit.com'
        },
        {
            thumbnail: 'assets/images/base1.png',
            siteId: 153,
            title: 'Sales only',
            link: 'base1.optimasit.com'
        },
        {
            thumbnail: 'assets/images/mod6.png',
            siteId: 50,
            title: 'Sales, Long Term Rentals, New Construction Project',
            link: 'mod6.optima-webs.com'
        },
        {
            thumbnail: 'assets/images/mod3.png',
            siteId: 51,
            title: 'Sales, Long Term Rentals, New Construction Project',
            link: 'mod3.optima-webs.com'
        },
        {
            thumbnail: 'assets/images/mod2.png',
            siteId: 54,
            title: 'Sales, Long Term Rentals, New Construction Project',
            link: 'mod2.optima-webs.com'
        },
        {
            thumbnail: 'assets/images/mod8.png',
            siteId: 57,
            title: 'Sales, Long Term Rentals, New Construction Project',
            link: 'mod8.optima-webs.com'
        },
        {
            thumbnail: 'assets/images/mod9.png',
            siteId: 58,
            title: 'Sales, Long Term Rentals, New Construction Project',
            link: 'mod9.optima-webs.com'
        },
        {
            thumbnail: 'assets/images/mod10.png',
            siteId: 59,
            title: 'Sales, Long Term Rentals, New Construction Project',
            link: 'mod10.optima-webs.com'
        },
        {
            thumbnail: 'assets/images/mod11.png',
            siteId: 60,
            title: 'Sales, Long Term Rentals, New Construction Project',
            link: 'mod11.optima-webs.com'
        },
        {
            thumbnail: 'assets/images/mod7.png',
            siteId: 61,
            title: 'Sales, Long Term Rentals, New Construction Project',
            link: 'mod7.optima-webs.com'
        },
        {
            thumbnail: 'assets/images/mod5.png',
            siteId: 64,
            title: 'Sales, Long Term Rentals, New Construction Project',
            link: 'mod5.optima-webs.com'
        },
        {
            thumbnail: 'assets/images/mod1.png',
            siteId: 65,
            title: 'Sales, Long Term Rentals, New Construction Project',
            link: 'mod1.optima-webs.com/'
        },
        {
            thumbnail: 'assets/images/mod4.png',
            siteId: 66,
            title: 'Sales, Long Term Rentals, New Construction Project',
            link: 'mod4.optima-webs.com/'
        },
        {
            thumbnail: 'assets/images/mod12.png',
            siteId: 94,
            title: 'Sales, Long Term Rentals, New Construction Project',
            link: 'mod12.optima-webs.com/'
        },
        {
            thumbnail: 'assets/images/mod15.png',
            siteId: 113,
            title: 'Sales, Long Term Rentals, New Construction Project',
            link: 'mod15.optima-webs.com'
        },
    ];
}
