// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-audit .products-table {
  flex: 1 1 auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
app-audit .products-table .mat-mdc-header-row {
  min-height: 64px;
}
app-audit .products-table .mat-mdc-cell {
  min-width: 0;
  display: flex;
  align-items: center;
}
app-audit .products-table .quantity-indicator {
  display: inline-block;
  vertical-align: middle;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-right: 8px;
}
app-audit .products-table .quantity-indicator + span {
  display: inline-block;
  vertical-align: middle;
}
app-audit .products-table .active-icon {
  border-radius: 50%;
}
app-audit .loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/audit/audit.component.scss"],"names":[],"mappings":"AACI;EACI,cAAA;EACA,4CAAA;EACA,cAAA;EACA,iCAAA;AAAR;AAEQ;EACI,gBAAA;AAAZ;AAGQ;EACI,YAAA;EACA,aAAA;EACA,mBAAA;AADZ;AAQQ;EACI,qBAAA;EACA,sBAAA;EACA,UAAA;EACA,WAAA;EACA,kBAAA;EACA,iBAAA;AANZ;AAQY;EACI,qBAAA;EACA,sBAAA;AANhB;AAUQ;EACI,kBAAA;AARZ;AAYI;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;EACA,+BAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAVR","sourcesContent":["app-audit {\n    .products-table {\n        flex: 1 1 auto;\n        border-bottom: 1px solid rgba(0, 0, 0, 0.12);\n        overflow: auto;\n        -webkit-overflow-scrolling: touch;\n\n        .mat-mdc-header-row {\n            min-height: 64px;\n        }\n\n        .mat-mdc-cell {\n            min-width: 0;\n            display: flex;\n            align-items: center;\n        }\n\n        // .mat-column-IP {\n        //   flex: 0 1 120px;\n        // }\n\n        .quantity-indicator {\n            display: inline-block;\n            vertical-align: middle;\n            width: 8px;\n            height: 8px;\n            border-radius: 4px;\n            margin-right: 8px;\n\n            &+span {\n                display: inline-block;\n                vertical-align: middle;\n            }\n        }\n\n        .active-icon {\n            border-radius: 50%;\n        }\n    }\n\n    .loading-shade {\n        position: absolute;\n        top: 0;\n        left: 0;\n        bottom: 0;\n        right: 0;\n        background: rgba(0, 0, 0, 0.15);\n        z-index: 1000;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
