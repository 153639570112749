import { Pipe, PipeTransform } from '@angular/core';
import { setMonth } from 'date-fns/esm';

@Pipe({
  name: 'monthDateFormatter'
})
export class MonthDateFormatterPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
    return monthNames[value - 1];
  }

}
