import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { startWith } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class EmailsService {
    systemLang: any;

    constructor(
        private http: HttpClient,
        private _translateService: TranslateService
    ) { 
        this.systemLang = this._translateService.currentLang;
        this._translateService.onLangChange.pipe(startWith('')).subscribe(() => {
            this.systemLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
          });
    }

    saveAsDraft(data: any): Observable<any> {
        return this.http.post(`${environment.yiiUrl}email-drafts`, { draft_data: data });
    }

    loadDrafts(filter: any): Observable<any> {
        return this.http.post(`${environment.yiiUrl}email-drafts/search`, filter);
    }

    deleteDraft(id: string): Observable<any> {
        return this.http.delete(`${environment.yiiUrl}email-drafts/${id}`);
    }

    previewEmail(data: any, templateLang?: any): Observable<any> {
        let lang = this.systemLang;
        if(typeof(templateLang) != 'undefined' && templateLang != '') {
            lang = templateLang;
        }
        return this.http.post(`${environment.yiiUrl}accounts/preview-email?lang=${lang}`, data);
    }

}
