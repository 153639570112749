import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'getPropertyUrl'
})
export class GetPropertyUrlPipe implements PipeTransform {

    constructor(
        private _translateService: TranslateService,
    ) { }

    transform(property: any, model?: any, locale?: any): any {
        let currentLang = locale ? locale.trim().toUpperCase() : 'EN';
        currentLang = currentLang == 'ES_AR' ? 'ES' : currentLang;
        model = model ? model.trim().toLowerCase() : 'residential';
        let title: any, websiteUrl: any, propertyWebsiteUrl: any, propertyUrl: any, location: string, propertyLocation: string, type: string, propertyType: string, language: string, agencyData: any;
        if(model == 'residential'){
            propertyLocation = property?.property_location;
            propertyType = property?.property_type;
            propertyWebsiteUrl = property?.website_url;
            agencyData = property?.agency_logo;
        }else{
            propertyLocation = property?.location_value;
            propertyType = property?.type_one_value;
            propertyWebsiteUrl = property?.agency_data?.property_url_website;
            agencyData = property?.agency_data;
        }
        if (propertyWebsiteUrl && typeof(propertyWebsiteUrl) == 'object' && Object.keys(propertyWebsiteUrl).length) {
            websiteUrl = propertyWebsiteUrl;
        }
        if (property.lt_rental || property.st_rental) {
            title = property.rental_title || {};
            propertyUrl = (websiteUrl && typeof(websiteUrl) == 'object' && Object.keys(websiteUrl).length && websiteUrl['rent']) ? websiteUrl['rent'] : '';
        } else {
            title = property.title || {};
            propertyUrl = (websiteUrl && typeof(websiteUrl) == 'object' &&  Object.keys(websiteUrl).length && websiteUrl['sale']) ? websiteUrl['sale'] : '';
        }
        if (!['es_AR', 'ES', 'EN'].includes(currentLang)) {
            if (currentLang == 'NL') {
                if ((propertyLocation[currentLang.toLowerCase()]) && (propertyLocation[currentLang.toLowerCase()] != '')) {
                    location = propertyLocation[currentLang.toLowerCase()];
                } else if (propertyLocation['ned'] && propertyLocation['ned'] != '') {
                    location = propertyLocation['ned'];
                }
                if (propertyType && propertyType[currentLang.toLowerCase()] && propertyType[currentLang.toLowerCase()] != '') {
                    type = propertyType[currentLang.toLowerCase()];
                } else if (propertyType && propertyType['ned'] && propertyType['ned'] != '') {
                    type = propertyType['ned'];
                }

                if (title[currentLang] && title[currentLang] != '') {
                    title = title[currentLang];
                } else if (title['NED'] && title['NED'] != '') {
                    title = title['NED'];
                } else if (title['Dutch'] && title['Dutch'] != '') {
                    title = title['Dutch'];
                }

                if (typeof(propertyUrl) == 'object' && Object.keys(propertyUrl).length) {
                    language = currentLang.toLowerCase();
                    if (propertyUrl[language] && propertyUrl[language] != '') {
                        propertyUrl = propertyUrl[language];
                    } else if (propertyUrl['ned'] && propertyUrl['ned'] != '') {
                        propertyUrl = propertyUrl['ned'];
                    } else if (propertyUrl['dutch'] && propertyUrl['dutch'] != '') {
                        propertyUrl = propertyUrl['dutch'];
                    } else if (propertyUrl['dui'] && propertyUrl['dui'] != '') {
                        propertyUrl = propertyUrl['dui'];
                    }
                }
            } else {
                if (propertyLocation[currentLang.toLowerCase()] && propertyLocation[currentLang.toLowerCase()] != '') {
                    location = propertyLocation[currentLang.toLowerCase()];
                }

                if (propertyType && propertyType[currentLang.toLowerCase()] && propertyType[currentLang.toLowerCase()] != '') {
                    type = propertyType[currentLang.toLowerCase()];
                }

                if (currentLang == 'FR' || currentLang == "RU") {
                    language = currentLang + '1';
                    if (title[language] && title[language] != '') {
                        title = title[language];
                    } else if (title[currentLang] && title[currentLang] != '') {
                        title = title[currentLang];
                    }
                    if (typeof(propertyUrl) == 'object' && Object.keys(propertyUrl).length) {
                        if (propertyUrl[currentLang.toLowerCase()] && propertyUrl[currentLang.toLowerCase()] != '') {
                            propertyUrl = propertyUrl[currentLang.toLowerCase()];
                        } else if (propertyUrl[language.toLowerCase()] && propertyUrl[language.toLowerCase()] != '') {
                            propertyUrl = propertyUrl[language.toLowerCase()];
                        }
                    }
                } else {
                    if (title[currentLang] && title[currentLang] != '') {
                        title = title[currentLang];
                    }
                    if (
                        typeof(propertyUrl) == 'object' &&
                        Object.keys(propertyUrl).length &&
                        propertyUrl[currentLang.toLowerCase()]
                    ) {
                        propertyUrl = propertyUrl[currentLang.toLowerCase()];
                    }
                }
            }
        } else {
            if (title[currentLang] && title[currentLang] != '') {
                title = title[currentLang];
            } else {
                title = '-';
            }
            if (propertyType && propertyType[currentLang.toLowerCase()] && propertyType[currentLang.toLowerCase()]) {
                type = propertyType[currentLang.toLowerCase()];
            }else{
                type = '-';
            }
            if (
                typeof(propertyUrl) == 'object' &&
                Object.keys(propertyUrl).length &&
                propertyUrl[currentLang.toLowerCase()]
            ) {
                propertyUrl = propertyUrl[currentLang.toLowerCase()];
            }
        }
        if (typeof(title) == 'object' && Object.keys(title).length) { // at this level `title` must be a string value if not it's mean title is an empty array and empty `title` array msut be converted to '-'
            title = '-';
        }
        // if propertyUrl not found in any language than default value get from 'en'
        if (typeof(propertyUrl) == 'object' && Object.keys(propertyUrl).length) {
            propertyUrl = propertyUrl['en'];
        }

        // if still propertyUrl not found than previous logic for propertyUrl will go
        if (!propertyUrl) {
            if (
                property.perma_link &&
                property.perma_link != '' &&
                property.perma_link[currentLang] &&
                property.perma_link[currentLang].indexOf('https') > -1
            ) {
                propertyUrl = property.perma_link[currentLang];
            } else if (property.url && property.url != '') {
                propertyUrl = property.url;
                propertyUrl = propertyUrl.replace('.com/', '.com/' + currentLang.toLowerCase() + '/');
                if (agencyData['agency_reference'].toLowerCase() == 'ie') {
                    if (!property.sale && property.rent) {
                        propertyUrl = propertyUrl.replace('ibiza-property-for-sale', 'ibiza-property-for-rent');
                    }
                    if (currentLang.toLowerCase() == 'nl') {
                        propertyUrl = propertyUrl.replace('ibiza-property-for-sale', 'huis-kopen-ibiza');
                        propertyUrl = propertyUrl.replace('ibiza-property-for-rent', 'huis-huren-ibiza');
                    }
                }
                if (!['es_AR', 'ES', 'EN'].includes(currentLang)) {
                    let urlArr = propertyUrl.split('/');
                    propertyUrl = propertyUrl.replace(
                        urlArr[(urlArr.length) - 1],
                        (type.replace(' ', '-') + '-' + location.replace(' ', '-') + '-' + title.replace(' ', '-') + '-_' + property.reference + '?crm=true')
                    );
                }
            } else if (agencyData['website'] == 'http://dreampropertiesnerja.com/') {
                propertyUrl = agencyData['website'] + '/' + title.replace(' ', '-').toLowerCase() + '/' + property.reference + '/01/';
            } else if (agencyData['website'] == 'http://keystyleproperties.com/') {
                propertyUrl = agencyData['website'] + '/property/' + title.replace(' ', '-').toLowerCase() + '/' + property.reference + '/';
            } else if (agencyData['website'] && agencyData['website'] != ''
            ) {
                let property_title = '';
                if (property.title && property.title[currentLang]
                ) {
                    property_title = property.title[currentLang].replace(' ', '-').toLowerCase();
                }
                propertyUrl = agencyData['website'] + '/' + (currentLang == "ES" ? 'propiedad' : 'property') + '/' + (property_title ? property_title : '') + '_' + property.reference;
            } else {
                propertyUrl = agencyData['website'] + '/' + title.replace(' ', '-').toLowerCase() + '/' + property.reference + '/';
            }
        }
        return propertyUrl;
    }
}
