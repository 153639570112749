import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isEmpty } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'app-screen-help',
  templateUrl: './screen-help.component.html',
  styleUrls: ['./screen-help.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScreenHelpComponent implements OnInit {

  content: any;
  currentLang: any;

  constructor(
    public matDialogRef: MatDialogRef<ScreenHelpComponent>,
    private _translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public _data: any
  ) {
    if (!isEmpty(this._data)) {
      this.content = this._data;
    }
  }

  ngOnInit(): void {
    this._translateService.onLangChange
      .pipe(startWith(''))
      .subscribe(() => {
        this.currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
      });
  }

}
