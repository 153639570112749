import { Directive, HostListener, ElementRef, Renderer2, Input } from '@angular/core';
import { ComposeDialogComponent } from './compose-dialog/compose-dialog.component';
import { FormGroup } from '@angular/forms';
import { MailBoxService } from 'app/services/mail-box.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Directive({
    selector: '[composeEmail]'
})
export class ComposeEmailDirective {

    @Input() relatedTo: string;
    @Input() to: string[];
    @Input() from: string[];
    @Input() cc: string[];
    @Input() subject: string;
    @Input() properties: any;
    @Input() mailAttachment: any;
    @Input() view: boolean;
    @Input() content: any;
    @Input() selectedIds = [];
    @Input() id: any;
    @Input() assignedEmails: any;
    @Input() model: any; // where we define according to which model it is (propeerties or commercial)

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        public _matDialog: MatDialog,
        public _mailBoxService: MailBoxService,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService,
    ) {
        this.renderer.setStyle(this.el.nativeElement, 'cursor', 'pointer');
    }

    @HostListener('click') onClick(): void {
        const dialogRef = this._matDialog.open(ComposeDialogComponent, {
            disableClose: true,
            panelClass: 'mail-compose-dialog',
            data: {
                to: this.to,
                id: this.id,
                cc: this.cc,
                from: this.from,
                relatedTo: this.relatedTo,
                subject: this.subject,
                properties: this.properties,
                mailAttachment: this.mailAttachment,
                view: this.view,
                content: this.content,
                selectedIds: this.selectedIds,
                assigned_emails: this.assignedEmails,
                model: this.model
            },
        });
    }
}
