import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { isEmpty } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CommercialPropertiesService {

  currentUser: any;
  currentAgency: any;

  constructor(
    private http: HttpClient,
    private _authenticationService: AuthenticationService
  ) {
    this.currentUser = this._authenticationService.currentUserValue;
    this.currentAgency = this._authenticationService.currentAgencyValue;
  }

  getData(filter?: any): Observable<any[]> {
    return this.http.post<any[]>(`${environment.apiUrl}commercial_properties`, filter);

  }
  getPropertyData(id): Observable<any[]> {
    return this.http.post<any[]>(`${environment.apiUrl}commercial_properties/view/${id}`, { observe: 'response' as 'body' });
  }
  getPropertyStatusHistory(id): Observable<any[]> {
    return this.http.post<any[]>(`${environment.apiUrl}commercial_properties/status-history/${id}`, { observe: 'response' as 'body' });
  }
  updateProperty(id, _property): Observable<any[]> {
    return this.http.put<any[]>(`${environment.apiUrl}commercial_properties/update/${id}`, _property);
  }
  createDuplicateProperty(id, _property): Observable<any[]> {
    return this.http.put<any[]>(`${environment.apiUrl}commercial_properties/create-duplicate/${id}`, _property);
  }
  createProperty(_property: any): Observable<any[]> {
    return this.http.post<any[]>(`${environment.apiUrl}commercial_properties/create`, _property, { observe: 'response' as 'body' });
  }
  createCopy(data: any): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}commercial_properties/create`, data);
  }
  getFeatures(filter?: any): Observable<any[]> {
    if (!isEmpty(filter)) {
      return this.http.post<any[]>(`${environment.apiUrl}commercialfeatures`, filter);
    }
    else {
      return this.http.post<any[]>(`${environment.apiUrl}commercialfeatures`, { options: { page: 1, limit: 10000, sort: { key: 1 } }, query: {} });
    }
  }
  getFeaturesAll(filter?: any): Observable<any[]> {
    return this.http.post<any[]>(`${environment.apiUrl}commercialfeatures/all`, filter);
  }

  getExpenseTypes(search?: any): Observable<any[]> {
    return this.http.post<any[]>(`${environment.apiUrl}expense_types/all`, search);
  }
  
  getCommercialAuctionDocs(search?: any): Observable<any[]> {
    return this.http.post<any[]>(`${environment.apiUrl}commercial_auction_docs/all`, search);
  }
  getHistory(id: any): Observable<any[]> {
    const filter = {};
    return this.http.post<any[]>(`${environment.apiUrl}commercial_properties/history/${id}`, filter);
  }
  getUser(id: any): Observable<any[]> {
    return this.http.get<any[]>(`${environment.yiiUrl}users/${id}`, { observe: 'response' as 'body' });
  }
  getLodgings(): Observable<any[]> {
    return this.http.post<any[]>(`${environment.apiUrl}lodging_classifications`, {});
  }
  restoreHistory(_property: any): Observable<any[]> {
    return this.http.put<any[]>(`${environment.apiUrl}commercial_properties/update/${_property.d._id}`, _property.d);
  }
  archivedCommercial(property: any): Observable<any[]> {
    return this.http.put<any[]>(`${environment.apiUrl}commercial_properties/update/${property._id}`, property);
  }

  bulkArchive(ids: any): Observable<any[]> {
    return this.http.post<any[]>(`${environment.apiUrl}bulk_archive`, ids);
  }

  getRelatedAccounts(id: string, filter: any, page: any, sortFilter: any, searchBy: string, search: string): Observable<any[]> {
    page = page ? page : 1;
    if (!Object.keys(sortFilter).length && search === '') {
      return this.http.post<any[]>(`${environment.yiiUrl}accounts/commercial-accounts?id=${id}&page=${page}&expand=language_data`, filter, { observe: 'response' as 'body' });
    }
    else {
      return this.http.post<any[]>(`${environment.yiiUrl}accounts/commercial-accounts?sort[]=
    ${sortFilter.active}&sort[]=${sortFilter.direction}&search[]=${searchBy}&id=${id}&search[]=${search}&page=${page}`, filter, { observe: 'response' as 'body' });
    }

  }

  // get duplications
  getDuplications(page: any, perPage: any): Observable<object> {
    page = page ? page : 1;
    perPage = perPage ? perPage : 10;
    return this.http.post(`${environment.yiiUrl}commercial-properties/get-duplications?user=${this.currentUser._id}&page=${page}&per-page=${perPage}`, {},
        { observe: 'response' as 'body' });
  }

  // tools/commercial-potential-duplications
  getPotentialDuplications(page: any, perPage: any): Observable<object> {
    page = page ? page : 1;
    perPage = perPage ? perPage : 10;
    return this.http.post(`${environment.yiiUrl}commercial-properties/potential-duplications?user=${this.currentUser._id}&page=${page}&per-page=${perPage}`, {},
        { observe: 'response' as 'body' });
  }

  // excluded properties
  getExcludedProperties(filter: any, page: any, perPage: any): Observable<object> {
    page = page ? page : 1;
    perPage = perPage ? perPage : 10;
    return this.http.post(`${environment.yiiUrl}commercial-properties/excluded-properties?expand=property_location,property_city&page=${page}&per-page=${perPage}`, filter,
        { observe: 'response' as 'body' });
  }

  // testionials methods
  getTestimonials(id: string): Observable<object> {
    return this.http.get(`${environment.yiiUrl}testimonials?property=${id}`);
  }

  getBookings(id: string): Observable<object> {
    let filter = { id: id , page: 100};
    return this.http.post(`${environment.apiUrl}commercial_properties/get-property-bookings22`, filter);
  }
  deleteProperty(id: any): Observable<any> {
    return this.http.delete<any>(`${environment.apiUrl}commercial_properties/delete/${id}`);
  }
  BulkUpdateProperty(filter?: any): Observable<any[]> {
    return this.http.post<any[]>(`${environment.yiiUrl}properties/bulk-update-commercial-properties`, filter);
  }
  getPriceHistory(filterId: any, data?: any): Observable<any[]> {
    return this.http.post<any[]>(`${environment.apiUrl}commercial_properties/price-history/${filterId}`, data);
  }
  fractionalPriceChange(data?: any): Observable<any[]> {
    return this.http.post<any[]>(`${environment.apiUrl}commercial_properties/add-fractional-price-history`, data);
  }
  fractionalPriceHistory(data?: any): Observable<any[]> {
    return this.http.post<any[]>(`${environment.apiUrl}commercial_properties/fractional-price-history`, data);
  }
  createFeaturesTabs(filterData: any): Observable<any[]> {
    return this.http.post<any[]>(`${environment.apiUrl}commercialfeaturetabs/create`, filterData);
  }
  getFeaturesTabs(filterData?: any): Observable<any[]> {
    if (!isEmpty(filterData)) {
      return this.http.post<any[]>(`${environment.apiUrl}commercialfeaturetabs`, filterData);
    } else {
      return this.http.post<any[]>(`${environment.apiUrl}commercialfeaturetabs`, { options: { page: 1, limit: 100, sort: { key: 1 } }, query: {} });
    }
  }
  getAllFeatureTabs(filterData?: any): Observable<any[]> {
    return this.http.post<any[]>(`${environment.apiUrl}commercialfeaturetabs/all`, filterData);
  }
  updateFeaturesTabs(filterData: any): Observable<any[]> {
    return this.http.put<any[]>(`${environment.apiUrl}commercialfeaturetabs/update/${filterData._id}`, filterData);
  }
  deleteFeaturesTab(id: any): Observable<any[]> {
    return this.http.delete<any[]>(`${environment.apiUrl}commercialfeaturetabs/delete/${id}`);
  }
  addFeature(filterData: any): Observable<any[]> {
    return this.http.post<any[]>(`${environment.apiUrl}commercialfeatures/create`, filterData);
  }
  updateFeature(filterData: any): Observable<any[]> {
    return this.http.put<any[]>(`${environment.apiUrl}commercialfeatures/update/${filterData._id}`, filterData);
  }
  deleteFeature(id: any): Observable<any[]> {
    return this.http.delete<any[]>(`${environment.apiUrl}commercialfeatures/delete/${id}`);
  }
  getAlllatLng(filters?: any): Observable<any[]> {
    return this.http.post<any[]>(`${environment.apiUrl}commercial_properties/all-with-lat-lang`, filters);
  }
  AddToList(id: any): Observable<any> {
    return this.http.get<any[]>(`${environment.yiiUrl}properties/add-to-list?model_id=${id}&model=commercial_properties`, { observe: 'response' as 'body' });
  }
  RemoveFromList(id: any): Observable<any> {
    return this.http.get<any[]>(`${environment.yiiUrl}properties/remove-from-list?model_id=${id}`, { observe: 'response' as 'body' });
  }
  restoreSelectedProperties(filter: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}commercial_properties/restore`, filter);
  }
  restoreAll(id: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}commercial_properties/restore-all`, id);
  }
  commercialPropertiesByOwner(filter: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}commercial_properties/by_owner`, filter);
  }
  deleteAllSelected(filter: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}commercial_properties/delete-all-selected`, filter);
  }
  deleteAll(filter: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}commercial_properties/delete-all`, filter);
  }

  getBaskets(property: any): Observable<any[]> {
    // tslint:disable-next-line: max-line-length
    return this.http.get<any[]>(`${environment.yiiUrl}buckets?pagination=false&expand=cc&model=commercial&property=${property}`, { observe: 'response' as 'body' });
  }

  // updateFeature

  insertPC(data: any): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}commercial_properties/commercial-property-copy`, data, { observe: 'response' as 'body' });
  }

  updatePC(data: any): Observable<any> {
    return this.http.put<any[]>(`${environment.apiUrl}commercial_properties/commercial-property-copy-update/${data._id}`, data,  { observe: 'response' as 'body' });
  }

  // Properties Statistics

  getPropertyImage(_id: string, model?: string): Observable<any[]> {
    if (typeof model == 'undefined') {
      model = "commercial_properties";
    } 
    return this.http.get<any[]>(`${environment.apiUrl}commercial_properties/get-property-images/${_id}?model=${model}`, { observe: 'response' as 'body' });
  }
  getPropertyDocument(_id: string, model?: string): Observable<any[]> {
    if (typeof model == 'undefined') {
      model = "commercial_properties";
    }
    return this.http.get<any[]>(`${environment.apiUrl}commercial_properties/get-property-document/${_id}?model=${model}`, { observe: 'response' as 'body' });
  }

  getPropertyStatData(id: string): Observable<any[]> {
    // tslint:disable-next-line: max-line-length
    return this.http.post<any[]>(`${environment.apiUrl}commercial_properties/view/${id}`, { observe: 'response' as 'body' });
  }

  getEmailsStatistics(_id: string, range: any): Observable<any[]> {
    return this.http.post<any[]>(`${environment.apiUrl}commercials/emails/${_id}`, range);
  }

  getSalesStatistics(_id: string): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}commercials/sales/${_id}`);
  }

  getOffersStatistics(_id: string): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}commercials/offers/${_id}`);
  }

  getViewingsContacts(_id: string): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}commercials/viewings-contacts/${_id}`);
  }

  getVisitors(_id: string): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}commercials/visitors/${_id}`);
  }

  getQuestionaire(_id: string): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}commercials/questionaire/${_id}`);
  }

  getPublications(_id: string): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}commercials/publications/${_id}`);
  }

  getEmailAndCallsStatistics(_id: string): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}commercials/emails_and_calls/${_id}`);
  }

  // Todo in future we converted in Nodejs
  getWebsiteUrl(filter?: any): Observable<any[]> {
    return this.http.post<any[]>(`${environment.yiiUrl}commercial-properties/website-url`, filter);
  }

  exportAttachments(data: any): Observable<any> {
    return this.http.post(`${environment.yiiUrl}commercial-properties/export-attachments-xml`, data);
  }

  uploadCsv(data, modelType): Observable<any> {        
    return this.http.post(`${environment.apiUrl}commercial_properties/import-data`, {'importData':data, 'modelType': modelType});
  }

  getBookmarkById(id: any): Observable<any> {
    return this.http.get<any>(`${environment.yiiUrl}bookmark-search/${id}`);
  }

  getVirtualTours(id: string): Observable<any> {
    return this.http.get<any>(`${environment.yiiUrl}virtual-tours/commercial-property-vt?p_id=${id}`);
  }

  commLogs(data?: any): Observable<any[]> {
    return this.http.post<any[]>(`${environment.yiiUrl}commercial-properties/comm-logs`, data);
  }

  getPropertyBookings(id: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}yovendo/property_bookings?id=${id}`);
  }

  updateShowOnOnly(filter?: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}commercial_properties/update-show-on-only`, filter)
  }
}
