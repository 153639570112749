import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MlsSharingService } from 'app/services/mls-sharing.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MlsRequestComponent } from '../mls-request/mls-request.component';
import { GlobalFuntions } from 'app/_helpers';
import { isEmpty } from 'lodash';
import { AuthenticationService } from 'app/services';

@Component({
    selector: 'mls-notifications',
    templateUrl: './mls-notifications.component.html',
    styleUrls: ['./mls-notifications.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MlsNotificationsComponent implements OnInit {
    newRequests: any;
    currentUser: any;

    constructor(
        public matDialogRef: MatDialogRef<MlsNotificationsComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        public _mlsSharingService: MlsSharingService,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService,
        private _matDialog: MatDialog,
        private _globalFuntions: GlobalFuntions,
        private _authenticationService: AuthenticationService,
    ) {
        this.currentUser = this._authenticationService.currentUserValue;
        this.newRequests = this._data;
    }

    ngOnInit(): void { }

    updateRequestApproved(request: any): void {
        request.status = true;
        this._mlsSharingService.updateSharingRequest(request)
            .subscribe((data: any) => {
                this._mlsSharingService.agencyToCompany(request.agency_from).subscribe();
                const message = this._translateService.instant('Congratulations!You have accepted the incoming sharing request from') + ' '
                    + request.agency_from_data.commercial_name + '.' + ' '
                    + this._translateService.instant('Now share back and define which level applies for') + ' '
                    + request.agency_to_data.commercial_name + '.' + ' '
                    + this._translateService.instant('Check your notifications to continue...');
                this._snackBar.open(message, this._translateService.instant('Close'), {
                    duration: 5000,
                });
            });
    }

    sendRequest(request: any): void {
        const dialogRef = this._matDialog.open(MlsRequestComponent, {
            panelClass: 'mls-request',
            data: request
        });
        dialogRef.afterClosed()
            .subscribe(result => {
                result = this._globalFuntions.clearObject(result);
                if (!isEmpty(result)) {
                    request.readed = true;
                    this._mlsSharingService.updateSharingRequest(request)
                        .subscribe(() => {
                            result.created_by = this.currentUser.user_id;
                            result.created_at = new Date();
                            result.status = true;
                            result.readed = false;
                            result.back = true;
                            result.agency_to = request.agency_from;
                            this._mlsSharingService.insertSharingRequest(result)
                                .subscribe((data) => {
                                    const message = this._translateService.instant('Success! You have invited') + ' '
                                        + request.agency_to.commercial_name + ' '
                                        + this._translateService.instant('to accept your invitation to share.') + ' '
                                        + this._translateService.instant('The assigned level is') + ' '
                                        + data.sharing_level.value + '.' + ' '
                                        + this._translateService.instant('Your request will remain in the Pending Requests list until') + ' '
                                        + request.agency_to.commercial_name + ' '
                                        + this._translateService.instant('has accepted your request') + '.';
                                    this._snackBar.open(message, this._translateService.instant('Close'), {
                                        duration: 5000,
                                    });
                                    this.getNewRequests();
                                });
                        });
                }
            });
    }

    updateRequestUnapproved(request: any): void {
        request.status = false;
        request.readed = true;
        this._mlsSharingService.updateSharingRequest(request)
            .subscribe(() => {
                this.getNewRequests();
            });
    }

    updateRequestApRd(request: any): void {
        request.status = true;
        request.readed = true;
        request.back = false;
        this._mlsSharingService.updateSharingRequest(request)
            .subscribe(() => {
                this.getNewRequests();
            });
    }

    getNewRequests(): void {
        this._mlsSharingService.getNewRequests()
            .subscribe((newRequests: any) => {
                this.newRequests = newRequests;
            });
    }

}
