import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseMatchMediaService } from '@fuse/services/match-media.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
// import { FuseConfigService } from '@fuse/services/config.service';
import * as _ from 'lodash';
import { AuthenticationService } from 'app/services';
import { TranslateService } from '@ngx-translate/core';
import { BaseService } from 'app/_helpers/base/base.service';

@Component({
    selector: 'fuse-shortcuts',
    templateUrl: './shortcuts.component.html',
    styleUrls: ['./shortcuts.component.scss']
})
export class FuseShortcutsComponent implements OnInit, AfterViewInit, OnDestroy {
    shortcutItems: any[];
    navigationItems: any[];
    filteredNavigationItems: any[];
    searching: boolean;
    mobileShortcutsPanelActive: boolean;

    @Input()
    navigation: any;

    @ViewChild('searchInput', { static: false })
    searchInputField;

    @ViewChild('shortcuts', { static: false })
    shortcutsEl: ElementRef;

    // Private
    private _unsubscribeAll: Subject<any>;
    // fuseConfig: any;

    /**
     * Constructor
     *
     * @param {CookieService} _cookieService
     * @param {FuseMatchMediaService} _fuseMatchMediaService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {MediaObserver} _mediaObserver
     * @param {Renderer2} _renderer
     */
    constructor(
        private _cookieService: CookieService,
        private _fuseMatchMediaService: FuseMatchMediaService,
        private _fuseNavigationService: FuseNavigationService,
        private _mediaObserver: MediaObserver,
        private _renderer: Renderer2,
        private _authenticationService: AuthenticationService,
        private _translateService: TranslateService,
        private _gService: BaseService
        // private _fuseConfigService: FuseConfigService,
    ) {
        // Set the defaults
        this.shortcutItems = [];
        this.searching = false;
        this.mobileShortcutsPanelActive = false;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._fuseNavigationService.onNavigationRegistered
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                const flatNavigation = this._fuseNavigationService.getFlatNavigation(this._fuseNavigationService.getCurrentNavigation());
                this.filteredNavigationItems = this.navigationItems = flatNavigation;
                this._authenticationService.currentUser
                    .subscribe((data: any) => {
                        if (data && data.shortcuts) {
                            this.shortcutItems = data.shortcuts;
                        }
                    });
            });
    }

    ngAfterViewInit(): void {
        // Subscribe to media changes
        this._fuseMatchMediaService.onMediaChange
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                if (this._mediaObserver.isActive('gt-sm')) {
                    this.hideMobileShortcutsPanel();
                }
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Search
     *
     * @param event
     */
    search(event): void {
        const value = event.target.value.toLowerCase();
        if (value === '') {
            this.searching = false;
            this.filteredNavigationItems = this.navigationItems;

            return;
        }
        this.searching = true;
        this.filteredNavigationItems = this.navigationItems.filter((navigationItem) => {
            return this._translateService.instant(navigationItem.title).toLowerCase().includes(value)
                || (navigationItem.translate && this._translateService.instant(navigationItem.translate).toLowerCase().includes(value));
        });
    }

    /**
     * Toggle shortcut
     *
     * @param event
     * @param itemToToggle
     */
    toggleShortcut(event, itemToToggle): void {
        event.stopPropagation();
        for (let i = 0; i < this.shortcutItems.length; i++) {
            if (this.shortcutItems[i].url === itemToToggle.url) {
                this.shortcutItems.splice(i, 1);

                // Save to the cookies
                this._authenticationService.currentUserValue.shortcuts = this.shortcutItems;
                this._authenticationService.setCurrentUser(this._authenticationService.currentUserValue);
                this._gService.get(`users/${this._authenticationService.currentUserValue._id}`).subscribe((data: any) => {
                    data.shortcuts = this.shortcutItems;
                    this._gService.put(`users/${this._authenticationService.currentUserValue._id}`, data)
                        .subscribe((response: any) => { });
                });

                return;
            }
        }

        this.shortcutItems.push(itemToToggle);

        // Save to the cookies
        this._authenticationService.currentUserValue.shortcuts = this.shortcutItems;
        this._authenticationService.setCurrentUser(this._authenticationService.currentUserValue);
        this._gService.get(`users/${this._authenticationService.currentUserValue._id}`).subscribe((data: any) => {
            data.shortcuts = this.shortcutItems;
            this._gService.put(`users/${this._authenticationService.currentUserValue._id}`, data)
                .subscribe((response: any) => { });
        });
    }

    /**
     * Is in shortcuts?
     *
     * @param navigationItem
     * @returns {any}
     */
    isInShortcuts(navigationItem): any {
        return this.shortcutItems.find(item => {
            return item.url === navigationItem.url;
        });
    }

    /**
     * On menu open
     */
    onMenuOpen(): void {
        setTimeout(() => {
            this.searchInputField.nativeElement.focus();
        });
    }

    /**
     * Show mobile shortcuts
     */
    showMobileShortcutsPanel(): void {
        this.mobileShortcutsPanelActive = true;
        this._renderer.addClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
    }

    /**
     * Hide mobile shortcuts
     */
    hideMobileShortcutsPanel(): void {
        this.mobileShortcutsPanelActive = false;
        this._renderer.removeClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
    }
}
