import { Directive, Input, TemplateRef, ViewContainerRef, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { includes } from 'lodash';

@Directive({
    selector: '[ifPermitted]'
})
/**
 * This directive class is responsible for checking the permission(s) and if found then render the view.
 *
 * how to use?
 *  <div *ifPermitted="'permission_name'">Your content is here...</div>
 * 
 * OR (if permissions are provided in array, it will render it if any of them will be allowed)
 *  <div *ifPermitted="['permission_name1', 'permission_name2'">Your content is here...</div>
 *
 * @class PermissionDirective
 * 
 */
export class PermissionDirective implements OnInit {
    /**
     * Input permission name provided when using the directive.
     */
    @Input('ifPermitted') permissionItem: any;

    /**
     * @constructor
     * @param templateRef TemplateRef
     * @param viewContainer ViewContainerRef
     */
    private isAdmin: boolean;
    constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) { }

    /**
     * On Init check the permission.
     */
    ngOnInit(): void {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        let foundItem;
        // If logged in users is a super user is_super_user = true
        if (user && user.user_role === 'admin' || this.permissionItem === undefined) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
        // Provided permission should not be null or undefined.
        else if (this.permissionItem) {

            if (typeof this.permissionItem === 'string' && this.permissionItem === user.user_role) {

                foundItem = true;

            }
            else {

                this.permissionItem.forEach(permElement => {

                    if (permElement && (permElement.agency === user.user_agency || permElement.agency === environment.roAgency || permElement.agency === environment.casafariAgency || permElement.agency === environment?.roCostaBlancaAgency)) {

                        if (user.user_role === 'agent' || user.user_role === 'agent_medium') {

                            if (user._id === permElement.created_by || user._id === permElement.listing_agent || includes(permElement.offices, user.user_office_id)) {

                                foundItem = true;

                            }

                        } else {

                            foundItem = true;

                        }

                    } else if ((typeof permElement === 'string') && user.user_role === permElement) {
                        foundItem = true;
                    } else if (user.user_role !== 'agent_medium' && user.user_role === permElement) {
                        foundItem = true;
                    }

                });

            }

            // // Render only if permission exist.
            if (foundItem) {

                this.viewContainer.createEmbeddedView(this.templateRef);

            }


        }
    }
}
