import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppDirectivesModule } from 'app/directives/directives';
import { AddressMapComponent } from './address-map.component';
// import { AgmCoreModule } from '@agm/core';
import { GoogleMapsModule } from '@angular/google-maps'
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import { AppPipesModule } from 'app/pipes/app-pipes.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';



@NgModule({
    declarations: [
        AddressMapComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        GoogleMapsModule,
        // AgmCoreModule.forRoot({
        //     apiKey: 'AIzaSyB9C0iVNLS5_igud0vkq-_7wQTtMbmRgeI'
        // }),
        TranslateModule,
        FlexLayoutModule,
        MatToolbarModule,
        FuseDirectivesModule,
        AppDirectivesModule,
        NgSelectModule,
        ReactiveFormsModule,
        FormsModule,
        FusePipesModule,
        AppPipesModule,
        MatProgressSpinnerModule
    ],
    exports: [AddressMapComponent]
})
export class AddressMapModule { }
