import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PropertiesSearchService {

  private propertiesSearchFilter: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public propertiesSearchData: Observable<any> = this.propertiesSearchFilter.asObservable();

  constructor() { }

  public get getpropertiesSearchFilter(): any {
    return this.propertiesSearchFilter.value;
  }

  public setpropertiesSearchFilter(data: any): any {
      this.propertiesSearchFilter.next(data);
  }
}
