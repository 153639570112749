import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { QuickPanelComponent } from 'app/layout/components/quick-panel/quick-panel.component';
import { ClipboardModule } from 'ngx-clipboard';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MlsNotificationsModule } from 'app/main/authentication/agencies/create-agency/tabs/mls-sharing/dialog/mls-notifications/mls-notifications.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NotificationComponent } from './dialog/notification/notification.component';

import { TimeLineModule } from '../../../main/marketplace/message-boards/layout/timeline/timeline.module';


@NgModule({
    declarations: [
        QuickPanelComponent,
        NotificationComponent
    ],
    imports: [
        MatDividerModule,
        MatListModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        MatTabsModule,
        MatInputModule,
        MatDialogModule,
        MatBadgeModule,
        FuseSharedModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
        ClipboardModule,
        MatToolbarModule,
     
        MlsNotificationsModule,
        InfiniteScrollModule,
        TimeLineModule
    ],
    exports: [
        QuickPanelComponent
    ]
})
export class QuickPanelModule {
}
