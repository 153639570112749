import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'inactive-confirmation',
  templateUrl: './inactive-confirmation.component.html',
  styleUrls: ['./inactive-confirmation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InactiveConfirmationComponent implements OnInit {

  reason: string;

  constructor(
    public dialogRef: MatDialogRef<InactiveConfirmationComponent>
  ) { }

  ngOnInit(): void {
  }

}
