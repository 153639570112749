import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppProviders, GlobalFuntions } from 'app/_helpers';
import { forEach } from 'lodash';
import { Subject } from 'rxjs';
import { BaseService } from './../../../_helpers/base/base.service';
import { takeUntil, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
@Component({
    selector: 'form-dialog',
    templateUrl: './form-dialog.component.html',
    styleUrls: ['./form-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class FormDialogComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;
    public _fg: UntypedFormGroup;
    public loading: boolean;
    constructor(
        public dialogRef: MatDialogRef<FormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _app: AppProviders,
        public _matDialog: MatDialog,
        public _baseService: BaseService,
        private _globalFunctions: GlobalFuntions,
        private _toastr: ToastrService,
        private _translateService: TranslateService,
        private _router: Router
    ) {
        this._unsubscribeAll = new Subject();
        this._fg = this.createForm();
    }

    ngOnInit(): void {
        if (this._data.apiUpdate) {
            this._baseService.get(this._data.apiGet, 'nodejs').subscribe((data: any) => {
                this._fg.patchValue(data);
            });
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    /** create form */
    createForm(): UntypedFormGroup {
        const controls = {} as any;
        forEach(this._data.formFields, (field: any) => {
            /** if filed is for multi languages */
            if (field.type === 'input:multiLang') {
                const dynamicLang = {};
                forEach(this._app.siteLanguages, (lang) => {
                    dynamicLang[lang.id] = new UntypedFormControl({
                        value: '',
                        disabled: (field.disabled ? field.disabled : false)
                    }, (field.validation ? [Validators.required] : null));
                });
                controls[field.control] = new UntypedFormGroup(dynamicLang);
            } else {
                controls[field.control] = new UntypedFormControl({
                    value: (field.default ? field.default : ''),
                    disabled: (field.disabled ? field.disabled : false)
                }, (field.validation ? [Validators.required] : null));
            }
        });
        return new UntypedFormGroup(controls);
    }
    /** save form */
    public save(from): void {
        this._fg.markAllAsTouched();
        if (from.valid) {
            this.loading = true;
            const apiUrl = this._data.apiUpdate ? this._data.apiUpdate : this._data.apiCreate;
            const method = this._data.apiUpdate ? 'put' : 'post';
            delete from.value._id;
            const formData = this._globalFunctions.clearObject(from.value);

            this._baseService[method](apiUrl, formData, 'nodejs')
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(
                    (res: any) => {
                        this.loading = false;
                        const translatedErrorMessage = this._translateService.instant('saved successfully');
                        const translatedErrorField = this._translateService.instant('success!');
                        this._toastr.success(translatedErrorMessage, translatedErrorField, {
                            closeButton: true
                        });
                        this.dialogRef.close();
                        this.reloadCurrentRoute();
                    },
                    (err: any) => {
                        /** error is being handled by global error interceptor */
                        this.loading = false;
                    },
                    () => { this.loading = false; }
                );
        }
    }
    reloadCurrentRoute(): void {
        const currentUrl = this._router.url;
        this._router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this._router.navigate([currentUrl]);
        });
    }
}
