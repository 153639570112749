import { Component, OnInit, ViewEncapsulation, Input, Injector, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import * as _ from 'lodash';
import { fuseAnimations } from '@fuse/animations';
import { DropdownsService } from 'app/services/dropdowns.service';
import { TranslateService } from '@ngx-translate/core';
import { merge, Subject } from 'rxjs';
import { startWith, tap, switchMap, takeUntil } from 'rxjs/operators';
import { AccountsCreateComponent } from '../../accounts-create.component';
import { map } from 'lodash';

@Component({
    selector: 'tab-contact',
    templateUrl: './tab-contact.component.html',
    styleUrls: ['./tab-contact.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})

export class TabContactComponent implements OnInit, AfterViewChecked {

    @Input() form: UntypedFormGroup;
    languages: any;
    currentLang: string;
    partners: UntypedFormArray;
    searching: boolean;
    countries: any;
    countrySearch: UntypedFormControl = new UntypedFormControl('');
    regions: any;
    regionSearch: UntypedFormControl = new UntypedFormControl('');
    provinces: any;
    provinceSearch: UntypedFormControl = new UntypedFormControl('');
    cities: any;
    citySearch: UntypedFormControl = new UntypedFormControl('');
    _accountsCreateComponent: AccountsCreateComponent;
    private _unsubscribeAll: Subject<any>;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService,
        private _dropdownsService: DropdownsService,
        private cdRef: ChangeDetectorRef,
        private injector: Injector,
    ) {
        this.currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {

        this._translateService.onLangChange
            .pipe(startWith(''), takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
                this.getLanguages();
            });

        if (!this.form) {
            this._accountsCreateComponent = this.injector.get(AccountsCreateComponent) as AccountsCreateComponent;
            this.form = this._accountsCreateComponent.form;
        }
        this.partners = this.form.get('partners') as UntypedFormArray;

        // this._dropdownsService.getCommunicationLanguages().subscribe((data: any) => {
        //     this.comLang = data.body;
        // });

        merge(this.countrySearch.valueChanges, this.form.get('country').valueChanges)
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    let search = this.form.get('country').value;
                    if (this.countrySearch.value) {
                        search = this.countrySearch.value;
                    }
                    return this._dropdownsService.getCountries(search);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.countries = data.docs;
            });

        merge(this.regionSearch.valueChanges, this.form.get('region').valueChanges, this.form.get('country').valueChanges)
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    let search = this.form.get('region').value;
                    if (this.regionSearch.value) {
                        search = this.regionSearch.value;
                    }
                    return this._dropdownsService.getRegions(search, this.form.get('country').value);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.regions = data.docs;
            });

        merge(this.provinceSearch.valueChanges, this.form.get('province').valueChanges, this.form.get('region').valueChanges)
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    let search = this.form.get('province').value;
                    if (this.provinceSearch.value) {
                        search = this.provinceSearch.value;
                    }
                    return this._dropdownsService.getProvinces(search, false, this.form.get('region').value);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.provinces = data.docs;
            });

        merge(this.citySearch.valueChanges, this.form.get('city').valueChanges, this.form.get('province').valueChanges)
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    let search = this.form.get('city').value;
                    if (this.citySearch.value) {
                        search = this.citySearch.value;
                    }
                    return this._dropdownsService.getCities(search, false, false, this.form.get('province').value);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.cities = data.docs;
            });
    }

    getLanguages(): void {
        this._dropdownsService.getLanguages()
            .subscribe((data: any) => {
                const languages = map(data.body, (body) => {
                    body.label = body.value[this.currentLang];
                    body.value = body.key;
                    return body;
                });
                this.languages = languages;
            });
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }
}
