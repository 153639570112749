import { Component, OnInit, ViewEncapsulation, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DistributionListService } from 'app/services/distribution-list.service';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { CreateListComponent } from '../create-list/create-list.component';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { ViewListComponent } from '../view-list/view-list.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'distribution-list',
    templateUrl: './distribution-list.component.html',
    styleUrls: ['./distribution-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DistributionListComponent implements OnInit, OnDestroy {

    private _unsubscribeAll: Subject<any>;
    loadingResults: boolean;
    displayedColumns: string[] = ['list_name', 'list_data', 'created_at', 'actions'];
    distributionList: any;

    constructor(
        public matDialogRef: MatDialogRef<DistributionListComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _distributionListService: DistributionListService,
        private _translateService: TranslateService,
        public _matDialog: MatDialog,
        private _snackBar: MatSnackBar,
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.getList();
    }

    getList(): void {
        this._distributionListService.getList(this._data.relatedTo)
            .pipe(
                tap(() => this.loadingResults = true),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe((data: any) => {
                this.loadingResults = false;
                this.distributionList = data;
            }, () => this.loadingResults = false);
    }

    addList(): void {
        const dialogRef = this._matDialog.open(CreateListComponent);
        dialogRef.afterClosed()
            .subscribe(response => {
                if (response) {
                    this._distributionListService.addList({ list_name: response })
                        .pipe(
                            tap(() => this.loadingResults = true),
                            takeUntil(this._unsubscribeAll)
                        )
                        .subscribe(() => this.getList(), () => this.loadingResults = false);
                }
            });
    }

    addSubscribers(listId: string): void {
        this._distributionListService.addSubscribersToList(this._data.ids, listId, this._data.relatedTo)
            .pipe(
                tap(() => this.loadingResults = true),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe((data:any) => {
                if(data !== undefined){
                    let message = '';
                    if (data.added && data.added > 0) {
                        message += `${data.added} ${this._translateService.instant('new subscriber/s added')}. `;
                    }
                    if (data.skipped && data.skipped > 0) {
                        message += `${data.skipped} ${this._translateService.instant('subscriber/s already exist')}. `;
                    }
                    if (data.failed && data.failed > 0) {
                        message += `${data.failed} ${this._translateService.instant('subscriber/s failed to add')}. `;
                    }
                    this._snackBar.open(message.trim(), this._translateService.instant('Close'), {
                        duration: 5000,
                    });
                }
                this.getList(), () => this.loadingResults = false
            });
    }

    deleteList(listId: string): void {
        const dialog = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });
        dialog.componentInstance.confirmMessage = this._translateService.instant('Are you sure you want to delete?');
        dialog.afterClosed().subscribe(result => {
            if (result) {
                this._distributionListService.deleteList(listId)
                    .pipe(
                        tap(() => this.loadingResults = true),
                        takeUntil(this._unsubscribeAll)
                    )
                    .subscribe(() => this.getList(), () => this.loadingResults = false);
            }
        });

    }

    viewSubscribers(data: string): void {
        const dialogRef = this._matDialog.open(ViewListComponent, {
            panelClass: 'subscribersList-dialog',
            data: {
                list_data: data,
                relatedTo: this._data.relatedTo
            },
        });
    }

    selectList(listId: string): void {
        this._distributionListService.getSubscribersList({ list_id: listId }, this._data.relatedTo)
            .pipe(
                tap(() => this.loadingResults = true),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe((data: any) => {
                this.loadingResults = false;
                const items = [];
                for (const item of data) {
                    items.push(item.model_data.email);
                }
                this.matDialogRef.close(items);
            }, () => this.loadingResults = false);
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
