import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { AuthenticationService, QuestionaireService } from 'app/services';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { forEach } from 'lodash';
import { AccountsService } from 'app/services/accounts.service';
import { UserService } from 'app/services';

@Component({
  selector: 'app-questionaires',
  templateUrl: './questionaires.component.html',
  styleUrls: ['./questionaires.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuestionairesComponent implements OnInit {

  user: any;
  filter: any;
  filled = {} as any;
  filledQuestionnaires = [] as any;
  answers = [] as any;
  form: UntypedFormGroup;
  payLoad = '';
  title: any;
  systemlang = null as any;
  date: any;
  latest: any;
  filledQuestionnaire = {} as any;
  formData = [];
  showQuestions = false;
  constructor(
    public matDialogRef: MatDialogRef<QuestionairesComponent>,
    private _authenticationService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _questionaireService: QuestionaireService,
    private formBuilder: UntypedFormBuilder,
    public _translateService: TranslateService,
    private _accountsService: AccountsService,
    private _userService: UserService,
  ) {
    console.log(this._data);
    this.systemlang = this._translateService.currentLang;
    this.user = this._authenticationService.currentUserValue;
    this.filter = {
      agency: { $in: [this.user.user_agency, '56b06e4d2bc1d95f60cedef2'] },
      status: 'active',
    };
    if (!_.isEmpty(this._data)) {
      this.filled.agency = { $in: [this.user.user_agency] };
      if (this._data._id) {
        this.filled.activity_id = this._data._id;
      }
      if (this._data.property_id) {
        this.filled.property_id = this._data.property_id;
      }
    } else {
      this.filled = {
        agency: { $in: [this.user.user_agency] }
      };
    }

    if(this._data.status && this._data.status == 'Booked'){
      this.showQuestions = true;
    }

    this.date = new Date().toISOString();
  }


  ngOnInit(): void {
    this._questionaireService.getLatest(this.filter).subscribe((data: any) => {
      if (data) {
        this.latest = data;
        let questions = data.questions;
        if (!_.isArray(questions)) {
          questions = _.toArray(questions);
        }
        this.formData = questions;

        this.title = data.title[this.systemlang];
      }
    });

    this._questionaireService.getFilled(this.filled).subscribe((data: any) => {
      forEach(data, (value) => {
        if (value.created_by && value.created_by !== '') {
          this._userService.getById(value.created_by).subscribe((user: any) => {
            if (user && user.full_name) {
              value.user_full_name = user.full_name;
            } else if (user.firstname) {
              value.user_full_name = user.firstname;
            }
          });
        }
        if (value.account_id && value.account_id !== '') {
          this._accountsService.getById(value.account_id).subscribe((account: any) => {
            if (account && account.full_name) {
              value.account_full_name = account.full_name;
            } else if (account.forename) {
              value.account_full_name = account.forename;
            }
          });
        }
      });
      this.filledQuestionnaires = _.map(data, (d) => {
        if (!_.isEmpty(d)) {
          d.questions = Object.values(d.questions);
        }
        return d;
      });
    });

    this.form = this.formBuilder.group({
      agency: this.user.user_agency,
      created_by: this.user.user_id,
      created_at: this.date,
      question: [],
      answer: [],
      type: [],
      questions: this.formBuilder.group({
        question: [],
        answer: [],
        type: [],
      }),
      activity_id: [],
      property_id: [],
      questionnaire_id: [],
      account_id: [],

    });
  }

  onSubmit(data?: any): void {
    const questions = {};
    for (let i = 0; i < data.length; i++) {
      questions[i] = data[i];
    }

    const filledQuestionnaire = {} as any;
    filledQuestionnaire.agency = this.user.user_agency;
    filledQuestionnaire.created_by = this.user.user_id;
    filledQuestionnaire.created_at = this.date;
    filledQuestionnaire.questions = questions;
    filledQuestionnaire.questionnaire_id = this.latest._id;
    if (this._data) {
      filledQuestionnaire.activity_id = this._data._id;
    }
    if (this._data.account) {
      filledQuestionnaire.account_id = this._data.account;
    }
    if (this._data.property_id) {
      filledQuestionnaire.property_id = this._data.property_id;
    }

    this._questionaireService.createQuestion(filledQuestionnaire).subscribe((response: any) => {
      this.matDialogRef.close(response);
    });
  }

}
