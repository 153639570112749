import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplateViewerComponent } from './template-viewer.component';
import { TranslateModule } from '@ngx-translate/core';
import { FroalaViewModule } from 'angular-froala-wysiwyg';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';



@NgModule({
    declarations: [
        TemplateViewerComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        MatToolbarModule,
        TranslateModule,
        FroalaViewModule
    ],
    exports: [
        TemplateViewerComponent
    ]
})
export class TemplateViewerModule { }
