import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'getBoatMooringUrl'
})
export class GetBoatMooringUrlPipe implements PipeTransform {

    constructor(
        private _translateService: TranslateService,
    ) { }

    transform(property: any, locale?: any): any {
        let url = property.agency_data?.property_url;
        if (url) {
            let model = 'mooring';
            const lang = locale === 'es_AR' ? 'ES' : 'EN';
            let title = '';
            if (property.title && property.title[lang]) {
                title = property.title[lang];
            }
            else if (property.title && property.title.EN) {
                title = property.title.EN;
            }
            else {
                let type='';
                if (property.type == 'mooring') {
                    type = property.type_one ? property.type_one : '';
                } else if (property.type == 'boat') {
                    model = 'boat';
                    type = property.boat_type?.value[locale];
                }
                title = type;
                const location = property.property_city?.accent_value[locale];
                if(location){
                    title = title + '-' + this._translateService.instant('in') + '-' + location;
                }
                if(title){
                    title = title.replace(' ', '-');
                }else{
                    title = property.reference;
                }
            }
            url = url.replace('/property/','/'+model+'/').replace('[url_title]',title).replace('[url_reference]', property.reference);            
        }
        return url;
    }
}
