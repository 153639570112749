import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FaqsService {

  constructor(private http: HttpClient) { }

  getData(page: number, perPage: number, filter?: any): Observable<any[]> {
    return this.http.get<any[]>(`${environment.yiiUrl}faqs?page=` + page + `&per-page=${perPage}` + (filter ? filter : ''), { observe: 'response' as 'body' })
      .pipe(map(data => {
        return data;
      }));
  }

  getGroups(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.yiiUrl}faqs/groups`,
      { observe: 'response' as 'body' });
  }

  getSubGroupsWithData(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.yiiUrl}faqs?per-page=`,
      { observe: 'response' as 'body' });
  }

  createData(data: any): Observable<object> {
    return this.http.post(`${environment.yiiUrl}faqs`, data);
  }

  updateData(data: any): Observable<object> {
    return this.http.put(`${environment.yiiUrl}faqs/${data._id}`, data);
  }

  deleteData(_id: any, createdBy: any): Observable<any> {
    return this.http.delete(`${environment.yiiUrl}faqs/${_id}?created_by=${createdBy}`);
  }

  getByUrl(url: any): Observable<any> {
    return this.http.get<any[]>(`${environment.yiiUrl}faqs/by-url?url=${url}`);
  }

  getUrls(): Observable<any> {
    return this.http.get(`${environment.apiUrl}crm_menu/with_urls`);
  }
}
