import { Injectable } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { includes } from 'lodash';

@Injectable()
export class MissingTranslations implements MissingTranslationHandler {

    missingKeys = [];

    constructor(router: Router) {

        router.events.subscribe((e: any) => {
            if (e instanceof NavigationEnd) {
                localStorage.setItem('missingTranslations', JSON.stringify(this.missingKeys));
            }
        });
    }

    handle(params: MissingTranslationHandlerParams): any {
        if (!includes(this.missingKeys, params.key)) {
            this.missingKeys = [...this.missingKeys, params.key];
        }
        return params.key;
    }
}
