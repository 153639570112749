import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class QuestionaireService {

  constructor(private http: HttpClient) { }

  getLatest(filter: any): Observable<any[]> {
    return this.http.post<any[]>(`${environment.apiUrl}questionnaires/get-latest`, filter);
  }
  createQuestion(data: any): Observable<any[]> {
    return this.http.post<any[]>(`${environment.apiUrl}filled-questionnaires/create`, data);
  }
  getFilled(filter: any): Observable<any[]> {
    return this.http.post<any[]>(`${environment.apiUrl}filled-questionnaires`, filter);
  }
}
