import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-owner-confirm-modal',
  templateUrl: './owner-confirm-modal.component.html',
  styleUrls: ['./owner-confirm-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OwnerConfirmModalComponent implements OnInit {

  constructor(
    public matDialogRef: MatDialogRef<OwnerConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
  ) { }

  ngOnInit(): void {
  }

}
