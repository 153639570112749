import { NgModule } from '@angular/core';
import { NgSelectFormFieldControlDirective } from './ng-select/ng-select.directive';
import { DisableUserDirective } from './disable-user/disable-user.directive';
import { ComposeEmailDirective } from './compose-email/compose-email.directive';
import { EventSequencesDirective } from './event-sequences/event-sequences.directive';
import { AddressMapDirective } from './maps/address-map.directive';
import { DropZoneDirective } from './drop-zone/drop-zone.directive';
import { DistributionListDirective } from './distribution-list/distribution-list.directive';
import { DrawMapDirective } from './maps/draw-map.directive';
import { GooglePlacesDirective } from './google-places/google-places.directive';
import { MailchimpDirective } from './mailchimp/mailchimp.directive';
import { FormateDatepickerDirective } from './formate-datepicker/formate-datepicker.directive';
import { PdfTemplatesDirective } from './pdf-templates/pdf-templates.directive';
import { WindowCardsDirective } from './window-cards/window-cards.directive';
import { JsonViewerDirective } from './json-viewer/json-viewer.directive';
import { TemplateViewerDirective } from './template-viewer/template-viewer.directive';
import { AppTwoDigitDecimaNumberDirective } from './app-two-digit-decima-number/app-two-digit-decima-number.directive';
import { FormSubmitOnEnterDirective } from './formSubmitOnEnter/form-submit-on-enter.directive';
import { ReloadAddressDirective } from './maps/reload-address.directive';
import { CustomFormlyDirective } from './custom-formly/custom-formly.directive';
import { PermissionDirective } from './permission.directive';
import { MlsPlansDirective } from './mls-plans/mls-plans.directive';
import { IfIsMlsDirective } from './if-is-mls/if-is-mls.directive';
import { OfficeGroupDirective } from './office-group/office-group.directive';
import { OfficesDirective } from './offices/offices.directive';
import { DisableControlDirective } from './disable-control/disable-control.directive';
import { ImgFallbackDirective } from './img-fallback.directive';
import { EditableContentDirective } from './editable-content/editable-content';
import { AppNonDigitDecimaNumberDirective } from './non-decima-number/app-non-digit-decima-number.directive';
import { SendinblueDirective } from './sendinblue/sendinblue.directive';
import { SendgridDirective } from './sendgrid/sendgrid.directive';
import { DownloadAttachmentsDirective } from './download-attachments/download-attachments.directive';

@NgModule({
  declarations: [
    NgSelectFormFieldControlDirective,
    DisableUserDirective,
    ComposeEmailDirective,
    OfficeGroupDirective,
    OfficesDirective,
    EventSequencesDirective,
    AddressMapDirective,
    DropZoneDirective,
    DistributionListDirective,
    DrawMapDirective,
    GooglePlacesDirective,
    MailchimpDirective,
    FormateDatepickerDirective,
    PdfTemplatesDirective,
    WindowCardsDirective,
    JsonViewerDirective,
    TemplateViewerDirective,
    AppTwoDigitDecimaNumberDirective,
    AppNonDigitDecimaNumberDirective,
    FormSubmitOnEnterDirective,
    ReloadAddressDirective,
    CustomFormlyDirective,
    PermissionDirective,
    MlsPlansDirective,
    IfIsMlsDirective,
    DisableControlDirective,
    ImgFallbackDirective,
    EditableContentDirective,
    SendinblueDirective,
    SendgridDirective,
    DownloadAttachmentsDirective
  ],
  imports: [],
  exports: [
    NgSelectFormFieldControlDirective,
    DisableUserDirective,
    ComposeEmailDirective,
    OfficeGroupDirective,
    OfficesDirective,
    EventSequencesDirective,
    AddressMapDirective,
    DropZoneDirective,
    DistributionListDirective,
    DrawMapDirective,
    GooglePlacesDirective,
    MailchimpDirective,
    FormateDatepickerDirective,
    PdfTemplatesDirective,
    WindowCardsDirective,
    JsonViewerDirective,
    TemplateViewerDirective,
    AppTwoDigitDecimaNumberDirective,
    AppNonDigitDecimaNumberDirective,
    FormSubmitOnEnterDirective,
    ReloadAddressDirective,
    CustomFormlyDirective,
    PermissionDirective,
    MlsPlansDirective,
    IfIsMlsDirective,
    DisableControlDirective,
    ImgFallbackDirective,
    EditableContentDirective,
    SendinblueDirective,
    SendgridDirective,
    DownloadAttachmentsDirective,
  ]
})
export class AppDirectivesModule { }
