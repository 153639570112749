import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { forEach, map as lodashMap, toString } from 'lodash';
import { GlobalFuntions } from 'app/_helpers/global.funtions';
@Injectable({
    providedIn: 'root'
})
export class CmsSitesService {

    id = 1;

    constructor(
        private http: HttpClient,
        private _globalFuntions: GlobalFuntions,

    ) { }

    getPageTemplate(filter: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}cms-page-template`, filter);
    }
    createPageTemplate(filter: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}cms-page-template/create`, filter);
    }
    updatePageTemplate(filter: any): Observable<any> {
        return this.http.put(`${environment.apiUrl}cms-page-template/update/${filter._id}`, filter);
    }
    deletePageTemplate(id: any): Observable<any> {
        return this.http.delete(`${environment.apiUrl}cms-page-template/delete/${id}`);
    }
    viewPageTemplate(id?: any): Observable<any> {
        return this.http.get(`${environment.apiUrl}cms-page-template/view/${id}`);
    }
    // <-- !CMS MENU -->

    // <-- CMS POST TYPES -->

    getActiveSites(filter: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}cms_sites`, filter);
    }

    getArchivedSites(filter: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}cms_sites/archived/`, filter);
    }

    archiveSite(data: any): Observable<any> {
        return this.http.put<any[]>(`${environment.apiUrl}cms_sites/update/${data._id}`, data);
    }

    deleteSite(data: any): Observable<any> {
        return this.http.delete<any[]>(`${environment.apiUrl}cms_sites/delete/${data._id}`);
    }

    createSites(data: any): Observable<any> {
        return this.http.post<any[]>(`${environment.apiUrl}cms_sites/create`, data);
    }

    updateSites(data: any): Observable<any> {
        return this.http.put<any[]>(`${environment.apiUrl}cms_sites/update/${data._id}`, data);
    }

    getOfficeName(data: any): Observable<object> {
        return this.http.post(`${environment.yiiUrl}offices/names`, data);
    }

    // <-- CMS MENU -->

    createMenu(data: any): Observable<object> {
        return this.http.post(`${environment.yiiUrl}cms-menus`, data);
    }

    updateMenu(data: any): Observable<object> {
        return this.http.put(`${environment.yiiUrl}cms-menus/${data._id}`, data);
    }

    getMenus(id: any, page?:any, perPage?:any): Observable<object> {
        return this.http.get(`${environment.yiiUrl}cms-menus?expand=host&site_id=${id}&page=${page}&per-page=${perPage}`, { observe: 'response' as 'body' });
    }

    getPages(id: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}cms-pages?type=page&pagination=false&site_id=${id}`);
    }

    getPosts(id: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}cms-pages?type=post&pagination=false&site_id=${id}`);
    }

    getMenuById(id: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}cms-menus/${id}?expand=host`)
            .pipe(map((data: any) => {
                data = this._globalFuntions.cleanObject(data);
                this.id = 1;
                data.menu_items = this.setIems(data.menu_items);
                return data;
            }));
    }

    private setIems(menuItems: any): any {
        // let id = 1;
        return lodashMap(menuItems, (item) => {
            item.item.id = toString(this.id);
            this.id++;
            // if (item.item.id && item.item.id.oid) {
            //     item.item.id = item.item.id.oid;
            // } else {
            //     item.item.id = item.item.id;
            // }
            if (item.children) {
                item.children = this.setIems(item.children);
            }
            return item;
        });
    }

    deleteMenus(id: any, createdBy: any): Observable<any> {
        return this.http.delete<any[]>(`${environment.apiUrl}cms_menus/${id}?created_by=${createdBy}`);
    }
    // <-- !CMS MENU -->

    // <-- CMS CATEGORIES -->

    getCategories(id: any, page?: any, perPage?: any): Observable<object> {
        return this.http.get(`${environment.yiiUrl}cms-categories?expand=host&site_id=${id}&page=${page}&per-page=${perPage}`, { observe: 'response' as 'body' });
    }

    getCategoryById(id: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}cms-categories/${id}?expand=host`, { observe: 'response' as 'body' });
    }

    createCategory(data: any): Observable<any> {
        return this.http.post(`${environment.yiiUrl}cms-categories`, data);
    }

    updateCategory(data: any): Observable<any> {
        return this.http.put(`${environment.yiiUrl}cms-categories/${data._id}`, data);
    }

    deleteCategory(id: any, createdBy: any): Observable<any> {
        return this.http.delete(`${environment.yiiUrl}cms-categories/${id}?created_by=${createdBy}`);
    }
    // <-- !CMS CATEGORIES -->

    // <-- CMS POST TYPES -->

    getPostTypes(id: any, data: any, page?: any, perPage?: any): Observable<object> {
        return this.http.post(`${environment.yiiUrl}cms-post-types/search?expand=host,pages_title&site_id=${id}&page=${page}&per-page=${perPage}`, data, { observe: 'response' as 'body' });
    }

    createPostType(data: any): Observable<any> {
        return this.http.post(`${environment.yiiUrl}cms-post-types`, data);
    }

    updatePostType(data: any): Observable<any> {
        return this.http.put(`${environment.yiiUrl}cms-post-types/${data._id}`, data);
    }

    getPostTypeById(id: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}cms-post-types?post_type_id=${id}?expand=host`);
    }

    deletePostType(id: any, createdBy: any): Observable<any> {
        return this.http.delete(`${environment.yiiUrl}cms-post-types/${id}?created_by=${createdBy}`);
    }
    // <-- !CMS POST TYPES -->

    // <-- CMS GENERAL SETTINGS -->

    addSettings(data: any): Observable<any> {
        return this.http.post(`${environment.yiiUrl}cms-settings`, data);
    }

    updateSettings(data: any): Observable<any> {
        return this.http.put(`${environment.yiiUrl}cms-settings/${data._id}`, data);
    }

    getRecord(id: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}cms-settings/get-record/?site_id=${id}`);
    }

    getSetting(id: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}cms-settings/${id}?expand=host`);
    }

    uploadImage(id: string, data: any, model: any, file_name: any): Observable<any> {
        const formData: any = new FormData();
        formData.append('id', id);
        formData.append('model', model);
        formData.append('file', data);
        formData.append('file_name', file_name);
        return this.http.post(`${environment.yiiUrl}users/upload-single`, formData);
    }
    // <-- !CMS GENERAL SETTINGS -->

    // <-- CMS TRANSLATIONS -->

    getTranslations(data: any, page: any, id: any): Observable<any> {
        return this.http.post(`${environment.yiiUrl}cms-translations/search?page=${page}&expand=host&site_id=${id}`, data, { observe: 'response' as 'body' });
    }

    addTranslation(data: any): Observable<any> {
        return this.http.post(`${environment.yiiUrl}cms-translations`, data);
    }

    updateTranslation(data: any): Observable<any> {
        return this.http.put(`${environment.yiiUrl}cms-translations/${data._id}`, data);
    }

    deleteTranslation(id: any): Observable<any> {
        return this.http.delete(`${environment.yiiUrl}cms-translations/${id}`);
    }
    // <-- !CMS TRANSLATIONS -->

    getLanguages(id: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}cms-languages?site_id=${id}`);
    }

    // <-- CMS LANGUAGES -->

    getAllLanguages(id: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}cms-languages?expand=host&site_id=${id}`, { observe: 'response' as 'body' });
    }

    addLanguage(data: any): Observable<any> {
        return this.http.post(`${environment.yiiUrl}cms-languages`, data);
    }

    updateLanguage(data: any): Observable<any> {
        return this.http.put(`${environment.yiiUrl}cms-languages/${data._id}`, data);
    }

    deleteLanguages(id: any, createdBy: any): Observable<any> {
        return this.http.delete(`${environment.yiiUrl}cms-languages/${id}?created_by=${createdBy}`);
    }
    // <-- !CMS LANGUAGES -->

    // <-- CMS POSTS -->

    getAllPosts(filter: any, page: any, lang: any, id: any, type: any, perPage?: any): Observable<any> {
        // tslint:disable-next-line: max-line-length
        return this.http.post(`${environment.yiiUrl}cms-pages/search?&expand=host&type=${type}&page=${page}&expand=host&site_id=${id}&lang=${lang}&site_id=${id}&per-page=${perPage}`, filter, { observe: 'response' as 'body' });
    }

    getById(id: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}cms-pages/${id}?expand=host,template`);
    }

    updatePage(data: any): Observable<any> {
        return this.http.put(`${environment.yiiUrl}cms-pages/${data._id}`, data);
    }

    insertPage(data: any): Observable<any> {
        return this.http.post(`${environment.yiiUrl}cms-pages`, data);
    }

    getAllCategories(id: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}cms-categories?type=category&per-page=1000&site_id=${id}`, { observe: 'response' as 'body' });
    }

    deletePages(id: any, createdBy: any): Observable<any> {
        return this.http.delete(`${environment.yiiUrl}cms-pages/${id}?created_by=${createdBy}`);
    }

    getTags(id: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}cms-categories?type=tag&per-page=1000&site_id=${id}`, { observe: 'response' as 'body' });
    }
    // <-- !CMS POSTS -->

    // <-- CMS MEDIA -->

    updateAttachment(data: any): Observable<any> {
        return this.http.put(`${environment.yiiUrl}cms-medias/${data._id}`, data);
    }

    mediaGallery(id: any, modelName: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}cms-medias/get-cms-medias?id=${id}&modelName=${modelName}&page=null`);
    }

    uploadMedia(id: string, data: any, model: any): Observable<any> {
        const formData: any = new FormData();
        formData.append('id', id);
        formData.append('model', model);
        formData.append('file', data);
        return this.http.post(`${environment.yiiUrl}users/upload-single?cms=true&model_id=${id}&model_name=${model}`, formData);
    }
    // <-- !CMS MEDIA -->

    /* Download Csv */
    post(modelUrl: string, form: any, remote?: any, options?: any): Observable<any> {
        let server: string;
        if (remote === 'nodejs') {
            server = environment.apiUrl;
        } else {
            server = environment.yiiUrl;
        }
    
        options = options ? options : {};
    
        return this.http.post<any>(`${server}${modelUrl}`, form, options);
    }

    /* upload Csv */
    uploadCsv(data, modelType): Observable<any> {
        return this.http.post(
            `${environment.apiUrl}cms_translations/import-data`,
            { importData: data, modelType: modelType }
        );
    }
}
