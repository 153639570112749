import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { merge } from 'rxjs';

@Injectable()
export class MatPaginatorCustomIntl extends MatPaginatorIntl {
    itemsPerPageLabel = 'Items per page:';
    nextPageLabel = 'Next page';
    previousPageLabel = 'Previous page';
    lastPageLabel = 'Last page';
    firstPageLabel = 'First page';

    constructor(private _translateService: TranslateService) {
        super();
        merge(
            this._translateService.onLangChange,
            this._translateService.onTranslationChange,
            this._translateService.onDefaultLangChange
        ).subscribe(() => {
            this.translateLabels();
        });
        this.translateLabels();
    }

    getRangeLabel = (
        page: number,
        pageSize: number,
        length: number
    ): string => {
       let matActions = ['mat-mdc-paginator-navigation-previous', 'mat-mdc-paginator-navigation-first', 'mat-mdc-paginator-navigation-next', 'mat-mdc-paginator-navigation-last'];
        if (length === 0 || pageSize === 0) {
            return `0 ${this._translateService.instant('of')} ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex =
            startIndex < length
                ? Math.min(startIndex + pageSize, length)
                : startIndex + pageSize;

        const scrollToTop = () => {
            console.log(' scrollToTop ===>>>');

            // this use for table scroll
            let matTableclassTag =
                document.getElementsByClassName('mat-mdc-table')[0];
            // this use for window scroll
            let innerScrollclassTag =
                document.getElementsByClassName('ps--active-y')[0];

            // in somecase there are only mat-pagination used not table
            if (typeof matTableclassTag !== 'undefined') {
                matTableclassTag.scrollTo({ top: 10, behavior: 'auto' });
            }
            if (typeof innerScrollclassTag !== 'undefined') {
                innerScrollclassTag.scrollTo({ top: 10, behavior: 'auto' });
            }
        };

        // this is use to scroll up page to top.
        let matPaginatorBtn =
            document.getElementsByClassName('mat-mdc-icon-button');
        Array.from(matPaginatorBtn).forEach(function (element) {
            if (
                element &&
                element.parentNode &&
                (<Element>element.parentNode).className == 'mat-mdc-paginator-range-actions'
            ) {
                matActions.forEach(function (actionClass) {
                    if (element.className.includes(actionClass)) {
                        element.addEventListener('click', scrollToTop);
                    }else {
                        element.addEventListener('click', scrollToTop);
                    }
                });
            }
        });
        // mat-option is use as it is based on click fun.
        let matPaginatorOpt = document.getElementsByClassName('mat-mdc-option');
        Array.from(matPaginatorOpt).forEach(function (element) {
            element.addEventListener('click', scrollToTop);
        });
        return `${
            startIndex + 1
        } – ${endIndex} ${this._translateService.instant('of')} ${length}`;
    };

    translateLabels(): any {
        this.itemsPerPageLabel =
            this._translateService.instant('Items per page:');
        this.nextPageLabel = this._translateService.instant('Next page');
        this.previousPageLabel =
            this._translateService.instant('Previous page');
        this.lastPageLabel = this._translateService.instant('Last page');
        this.firstPageLabel = this._translateService.instant('First page');
        this.changes.next();
    }
}
