import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root'
})
export class MailchimpService {

    constructor(
        private http: HttpClient,
        private _authenticationService: AuthenticationService
    ) { }

    getList(ids: any): Observable<any> {
        return this.http.post(`${environment.yiiUrl}accounts/multi-accounts-mailchimp?type=GET`, ids);
    }

    addToList(id: any, ids: any, owner?: boolean): Observable<any> {
        return this.http.post(`${environment.yiiUrl}accounts/multi-accounts-mailchimp?type=Multi_Accounts&list_id=${id}${owner ? '&owner=true' : ''}`, ids);
    }

    removeFromList(id: any, ids: any, owner?: boolean): Observable<any> {
        return this.http.post(`${environment.yiiUrl}accounts/multi-accounts-mailchimp?type=Multi_Accounts_Delete&list_id=${id}${owner ? '&owner=true' : ''}`, ids);
    }

}
