import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Countries } from './../models/countries';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  constructor(private http: HttpClient) { }

  getData(filter: any): Observable<Countries[]> {
    return this.http.post<Countries[]>(`${environment.apiUrl}countries`, filter);
  }

  createData(data: any): Observable<object> {
    return this.http.post(`${environment.apiUrl}countries/create`, data);
  }

  updateData(data: any): Observable<object> {
    return this.http.put(`${environment.apiUrl}countries/update/${data._id}`, data);
  }

  deleteData(_id: any): Observable<any> {
    return this.http.delete(`${environment.apiUrl}countries/delete/${_id}`);
  }

  getListData(filter: any): Observable<object> {
    return this.http.post<Countries[]>(`${environment.apiUrl}countries`, filter);
  }
  // this is use for commerical properties in offer create
  getSearchByKey(filter: any): Observable<object> {
    return this.http.post<any[]>(`${environment.apiUrl}countries/search-key`, filter);
  }

  getModel(): Observable<object> {
    return this.http.get<any>(`${environment.yiiUrl}agencies/get-exclusions?model=countries`);
  }

  updateModel(data: any): Observable<object> {
    return this.http.post<any>(`${environment.yiiUrl}agencies/model-exclusions`, data);
  }

  getAllModel(): Observable<object> {
    return this.http.get<any>(`${environment.yiiUrl}agencies/is-all-check?model=countries`);
  }

  updateAllModel(data: any): Observable<object> {
    return this.http.post<any>(`${environment.yiiUrl}agencies/model-all-exclusions`, data);
  }
}
