// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-card {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: stretch center;
  align-items: stretch;
  flex: 1 1 0;
}
mat-card mat-card-content {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  max-width: 100%;
  place-content: stretch flex-start;
  align-items: stretch;
}
mat-card mat-card-content mat-icon {
  margin-right: 10px;
}
mat-card mat-card-content .info-row {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/custom-mat-password-strength-info/mat-password-strength-info.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,sBAAA;EACA,aAAA;EACA,6BAAA;EACA,oBAAA;EACA,WAAA;AACF;AACE;EACE,sBAAA;EACA,sBAAA;EACA,aAAA;EACA,eAAA;EACA,iCAAA;EACA,oBAAA;AACJ;AACI;EACE,kBAAA;AACN;AAEI;EACE,mBAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;AAAN","sourcesContent":["mat-card {\n  flex-direction: row;\n  box-sizing: border-box;\n  display: flex;\n  place-content: stretch center;\n  align-items: stretch;\n  flex: 1 1 0;\n\n  mat-card-content {\n    flex-direction: column;\n    box-sizing: border-box;\n    display: flex;\n    max-width: 100%;\n    place-content: stretch flex-start;\n    align-items: stretch;\n\n    mat-icon {\n      margin-right: 10px;\n    }\n\n    .info-row {\n      flex-direction: row;\n      box-sizing: border-box;\n      display: flex;\n      align-items: center;\n    }\n\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
