import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuditService {

  constructor(
    private http: HttpClient,
  ) { }

  getAuditHistory(id: any, name: any, resId: string = ''): Observable<any> {
    let params = `id=${id}&modelName=${name}`;
    if(resId){
        params  += `&res_id=${resId}`;
    }
    return this.http.get(environment.yiiUrl + `history/get-histories?${params}`, { observe: 'response' as 'body' });
  }
}
