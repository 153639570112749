import { Directive, Input, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TemplateViewerComponent } from './template-viewer/template-viewer.component';

@Directive({
  selector: '[appTemplateViewer]'
})
export class TemplateViewerDirective {

  @Input() data: any;
  constructor(
    public _matDialog: MatDialog
  ) { }

  @HostListener('click') onClick(): void {
    console.log(this.data);
    const dialogRef = this._matDialog.open(TemplateViewerComponent, {
      disableClose: true,
      panelClass: 'temp-compose-dialog',
      data: this.data
    });
    dialogRef.afterClosed()
      .subscribe(response => {
      });
  }

}
