import { Component, OnInit, OnDestroy, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subject, merge } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { HistoryService } from 'app/services';
import { startWith, tap, takeUntil, switchMap } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-email-history',
  templateUrl: './email-history.component.html',
  styleUrls: ['./email-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class EmailHistoryComponent implements OnInit, OnDestroy {

  @Input() id: any;
  @Input() relatedTo: any;
  @Input() formEmail: any;
  displayedColumns = ['from', 'to', 'cc', 'bcc', 'subject', 'mail_date', 'mail_read_at', 'action'];
  displayedMailBoxColumns = ['from', 'to', 'subject', 'mail_date', 'action'];
  emails = [] as any;
  loadingResults: boolean;
  mailBoxEmails = [];

  private _unsubscribeAll: Subject<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private _historyService: HistoryService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {

    if (this.relatedTo === 'accounts') {
      this._historyService.getMailboxEmails(this.relatedTo, this.id)
      .pipe(
        tap(() => this.loadingResults = true),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((data: any) => {
        this.loadingResults = false;
        this.mailBoxEmails = data;
      }, () => this.loadingResults = false);
    }
    
    merge(this.paginator.page)
      .pipe(
        startWith(''),
        tap(() => this.loadingResults = true),
        takeUntil(this._unsubscribeAll),
        switchMap(() => {
          return this._historyService.getRelatedEmails(this.id, this.paginator.pageIndex + 1, this.relatedTo);
        })
      )
      .subscribe((data: any) => {
        this.loadingResults = false;
        this.emails.data = data.body;
        this.emails.total = data.headers.get('x-pagination-total-count');
        this.emails.limit = data.headers.get('x-pagination-per-page');
      }, () => this.loadingResults = false);
  }

  viewEmial(email: any): void {
    
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
