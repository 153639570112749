import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { Mail } from 'app/main/dashboard/mail/mail.model';

@Injectable({
    providedIn: 'root'
})
export class MailBoxService {

    currentUser: any;

    constructor(
        private http: HttpClient,
        private _authenticationService: AuthenticationService
    ) {
        this._authenticationService.currentUser
            .subscribe(() => {
                this.currentUser = this._authenticationService.currentUserValue;
            });
    }

    getSettings(): Observable<any> {
        return this.http.get(`${environment.mailBoxUrl}settings/view-settings&user=${this.currentUser.user_id}`);
    }

    getFoldersSettings(): Observable<any> {
        return this.http.get(`${environment.mailBoxUrl}settings/mailbox-folders&user=${this.currentUser.user_id}`);
    }

    updateFolder(folder: any): Observable<any> {
        return this.http.put(`${environment.mailBoxUrl}settings/update-folder`, folder);
    }

    saveSettings(data: any): Observable<any> {
        return this.http.post(
            `${environment.mailBoxUrl}settings/update-settings&user=${this.currentUser.user_id}&agency=${this.currentUser.user_agency}&office=${this.currentUser.user_office_id}`,
            data
        );
    }

    deleteSettings(): Observable<any> {
        return this.http.get(`${environment.mailBoxUrl}settings/delete-settings&user=${this.currentUser.user_id}&agency=${this.currentUser.user_agency}`);
    }

    getGmailAuthUrl(): Observable<any> {
        return this.http.get(`${environment.mailBoxUrl}settings/get-gmail-auth-url`);
    }

    getOutlookAuthUrl(): Observable<any> {
        return this.http.get(`${environment.mailBoxUrl}settings/get-outlook-auth-url`);
    }

    deleteMails(data: any): Observable<any> {
        return this.http.post(`${environment.mailBoxUrl}settings/delete-mails&user=${this.currentUser.user_id}`, data);
    }

    sendEmail(data: any): Observable<any> {
        return this.http.post(`${environment.mailBoxUrl}settings/send-email&user=${this.currentUser.user_id}&agency=${this.currentUser.user_agency}`, data);
    }

    mailAttachment(file: File): Observable<any> {
        const formData: any = new FormData();
        formData.append('file', file);
        return this.http.post(`${environment.mailBoxUrl}settings/mail-attachment`, formData);
    }
}
