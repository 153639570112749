import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WindowcardsViewDialogComponent } from './windowcards-view-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { WindowcardOneComponent } from './windowcard-one/windowcard-one.component';
import { AppPipesModule } from 'app/pipes/app-pipes.module';
import { WindowcardsEmailComponent } from '../windowcards-email/windowcards-email.component';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
// import { NgxQRCodeModule } from 'ngx-qrcode2';
 @NgModule({
    declarations: [
        WindowcardsViewDialogComponent,
        WindowcardOneComponent,
        WindowcardsEmailComponent,
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatToolbarModule,
        MatButtonModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatTooltipModule,
        MatTabsModule,
        MatButtonToggleModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        FlexLayoutModule,
        FuseDirectivesModule,
        AppPipesModule,
        MatSlideToggleModule,
        EditorModule,
        
        // NgxQRCodeModule is not used also have compatibility issues with Angular Ivy compiler so it's commented
        // NgxQRCodeModule
        
    ],
    exports: [WindowcardsViewDialogComponent],
    providers: [
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
    ]
})
export class WindowcardsViewDialogModule { }
