import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PropertyCategoriesService {

  constructor(private http: HttpClient) { }

  getData(postData: any, page: any, perPage ?: any): Observable<any[]> {
    return this.http.post<any[]>(`${environment.yiiUrl}property-categories/search?page=` + page + `&per-page=${perPage}`, postData, { observe: 'response' as 'body' })
    .pipe(map(data => {
      return data;
  }));
  }

  createData(data: any): Observable<object> {
    return this.http.post(`${environment.yiiUrl}property-categories`, data);
  }

  updateData(data: any): Observable<object> {
    return this.http.put(`${environment.yiiUrl}property-categories/${data._id}`, data);
  }

  deleteData(_id: any, createdBy: any): Observable<any> {
    return this.http.delete(`${environment.yiiUrl}property-categories/${_id}?created_by=${createdBy}`);
  }
}
