import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MailchimpService } from 'app/services/mailchimp.service';
import { Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'mailchimp-list',
    templateUrl: './mailchimp-list.component.html',
    styleUrls: ['./mailchimp-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MailchimpListComponent implements OnInit {
    ids: any = [];
    displayedColumns: string[] = ['name', 'date_created', 'actions'];
    mailchimpList: any;
    private _unsubscribeAll: Subject<any>;
    loadingResults: boolean;
    owner: boolean;

    constructor(
        public matDialogRef: MatDialogRef<MailchimpListComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        public _mailchimpService: MailchimpService,
        private _translateService: TranslateService,
        private _snackBar: MatSnackBar
    ) {
        this.ids = this._data.ids;
        this.owner = this._data.owner;
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.getList();
    }

    getList(): void {
        this._mailchimpService.getList(this.ids)
            .pipe(
                tap(() => this.loadingResults = true),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe((data) => {
                this.loadingResults = false;
                if (data) {
                    this.mailchimpList = data.lists;
                }
            }, () => this.loadingResults = false);
    }

    addToList(list: any): void {
        this.loadingResults = true;
        this._mailchimpService.addToList(list.id, this.ids, this.owner)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._snackBar.open(this._translateService.instant('Added successfully'), this._translateService.instant('Close'), {
                    duration: 2000,
                });
                this.matDialogRef.close();
            }, () => this.matDialogRef.close());
    }

    removeFromList(list: any): void {
        this.loadingResults = true;
        this._mailchimpService.removeFromList(list.id, this.ids, this.owner)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._snackBar.open(this._translateService.instant('Removed successfully'), this._translateService.instant('Close'), {
                    duration: 2000,
                });
                this.matDialogRef.close();
            }, () => this.matDialogRef.close());
    }

}
