import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    constructor(private http: HttpClient) { }

    getData(filter: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}sequence`, filter);
    }

    getActivities(id: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}sequence/activities`, id);
    }

    getTasks(filter: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}activities_dashboard/tasks`, filter);
    }

    deleteData(data: any): Observable<any> {
        return this.http.delete(`${environment.apiUrl}sequence/delete/${data._id}`, data);
    }

    activitiesSchedule(range: string, type: string, filter: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}dashboard/activities_schedule?range=${range}&type=${type}`, filter);
    }

    activitiesDistribution(range: string, filter: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}dashboard/activities_distribution?range=${range}`, filter);
    }

    activitiesSaleOffer(range: string, filter: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}dashboard/activities_sales_offers?range=${range}`, filter);
    }

    newAccounts(range: string, page: any, filter: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}dashboard/new_accounts?range=${range}&page=${page}`, filter);
    }

    accountsAssignments(range: string): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}dashboard/accounts_assignments?range=${range}`, {});
    }

    updatedProperties(range: string, page: any, filter: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}dashboard/updated_properties?range=${range}&page=${page}`, filter);
    }

    updatedCommercialProperties(range: string, page: any, filter: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}dashboard/updated_commercial_properties?range=${range}&page=${page}`, filter);
    }

    changeStatus(data: any): Observable<any[]> {
        return this.http.put<any[]>(`${environment.yiiUrl}activities/${data._id}`, data);
    }

    deleteActivity(id: any, createdBy: any, agency: any): Observable<any[]> {
        return this.http.delete<any[]>(`${environment.yiiUrl}activities/${id}?created_by=${createdBy}&agency=${agency}`);
    }

    copyAcctivity(data: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.yiiUrl}activities`, data);
    }

    getMeetingsTours(filter: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}activities_dashboard/meetings_tours`, filter);
    }
}
