// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `fuse-countdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
fuse-countdown .fuse-countdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}
fuse-countdown .fuse-countdown .time {
  display: flex;
  flex-direction: column;
  padding: 0 12px;
}
fuse-countdown .fuse-countdown .time .value {
  font-size: 34px;
  line-height: 34px;
  padding-bottom: 8px;
}
fuse-countdown .fuse-countdown .time .title {
  color: rgba(0, 0, 0, 0.54);
}`, "",{"version":3,"sources":["webpack://./src/@fuse/components/countdown/countdown.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AACI;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;AACR;AACQ;EACI,aAAA;EACA,sBAAA;EACA,eAAA;AACZ;AACY;EACI,eAAA;EACA,iBAAA;EACA,mBAAA;AAChB;AAEY;EACI,0BAAA;AAAhB","sourcesContent":["fuse-countdown {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n\n    .fuse-countdown {\n        display: flex;\n        flex-direction: row;\n        align-items: center;\n        justify-content: center;\n        text-align: center;\n\n        .time {\n            display: flex;\n            flex-direction: column;\n            padding: 0 12px;\n\n            .value {\n                font-size: 34px;\n                line-height: 34px;\n                padding-bottom: 8px;\n            }\n\n            .title {\n                color: rgba(0, 0, 0, 0.54);\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
