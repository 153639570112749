import { Directive, Output, EventEmitter, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[fileDropZone]'
})
export class DropZoneDirective {

  @Output() fileDropped = new EventEmitter<any>();

  @HostBinding('style.background-color') background: string;
  @HostBinding('style.opacity') opacity: string;

  constructor() { }

  @HostListener('dragover', ['$event']) onDragOver(evt): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#c5c6cb';
    this.opacity = '0.8';
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = 'transparent';
    this.opacity = '1';
  }

  @HostListener('drop', ['$event']) public ondrop(evt): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = 'transparent';
    this.opacity = '1';
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }
}
