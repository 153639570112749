import { Directive, ElementRef, Renderer2, HostListener, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SequencesComponent } from './dialogs/sequences/sequences.component';
import { EventsComponent } from './dialogs/events/events.component';

@Directive({
    selector: '[eventSequences]'
})
export class EventSequencesDirective {

    @Input() data: string;
    @Input() relatedTo: string;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        public _matDialog: MatDialog
    ) {
        this.renderer.setStyle(this.el.nativeElement, 'cursor', 'pointer');
    }

    @HostListener('click') onClick(): void {
        let relatedTo = null;
        if (this.relatedTo === 'account') {
            relatedTo = 'Accounts';
        }
        else if (this.relatedTo === 'contracts') {
            relatedTo = 'Rental Contracts';
        }
        else if (this.relatedTo === 'owner') {
            relatedTo = 'Owners';
        }
        else if (this.relatedTo === 'property') {
            relatedTo = 'Properties';
        }
        else if (this.relatedTo === 'sales') {
            relatedTo = 'Sales Stages';
        }
        else if (this.relatedTo === 'commercials') {
            relatedTo = 'Commercial Properties';
        }
        else if (this.relatedTo === 'Bookings') {
            relatedTo = 'Bookings';
        }
        else if (this.relatedTo === 'Moorings') {
            relatedTo = 'Moorings';
        }
        else if (this.relatedTo === 'Boats') {
            relatedTo = 'Boats';
        }
        const dialogRef = this._matDialog.open(SequencesComponent, {
            panelClass: 'sequences-dialog',
            data: {
                data: this.data,
                relatedTo: relatedTo
            },
        });
        dialogRef.afterClosed()
            .subscribe(response => {
                if (response) {
                    this.openActivityEvent(response);
                }
            });
    }

    openActivityEvent(sequence: any): void {
        let relatedTo = null;
        if (this.relatedTo === 'account') {
            relatedTo = 'Accounts';
        }
        else if (this.relatedTo === 'owner') {
            relatedTo = 'Owners';
        }
        else if (this.relatedTo === 'property') {
            relatedTo = 'Properties';
        }
        else if (this.relatedTo === 'sales') {
            relatedTo = 'Sales Stages';
        }
        else if (this.relatedTo === 'commercials') {
            relatedTo = 'Commercial Properties';
        }
        else if (this.relatedTo === 'Bookings') {
            relatedTo = 'Bookings';
        }
        else if (this.relatedTo === 'Moorings') {
            relatedTo = 'Moorings';
        }
        else if (this.relatedTo === 'Boats') {
            relatedTo = 'Boats';
        }
        const dialogRef = this._matDialog.open(EventsComponent, {
            panelClass: 'events-dialog',
            data: {
                data: this.data,
                sequence: sequence,
                relatedTo: relatedTo
            },
        });
        dialogRef.afterClosed()
            .subscribe(response => {
            });
    }
}
