// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mgl {
  margin-left: 5px;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/main/marketplace/message-boards/layout/timeline/comment/comment.component.scss"],"names":[],"mappings":"AAAA;EACG,gBAAA;EACA,gBAAA;AACH","sourcesContent":[".mgl{\n   margin-left: 5px; \n   margin-top:10px\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
