import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SalesService {

  constructor(
    private http: HttpClient,
  ) { }

  getData(filter: any, page: any, perPage?: any): Observable<object> {
    return this.http.post<object>(`${environment.yiiUrl}sales/search?expand=property_data,listing_agency,agency_users,account_data,owner_data,company_data,offices_data&page=`
      + page + `&per-page=${perPage}`, filter, { observe: 'response' as 'body' })
      .pipe(map(data => {
        return data;
      }));
  }

  getById(id: any): Observable<object> {
    return this.http.get(`${environment.yiiUrl}sales/${id}?expand=property_data,created_by_data,owner_data,company_data`, { observe: 'response' as 'body' });
  }

  create(data: any): Observable<object> {
    return this.http.post(`${environment.yiiUrl}sales`, data);
  }

  save(data: any, id: any): Observable<object> {
    return this.http.put(`${environment.yiiUrl}sales/${id}`, data);
  }

  delete(id: any, createdBy: any): Observable<object> {
    return this.http.delete(`${environment.yiiUrl}sales/${id}?created_by=${createdBy}`);
  }
  getSalesStages(data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}sequence`, data);
  }
  getOwner(id: any): Observable<object> {
    return this.http.get(`${environment.yiiUrl}owners/${id}?expand=account`, { observe: 'response' as 'body' });
  }
  getByPid(id: any): Observable<object> {
    return this.http.get(`${environment.yiiUrl}sales?property_reference=${id}`, { observe: 'response' as 'body' });
  }

  getAveragePrices(filter): Observable<any> {
    return this.http.post(`${environment.apiUrl}average_prices/by_city`, filter);
  }

  getUrbanisationSales(data: any, page: any, limit: any, salesID: string): Observable<any> {
    page = page ? page : 1;
    limit = limit ? limit : 30;
    const filter = {
      options: {
        page: page,
        limit: limit
      }
    };
    salesID = salesID ? salesID : '-1';
    return this.http.post(`${environment.apiUrl}urbanisations/sales/${salesID}`, filter);
  }

  // commercial-sale 
  getCommercialData(filter: any, page: any, perPage?: any): Observable<object> {
    return this.http.post<object>(`${environment.yiiUrl}sales/search?expand=commercial_property_data,listing_agency,agency_users,account_data,owner_data,company_data,offices_data&page=`
      + page + `&per-page=${perPage}`, filter, { observe: 'response' as 'body' })
      .pipe(map(data => {
        return data;
      }));
  }

}
