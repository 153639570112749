// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register-token .mat-mdc-dialog-container {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/layout/components/generate-register-token/generate-register-token.component.scss"],"names":[],"mappings":"AACI;EACI,UAAA;AAAR","sourcesContent":[".register-token {\n    .mat-mdc-dialog-container {\n        padding: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
