import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MlsWizardComponent } from './mls-wizard.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';



@NgModule({
    declarations: [MlsWizardComponent],
    imports: [
        CommonModule,
        FuseSharedModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        MatToolbarModule,
        MatStepperModule,
        MatTableModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatInputModule,
        MatTooltipModule,
        MatCheckboxModule
    ],
    exports: [MlsWizardComponent]
})
export class MlsWizardModule { }
