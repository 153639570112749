import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {

  transform(date: any, args: any): any {
    if (date && date != '') {
      if (typeof date === 'number') {
        date = date.toString().length === 10 ? date * 1000 : date;
        return date;
      }
    }
  }

}
