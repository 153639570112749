import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProvincesService {

  constructor(private http: HttpClient) { }

  getData(filter: any): Observable<any[]> {
    return this.http.post<any[]>(`${environment.apiUrl}provinces`, filter);
  }

  createData(data: any): Observable<object> {
    return this.http.post(`${environment.apiUrl}provinces/create`, data);
  }

  updateData(data: any): Observable<object> {
    return this.http.put(`${environment.apiUrl}provinces/update/${data._id}`, data);
  }

  deleteData(data: any): Observable<any> {
    return this.http.delete(`${environment.apiUrl}provinces/delete/${data._id}`);
  }
  ModelExclusions(data: any): Observable<any> {
    return this.http.post(`${environment.yiiUrl}agencies/model-exclusions`, data);
  }
  ModelAllExclusions(data: any): Observable<any> {
    return this.http.post(`${environment.yiiUrl}agencies/model-all-exclusions`, data);
  }
  getExcludedList(): Observable<object> {
    return this.http.get<any[]>(`${environment.yiiUrl}agencies/get-exclusions?model=provinces`);
  }
  getModel(): Observable<object> {
    return this.http.get<any>(`${environment.yiiUrl}agencies/get-exclusions?model=provinces`);
  }

  updateModel(data: any): Observable<object> {
    return this.http.post<any>(`${environment.yiiUrl}agencies/model-exclusions`, data);
  }

  getAllModel(): Observable<object> {
    return this.http.get<any>(`${environment.yiiUrl}agencies/is-all-check?model=provinces`);
  }

  updateAllModel(data: any): Observable<object> {
    return this.http.post<any>(`${environment.yiiUrl}agencies/model-all-exclusions`, data);
  }
}
