import { Component, OnInit, ViewEncapsulation, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmailsService } from 'app/services/emails.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-preview-email',
  templateUrl: './preview-email.component.html',
  styleUrls: ['./preview-email.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PreviewEmailComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;
  public content = {} as any;
  loadingResults = true as boolean;
  constructor(
    private _emailsService: EmailsService,
    public matDialogRef: MatDialogRef<PreviewEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    // this._emailsService.previewEmail(this._data, this._data.contentLang)
    this._emailsService.previewEmail(this._data)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data: any) => {
        this.content = data.content ? data.content : data;
        this.loadingResults = false;
      }, () => this.loadingResults = false);
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
