import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CounterOffersComponent } from './counter-offers.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDividerModule } from '@angular/material/divider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
    declarations: [CounterOffersComponent],
    imports: [
        CommonModule,
        FuseSharedModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatToolbarModule,
        MatDividerModule,
        MatDatepickerModule,
        MatTooltipModule
    ],
    exports: [CounterOffersComponent]
})
export class CounterOffersModule { }
