import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditInputComponent } from './edit-input.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';


@NgModule({
  declarations: [
    EditInputComponent
  ],
  imports: [
    CommonModule,
    FuseSharedModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatToolbarModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatIconModule
  ],
  exports: [
    EditInputComponent
  ]
})
export class EditInputModule { }
