import { Directive, HostListener, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AttachmentListDialogComponent } from './attachment-list-dialog/attachment-list-dialog.component';

@Directive({
    selector: '[downloadAttachments]'
})
export class DownloadAttachmentsDirective {

    @Input() modelId: string;
    @Input() modelName: string;

    constructor(
        public _matDialog: MatDialog
    ) {}

    @HostListener('click') onClick(): void {
        const dialogRef = this._matDialog.open(AttachmentListDialogComponent, {
            panelClass: 'attachment-list-dialog',
            data: {
                modelId: this.modelId,
                modelName: this.modelName,
            },
        });
        dialogRef.afterClosed()
            .subscribe(response => {
                if (response) {
                    console.log("CLose successfully");
                }
            });
    }

}
