import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  constructor(
    private http: HttpClient,
  ) { }

  getData(filter: any, page: any, perPage?: any): Observable<object> {
    page = page ? page : 1;
    perPage = perPage ? perPage : 10;
    return this.http.post<object>(`${environment.yiiUrl}activities/get-tasks?page=` + page +
      `&expand=agency_users,assignee_user&per-page=` + perPage, filter, { observe: 'response' as 'body' })
      .pipe(map(data => {
        return data;
      }));
  }

  expandedData(aid: any): Observable<object> {
    return this.http.get<object>(`${environment.yiiUrl}activities/get-expanded-data?aid=` + aid);
  }

  getById(id: any): Observable<any> {
    return this.http.get<any>(`${environment.yiiUrl}activities/${id}?expand=a_assignee`);
  }

  create(data: any): Observable<any> {
    return this.http.post(`${environment.yiiUrl}activities`, data);
  }

  update(data: any, id: any): Observable<any> {
    return this.http.put(`${environment.yiiUrl}activities/${id}`, data);
  }

  delete(id: any, create: any, agency: any): Observable<any> {
    return this.http.delete(`${environment.yiiUrl}activities/${id}?created_by=${create}&agency=${agency}`);
  }

  getUrbanisationTasks(filter: any, tasksID: string): Observable<any> {
    tasksID = tasksID ? tasksID : '-1';
    return this.http.post(`${environment.apiUrl}urbanisations/tasks/${tasksID}`, filter);
  }

}
