import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { Inject } from '@angular/core';
import { AuthenticationService, LanguagesService } from 'app/services';
import { PropertiesService } from 'app/services/properties.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, from } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { forEach, isSet } from 'lodash';
@Component({
    selector: 'app-traffic-light',
    templateUrl: './traffic-light.component.html',
    styleUrls: ['./traffic-light.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class TrafficLightComponent implements OnInit {
    property: any;
    currentAgency: any;
    imagesTrafic: any = [];
    private _unsubscribeAll: Subject<any>;
    documentTypeECG = false;
    documentTypeDeed = false;
    documentsData: any = [];
    currentLang: any;
    descriptionGreen = true;
    descriptionGreenInternal = true;
    location = false;
    descriptionOrange = false;
    priceTrafic = false;
    commissionUnit = true as boolean;

    constructor(
        private _authenticationService: AuthenticationService,
        private _propertiesService: PropertiesService,
        private _translateService: TranslateService,
        private _languagesService: LanguagesService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<TrafficLightComponent>
    ) {
        this.property = this.data;
        this.currentAgency = this._authenticationService.currentAgencyValue;
        this.currentLang = this._translateService.currentLang;
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this._translateService.onLangChange
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.currentLang = this._translateService.currentLang;
            });
        if (this.property) {
            this._propertiesService.getPropertyImage(this.property._id)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((data: any) => {
                    this.imagesTrafic = data.body;
                });
            this._propertiesService.getPropertyDocument(this.property._id)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((data: any) => {
                    this.documentsData = data.body;
                    for (const document of this.documentsData) {
                        if (document.identification_type && document.identification_type === 'ECG') {
                            this.documentTypeECG = true;
                        } else if (document.identification_type && document.identification_type === 'Deed') {
                            this.documentTypeDeed = true;
                        }
                    }
                });
            this._languagesService.getLanguagesForSystem()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((systemLanguages: any) => {
                    if (this.property?.sale || this.property?.rent) {
                        this.descriptionGreen = true;
                        this.descriptionGreenInternal = true;
                        this.descriptionOrange = false;
                    }
                    if (this.property.sale && !this.property.rent) {

                        // this.descriptionGreen = true;
                        // this.descriptionGreenInternal = true;
                        // this.descriptionOrange = false;
                        // forEach(systemLanguages, (value, key) => {
                        //     if (!this.property.shared_data || !this.property.shared_data.description || !this.property.shared_data.description[value.key]) {
                        //         this.descriptionGreen = false;
                        //     }
                        // });
                        // forEach(systemLanguages, (value, key) => {
                        //     if (!this.property.description || !this.property.description[value.key]) {
                        //         this.descriptionGreenInternal = false;
                        //     }
                        // });
                        // forEach(systemLanguages, (value, key) => {
                        //     if (this.property.description && this.property.description[value.key]) {
                        //         this.descriptionOrange = true;
                        //     }
                        // });
                        // if (this.property.currentprice) {
                        //     this.priceTrafic = true;
                        // }
                        forEach(systemLanguages, (value) => {
                            const external_desc = this.getClearDescription(this.property?.shared_data?.description?.[value.key]);
                            const internal_desc = this.getClearDescription(this.property?.description?.[value.key]);
                            if (!external_desc) {
                                this.descriptionGreen = false;
                            }
                            if (!internal_desc) {
                                this.descriptionGreenInternal = false;
                            }
                            if (internal_desc) {
                                this.descriptionOrange = true;
                            }
                        });

                        this.priceTrafic = !!this.property?.currentprice;
                    } else if (!this.property.sale && this.property.rent) {
                        // this.descriptionGreen = true;
                        // this.descriptionGreenInternal = true;
                        // this.descriptionOrange = false;
                        // forEach(systemLanguages, (value, key) => {
                        //     console.log(this.property.rental_external_description);
                        //     if (!this.property.rental_external_description[value.key]) {
                        //         this.descriptionGreen = false;
                        //     }
                        // });
                        // forEach(systemLanguages, (value, key) => {
                        //     console.log(this.property.rental_description);
                        //     if (!this.property.rental_description[value.key]) {
                        //         this.descriptionGreenInternal = false;
                        //     }
                        // });
                        // forEach(systemLanguages, (value, key) => {
                        //     if (this.property.rental_description[value.key]) {
                        //         this.descriptionOrange = true;
                        //     }
                        // });
                        // if (this.property.period_seasons[0].new_price && this.property.period_seasons[0].new_price) {
                        //     this.priceTrafic = true;
                        // }

                        forEach(systemLanguages, (value, key) => {
                            const external_desc = this.getClearDescription(this.property?.rental_external_description?.[value.key]);
                            const internal_desc = this.getClearDescription(this.property?.rental_description?.[value.key]);
                            if (!external_desc) {
                                this.descriptionGreen = false;
                            }
                            if (!internal_desc) {
                                this.descriptionGreenInternal = false;
                            }
                            if (internal_desc) {
                                this.descriptionOrange = true;
                            }
                        });

                        if (this.property?.period_seasons?.length > 0 && this.property.period_seasons[0]?.new_price) {
                            this.priceTrafic = true;
                        }

                    } else if (this.property.sale && this.property.rent) {
                        // this.descriptionGreen = true;
                        // this.descriptionOrange = false;
                        // this.descriptionGreenInternal = true;
                        // forEach(systemLanguages, (value, key) => {
                        //     if (
                        //         (!this.property.shared_data.description || !this.property.shared_data.description[value.key])
                        //         &&
                        //         (!this.property.rental_external_description || !this.property.rental_external_description[value.key])
                        //     ) {
                        //         this.descriptionGreen = false;
                        //     }
                        // });
                        // forEach(systemLanguages, (value, key) => {
                        //     if (
                        //         (!this.property.description || !this.property.description[value.key])
                        //         &&
                        //         (!this.property.rental_description || !this.property.rental_description[value.key])
                        //     ) {
                        //         this.descriptionGreenInternal = false;
                        //     }
                        // });
                        // forEach(systemLanguages, (value, key) => {
                        //     if (
                        //         (this.property.description && this.property.description[value.key])
                        //         ||
                        //         (this.property.rental_description && this.property.rental_description[value.key])
                        //     ) {
                        //         this.descriptionOrange = true;
                        //     }
                        // });
                        // if (this.property.currentprice || (this.property.period_seasons[0].new_price && this.property.period_seasons[0].new_price)) { }
                        // this.priceTrafic = true;

                        forEach(systemLanguages, (value, key) => {
                            const hasSharedDataDescription = !!this.getClearDescription(this.property?.shared_data?.description?.[value.key]);
                            const hasRentalExternalDescription = !!this.getClearDescription(this.property?.rental_external_description?.[value.key]);

                            if (hasSharedDataDescription && hasRentalExternalDescription) {
                                this.descriptionGreen = false;
                            }

                            const hasDescription = !!this.getClearDescription(this.property?.description?.[value.key]);
                            const hasRentalDescription = !!this.getClearDescription(this.property?.rental_description?.[value.key]);

                            if (hasDescription && hasRentalDescription) {
                                this.descriptionGreenInternal = false;
                            }

                            const hasDescriptionOrRentalDescription = hasDescription || hasRentalDescription;

                            if (hasDescriptionOrRentalDescription) {
                                this.descriptionOrange = true;
                            }
                        });

                        this.priceTrafic = true;
                    }

                    // https://gitlab.optimasit.com/arsl/optima-crm-v2/-/issues/3535#note_71776
                    if(!this.descriptionGreen && this.descriptionGreenInternal){
                        this.descriptionGreen = true;
                    }
                });

            if (this.property.sale && this.property.share_sale) {
                if (!this.property.private_info_object[this.currentAgency._id] || !this.property.private_info_object[this.currentAgency._id].commission_unit) {
                    this.commissionUnit = false;
                }
                // if (this.property.private_info_object[this.currentAgency._id] && this.property.private_info_object[this.currentAgency._id].commission_unit === 0) {
                //     this.commissionUnit = true;
                // }
            }

            if (this.property.rent && this.property.share_rent && this.property.lt_rental) {
                if (!this.property.ltr?.commission_unit) {
                    this.commissionUnit = false;
                }
            }

            if (this.property.rent && this.property.share_rent &&  this.property.st_rental) {
                if (!this.property.rental_commission) {
                    this.commissionUnit = false;
                }
            }

            if (this.property.location) {
                this.location = true;
            }

        }
    }
    onClickClose(): void {
        this.dialogRef.close();
    }
    getClearDescription(description) {
        if (!description) {
            return '';
        }
        return description
            .replace(/>\s*</g, "><") // remove any whitespace between HTML tags
            .replace(/(<\/?[^>]+>|[\n])/g, ""); // remove HTML tags and newline characters(\n)
    }

}
