import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GridBoardsService } from 'app/services/grid-boards.service';
import { GridBoardCreateDialogComponent } from 'app/main/master-data/grid-boards/grid-board-create-dialog/grid-board-create-dialog.component';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'app/services';
import { GlobalFuntions } from 'app/_helpers';
import { environment } from 'environments/environment';

@Component({
    selector: 'grid-boards',
    templateUrl: './grid-boards.component.html',
    styleUrls: ['./grid-boards.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class GridBoardsComponent implements OnInit, OnDestroy {

    relatedTo: any;
    private _unsubscribeAll: Subject<any>;
    boards: any;
    routFor: string;
    currentUser: any;
    currentOffice: any;
    public env = environment;
    public currentAgency: any;
    constructor(
        private route: ActivatedRoute,
        public _matDialog: MatDialog,
        public _gridBoardsService: GridBoardsService,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService,
        public _authenticationService: AuthenticationService,
        public _globalFuntions: GlobalFuntions,
    ) {
        this._unsubscribeAll = new Subject();
        this.currentOffice = _authenticationService.currentOfficeValue;
        this.currentUser = this._authenticationService.currentUserValue;
        this.currentAgency = this._authenticationService.currentAgencyValue;
        this.route.data
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(v => this.relatedTo = v.relatedTo);
    }

    ngOnInit(): void {
        this.routFor = this.relatedTo === 'account' ? 'accounts' : 'owners';
        this.getGridBoards();
    }

    getGridBoards(): void {
        const filter = { related_to: this.relatedTo };
        this._gridBoardsService.getBoards(filter)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data: any) => {
                this.boards = data;
            });
    }

    createNewGrid(): void {
        const dialogRef = this._matDialog.open(GridBoardCreateDialogComponent, {
            width: '350px',
            panelClass: 'event-form-dialog',
            data: {}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                delete result._id;
                const postData = {
                    title: result.title,
                    offices: result.offices,
                    related_to: this.relatedTo,
                    settings: {
                        color: 'fuse-dark',
                        subscribed: false,
                    },
                    grids: [],
                };
                this._gridBoardsService.addEditBoards(postData)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((data) => {
                        this.getGridBoards();
                    });
            }
        });
    }

    delete(board: any): void {
        const dialog = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });
        dialog.componentInstance.confirmMessage = this._translateService.instant('Are you sure you want to delete board you will not abel to recover it again?');
        dialog.afterClosed()
            .subscribe(result => {
                if (result) {
                    this._gridBoardsService.deleteBoard(board.id)
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe(() => {
                            this._snackBar.open(this._translateService.instant('Deleted successfully'), this._translateService.instant('Close'), {
                                duration: 2000,
                            });
                            this.getGridBoards();
                        });
                }
            });
    }

    copy(board: any): void {
        const newBoard = {} as any;
        newBoard.grids = board.grids;
        newBoard.related_to = board.related_to;
        newBoard.settings = board.settings;
        newBoard.title = board.title;
        newBoard.offices = [this.currentOffice._id];
        this._gridBoardsService.addEditBoards(newBoard)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data) => {
                this.getGridBoards();
            });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    editBoard(boardData: any): any{
        const dialogRef = this._matDialog.open(GridBoardCreateDialogComponent, {
            width: '350px',
            panelClass: 'event-form-dialog',
            data: boardData
        });
        dialogRef.afterClosed().subscribe(result => {
           
            if (result) {
                let Data = {} as any;
                Data = result;
                result.related_to = this.relatedTo;
                this._gridBoardsService.addEditBoards(Data)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((data) => {
                    this._snackBar.open(this._translateService.instant('Updated successfully'), this._translateService.instant('Close'), {
                        duration: 2000,
                    });
                    this.getGridBoards();
                });
            }
        });
    }
}
