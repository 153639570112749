import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from './../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;
    private currentOfficeSubject: BehaviorSubject<any>;
    public currentOffice: Observable<any>;
    private currentAgencySubject: BehaviorSubject<any>;
    public currentAgency: Observable<any>;
    currentLang: string;

    constructor(
        private http: HttpClient,
        private router: Router,
        private _cookieService: CookieService,
        private _translateService: TranslateService
    ) {
        this._translateService.onLangChange
            .subscribe(() => {
                this.currentLang = this._translateService.currentLang;
            });

        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.currentOfficeSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentOffice')));
        this.currentOffice = this.currentOfficeSubject.asObservable();
        this.currentAgencySubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentAgency')));
        this.currentAgency = this.currentAgencySubject.asObservable();
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    public get currentOfficeValue(): any {
        return this.currentOfficeSubject.value;
    }

    public get currentAgencyValue(): any {
        return this.currentAgencySubject.value;
    }

    public setCurrentUser(data: any): any {
        localStorage.setItem('currentUser', JSON.stringify(data));
        this.currentUserSubject.next(data);
    }

    public setCurrentOffice(data: any): any {
        localStorage.setItem('currentOffice', JSON.stringify(data));
        this.currentOfficeSubject.next(data);
    }

    public setCurrentAgency(data: any): any {
        localStorage.setItem('currentAgency', JSON.stringify(data));
        this.currentAgencySubject.next(data);
    }

    login(username: string, password: string): Observable<any> {
        return this.http.post<any>(`${environment.yiiUrl}users/login`, { username, password })
            .pipe(map(user => {
                return this.saveLoggedinUserData(user);
            }));
    }

    loginByToken(accessToken: string): Observable<any> {
        return this.http.get<any>(`${environment.yiiUrl}users/login-by-token?accessToken=${accessToken}`)
            .pipe(map(user => {
                return this.saveLoggedinUserData(user);
            }));
    }

    loginByUser(userData: any): Observable<any> {
        return this.http.post<any>(`${environment.yiiUrl}users/admin-login-as`, userData)
            .pipe(map(user => {
                return this.saveLoggedinUserData(user);
            }));
    }

    saveLoggedinUserData(user: any): any {
        if (user && user.access_token) {
            this._cookieService.set('accessToken', user.access_token, 1, '/', '.immosurance.net');
            this._cookieService.set('userId', user.user_id, 1, '/', '.immosurance.net');
            this.setCurrentUser(user);
            if (user.user_agency_data) {
                this.setCurrentAgency(user.user_agency_data);
            }
            if (user.user_office_data) {
                this.setCurrentOffice(user.user_office_data);
            }
        }
        return user;
    }

    emailValidation(username: string): Observable<any> {
        return this.http.get<any>(`${environment.frontendUrl}index.php?r=site/request-password-reset&email=${username}&environment=V2&lang=${this.currentLang}`);
    }

    resetPassword(password: string, token: any): Observable<any> {
        return this.http.get<any>(`${environment.frontendUrl}index.php?r=site/reset-password&token=${token}&pass=${password}`);
    }

    logout(redirect = true): void {
        this._cookieService.delete('accessToken', '/', '.immosurance.net');
        this._cookieService.delete('userId', '/', '.immosurance.net');
        localStorage.removeItem('lang');
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        localStorage.removeItem('currentOffice');
        this.currentOfficeSubject.next(null);
        localStorage.removeItem('currentAgency');
        this.currentAgencySubject.next(null);
        if (redirect) {
            this.router.navigate(['/login']);
        }
    }
}
