import { Component, OnInit, ViewEncapsulation, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PdfTemplatesService } from 'app/services/pdf-templates.service';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { LanguagesService } from 'app/services/languages.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'app/services';
import { fuseAnimations } from '@fuse/animations';
import { isEmpty } from 'lodash';

@Component({
    selector: 'pdf-list-dialog',
    templateUrl: './pdf-list-dialog.component.html',
    styleUrls: ['./pdf-list-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class PdfListDialogComponent implements OnInit, OnDestroy {

    contentType = '';
    private _unsubscribeAll: Subject<any>;
    pdfTemplates: any;
    selectedTemplates = [];
    languages: any;
    currentLang: string;
    loader = false;
    pdf = true;
    multiplePdf = true;
    selectedLanguage = {
        lang: '',
        key: ''
    } as any;
    currentAgency: any;
    constructor(
        public matDialogRef: MatDialogRef<PdfListDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _pdfTemplatesService: PdfTemplatesService,
        private _languagesService: LanguagesService,
        private _translateService: TranslateService,
        private _authenticationService: AuthenticationService
    ) {
        this.currentLang = this._translateService.currentLang;
        this.currentAgency = this._authenticationService.currentAgencyValue;
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        let payload = {};
        if (!isEmpty(this._data.type)) {
            payload = {
                options: { page: 1, limit: 100, sort: { key: 1 } },
                query: { type: this._data.type }
            };
        }
        else {
            payload = {
                options: { page: 1, limit: 100, sort: { key: 1 } },
            };
        }

        this._pdfTemplatesService.getData(payload)
            .pipe(
                tap(() => this.loader = true),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe((data: any) => {
                this.pdfTemplates = data.docs;
                this.loader = false;
            });

        if (this._data.viewRecord) {
            this._languagesService.getLanguagesForSystem()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((data: any) => {
                    this.languages = data;
                });
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    selectTemplate(template): void {
        if (!this._data.viewRecord) {
            this.matDialogRef.close(template);
        } else {
            if (template.selected) {
                template.selected = false;
                const index = this.selectedTemplates.indexOf(template);
                this.selectedTemplates.splice(index, 1);
            } else {
                template.selected = true;
                this.selectedTemplates.push(template);
            }
        }
    }

    successFunction(): void {
        this.loader = true;
        const filter = {
            lang: this.selectedLanguage.key,
            cards: this.selectedTemplates,
            properties: this._data.properties,
            agency: this._authenticationService.currentAgencyValue._id,
            pdf: this.pdf,
            multiplePdf: this.multiplePdf,
            contentType: this.contentType
        };
        this._pdfTemplatesService.getWindowsCardTemplates(filter)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data: any) => {
                this.loader = false;
                this.matDialogRef.close(data);
            });
    }
}
