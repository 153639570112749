import { Component, OnInit, ViewEncapsulation, Input, EventEmitter, ViewChild } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Subject, merge } from 'rxjs';
import { HistoryService, AuthenticationService, OfficesService } from 'app/services';
import { TranslateService } from '@ngx-translate/core';
import { OwnersService } from 'app/services/owners.service';
import { Router } from '@angular/router';
import { AttachmentsService } from 'app/services/attachments.service';
import { GetImageSrcPipe } from 'app/pipes/get-image-src.pipe';
import { takeUntil, startWith, tap, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-sales-history',
  templateUrl: './sales-history.component.html',
  styleUrls: ['./sales-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  providers: [GetImageSrcPipe]
})
export class SalesHistoryComponent implements OnInit {

  @Input() id: any;
  @Input() relatedTo: any;
  displayedColumns: string[] = ['expand', 'property_reference', 'listing_agency', 'listing_agent', 'sales_price', 'created_at', 'status', 'actions'];
  properties: any = {};
  currentLang: string;
  user: any;
  loadingResults: boolean;
  expandedRow = {} as any;
  expandedAll: boolean;
  currentAgency: any;
  reloadProperties: EventEmitter<any>;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _historyService: HistoryService,
    private _translateService: TranslateService,
    private _ownersService: OwnersService,
    private router: Router,
    private _matDialog: MatDialog,
    private _authenticationService: AuthenticationService,
    private _officesService: OfficesService,
    private _attachmentsService: AttachmentsService,
    private _getImageSrcPipe: GetImageSrcPipe,
  ) {
    this.reloadProperties = new EventEmitter();
    this.currentAgency = this._authenticationService.currentAgencyValue;
    this.user = this._authenticationService.currentUserValue;
    this.currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this._translateService.onLangChange
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
      });

    merge(this.sort.sortChange, this.paginator.page, this.reloadProperties)
      .pipe(
        startWith(''),
        tap(() => this.loadingResults = true),
        takeUntil(this._unsubscribeAll),
        switchMap(() => {
          if (this.relatedTo === 'accounts') {
            return this._historyService.getAccountsSales(this.id, this.paginator.pageIndex + 1);
          } else if (this.relatedTo === 'owners') {
            return this._historyService.getOwnersSales(this.id, this.paginator.pageIndex + 1);
          } else if (this.relatedTo === 'companies') {
            return this._historyService.getCompaniesSales(this.id, this.paginator.pageIndex + 1);
          }
        })
      )
      .subscribe((data: any) => {
        this.loadingResults = false;
        this.properties.data = data.body;
        this.properties.total = data.headers.get('x-pagination-total-count');
        this.properties.limit = data.headers.get('x-pagination-per-page');
      }, () => this.loadingResults = false);
  }

  expandedTbl(property: any): void {
    this.expandedRow[property._id] = !this.expandedRow[property._id];
    if (this.expandedRow[property._id]) {
      if (property.private_info_object && property.private_info_object[this.currentAgency._id] && property.private_info_object[this.currentAgency._id].owner) {
        const owner = property.private_info_object[this.currentAgency._id].owner;
        this._ownersService.getById(owner)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((data: any) => {
            property.owner_data = data;
          });
      }
      if (property.offices) {
        const filter = { _id: property.offices };
        this._officesService.getAll(filter, 1, false)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((data: any) => {
            property.offices_data = data;
          });
      }
      this._attachmentsService.getModelAttachments(property._id, 'properties_images')
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((data: any) => {
          property.images = data;
          property._albums = [];
          _.forEach(data, (model: any) => {
            const album = {
              src: this._getImageSrcPipe.transform(model),
              caption: '',
              thumb: this._getImageSrcPipe.transform(model, 300)
            };
            property._albums.push(album);
          });
        });
    }
  }

  deleteSales(_id: any, createdBy: any): void {
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });
    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';
    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._historyService.deleteSales(_id, createdBy).subscribe(() => {
          this.reloadProperties.next(true);
        });
      }
      this.confirmDialogRef = null;
    });
  }

  viewSales(_id: any): void {
    this.router.navigate(['/activities/sales/view/' + _id]);
  }

  editSales(_id: any): void {
    this.router.navigate(['/activities/sales/update/' + _id]);
  }

}
