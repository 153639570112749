import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { UntypedFormGroup, FormControl, Validators } from '@angular/forms';
import { UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { AccountsCreateComponent } from '../../accounts-create.component';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'tab-history',
  templateUrl: './tab-history.component.html',
  styleUrls: ['./tab-history.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class TabHistoryComponent implements OnInit {
  form: UntypedFormGroup;
  id: string;
  /**
   * Constructor
   * 
   * @param {FormBuilder} _formBuilder
   */
  custom2Settings: UntypedFormArray;
  viewRecord: boolean;
  constructor(
    private _formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private _accountsCreateComponent: AccountsCreateComponent
  ) {
    this.form = this._accountsCreateComponent.form;
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.parent.paramMap.get('id');
  }

}
