import { Component, OnInit, ViewEncapsulation, ViewChild, EventEmitter, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { HistoryService, OfficesService, AuthenticationService } from 'app/services';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil, startWith, tap, switchMap } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { OwnersService } from 'app/services/owners.service';
import * as _ from 'lodash';
import { AttachmentsService } from 'app/services/attachments.service';
import { GetImageSrcPipe } from 'app/pipes/get-image-src.pipe';
import { Router } from '@angular/router';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-properties-history',
  templateUrl: './properties-history.component.html',
  styleUrls: ['./properties-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  providers: [GetImageSrcPipe]
})
export class PropertiesHistoryComponent implements OnInit {

  @Input() id: any;
  @Input() relatedTo: any;
  displayedColumns: string[] = ['expand', 'reference', 'type_one', 'location', 'currentprice', 'bedrooms', 'status', 'actions'];
  properties: any = {};
  currentLang: string;
  user: any;
  loadingResults: boolean;
  expandedRow = {} as any;
  expandedAll: boolean;
  currentAgency: any;
  reloadProperties: EventEmitter<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _historyService: HistoryService,
    private _translateService: TranslateService,
    private _ownersService: OwnersService,
    private router: Router,
    private _matDialog: MatDialog,
    private _authenticationService: AuthenticationService,
    private _officesService: OfficesService,
    private _attachmentsService: AttachmentsService,
    private _getImageSrcPipe: GetImageSrcPipe,
    private _lightbox: Lightbox,
  ) {
    this.reloadProperties = new EventEmitter();
    this.currentAgency = this._authenticationService.currentAgencyValue;
    this.user = this._authenticationService.currentUserValue;
    this.currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this._translateService.onLangChange
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
      });

    merge(this.sort.sortChange, this.paginator.page, this.reloadProperties)
      .pipe(
        startWith(''),
        tap(() => this.loadingResults = true),
        takeUntil(this._unsubscribeAll),
        switchMap(() => {

          if (this.relatedTo === 'accounts') {
            return this._historyService.getAccountsProperties(this.id, this.paginator.pageIndex + 1);
          } else if (this.relatedTo === 'owners') {
            return this._historyService.getOwnersProperties(this.id, this.paginator.pageIndex + 1);
          } else if (this.relatedTo === 'companies') {
            return this._historyService.getCompaniesProperties(this.id, this.paginator.pageIndex + 1);
          }

        })
      )
      .subscribe((data: any) => {
        this.loadingResults = false;
        this.properties.data = data.body;
        this.properties.total = data.headers.get('x-pagination-total-count');
        this.properties.limit = data.headers.get('x-pagination-per-page');
      }, () => this.loadingResults = false);
  }

  expandAll(): void {
    this.expandedAll = !this.expandedAll;
    this.expandedRow = {};
    if (this.expandedAll) {
      _.forEach(this.properties.data, (property: any) => {
        this.expandedTbl(property);
      });
    }
  }

  expandedTbl(property: any): void {
    this.expandedRow[property._id] = !this.expandedRow[property._id];
    if (this.expandedRow[property._id]) {
      if (property.private_info_object && property.private_info_object[this.currentAgency._id] && property.private_info_object[this.currentAgency._id].owner) {
        const owner = property.private_info_object[this.currentAgency._id].owner;
        this._ownersService.getById(owner)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((data: any) => {
            property.owner_data = data;
          });
      }
      if (property.offices) {
        const filter = { _id: property.offices };
        this._officesService.getAll(filter, 1, false)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((data: any) => {
            property.offices_data = data;
          });
      }
      this._attachmentsService.getModelAttachments(property._id, 'properties_images')
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((data: any) => {
          property.images = data;
          property._albums = [];
          _.forEach(data, (model: any) => {
            const album = {
              src: this._getImageSrcPipe.transform(model),
              caption: '',
              thumb: this._getImageSrcPipe.transform(model, 300)
            };
            property._albums.push(album);
          });
        });
    }
  }

  dateDiff(date: any): any {
    if (date) {
      const toDay = new Date();
      const propertyDate = new Date(date);
      const magicNumber = (1000 * 60 * 60 * 24);
      const days = Math.round(Math.abs((toDay.getTime() - propertyDate.getTime()) / (magicNumber)));
      const weeks = Math.round(days / 7);
      const months = Math.round(weeks / 4);
      const years = Math.round(months / 12);
      return {
        years: years,
        months: months,
        weeks: weeks,
        days: days
      };
    } else {
      return 'Not updated';
    }
  }

  ViewProperty(id: any): void {
    this.router.navigateByUrl('/properties/view/' + id);
  }

  updateFeature(property: any): void {
    property.featured = !property.featured;
    this._historyService.updateProperty(property).subscribe(() => {
      this.reloadProperties.next(true);
    });
  }

  openLightbox(_albums: any, index: any): void {
    this._lightbox.open(_albums, index);
  }

}
