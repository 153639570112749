import { Directive, HostListener, ElementRef, Renderer2, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MailBoxService } from 'app/services/mail-box.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PlansDialogComponent } from './plans-dialog/plans-dialog.component';

@Directive({
    selector: '[mlsPlans]'
})
export class MlsPlansDirective {

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        public _matDialog: MatDialog,
        public _mailBoxService: MailBoxService,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService,
    ) {
        this.renderer.setStyle(this.el.nativeElement, 'cursor', 'pointer');
    }

    @HostListener('click') onClick(): void {
        const dialogRef = this._matDialog.open(PlansDialogComponent, {
            disableClose: true,
            panelClass: 'plans-dialog',
            data: {},
        });
    }

}
