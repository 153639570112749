import { Directive, Input, HostListener } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { filter, forEach, union } from 'lodash';

@Directive({
  selector: '[appOffices]'
})
export class OfficesDirective {

  @Input() form: UntypedFormGroup;
  @Input() office: any;
  @Input() users: any;
  @Input() from: any;

  constructor() { }

  @HostListener('click') onClick(): void {
    const usersData = filter(this.users, ['office', this.office]);
    let usersID = [];
    if (this.from && this.from == 'owner') {
      forEach(usersData, (value) => {
        usersID.push(value._id);
      });
      this.form.get('assign_agent').patchValue(union(usersID, this.form.get('assign_agent').value));
    } else {
      forEach(usersData, (value) => {
        usersID.push(value.username);
      });
      this.form.get('assigned_to').patchValue(union(usersID, this.form.get('assigned_to').value));
    }
  }

}
