import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes, Router } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule, TranslateLoader, MissingTranslationHandler, MissingTranslationHandlerParams, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';
import { JwtInterceptor, ErrorInterceptor, AppProviders, MissingTranslations } from './_helpers';

import { FakeDbService } from 'app/fake-db/fake-db.service';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { registerLocaleData } from '@angular/common';


import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { environment } from 'environments/environment';
import { FuseThemeOptionsModule, TimeLineModule } from './components';

import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { AppRoutingModule } from './app-routing.module';
import * as _ from 'lodash';
// import * as Sentry from "@sentry/angular";

import localeEs from '@angular/common/locales/es';
import localeNl from '@angular/common/locales/nl';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localePt from '@angular/common/locales/pt';
import localeTr from '@angular/common/locales/tr';
import localeCa from '@angular/common/locales/ca';
import localeRo from '@angular/common/locales/ro';

import { ToastrModule } from 'ngx-toastr';
import { UserFaqsModule } from './main/user-faqs/user-faqs.module';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { MatPaginatorCustomIntl } from './_helpers/mat-paginator-custom-intl';
import { ResetPasswordModule } from './components/reset-password/reset-password.module';
import { SearchPanelModule } from './components/search-panel/search-panel.module';
import { SystemMessagesModule } from './components/system-messages/system-messages.module';
import { SharingWarningModule } from './components/sharing-warning/sharing-warning.module';
import { MlsWizardModule } from './components/mls-wizard/mls-wizard.module';
import { ClientRegistrationRequestModule } from './main/properties/index/client-registration-request/client-registration-request.module';
import { PropertyReservationModule } from './main/properties/index/property-reservation/property-reservation.module';
import { RenewSubscriptionModule } from './components/renew-subscription/renew-subscription.module';
import { HistoryModule } from './main/history/history.module';
// import { WINDOW_PROVIDERS } from './services/window.service';

registerLocaleData(localeEs, 'es_AR');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeNl, 'nl');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeIt, 'it');
registerLocaleData(localePt, 'pt');
registerLocaleData(localeTr, 'tr');
registerLocaleData(localeCa, 'ca');
registerLocaleData(localeRo, 'ro');

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, environment.yiiUrl + 'translations/get-translation?lang=', '');
}

export function appFactory(provider: AppProviders): any {
    return () => provider.load();
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatPasswordStrengthModule.forRoot(),

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: MissingTranslations
            },
        }),
        // TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),

        // Froala Module
        // FroalaEditorModule.forRoot(),
        // FroalaViewModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        ToastrModule.forRoot(),

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        // App modules
        LayoutModule,
        AppRoutingModule,
        UserFaqsModule,
        HistoryModule,
        RenewSubscriptionModule,
        ResetPasswordModule,
        SharingWarningModule,
        SystemMessagesModule,
        MatSnackBarModule,
        TimeLineModule,
        SearchPanelModule,
        MlsWizardModule,
        InfiniteScrollModule,
        // ClientRegistrationRequestModule,
        // PropertyReservationModule
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        // WINDOW_PROVIDERS,
        AppProviders,
        { provide: APP_INITIALIZER, useFactory: appFactory, deps: [AppProviders], multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: { display: { dateInput: 'DD/MM/YYYY', monthYearLabel: 'MMM YYYY', } } },
        { provide: MatPaginatorIntl, useClass: MatPaginatorCustomIntl },
        // {
        //     provide: ErrorHandler,
        //     useValue: Sentry.createErrorHandler({
        //         showDialog: false,
        //     }),
        // },
        // {
        //     provide: Sentry.TraceService,
        //     deps: [Router],
        // },
        // {
        //     provide: APP_INITIALIZER,
        //     useFactory: () => () => { },
        //     deps: [Sentry.TraceService],
        //     multi: true,
        // },
    ],
})
export class AppModule {
}
