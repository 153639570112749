import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ConfirmDialogComponent implements OnInit {

    public confirmMessage: string;
    public reason: boolean;
    public comments: string;

    public headerLabel = 'Confirm';
    public label = 'Comment';
    public confirmBtnLabel = 'Confirm';
    public cancelBtnLabel = 'Cancel';

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any
    ) {

    }

    ngOnInit(): void {

    }

    isDisabled(): boolean {
        if (this.reason && !this.comments) {
            return true;
        }
        return false;
    }

}
