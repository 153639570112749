import { Component, OnInit, ViewEncapsulation, ViewChild, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { startWith, takeUntil } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseService } from '../../../../../../_helpers/base/base.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShareModalComponent implements OnInit,OnDestroy {
  
  public borderColor: string;
  public toggle = false as boolean;
  public post;
  public currentLang;

  public form: UntypedFormGroup;

  public boards = [];

  public env = environment;

  private unsubscribeAll: Subject<any> = new Subject();
  
  constructor(
    private dialogRef: MatDialogRef<ShareModalComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private globalService: BaseService,
    private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.translateService.onLangChange
    .pipe(startWith(''))
    .subscribe(() => {
        this.currentLang = this.translateService.currentLang;
    });

    this.form = this.formBuilder.group({
      toBoard: [null, Validators.required]
    });
    this.post = data;
    this.form.get('toBoard').setValue(this.post.board._id);
  }

  ngOnInit(): void {
    this.globalService.post('mp/board/get/all', {filterByCountry:true}, 'nodejs')
    .pipe(takeUntil(this.unsubscribeAll))
    .subscribe(res => {
      this.boards = res.docs;
    });
  }

  public sharePost(){
    this.dialogRef.close({ status: true, post: this.post,toBoard:this.form.value.toBoard });
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  }

  public save(): void {
    this.dialogRef.close({ status: true, post: this.post });
  }

  public close(): void {
    this.dialogRef.close({ status: false });
  }

   /**
   * On destroy
   */

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

}
