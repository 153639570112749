import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { Sequences } from 'app/models/sequences';
@Injectable({
    providedIn: 'root'
})
export class SequencesService {

    constructor(
        private http: HttpClient,
    ) { }

    getAll(data: any, page: any, perPage?: any): Observable<object> {
        page = page ? page : 1;
        perPage = perPage ? perPage : 10;
        return this.http.post<object>(`${environment.yiiUrl}events-sequence/search?expand=offices_data&page=${page}&per-page=${perPage}`, data, { observe: 'response' as 'body' })
            .pipe(map(sequences => {
                return sequences;
            }));
    }

    getById(_id: string): Observable<object> {
        return this.http.get(`${environment.yiiUrl}events-sequence/${_id}`, {});
    }

    create(sequences: Sequences): Observable<object> {
        return this.http.post(`${environment.yiiUrl}events-sequence`, sequences);
    }

    update(sequence: Sequences): Observable<object> {
        return this.http.put(`${environment.yiiUrl}events-sequence/${sequence._id}`, sequence);
    }

    delete(_id: number): Observable<object> {
        return this.http.delete(`${environment.yiiUrl}events-sequence/${_id}`);
    }
    dublicate(_id: string): Observable<object> {
        return this.http.get(`${environment.yiiUrl}events-sequence/duplicate?id=${_id}`);
    }

    searchAgencies(page: string, data: any): Observable<Sequences[]> {
        return this.http.post<Sequences[]>(`${environment.yiiUrl}events-sequence/search?page=` + page, data, { observe: 'response' as 'body' })
            .pipe(map(sequences => {
                return sequences;
            }));
    }

    getEvents(data: any, page: any): Observable<object> {
        return this.http.post<object>(`${environment.yiiUrl}activity-events/search?page=` + page, data, { observe: 'response' as 'body' })
            .pipe(map(sequences => {
                return sequences;
            }));
    }

    activateCron(data: any): Observable<any> {
        return this.http.post(`${environment.yiiUrl}activity-events/creat-job`, data);
    }

    createActivityEvent(sequences: any): Observable<object> {
        return this.http.post(`${environment.yiiUrl}activity-events`, sequences);
    }
    updateActivityEvent(sequence: any): Observable<object> {
        return this.http.put(`${environment.yiiUrl}activity-events/${sequence._id}`, sequence);
    }
    DeleteActivityEvent(_id: any): Observable<any> {
        return this.http.delete(`${environment.yiiUrl}activity-events/${_id}`);
    }
    getSequence(filter: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}sequence`, filter);
    }

    getActivities(filter: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}sequence/activities`, filter);
    }

    deleteSequence(data: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}activities/delete-sequence?id=${data._id}`);
    }

    deletePendingEvents(data: any): Observable<any> {
        return this.http.get(`${environment.yiiUrl}activities/delete-sequence-activities?id=${data._id}`);
    }

    updateActiveStatus(data: any): Observable<any> {
        return this.http.put(`${environment.yiiUrl}activities/${data._id}`, data);
    }
    removeActivityMilestone(data: any): Observable<any> {
        return this.http.put(`${environment.yiiUrl}activity-events/remove-milestone`, data);
    }
    eventsMilestone(data: any): Observable<any> {
        return this.http.post(`${environment.yiiUrl}activity-events/of-milestone`, data);
    }

    saveEmail(data: any): Observable<any> {
        return this.http.post(`${environment.yiiUrl}activities/save-email-activity`, data);
    }

    updateEmailEvent(data: any): Observable<any> {
        return this.http.post(`${environment.yiiUrl}activities/update-email-event`, data);
    }
}
