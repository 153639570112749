import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DistributionListService {

    constructor(private http: HttpClient) { }

    getList(relatedTo: string): Observable<object> {
        return this.http.post(`${environment.yiiUrl}accounts/distribution-list?type=${relatedTo}`, {});
    }

    addList(data: any): Observable<object> {
        return this.http.post(`${environment.yiiUrl}accounts/create-distribution-list`, data);
    }

    deleteList(listId: string): Observable<object> {
        return this.http.get(`${environment.yiiUrl}accounts/delete-distribution-list?list_id=${listId}`);
    }

    addSubscribersToList(data: any, listId: string, relatedTo: any): Observable<object> {
        return this.http.post(`${environment.yiiUrl}accounts/pass-data-to-list?list_id=${listId}&type=${relatedTo}`, data);
    }

    getSubscribersList(data: any, relatedTo: any): Observable<object> {
        return this.http.post(`${environment.yiiUrl}accounts/view-distribution-list?type=${relatedTo}`, data);
    }

    deleteSubscriberFromList(data: any): Observable<object> {
        return this.http.get(`${environment.yiiUrl}accounts/remove-list-subscriber?id=${data._id}`);
    }
}
