import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root'
})
export class PropertiesService {
    currentUser: any;
    currentAgency: any;

    constructor(
        private http: HttpClient,
        private _authenticationService: AuthenticationService
    ) {
        this.currentUser = this._authenticationService.currentUserValue;
        this.currentAgency = this._authenticationService.currentAgencyValue;
    }

    getData(postData: any, page?: any, perPage?: any, exporter?: any, lang?: any): Observable<any[]> {
        page = page ? page : 1;
        perPage = perPage ? perPage : 10;

        if (exporter) {
            // tslint:disable-next-line: max-line-length
            return this.http.post<any[]>(`${environment.yiiUrl}properties/search?expand=bookings,properties_copy,url,website_url,agency_logo,brochures,property_type,property_type_two,property_location,property_city,property_urbanisation,property_region,property_province,listing_agent_name,vt,cart_user,rejection_reason,sharing_data&page=${page}&per-page=${perPage}&exporter=true`, postData, { observe: 'response' as 'body' });
        } else {
            // tslint:disable-next-line: max-line-length
            return this.http.post<any[]>(`${environment.yiiUrl}properties/search?expand=bookings,properties_copy,url,website_url,agency_logo,brochures,property_type,property_type_two,property_location,property_city,property_urbanisation,property_region,property_province,listing_agent_name,vt,cart_user,rejection_reason,sharing_data,keyholder_company_data&page=${page}&per-page=${perPage}&language=${lang}`, postData, { observe: 'response' as 'body' });
        }
    }

    getProperties(filter: any, refs?: any): Observable<any[]> {
        // tslint:disable-next-line: max-line-length
        return this.http.get<any[]>(`${environment.yiiUrl}properties/load-properties?expand=agency_logo,listing_agent_name,property_location&ref=${filter}&refs=${refs}`, { observe: 'response' as 'body' });
    }

    getGridData(page?: any): Observable<any[]> {
        // tslint:disable-next-line: max-line-length
        return this.http.get<any[]>(`${environment.yiiUrl}properties?expand=properties_copy,url,website_url,agency_logo,property_type,property_type_two,property_location,property_city,property_urbanisation,property_region,property_province,listing_agent_name,cart_user&page=${page}`, { observe: 'response' as 'body' });
    }

    getPropertyImage(_id: string): Observable<any[]> {
        // tslint:disable-next-line: max-line-length
        return this.http.get<any[]>(`${environment.yiiUrl}properties/get-property-images?p_id=${_id}`, { observe: 'response' as 'body' });
    }

    getPropertyData(_id: string): Observable<any[]> {
        // tslint:disable-next-line: max-line-length
        return this.http.get<any[]>(`${environment.yiiUrl}properties/${_id}?expand=properties_copy,url,website_url,agency_logo,shared_agency_ids,p_agency_name,p_buckets,p_user_name,cart_user,property_type,property_type_two,property_location,listing_agent_name,p_agency_data,vt,website_url`, { observe: 'response' as 'body' });
    }

    getAttachments(_id: string): Observable<any[]> {
        return this.http.get<any[]>(`${environment.yiiUrl}properties/get-attachments?p_id=${_id}`, { observe: 'response' as 'body' });
    }

    getBaskets(property: any): Observable<any[]> {
        return this.http.get<any[]>(`${environment.yiiUrl}buckets?pagination=false&expand=cc&model=properties&property=${property}`, { observe: 'response' as 'body' });
    }

    updateProperty(_id: any, _property: any): Observable<any[]> {
        return this.http.put<any[]>(`${environment.yiiUrl}properties/${_id}`, _property);
    }

    bulkArchive(ids: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.yiiUrl}properties/bulk-archive`, ids);
    }

    getPropertyDocument(_id): Observable<any[]> {
        return this.http.get<any[]>(`${environment.yiiUrl}properties/get-property-documents?p_id=${_id}`, { observe: 'response' as 'body' });
    }

    createProperty(_property: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.yiiUrl}properties`, _property, { observe: 'response' as 'body' });
    }

    AddToList(id: any): Observable<any> {
        return this.http.get<any[]>(`${environment.yiiUrl}properties/add-to-list?model_id=${id}`, { observe: 'response' as 'body' });
    }

    RemoveFromList(id: any): Observable<any> {
        return this.http.get<any[]>(`${environment.yiiUrl}properties/remove-from-list?model_id=${id}`, { observe: 'response' as 'body' });
    }

    getPrivateBasketList(data: any, page: any): Observable<any> {
        return this.http.get<any[]>(`${environment.yiiUrl}properties/get-list?page=${page}`, { observe: 'response' as 'body' });
    }

    getPropertyFeatures(filters?: any): Observable<any> {
        return this.http.post<any[]>(`${environment.apiUrl}propertyfeatures/all`, filters);
    }

    generatePdf(data: any): Observable<any> {
        return this.http.post<any[]>(`${environment.yiiUrl}pdf-dom/genrate-pdf`, data);
    }

    insertPC(data: any): Observable<any> {
        return this.http.post<any[]>(`${environment.yiiUrl}properties-copy`, data);
    }

    updatePC(data: any): Observable<any> {
        return this.http.put<any[]>(`${environment.yiiUrl}properties-copy/${data._id}`, data);
    }

    createCopy(data: any): Observable<any> {
        return this.http.post<any[]>(`${environment.yiiUrl}properties`, data);
    }

    copyAttachments(from: any, to: any): Observable<any> {
        return this.http.get<any[]>(`${environment.yiiUrl}attachments/copy-attachment?from_id=${from}&to_id=${to}`);
    }

    getUrbanisationProperties(dataFilter: any, propertyId: string, page?: any, limit?: any): Observable<any[]> {
        page = page ? page : 1;
        limit = limit ? limit : 30;
        const filter = {
            options: {
                page: page,
                limit: limit
            },
            query: dataFilter
        };
        propertyId = propertyId ? propertyId : '-1';
        return this.http.post<any[]>(`${environment.apiUrl}urbanisations/properties/${propertyId}`, filter, { observe: 'response' as 'body' });
    }

    getRelatedAccounts(propertyId: string, filter: any, page: any, sortFilter: any, searchBy: string, search: string): Observable<any[]> {
        page = page ? page : 1;
        if (!Object.keys(sortFilter).length && search === '') {
            return this.http.post<any[]>(
                `${environment.yiiUrl}accounts/get-related-accounts?id=${propertyId}&page=${page}&expand=language_data`,
                filter,
                { observe: 'response' as 'body' }
            );
        }
        else {
            // tslint:disable-next-line:max-line-length
            return this.http.post<any[]>(`${environment.yiiUrl}accounts/get-related-accounts?sort[]=${sortFilter.active}&sort[]=${sortFilter.direction}&search[]=${searchBy}&search[]=${search}&id=${propertyId}&page=${page}&expand=language_data`, filter,
                { observe: 'response' as 'body' });
        }
    }

    getVirtualTours(id: string): Observable<any> {
        return this.http.get<any>(`${environment.yiiUrl}virtual-tours/property-vt?p_id=${id}`);
    }

    getAccounts(id: string, page?: any): Observable<any> {
        page = page ? page : 1;
        return this.http.get<any>(`${environment.yiiUrl}properties/get-accounts?property_id=${id}&expand=users&page=${page}`);
    }

    getActivities(id: string, page?: any, duration?: any, type?: any): Observable<any> {
        page = page ? page : 1;
        duration = duration ? duration : '';
        type = type ? type : '';
        return this.http.get<any>(`${environment.yiiUrl}properties/get-activities?property_id=${id}&expand=users&page=${page}&duration=${duration}&type=${type}`);
    }

    getSales(id: string, page?: any): Observable<any> {
        page = page ? page : 1;
        return this.http.get<any>(`${environment.yiiUrl}properties/get-sales?property_id=${id}&expand=property_ref,listing_agency&page=${page}`);
    }

    getOffers(id: string, page?: any): Observable<any> {
        page = page ? page : 1;
        // tslint:disable-next-line:max-line-length
        return this.http.get<any>(`${environment.yiiUrl}property-offers?property_id=${id}&page=${page}&sort[]=undefined&sort[]=undefined&expand=property_data,account_data,agency_data,all_offers,offers,c_offers`);
    }

    getRelatedEmails(id: string, page?: any, filter?: any): Observable<any> {
        filter = filter ? filter : '';
        page = page ? page : 1;
        return this.http.get<any>(`${environment.yiiUrl}properties/get-related-emails?id=${id}&page=${page}&expand=properties_data&duration=${filter}`);
    }

    getRentalContracts(id: string, page?: any): Observable<any> {
        page = page ? page : 1;
        const filter = {
            contract_for: 'properties',
            property: id
        };
        return this.http.post<any>(`${environment.yiiUrl}rental-contracts/search?page=${page}&expand=property_data`, filter);
    }

    getBookmarks(model?: any): Observable<any> {
        return this.http.get<any>(`${environment.yiiUrl}bookmark-search?model=${model}`);
    }

    getBookmarkById(id: any): Observable<any> {
        return this.http.get<any>(`${environment.yiiUrl}bookmark-search/${id}`);
    }

    bookmarkSearch(data: any): Observable<any> {
        return this.http.post<any>(`${environment.yiiUrl}bookmark-search`, data);
    }

    removeBookmark(id: any): Observable<any> {
        return this.http.delete<any>(`${environment.yiiUrl}bookmark-search/${id}`);
    }

    getPropertyBookings(id: string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}yovendo/property_bookings?id=${id}`);
    }

    getArchiveProperties(filter: any, page?: any, perPage?: any): Observable<any> {
        page = page ? page : 1;
        perPage = perPage ? perPage : 10;
        // tslint:disable-next-line: max-line-length
        return this.http.post<any>(`${environment.yiiUrl}properties/deleted-properties?expand=property_type,property_type_two,property_location&page=${page}&per-page=${perPage}&sharing=false`, filter, { observe: 'response' as 'body' });
    }

    restoreArchiveProperty(_id: number, data: any): Observable<object> {
        return this.http.put(`${environment.yiiUrl}properties/${_id}`, data);
    }

    deleteArchiveProperty(_id: number, createdby: any, agency: any): Observable<object> {
        return this.http.delete(`${environment.yiiUrl}properties/${_id}?created_by=${createdby}&agency=${agency}`);
    }

    restoreSelectedProperties(filter: any): Observable<any> {
        return this.http.post<any>(`${environment.yiiUrl}properties/restore-selected`, filter);
    }

    deleteSeletedArchiveProperties(filter: any): Observable<object> {
        return this.http.post(`${environment.yiiUrl}properties/delete-selection`, filter);
    }

    restoreAll(agency: any): Observable<object> {
        return this.http.get(`${environment.yiiUrl}properties/restore-all?agency=${agency}`);
    }

    deleteAll(agency: any): Observable<object> {
        return this.http.delete(`${environment.yiiUrl}properties/delete-all?agency=${agency}`);
    }

    getPricesHistory(id: string): Observable<object> {
        return this.http.get(`${environment.yiiUrl}properties/change-prices?property=${id}`);
    }

    getPropertiesViewCount(filter: any): Observable<object> {
        return this.http.post(`${environment.yiiUrl}properties/properties-view-count`, filter);
    }

    // testionials methods
    getTestimonials(id: string): Observable<object> {
        return this.http.get(`${environment.yiiUrl}testimonials?property=${id}`);
    }

    getBookings(id: string): Observable<object> {
        return this.http.get(`${environment.yiiUrl}properties/get-property-bookings?id=${id}&page=100`);
    }

    updateTestimonial(data: any): Observable<object> {
        return this.http.put(`${environment.yiiUrl}testimonials/${data._id}`, data);
    }

    deleteTestimonial(id: string): Observable<object> {
        return this.http.delete(`${environment.yiiUrl}testimonials/${id}`);
    }

    createTestimonial(data: any): Observable<object> {
        return this.http.post(`${environment.yiiUrl}testimonials`, data);
    }

    getPropertyOwner(oid: any, createdBy: any): Observable<object> {
        return this.http.get(`${environment.yiiUrl}properties/get-property-owner?o_id=${oid}&created_by=${createdBy}`);
    }

    bulkUpdate(data: any): Observable<object> {
        return this.http.post(`${environment.yiiUrl}properties/bulk-update`, data);
    }

    selectAll(filter: any): Observable<object> {
        return this.http.post(`${environment.yiiUrl}properties/select-all-query`, filter);
    }

    // get duplications
    getDuplications(page: any, perPage: any): Observable<object> {
        page = page ? page : 1;
        perPage = perPage ? perPage : 10;
        return this.http.post(`${environment.yiiUrl}properties/get-duplications?user=${this.currentUser._id}&page=${page}&per-page=${perPage}`, {},
            { observe: 'response' as 'body' });
    }

    getPropertyforDuplication(id: string): Observable<object> {
        return this.http.get(`${environment.yiiUrl}properties/${id}?expand=images,listing_agent_name,owner_data`);
    }

    // excluded properties
    getExcludedProperties(filter: any, page: any, perPage: any): Observable<object> {
        page = page ? page : 1;
        perPage = perPage ? perPage : 10;
        return this.http.post(`${environment.yiiUrl}properties/excluded-properties?expand=property_location,property_type&page=${page}&per-page=${perPage}`, filter,
            { observe: 'response' as 'body' });
    }


    getExcludedPropertiesReason(id: any, filter: any = []): Observable<any> {
        return this.http.post<any>(`${environment.yiiUrl}properties/excluded-properties-reason?id=${id}`, filter);
    }

    getPotentialDuplications(page: any, perPage: any): Observable<object> {
        page = page ? page : 1;
        perPage = perPage ? perPage : 10;
        return this.http.post(`${environment.yiiUrl}properties/potential-duplications?user=${this.currentUser._id}&page=${page}&per-page=${perPage}`, {},
            { observe: 'response' as 'body' });
    }

    clearBaskets(): Observable<object> {
        return this.http.get(`${environment.yiiUrl}properties/clear-list`);
    }

    // Properties Statistics

    getPropertyStatData(_id: string): Observable<any[]> {
        // tslint:disable-next-line: max-line-length
        return this.http.get<any[]>(`${environment.yiiUrl}properties/${_id}?expand=shared_agency_ids,p_agency_name,p_buckets,p_user_name,bookings,properties_copy,url,website_url,agency_logo,property_type,property_type_two,property_location,property_city,property_urbanisation,property_region,property_province,listing_agent_name,vt,cart_user`, { observe: 'response' as 'body' });
    }

    getEmailsStatistics(_id: string, range: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}properties/emails/${_id}`, range);
    }

    getSalesStatistics(_id: string): Observable<any[]> {
        return this.http.get<any[]>(`${environment.apiUrl}properties/sales/${_id}`);
    }

    getOffersStatistics(_id: string): Observable<any[]> {
        return this.http.get<any[]>(`${environment.apiUrl}properties/offers/${_id}`);
    }

    getViewingsContacts(_id: string): Observable<any[]> {
        return this.http.get<any[]>(`${environment.apiUrl}properties/viewings-contacts/${_id}`);
    }

    getVisitors(_id: string): Observable<any[]> {
        return this.http.get<any[]>(`${environment.apiUrl}properties/visitors/${_id}`);
    }

    getQuestionaire(_id: string): Observable<any[]> {
        return this.http.get<any[]>(`${environment.apiUrl}properties/questionaire/${_id}`);
    }

    getPublications(_id: string): Observable<any[]> {
        return this.http.get<any[]>(`${environment.apiUrl}properties/publications/${_id}`);
    }

    getEmailAndCallsStatistics(_id: string): Observable<any[]> {
        return this.http.get<any[]>(`${environment.apiUrl}properties/emails_and_calls/${_id}`);
    }

    uploadCsv(file: File): Observable<any> {
        const formData: any = new FormData();
        formData.append('file', file);
        return this.http.post(`${environment.yiiUrl}properties/csv-properties-uploader?user=${this.currentUser._id}`, formData);
    }
    downloadReport(data: any): Observable<any> {
        return this.http.post<any[]>(`${environment.yiiUrl}pdf/download-report`, data);
    }
    changeWebPublishing(data: any): Observable<any> {
        return this.http.post(`${environment.yiiUrl}properties/change-web-publishing`, data);
    }
    exportAttachments(data: any): Observable<any> {
        return this.http.post(`${environment.yiiUrl}properties/export-attachments-xml`, data);
    }

}
