import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SendinblueService {

  constructor(
    private http: HttpClient,
  ) { }

  getList(ids: any): Observable<any> {
    return this.http.post(`${environment.yiiUrl}accounts/multi-accounts-sendinblue?type=GET`, ids);
  }

  addToList(id: any, ids: any, owner?: boolean): Observable<any> {
    return this.http.post(`${environment.yiiUrl}accounts/multi-accounts-sendinblue?type=Multi_Accounts&list_id=${id}${owner ? '&owner=true' : ''}`, ids);
  }

  removeFromList(id: any, ids: any, owner?: boolean): Observable<any> {
    return this.http.post(`${environment.yiiUrl}accounts/multi-accounts-sendinblue?type=Multi_Accounts_Delete&list_id=${id}${owner ? '&owner=true' : ''}`, ids);
  }
}
