import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TimeLineService {

    constructor(
        private http: HttpClient,
    ) { }

    getAccountTimeline(model: string, modelId: string, filter?: string): Observable<object> {
        return this.http.get(`${environment.yiiUrl}comments/accounts-timeline?model=${model}&model_id=${modelId}&expand=attachments,user_data,created_user&filter=${filter}`);
    }

    getAccountTimelineEmails(model: string, modelId: string, filter?: string): Observable<object> {
        return this.http.get(`${environment.yiiUrl}comments/accounts-timeline-emails?model=${model}&model_id=${modelId}&expand=attachments,user_data,created_user&filter=${filter}`);
    }
    // ticket #2683
    getAccountTimelineMessages(model: string, modelId: string, filter?: string): Observable<object> {
        return this.http.get(`${environment.yiiUrl}comments/accounts-timeline-messages?model=${model}&model_id=${modelId}&expand=attachments,user_data,created_user&filter=${filter}`);
    }
    getAccountExpandedPropertiesTimeline(property_id: string, account_id: string, filter?: string): Observable<object> {
        return this.http.get(`${environment.yiiUrl}comments/expanded-properties-timeline-in-account?property_id=${property_id}&account_id=${account_id}&expand=attachments,user_data,created_user&filter=${filter}`);
    }
    getAccountExpandedPropertiesTimelineStatus(account_id: string): Observable<object> {
        return this.http.get(`${environment.yiiUrl}comments/accounts-timeline-messages-status?account_id=${account_id}&expand=attachments,user_data,created_user`);
    }
    getAccountTimelineUpdateStatuses(postData: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.yiiUrl}comments/account-timeline-update-statuses`, postData, { observe: 'response' as 'body' });
    }

    getOwnerTimeline(model: string, modelId: string, filter?: string): Observable<object> {
        return this.http.get(`${environment.yiiUrl}comments/owners-timeline?model=${model}&model_id=${modelId}&expand=attachments,user_data,created_user&filter=${filter}`);
    }

    getOwnerTimelineEmails(model: string, modelId: string, filter?: string): Observable<object> {
        return this.http.get(`${environment.yiiUrl}comments/owners-timeline-emails?model=${model}&model_id=${modelId}&expand=attachments,user_data,created_user&filter=${filter}`);
    }

    getPropertyTimeline(model: string, modelId: string, filter?: string): Observable<object> {
        return this.http.get(`${environment.yiiUrl}comments/properties-timeline?model=${model}&model_id=${modelId}&expand=attachments,user_data,created_user&filter=${filter}`);
    }
    getCommercialTimeline(model: string, modelId: string): Observable<object> {
        return this.http.get(`${environment.yiiUrl}comments/commercial-time-line?model=${model}&model_id=${modelId}&expand=attachments,user_data,created_user`);
    }
    
    getBoatMooringTimeline(model: string, modelId: string): Observable<object> {
        return this.http.get(`${environment.yiiUrl}comments/boat-mooring-timeline?model=${model}&model_id=${modelId}&expand=attachments,user_data,created_user`);
    }

    getSequenceTimeline(model: string, modelId: string, filter?: string): Observable<object> {
        return this.http.get(`${environment.yiiUrl}comments/sequence-time-line?model=${model}&model_id=${modelId}&filter=${filter}`);
    }

    addComment(comment: any): Observable<object> {
        return this.http.post(`${environment.yiiUrl}comments`, comment);
    }

    getActivitiesTimeline(model: string, modelId: string, filter?: string): Observable<object> {
        return this.http.get(`${environment.yiiUrl}comments?model=${model}&model_id=${modelId}&expand=attachments,user_data,created_user`);
    }

    getCompaniesTimeline(model: string, modelId: string, filter?: string): Observable<object> {
        return this.http.get(`${environment.yiiUrl}comments/companies-timeline?model=${model}&model_id=${modelId}&expand=attachments,user_data,created_user`);
    }
}
