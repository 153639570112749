import { Directive, HostListener, ElementRef, Renderer2, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MailchimpListComponent } from './mailchimp-list/mailchimp-list.component';

@Directive({
    selector: '[mailchimp]'
})
export class MailchimpDirective {

    @Input() ids = [];
    @Input() owner = false;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        public _matDialog: MatDialog
    ) {
        this.renderer.setStyle(this.el.nativeElement, 'cursor', 'pointer');
    }

    @HostListener('click') onClick(): void {
        const dialogRef = this._matDialog.open(MailchimpListComponent, {
            panelClass: 'mailchimp-list-dialog',
            data: {
                ids: this.ids,
                owner: this.owner
            },
        });
        dialogRef.afterClosed()
            .subscribe(response => {
                console.log(response);
            });
    }

}
