// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `menu-access .menu-tree-invisible {
  display: none;
}
menu-access .menu-tree ul,
menu-access .menu-tree li {
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/menu-access/menu-access.component.scss"],"names":[],"mappings":"AACI;EACI,aAAA;AAAR;AAGI;;EAEI,aAAA;EACA,gBAAA;EACA,qBAAA;AADR","sourcesContent":["menu-access {\n    .menu-tree-invisible {\n        display: none;\n    }\n\n    .menu-tree ul,\n    .menu-tree li {\n        margin-top: 0;\n        margin-bottom: 0;\n        list-style-type: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
