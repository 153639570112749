import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from 'app/services';
import { UntypedFormControl } from '@angular/forms';
import { startWith, tap, switchMap, debounceTime } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BaseService } from 'app/_helpers/base/base.service';

@Component({
    selector: 'history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HistoryComponent implements OnInit {
    loadingResults = false;
    recordsArray = [];
    allData = [];
    tabs = [];
    faqs: any;
    subFaqs: any;
    systemlang: any;
    searchInput: UntypedFormControl = new UntypedFormControl('');
    currentLang: string;
    currentAgency: any;
    messageQueue: boolean = false;

    constructor(
        public _translateService: TranslateService,
        private _gService: BaseService,
        private _authenticationService: AuthenticationService,
    ) {
        this.systemlang = this._translateService.currentLang;
        this.currentAgency = this._authenticationService.currentAgencyValue;
    }

    ngOnInit(): void {
        if (this.currentAgency?.message_queue) {
            this.messageQueue = true;
            this.loadingResults = true;
            this._gService.get('models', 'nodejs').subscribe((models: any) => {
                if (models && models.length) {
                    this.tabs = models
                }
            })

            this._gService.get('records', 'nodejs').subscribe((data: any) => {
                this.recordsArray = [];
                if (Object.keys(data).length > 0) {
                    this.recordsArray = data;
                    this.allData = data;
                }
                this.loadingResults = false;
            }, () => { this.loadingResults = false; });

            try {
                this.searchInput.valueChanges
                    .pipe(
                        debounceTime(500),
                        startWith(''),
                        tap(() => this.loadingResults = true),
                        switchMap(async () => {
                            let filteredData:any = [{}];
                            if (this.searchInput.value) {
                                for (const tab of this.tabs) {
                                    filteredData[0][tab] = [];
                                    filteredData[0][tab] = await this.searchInNestedObjects(this.allData[tab], this.searchInput.value);
                                }
                                return filteredData;
                            } else {
                                return (filteredData[0] = this.allData);
                            }
                        })
                    )
                    .subscribe((data: any) => {
                        this.recordsArray = [];
                        data = (Array.isArray(data) && data.length) ? data[0] : data;
                        if (Object.keys(data).length > 0) {
                            this.recordsArray = data;
                        }
                        this.loadingResults = false;
                    }, () => { this.loadingResults = false; });
            } catch (error) {
                console.log(error);
            }
        }
    }

    async searchInNestedObjects(data, searchQuery) {
        let pattern = new RegExp(searchQuery, 'i')
        const filteredArray = await data.filter((obj) => {
            return pattern.test(JSON.stringify(obj));
        });
        return Array.isArray(filteredArray) ? filteredArray : [];;
    }

}
