// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 599px) {
  .mls-wizard {
    width: 100%;
  }
}
@media screen and (min-width: 600px) {
  .mls-wizard {
    width: 50%;
  }
}
.mls-wizard .mat-mdc-dialog-container {
  padding: 0;
}
.mls-wizard .dialog-content-wrapper {
  max-height: 85vh;
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/@fuse/scss/partials/_breakpoints.scss","webpack://./src/app/components/mls-wizard/mls-wizard.component.scss"],"names":[],"mappings":"AAiCQ;EC/BR;IAEQ,WAAA;EADN;AACF;AD6BQ;EC/BR;IAMQ,UAAA;EAAN;AACF;AAEI;EACI,UAAA;AAAR;AAGI;EACI,gBAAA;EACA,aAAA;EACA,sBAAA;AADR","sourcesContent":["// Media step breakpoint mixin based on Angular Material lib\n$breakpoints: (\n    xs: 'screen and (max-width: 599px)',\n    sm: 'screen and (min-width: 600px) and (max-width: 959px)',\n    md: 'screen and (min-width: 960px) and (max-width: 1279px)',\n    lg: 'screen and (min-width: 1280px) and (max-width: 1919px)',\n    xl: 'screen and (min-width: 1920px) and (max-width: 5000px)',\n    lt-sm: 'screen and (max-width: 599px)',\n    lt-md: 'screen and (max-width: 959px)',\n    lt-lg: 'screen and (max-width: 1279px)',\n    lt-xl: 'screen and (max-width: 1919px)',\n    gt-xs: 'screen and (min-width: 600px)',\n    gt-sm: 'screen and (min-width: 960px)',\n    gt-md: 'screen and (min-width: 1280px)',\n    gt-lg: 'screen and (min-width: 1920px)'\n) !default;\n\n// Re-map the breakpoints for the helper classes\n$helper-breakpoints: (\n    xs: null,\n    sm: 'gt-xs',\n    md: 'gt-sm',\n    lg: 'gt-md',\n    xl: 'gt-lg'\n);\n\n@mixin media-breakpoint($breakpointName) {\n\n    $mediaQuery: map-get($breakpoints, $breakpointName);\n\n    @if ($mediaQuery == null) {\n        @content\n    } @else {\n        @media #{$mediaQuery} {\n            @content\n        }\n    }\n}\n","@import \"src/@fuse/scss/partials/breakpoints\";\n\n.mls-wizard {\n    @include media-breakpoint(\"xs\") {\n        width: 100%;\n    }\n\n    @include media-breakpoint(\"gt-xs\") {\n        width: 50%;\n    }\n\n    .mat-mdc-dialog-container {\n        padding: 0;\n    }\n\n    .dialog-content-wrapper {\n        max-height: 85vh;\n        display: flex;\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
