import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'app/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'system-messages',
    templateUrl: './system-messages.component.html',
    styleUrls: ['./system-messages.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SystemMessagesComponent implements OnInit {
    message: any;
    pagination: any;
    currentLang: string;

    constructor(
        public matDialogRef: MatDialogRef<SystemMessagesComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _userService: UserService,
        private _translateService: TranslateService,
    ) {
        this.pagination = {};
        this.message = {};
        this.currentLang = this._translateService.currentLang;
    }

    ngOnInit(): void {
        this.loadMessages();
    }

    loadMessages(page?: any): void {
        page = page ? page : 1;
        this._userService.getSystemMessages(page)
            .subscribe((data: any) => {
                this.message = (data.body && data.body[0]) ? data.body[0] : '';
                this.pagination.totalItems = data.headers.get('X-Pagination-Total-Count') * 1;
                this.pagination.currentPage = data.headers.get('X-Pagination-Current-Page') * 1;
                this.pagination.perPage = data.headers.get('X-Pagination-Per-Page') * 1;
                if (this.pagination.totalItems === 0) {
                    this.matDialogRef.close('closed');
                }
            });
    }

    back(): void {
        this.pagination.currentPage--;
        this.loadMessages(this.pagination.currentPage);
    }

    next(): void {
        this.pagination.currentPage++;
        this.loadMessages(this.pagination.currentPage);
    }

    messageGotIt(messageId: any): void {
        this._userService.messageGotIt(messageId)
            .subscribe(() => {
                this.loadMessages(this.pagination.currentPage);
            });
    }

    messageRemindLater(): void {
        if (this.pagination.currentPage === this.pagination.totalItems) {
            this.matDialogRef.close();
        } else {
            this.pagination.currentPage++;
            this.loadMessages(this.pagination.currentPage);
        }
    }
}
