import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { DropdownsService, AuthenticationService } from 'app/services';
import { Subject } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AccountConfirmModalComponent } from './account-confirm-modal/account-confirm-modal.component';
import { cloneDeep, includes, remove, forEach, isEmpty, trim } from 'lodash';
import { GlobalFuntions } from 'app/_helpers';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialog,
} from '@angular/material/dialog';
import { AccountsService } from 'app/services/accounts.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-account-mass-update',
    templateUrl: './account-mass-update.component.html',
    styleUrls: ['./account-mass-update.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class AccountMassUpdateComponent implements OnInit {
    gdpr = [
        {
            value: '0',
            name: this._translateService.instant('No value'),
        },
        {
            value: '1',
            name: this._translateService.instant('Accepted'),
        },
        {
            value: '2',
            name: this._translateService.instant('Pending'),
        },
        {
            value: '3',
            name: this._translateService.instant('no-permission'),
        },
    ];

    massUpdate: UntypedFormGroup;
    searchOffices$ = new Subject<string>();
    offices: any;
    searchAsignee$ = new Subject<string>();
    asignee: any;
    searchDialogRef: MatDialogRef<AccountConfirmModalComponent, any>;
    selectedOffices = [];
    private _unsubscribeAll: Subject<any>;
    currentOffice: any;

    constructor(
        public matDialogRef: MatDialogRef<AccountMassUpdateComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService,
        private _matDialog: MatDialog,
        private _authenticationService: AuthenticationService,
        private _dropdownsService: DropdownsService,
        private _globalFuntions: GlobalFuntions,
        private _accountsService: AccountsService,
        private _snackBar: MatSnackBar
    ) {
        this.currentOffice = _authenticationService.currentOfficeValue;
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.massUpdate = this.formBuilder.group({
            offices: '',
            assigned_to: '',
            gdpr_status: '',
            status: '',
            delete_flag: '',
            office_append: '',
            assigne_append: '',
        });

        this.searchOffices$
            .pipe(
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    return this._dropdownsService.getOffices(search);
                })
            )
            .subscribe((data: any) => {
                this.offices = data.body;
            });

        this.searchAsignee$
            .pipe(
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    return this._dropdownsService.getAllAgencyUsers(search);
                })
            )
            .subscribe((data: any) => {
                forEach(data, (value) => {
                    value.disabled =
                        !this.currentOffice.master_office &&
                        this.currentOffice._id !== value.office
                            ? true
                            : false;
                    value.label = isEmpty(trim(value.full_name))
                        ? value.username
                        : value.full_name;
                });
                this.asignee = data;
            });
    }

    doMassUpdate(): void {
        const data = cloneDeep(this.massUpdate.value);
        data.selected_offices = this.selectedOffices;
        this.searchDialogRef = this._matDialog.open(
            AccountConfirmModalComponent,
            {
                disableClose: false,
                data: {
                    accounts: this._data.accounts,
                    data: data,
                },
            }
        );
        this.searchDialogRef
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response: any) => {
                if (response) {
                    console.log(this.massUpdate.value);
                    this._accountsService
                        .massUpdate({ ids: this._data.accounts, data: data })
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe((data: any) => {
                            if (data.error) {
                                this._snackBar.open(
                                    this._translateService.instant(
                                        'error occoured while updating!'
                                    ),
                                    this._translateService.instant('Close'),
                                    {
                                        duration: 2000,
                                    }
                                );
                            } else {
                                this._snackBar.open(
                                    data.nModified +
                                        ' ' +
                                        this._translateService.instant(
                                            'records successfully'
                                        ),
                                    this._translateService.instant('Close'),
                                    {
                                        duration: 2000,
                                    }
                                );
                            }
                            this.matDialogRef.close(
                                this._globalFuntions.cleanObject(
                                    this.massUpdate.value
                                )
                            );
                        }, (err) => {
                            console.error(err);
                        });
                    // this.matDialogRef.close(this._globalFuntions.cleanObject(this.massUpdate.value));
                }
            });
    }

    onAddRemoveOffices(event: any, toDo: boolean): void {
        if (toDo && !includes(this.selectedOffices, event.name)) {
            this.selectedOffices = [...this.selectedOffices, event.name];
        }
        if (!toDo) {
            remove(this.selectedOffices, (n) => n === event.value.name);
        }
    }
}
