import { Directive, ElementRef, Renderer2, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DistributionListComponent } from './dialogs/distribution-list/distribution-list.component';

@Directive({
    selector: '[distributionList]'
})
export class DistributionListDirective {

    @Input() selectedAccountIds = [];
    @Input() emailInput = false;
    @Input() owner = false;
    @Output() emailsList: EventEmitter<any>;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        public _matDialog: MatDialog
    ) {
        this.renderer.setStyle(this.el.nativeElement, 'cursor', 'pointer');
        this.emailsList = new EventEmitter();
    }

    @HostListener('click') onClick(): void {
        const dialogRef = this._matDialog.open(DistributionListComponent, {
            panelClass: 'distributionList-dialog',
            data: {
                ids: this.selectedAccountIds,
                relatedTo: this.owner ? 'owners' : 'accounts',
                emailInput: this.emailInput
            },
        });
        dialogRef.afterClosed()
            .subscribe(response => {
                this.emailsList.next(response);
            });
    }

}
