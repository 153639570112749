import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationService } from 'app/services';

@Pipe({
  name: 'getCommercialReference'
})
export class GetCommercialReferencePipe implements PipeTransform {

  constructor(
    private _authenticationService: AuthenticationService,
  ) { }

  transform(property: any, args?: any): any {
    const agency = this._authenticationService.currentAgencyValue;
    let reference = property?.reference;
    if (agency?.pdf_external_ref === 'external_reference' && typeof property?.other_reference !== 'undefined' && property?.other_reference) {
      reference = property.other_reference;
    }
    if (agency?.pdf_external_ref === 'agency_reference' && typeof property?.external_reference !== 'undefined' && property?.external_reference) {
      reference = property.external_reference;
    }
    return reference;
  }

}
