import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuditService, DropdownsService, UserService } from 'app/services';
import { AccountsService } from 'app/services/accounts.service';
import { OwnersService } from 'app/services/owners.service';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class AuditComponent implements OnInit {

  @Input() data: any;
  @Input() id: any;
  @Input() workOrderCreatedBy: any;
  @Input() workOrderCreatedAt: any;
  dataTitle: string;
  user: any;
  createdAt: any;
  converted: boolean;
  loadingResults: boolean;

  displayedColumns: string[] = ['UpdatedAt', 'UpdatedBy', 'updated_attributes', 'IP'];
  dataSource: {};

  constructor(
    private route: ActivatedRoute,
    private _auditService: AuditService,
    private _accountsService: AccountsService,
    private _ownersService: OwnersService,
    private _userService: UserService
  ) {

  }

  ngOnInit(): void {
    if (this.data) {
      this.dataTitle = this.data;
    }
    if (!this.data) {
      this.data = this.route.snapshot.data;
      this.dataTitle = this.data.title;
    }
    if (!this.id) {
      this.id = this.route.snapshot.parent.paramMap.get('id');
    }
    if (this.dataTitle === 'account') {
      this._accountsService.getById(this.id).subscribe((data: any) => {
        this.createdAt = data.created_at;
        this._userService.getById(data.created_by).subscribe((user: any) => {
          this.user = user;
        });
      });
    }
    
    if (this.dataTitle === 'owner') {
      // this.displayedColumns = ['UpdatedAt', 'UpdatedBy', 'IP'];
      this._ownersService.getById(this.id).subscribe((data: any) => {
        this.createdAt = data.created_at;
        if (data.account_id) {
          this.converted = true;
        }
        this._userService.getById(data.created_by).subscribe((user: any) => {
          this.user = user;
        });
      });
    }
    if (this.id) { this.getHistory(); }


  }

  getHistory(): void {
    this.loadingResults = true;
    this._auditService.getAuditHistory(this.id, this.dataTitle).subscribe((data: any) => {
      this.dataSource = data.body;
      this.loadingResults = false;
    });
  }

  getDate(date: any): any {
    if (typeof date === 'string') {
      return date;
    } else {
      return date * 1000;
    }
  }

}
