import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SendinblueService } from 'app/services/sendinblue.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'sendinblue-list',
  templateUrl: './sendinblue-list.component.html',
  styleUrls: ['./sendinblue-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SendinblueListComponent implements OnInit {

  ids: any = [];
  displayedColumns: string[] = ['name', 'actions'];
  private _unsubscribeAll: Subject<any>;
  loadingResults: boolean;
  owner: boolean;
  sendinblueList: any;

  constructor(
    public matDialogRef: MatDialogRef<SendinblueListComponent>,
      @Inject(MAT_DIALOG_DATA) public _data: any,
      public _sendinblueService: SendinblueService,
      private _translateService: TranslateService,
      private _snackBar: MatSnackBar,
      private _toaster: ToastrService
  ) { 
    this.ids = this._data.ids;
    this.owner = this._data.owner;
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.getList();
  }

  getList(): void {
    this.loadingResults = true;
    this._sendinblueService.getList(this.ids)
        .pipe(
            tap(() => this.loadingResults = true),
            takeUntil(this._unsubscribeAll)
        )
        .subscribe((data) => {
            this.loadingResults = false;
            if (data) {
                this.sendinblueList = data.lists;
            }
        }, () => this.loadingResults = false);
  }

  addToList(contact: any): void {
    this.loadingResults = true;
    this._sendinblueService.addToList(contact.id, this.ids, this.owner)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((data) => {
            if(data == "Contact already in list and/or does not exist") {
              this._toaster.error(
                  this._translateService.instant('Contact already in list'),
                  this._translateService.instant('Error')
              );
              this.matDialogRef.close();
            } else if(data == true) { // Data true means contact already not save
              this._snackBar.open(this._translateService.instant('Added successfully'), this._translateService.instant('Close'), {
                duration: 2000,
              });
              this.matDialogRef.close();
            }
        }, () => this.matDialogRef.close());
  }

  removeFromList(contact: any): void {
    this.loadingResults = true;
    this._sendinblueService.removeFromList(contact.id, this.ids, this.owner)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(() => {
            this._snackBar.open(this._translateService.instant('Removed successfully'), this._translateService.instant('Close'), {
                duration: 2000,
            });
            this.matDialogRef.close();
        }, () => this.matDialogRef.close());
}

}
