import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailHistoryComponent } from './email-history.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';



@NgModule({
  declarations: [
    EmailHistoryComponent
  ],
  imports: [
    CommonModule,
    FuseSharedModule,
    MatIconModule,
    MatButtonModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatMenuModule,
  ],
  exports: [
    EmailHistoryComponent
  ]
})
export class EmailHistoryModule { }
