// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fuse-material-color-picker-menu {
  width: 245px;
}
.fuse-material-color-picker-menu .mat-mdc-menu-content {
  padding: 0;
}
.fuse-material-color-picker-menu .mat-mdc-menu-content .views {
  display: flex;
  flex-direction: column;
  min-height: 165px;
}
.fuse-material-color-picker-menu .mat-mdc-menu-content .views .view {
  overflow: hidden;
}
.fuse-material-color-picker-menu .mat-mdc-menu-content .views .view .colors {
  padding: 1px 0 0 0;
  margin-left: -1px;
}
.fuse-material-color-picker-menu .mat-mdc-menu-content .views .view .colors .color {
  width: 40px;
  height: 40px;
  margin: 0 0 1px 1px;
  border-radius: 0;
  cursor: pointer;
  transition: border-radius 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.fuse-material-color-picker-menu .mat-mdc-menu-content .views .view .colors .color:hover {
  border-radius: 20%;
}
.fuse-material-color-picker-menu .mat-mdc-menu-content .views .view .colors .color.selected {
  border-radius: 50% !important;
}`, "",{"version":3,"sources":["webpack://./src/@fuse/components/material-color-picker/material-color-picker.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;AACI;EACI,UAAA;AACR;AACQ;EACI,aAAA;EACA,sBAAA;EACA,iBAAA;AACZ;AACY;EACI,gBAAA;AAChB;AACgB;EACI,kBAAA;EACA,iBAAA;AACpB;AACoB;EACI,WAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,+DAAA;AACxB;AACwB;EACI,kBAAA;AAC5B;AAEwB;EACI,6BAAA;AAA5B","sourcesContent":[".fuse-material-color-picker-menu {\n    width: 245px;\n\n    .mat-mdc-menu-content {\n        padding: 0;\n\n        .views {\n            display: flex;\n            flex-direction: column;\n            min-height: 165px;\n\n            .view {\n                overflow: hidden;\n\n                .colors {\n                    padding: 1px 0 0 0;\n                    margin-left: -1px;\n\n                    .color {\n                        width: 40px;\n                        height: 40px;\n                        margin: 0 0 1px 1px;\n                        border-radius: 0;\n                        cursor: pointer;\n                        transition: border-radius .4s cubic-bezier(.25, .8, .25, 1);\n\n                        &:hover {\n                            border-radius: 20%;\n                        }\n\n                        &.selected {\n                            border-radius: 50% !important;\n                        }\n                    }\n                }\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
