import { Component, OnInit, ViewEncapsulation, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { cloneDeep, forEach, findIndex, isEqual } from 'lodash';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { map, filter } from 'rxjs/operators';
import { AuthenticationService } from './../../../services';
import { Subject } from 'rxjs';
declare const google: any;

@Component({
    selector: 'draw-map',
    templateUrl: './draw-map.component.html',
    styleUrls: ['./draw-map.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DrawMapComponent implements OnInit {

    // lat = 40.411335;
    // lng = -3.674908;
    
    center: google.maps.LatLngLiteral = { lat: 40.411335, lng: -3.674908 };
    
    zoom = 10;
    map: any;
    selectedShape: any;
    polygons: google.maps.LatLngLiteral[] = [];
    selectedIndex = -1;
    mapHeight: any;
    mapWidth: any;
    mqAlias: string;
    view = false;
    private _unsubscribeAll: Subject<any>;

    constructor(
        public matDialogRef: MatDialogRef<DrawMapComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _authenticationService: AuthenticationService,
        private mediaObserver: MediaObserver,
    ) {
        // this.mapHeight = (window.innerHeight - 260) + 'px';
        this.polygons = cloneDeep(this._data.polygons);
        this.view = this._data.view;
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        const agency = this._authenticationService.currentAgencyValue;
        if (agency.map) {
            // this.lat = parseFloat(agency.map.lat);
            // this.lng = parseFloat(agency.map.lng);
            this.center.lat = parseFloat(agency.map.lat);
            this.center.lng = parseFloat(agency.map.lng);
            if(agency.map.zoom)
                this.zoom = +agency.map.zoom;
        }
        const office = this._authenticationService.currentOfficeValue;
        if (office.map) {
            // this.lat = parseFloat(office.map.lat);
            // this.lng = parseFloat(office.map.lng);
            if (office.map.lat && office.map.lng) {
                this.center.lat = parseFloat(office.map.lat);
                this.center.lng = parseFloat(office.map.lng);
            }
            if(office.map.zoom)
                this.zoom = +office.map.zoom;
        }

        this.mediaObserver.asObservable()
            .pipe(
                filter((changes: MediaChange[]) => changes.length > 0),
                map((changes: MediaChange[]) => changes[0])
            )
            .subscribe((change: MediaChange) => {
                this.mqAlias = change.mqAlias;
                let adjustScreen = 265;
                if (this.mqAlias === 'xl' || this.mqAlias === 'gt-lg') {
                    adjustScreen = 500;
                }
                this.mapHeight = (window.innerHeight - adjustScreen) + 'px';
                this.mapWidth = (window.innerWidth - 300) + 'px';
            });
    }

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        let adjustScreen = 265;
        if (this.mqAlias === 'xl' || this.mqAlias === 'gt-lg') {
            adjustScreen = 500;
        }
        this.mapHeight = (window.innerHeight - adjustScreen) + 'px';
        this.mapWidth = (window.innerWidth - 300) + 'px';
        google.maps.event.trigger(this.map, 'resize');
        // this.map.panTo(new google.maps.LatLng(this.lat, this.lng));
        this.map.panTo(new google.maps.LatLng(this.center.lat, this.center.lng));
    }

    onMapReady(currentMap: any): void {
        this.map = currentMap;
        this.initDrawingManager();
    }

    initDrawingManager(): void {
        const options = {
            drawingControl: !this.view,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: ['polygon']
            },
            polygonOptions: {
                draggable: !this.view,
                editable: !this.view
            },
            drawingMode: google.maps.drawing.OverlayType.POLYGON
        };

        const drawingManager = new google.maps.drawing.DrawingManager(options);
        drawingManager.setMap(this.map);
        drawingManager.setDrawingMode(null);

        if (this.polygons && this.polygons.length) {
            forEach(this.polygons, (polygon) => {
                const mapPolygon = new google.maps.Polygon({ paths: polygon });
                mapPolygon.setMap(this.map);
                this.addListener(mapPolygon.getPath());
                google.maps.event.addListener(mapPolygon, 'click', () => this.setSelection(mapPolygon));
            });
        }

        google.maps.event.addListener(this.map, 'click', () => this.clearSelection());
        google.maps.event.addListener(drawingManager, 'overlaycomplete', (e: any) => {
            const newShape = e.overlay;
            newShape.type = e.type;
            drawingManager.setDrawingMode(null);
            google.maps.event.addListener(newShape, 'click', () => this.setSelection(newShape));
            setTimeout(() => this.setSelection(newShape));
            this.setPathToScope(e.overlay.getPath(), false);
            this.addListener(e.overlay.getPath());
        });

    }

    addListener(path: any): void {
        google.maps.event.addListener(path, 'insert_at', () => this.setPathToScope(path, true));
        google.maps.event.addListener(path, 'remove_at', () => this.setPathToScope(path, true));
        google.maps.event.addListener(path, 'set_at', () => this.setPathToScope(path, true));
    }

    setSelection(shape: any): void {
        this.clearSelection();
        shape.setEditable(true);
        shape.setDraggable(true);
        this.selectedShape = shape;
        const polygon = this.getPathArray(shape.getPath());
        const index = findIndex(this.polygons, (p) => isEqual(p, polygon));
        this.selectedIndex = index;
        google.maps.event.trigger(this.map, 'resize');
    }

    clearSelection(): void {
        if (this.selectedShape) {
            this.selectedShape.setEditable(false);
            this.selectedShape.setDraggable(false);
            this.selectedShape = null;
            this.selectedIndex = -1;
        }
    }

    setPathToScope(vertices: any, update: any): void {
        const pathArray = this.getPathArray(vertices);
        if (update) {
            this.polygons[this.selectedIndex] = pathArray;
        } else {
            this.polygons = [...this.polygons, pathArray];
        }
    }

    getPathArray(vertices: any): any {
        const pathArray = [];
        for (let i = 0; i < vertices.getLength(); i++) {
            const xy = vertices.getAt(i);
            pathArray.push({
                lat: xy.lat(),
                lng: xy.lng()
            });
        }
        return pathArray;
    }

    deleteSelected(): void {
        this.polygons.splice(this.selectedIndex, 1);
        this.polygons = [...this.polygons];
        if (this.selectedShape) {
            this.selectedShape.setMap(null);
            this.clearSelection();
        }
    }
}
