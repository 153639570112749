import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PropertiesHistoryComponent } from './properties-history.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatToolbarModule } from '@angular/material/toolbar';

import { FuseSidebarModule } from '@fuse/components';
import { LightboxModule } from 'ngx-lightbox';
import { SearchFormModule } from 'app/main/properties/components/search-form/search-form.module';
import { TrafficLightModule } from 'app/main/properties/index/traffic-light/traffic-light.module';



@NgModule({
  declarations: [
    PropertiesHistoryComponent
  ],
  imports: [
    CommonModule,
    FuseSharedModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatMenuModule,
    FuseSidebarModule,
    LightboxModule,
    SearchFormModule,
    TrafficLightModule,
    MatDialogModule,
    MatCheckboxModule,
    MatToolbarModule
  ],
  exports: [
    PropertiesHistoryComponent
  ]
})
export class PropertiesHistoryModule { }
