import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlansDialogComponent } from './plans-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTableModule } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SubscribtionModule } from 'app/main/authentication/register/subscribtion/subscribtion.module';



@NgModule({
    declarations: [PlansDialogComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatTooltipModule,
        MatButtonToggleModule,
        MatTableModule,
        MatProgressSpinnerModule,
        TranslateModule,
        FlexLayoutModule,
        SubscribtionModule,
    ],
    exports: [PlansDialogComponent]
})
export class PlansDialogModule { }
