import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { UntypedFormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountsService } from 'app/services/accounts.service';
import { fuseAnimations } from '@fuse/animations';
import * as _ from 'lodash';
import { Accounts } from 'app/models/accounts';
import { AccountsCreateComponent } from '../../accounts-create.component';
@Component({
  selector: 'tab-documents',
  templateUrl: './tab-documents.component.html',
  styleUrls: ['./tab-documents.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class TabDocumentsComponent implements OnInit {
  form: UntypedFormGroup;
  id: string;
  /**
   * Constructor
   * 
   * @param {FormBuilder} _formBuilder
   */
  viewRecord: boolean;
  constructor(
    private accountsService: AccountsService,
    private router: Router,
    private _accountsCreateComponent: AccountsCreateComponent
  ) {
    this.form = this._accountsCreateComponent.form;
    this.id = this._accountsCreateComponent.id;
  }

  // submitForm(): void {
  //   if (this.id) {
  //     this.form.value._id = this.id;
  //     this.accountsService.update(this.form.value).subscribe((account: Accounts) => {
  //       this.router.navigate(['/master-data/accounts']);
  //     });
  //   } else {
  //     this.accountsService.create(this.form.value).subscribe((data: any) => {
  //       this.router.navigate(['/master-data/accounts']);
  //     });
  //   }

  // }

  ngOnInit(): void { }

}
