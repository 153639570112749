import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'getCommercialTitle'
})
export class GetCommercialTitlePipe implements PipeTransform {

    constructor(
        private _translateService: TranslateService,
    ) { }

    transform(property: any, locale?: any): any {
        const lang = locale === 'es_AR' ? 'ES' : 'EN';
        let title = '';
        if (property.title && property.title[lang]) {
            title = property.title[lang];
        }
        if (!title && property.title && property.title.EN) {
            title = property.title.EN;
        }
        if (!title) {
            const type = property.type_one_value && property.type_one_value[locale] ? property.type_one_value[locale] : '';
            const location = property.location_value && property.location_value[locale] ? property.location_value[locale] : '';
            title = type + ' ' + this._translateService.instant('in') + ' ' + location;
        }
        return title;
    }

}
