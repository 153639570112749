import { Component, OnInit, ViewEncapsulation, OnDestroy, AfterViewInit, ChangeDetectorRef, AfterViewChecked, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { DropdownsService, AuthenticationService } from 'app/services';
import { takeUntil, switchMap, startWith } from 'rxjs/operators';
import { endOfMonth, startOfMonth } from 'date-fns';
// import * as moment from 'moment';
import moment from 'moment-timezone';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SequencesService } from 'app/services/sequences.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { filter, head } from 'lodash';
import { TimeLineDataService } from 'app/components/time-line/time-line-data.service';
import { GlobalFuntions } from 'app/_helpers';
import { forEach } from 'lodash';
// const moment = _moment;

@Component({
    selector: 'app-sequences',
    templateUrl: './sequences.component.html',
    styleUrls: ['./sequences.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SequencesComponent
    implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy
{
    subRouts: { label: string; path: string; index: number }[];
    searchForm: UntypedFormGroup;
    searchOffices$ = new Subject<string>();
    offices: any;
    searchUsers$ = new Subject<string>();
    users: any;
    userData: any;
    agencyData: any;
    user: any;

    private _unsubscribeAll: Subject<any>;
    currentOffice: any;
    reloadData: EventEmitter<any>;
    disableOffice = false as boolean;
    disableUser = false as boolean;
    activeTab = 'Accounts' as string;

    constructor(
        private _timeLineData: TimeLineDataService,
        private _formBuilder: UntypedFormBuilder,
        private _dropdownsService: DropdownsService,
        private cdRef: ChangeDetectorRef,
        private _authenticationService: AuthenticationService,
        private _fuseSidebarService: FuseSidebarService,
        private _globalFunctions: GlobalFuntions
    ) {
        this.userData = _authenticationService.currentUserValue;
        this.agencyData = _authenticationService.currentAgencyValue;
        if (
            this.userData.user_role === 'agency_manager' ||
            this._globalFunctions.getUserRole(this.userData.user_role)
        ) {
            this.disableOffice = true;
        }
        if (this.userData.user_role === 'agent') {
            this.disableUser = true;
        }
        this.searchForm = this._formBuilder.group({
            date_from: { value: '', disabled: false },
            date_to: { value: '', disabled: false },
            for: { value: '', disabled: false },
            offices: { value: '', disabled: this.disableOffice },
            users: { value: '', disabled: this.disableUser },
            account_name: { value: '', disabled: this.disableUser },
            owner_name: { value: '', disabled: this.disableUser },
            booking_reference: { value: '', disabled: this.disableUser },
            sale_reference: { value: '', disabled: this.disableUser },
            resd_reference: { value: '', disabled: this.disableUser },
            comm_prop_reference: { value: '', disabled: this.disableUser },
            rental_contract_reference: { value: '', disabled: this.disableUser },
        });
        this.reloadData = new EventEmitter();
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.currentOffice = this._authenticationService.currentOfficeValue;
        this.searchOffices$
            .pipe(
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    return this._dropdownsService.getOffices(search);
                })
            )
            .subscribe((data: any) => {
                this.offices = data.body;
            });
        this.searchUsers$
            .pipe(
                startWith(''),
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    if (this.userData.user_role === 'agent') {
                        search = this.userData._id;
                    }
                    return this._dropdownsService.getUsers(search, false);
                })
            )
            .subscribe((data: any) => {
                if (this.userData.user_role === 'agency_manager') {
                    this.users = filter(data.body, [
                        'office',
                        this.currentOffice._id,
                    ]);
                } else {
                    this.users = data.body;
                }
            });
        this.subRouts = [
            {
                label: 'Accounts',
                path: './accounts-sequence',
                index: 0,
            },
            {
                label: 'Owners',
                path: './owners',
                index: 1,
            },
            {
                label: 'Urbanisations',
                path: './urbanisations',
                index: 4,
            },
            {
                label: 'Sales stages',
                path: './sales-stages',
                index: 5,
            },
            
        ];
        if (this.agencyData?.residential_properties) {
            this.subRouts.push({
                label: 'Properties',
                path: './properties',
                index: 2,
            });
        }
        if (this.agencyData?.commercial_properties) {
            this.subRouts.push({
                label: 'Non-residential',
                path: './non-residentials',
                index: 3,
            });
        }
        if (this.agencyData?.booking_module) {
            this.subRouts.push({
                label: 'Bookings',
                path: './bookings',
                index: 6,
            });
        }
        if (this.agencyData?.rental_contracts_module) {
            this.subRouts.push({
                label: 'Rental contracts',
                path: './rental-contracts',
                index: 7,
            });
        }
    }

    sequenceStatus(event: any): any {
        // https://gitlab.optimasit.com/arsl/optima-crm-v2/-/issues/1722#note_26917
        const tottal = event.count_tottal ? event.count_tottal : 0;
        const open = event.count_open ? event.count_open : 0;
        const closed = event.count_closed ? event.count_closed : 0;
        const cancelled = event.count_cancelled ? event.count_cancelled : 0;
        if (tottal > 0) {
            return _.round(((closed + cancelled) / tottal) * 100, 2);
        } else {
            return 0;
        }
    }

    sequenceTimeline(sequence: any): void {
        this._timeLineData.setModelDataValue(sequence);
        this._timeLineData.setRelatedToValue('sequence');
        this._fuseSidebarService.getSidebar('timeline').toggleOpen();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngAfterViewInit(): void {
        const startDate = startOfMonth(new Date());
        const endDate = endOfMonth(new Date());
        // this._dropdownsService.getUsers(this.userData._id).subscribe((data: any) => {
        //     console.log('users: ', head(data.body));
        //     this.user = data.body;
        //     // this.searchForm.get('users').setValue(head(this.user));
        // });
        this.searchForm.patchValue({
            date_from: moment([
                startDate.getFullYear(),
                startDate.getMonth(),
                startDate.getDate(),
            ]),
            date_to: moment([
                endDate.getFullYear(),
                endDate.getMonth(),
                endDate.getDate(),
            ]),
            offices: [this.currentOffice._id],
            users: [],
        });
        this.searchOffices$.next('');
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    getActiveTab(currentTab) {
        this.activeTab = currentTab;
        this.resetSearchForm()
    }
    
    /**
     * this function reset form value on changing of tab
     * just serach fields only reset not all form
     */
    resetSearchForm() {
        forEach(this.searchForm.controls, (controlName, key) => {
            if(key == 'account_name' || key == 'owner_name' || key == 'booking_reference' || key == 'sale_reference' || key == 'resd_reference' || key == 'comm_prop_reference' || key == 'rental_contract_reference') {
                controlName.setValue('');
            }
        });
    }

}
