import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { AuthenticationService } from 'app/services';
import { environment } from 'environments/environment';
import { of, Subject } from 'rxjs';
import { catchError, map, startWith, takeUntil } from 'rxjs/operators';
import { GLOBALS } from '../../../../../../config/globals';
import { BaseService } from '../../../../../../_helpers/base/base.service';
import { ReportComponent } from '../report/report.component';
import { ShareModalComponent } from '../share-modal/share-modal.component';
import { WebsocketService } from '../../../../../../services/websocket.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-post',
    templateUrl: './post.component.html',
    styleUrls: ['./post.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class PostComponent implements OnInit, OnDestroy {


    @Output() reload = new EventEmitter<boolean>();
    public post;
    public board;

    public env = environment;

    public read = true;
    public edit = false;

    private _confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    public loaders = { ...GLOBALS.loaders }; // To display/hide loading spinner
    public animations = { ...GLOBALS.animations }; // To use centralized animations
    public pageActions = GLOBALS.pageActions; // To use same naming conventions for opening one form for multiple purposes

    public postLikes = [];
    public likesCount;
    public currentLang: string;

    @Input()
    set _post(val) {
        this.post = val;
        this.board = this.post.board._id;
    }

    //
    @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;

    //
    public files = [];
    public inProgress = false;

    private _unsubscribeAll: Subject<any> = new Subject();

    constructor(
        private _matDialog: MatDialog,
        private _gService: BaseService,
        private _formBuilder: UntypedFormBuilder,
        public _authenticationService: AuthenticationService,
        private actRoute: ActivatedRoute,
        private socket: WebsocketService,
        private _translateService: TranslateService

    ) { }

    public postMessage: UntypedFormGroup;

    ngOnInit(): void {
        this._translateService.onLangChange
            .pipe(startWith(''))
            .subscribe(() => {
                this.currentLang = this._translateService.currentLang;
            });
        this.postMessage = this._formBuilder.group({
            message: this._formBuilder.control(this.post.message),
            photo: this._formBuilder.control(this.post.photo)
        });
        this._gService.post('mp/boardmessage/likes/get/all/' + this.post.board._id, { message: this.post._id }, 'nodejs')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(res => {
                this.postLikes = res.likes;
                this.likesCount = res.count;
            });
    }

    public deletePost(): void {
        this._confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });
        this._confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this._confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.loaders.page = true;
                this._gService.delete('mp/board/message/delete/' + this.post._id, {}, 'nodejs')
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(res => {
                        if (res.status === true) {
                            this.loaders.page = false;
                            this._gService.succussMessage(res.message, 'Success');
                            this.reload.emit(true);
                            // this.socket.emit('postDelete', {
                            //     created_by: this._authenticationService.currentUserValue._id, updated_by: this._authenticationService.currentUserValue._id,
                            //     agency: this._authenticationService.currentUserValue.agency, board: this.board,
                            //     created_name: this._authenticationService.currentUserValue.full_name, model_id: this.post._id
                            // });
                        }
                    });
            }
            this._confirmDialogRef = null;
        });
    }

    public likeUnlikePost(postId, str): void {
        let url: string;
        let payload = {} as any;
        this.loaders.page = true;

        if (str === 'like') {
            url = 'mp/boardmessage/like/create';
            payload = { board: this.board, message: postId, like: true };
        } else {
            url = 'mp/boardmessage/unlike/' + this.board;
            payload = { message: postId };
        }
        this._gService.post(url, payload, 'nodejs')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(res => {
                if (res.status === true) {
                    this.loaders.page = false;
                    this.ngOnInit();
                    this.socket.emit('postLike', {
                        created_by: this._authenticationService.currentUserValue._id, updated_by: this._authenticationService.currentUserValue._id,
                        agency: this._authenticationService.currentUserValue.agency, board: this.board,
                        created_name: this._authenticationService.currentUserValue.full_name, model_id: this.post._id, status: (str === 'like') ? 'like' : 'unlike'
                    });
                }
            });

    }

    public reportPost(): void {
        const reportDialogeRef = this._matDialog.open(ReportComponent, {
            panelClass: 'report-dialog',
            disableClose: false
        });
        reportDialogeRef.afterClosed().subscribe(res => {
            if (res.status === true) {
                this.loaders.box = true;
                this._gService.post('mp/board/message/report/create',
                    { board: this.post.board._id, message: this.post._id, description: res.form.description }, 'nodejs')
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(response => {
                        this.loaders.box = false;
                        if (response.status === true) {
                            this._gService.succussMessage('Your Complaint has been Registered.', 'Success!');
                            this.socket.emit('postReport', {
                                created_by: this._authenticationService.currentUserValue._id, updated_by: this._authenticationService.currentUserValue._id,
                                agency: this._authenticationService.currentUserValue.agency, board: this.board,
                                created_name: this._authenticationService.currentUserValue.full_name, model_id: this.post._id
                            });
                        }
                    });
            }
        });
    }

    public sharePost(): void {

        const shareDialogeRef = this._matDialog.open(ShareModalComponent, {
            panelClass: 'share-post-dialog',
            disableClose: false,
            data: this.post
        });
        shareDialogeRef.afterClosed().subscribe(res => {
            if (res.status === true) {
                this.loaders.box = true;
                this._gService.post('mp/board/message/create', { board: res.toBoard, message: res.post.message, photo: res.post.photo, share: true, fromBoard: res.post.board._id }, 'nodejs')
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(resp => {
                        if (resp.status === true) {
                            this._gService.post('mp/boardmessage/share/create', { toBoard: res.toBoard, fromBoard: res.post.board._id, message: res.post._id }, 'nodejs')
                                .pipe(takeUntil(this._unsubscribeAll))
                                .subscribe(response => {
                                    if (response.status === true) {
                                        if(res.toBoard === this.board){
                                            this.reload.emit(true);
                                        }
                                        this._gService.succussMessage('Successfully Share the Post.', 'Success!');
                                    } else {
                                        this._gService.errorMessage('Something Failed!', 'Error!');
                                    }
                                })
                        } else {
                            this._gService.errorMessage('Something Failed!', 'Error!');
                        }
                    });
            }
        });
    }

    public editPost(): void {
        this.read = false;
        this.edit = true;
    }

    public updatePost(): void {
        this.loaders.page = true;
        this._gService.put('mp/board/message/update/' + this.post._id, this.postMessage.value, 'nodejs')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(res => {
                if (res.status === true) {
                    this.loaders.page = false;
                    this._gService.succussMessage(res.message, 'Success');
                    this.reload.emit(true);
                    this.socket.emit('postUpdate', {
                        created_by: this._authenticationService.currentUserValue._id, updated_by: this._authenticationService.currentUserValue._id,
                        agency: this._authenticationService.currentUserValue.agency, board: this.board,
                        created_name: this._authenticationService.currentUserValue.full_name, model_id: this.post._id
                    });
                } else {
                    this.loaders.page = false;
                    this._gService.deleteMessage('You are not allowed to edit this Post!', 'Failed');
                }
            });

    }

    private uploadFile(file): void {
        this.inProgress = true;
        file.inProgress = true;

        const data = {
            id: this.board,
            model: 'mp_board_messages'
        };
        this._gService.uploadImage('attachments/single-upload/' + data.model + '/' + data.id, {}, file.data, 'nodejs')

            // this._gService.uploadImage('attachments/single-upload', data, file.data, 'nodejs')
            .pipe(takeUntil(this._unsubscribeAll))
            .pipe(
                map(event => {
                    switch (event.type) {
                        case HttpEventType.UploadProgress:
                            file.progress = Math.round(event.loaded * 100 / event.total);
                            break;
                        case HttpEventType.Response:
                            return event;
                    }
                }),
                catchError((error: HttpErrorResponse) => {
                    file.inProgress = false;
                    return of(`${file.data.name} upload failed.`);
                }))
            .subscribe((event: any) => {
                if (typeof (event) === 'object') {
                    // this.postMessage.addControl('photo', new FormControl(''));
                    this.postMessage.get('photo').patchValue(event.body.file_name);
                    this._gService.succussMessage('Image Uploaded', 'success');
                    this.inProgress = false;
                }
            });
    }

    /**
     * Call Upload File Function
     */
    public uploadFiles(): void {
        this.fileUpload.nativeElement.value = '';

        this.files.forEach(file => {
            this.uploadFile(file);
        });
    }

    public onClick(): void {
        const fileUpload = this.fileUpload.nativeElement;
        fileUpload.onchange = () => {
            this.files = [];
            for (let index = 0; index < fileUpload.files.length; index++) {
                const file = fileUpload.files[index];
                this.files.push({ data: file, inProgress: false, progress: 0 });
            }
            this.uploadFiles();
        };
        fileUpload.click();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

}
