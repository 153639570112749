import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appFormSubmitOnEnter]'
})
export class FormSubmitOnEnterDirective {

  constructor() {
  }

  @HostListener('document:keyup', ['$event']) OnKeyUp(event: KeyboardEvent): any {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }
  @HostListener('document:keydown', ['$event']) OnKeyDown(event: KeyboardEvent): any {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }
}
