import { Component, OnInit, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import * as _ from 'lodash';
import { OffersService } from 'app/services';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { PropertiesService } from 'app/services/properties.service';
import { CommercialPropertiesService } from 'app/services/commercial-properties.service';

@Component({
    selector: 'app-counter-offers',
    templateUrl: './counter-offers.component.html',
    styleUrls: ['./counter-offers.component.scss'],
    animations: fuseAnimations
})
export class CounterOffersComponent implements OnInit {

    offers: any;
    counter = false;
    subOffer: UntypedFormGroup;
    currentProperty: any;
    update: UntypedFormControl = new UntypedFormControl('');
    property: any;
    propertyData: any;
    @Input()
    set counterOffers(counterOffers: any) {
        if (counterOffers) {
            this.offers = counterOffers;
            this.currentProperty = counterOffers.property_data;
        }

    }

    constructor(
        private formBuilder: UntypedFormBuilder,
        private http: HttpClient,
        private _offersService: OffersService,
        private _translateService: TranslateService,
        private _matDialog: MatDialog,
        private _propertiesService: PropertiesService,
        private _commercialsService: CommercialPropertiesService,
    ) {
        if (this.currentProperty) {
            this._propertiesService.getPropertyData(this.currentProperty._id).subscribe((data: any) => {
                this.currentProperty = data.body;
            });
        }
    }

    ngOnInit(): void {
        this.setForm();
    }

    setForm(): void {
        this.subOffer = this.formBuilder.group({
            date: { value: '', disabled: false },
            amount: { value: '', disabled: false },
            comment: { value: '', disabled: false },
            counter_offer: { value: this.counter, disabled: false },
            property_offer_id: { value: this.offers._id, disabled: false },
            related_to: { value: this.offers.related_to, disabled: false },
            property_data: { value: this.offers.property_data, disabled: false },
            date_from: { value: this.offers.date_from, disabled: false },
            date_to: { value: this.offers.date_to, disabled: false },
            month_prepaid: { value: this.offers.month_prepaid, disabled: false },
            prepaid_amount: { value: this.offers.prepaid_amount, disabled: false }
        });
    }

    addOffer(): void {

        this._offersService.createSubOffer(this.subOffer.value).subscribe((data: any) => {
            this.update.setValue('update');
            const dialog = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: false
            });
            dialog.componentInstance.confirmMessage = this._translateService.instant('Are you sure you want to update property status to Under Offer?');
            dialog.afterClosed().subscribe(result => {
                if (result) {
                    this.propertyData = this.currentProperty;
                    this.propertyData.status = 'Under Offer';
                    if (this.subOffer.value.related_to === 'non_residental_properties') {
                        this._commercialsService.updateProperty(this.propertyData._id, this.propertyData)
                            .subscribe((response) => {
                                if (response) {
                                }
                            });
                    }
                    if (this.offers.related_to === 'residental_properties') {
                        this._propertiesService.
                            updateProperty(this.propertyData._id, this.propertyData)
                            .subscribe((response) => {
                                if (response) {
                                }
                            });
                    }
                }
            });
            this._offersService.getById(this.offers._id).subscribe((offerData: any) => {
                this.offers = offerData.body;
            });
        });
    }

    respondOffer(offer: any, status: any): void {
        this.subOffer.reset();
        if (status === 'Countered' || status === '') {
            this.counter = true;
        } else {
            this.counter = false;
        }
        this.subOffer = this.formBuilder.group({
            agency: { value: offer.agency, disabled: false },
            amount: { value: offer.amount, disabled: false },
            comment: { value: offer.comment, disabled: false },
            counter_offer: { value: this.counter, disabled: false },
            created_at: { value: offer.created_at, disabled: false },
            created_by: { value: offer.created_by, disabled: false },
            date: { value: offer.date, disabled: false },
            offer_number: { value: offer.offer_number, disabled: false },
            property_offer_id: { value: offer.property_offer_id, disabled: false },
            related_to: { value: offer.related_to, disabled: false },
            status: { value: status, disabled: false },
            date_from: { value: offer.date_from, disabled: false },
            date_to: { value: offer.date_to, disabled: false },
            month_prepaid: { value: offer.month_prepaid, disabled: false },
            prepaid_amount: { value: offer.prepaid_amount, disabled: false },
            _id: { value: offer._id, disabled: false }
        });

        this._offersService.acceptOffer(this.subOffer.value, offer._id).subscribe((data: any) => {
            this.update.setValue('update');
            if (status === 'Accepted') {
                const dialog = this._matDialog.open(FuseConfirmDialogComponent, {
                    disableClose: false
                });
                dialog.componentInstance.confirmMessage = this._translateService.instant('Are you sure you want to update property status to Reserved?');
                dialog.afterClosed().subscribe(result => {
                    if (result) {
                        this.property = this.currentProperty;
                        this.property.status = 'Reserved';
                        if (this.subOffer.value.related_to === 'non_residental_properties') {
                            this._commercialsService.updateProperty(this.property._id, this.property)
                                .subscribe((response) => {
                                    if (response) {
                                    }
                                });
                        }
                        if (this.offers.related_to === 'residental_properties') {
                            this._propertiesService.
                                updateProperty(this.property._id, this.property)
                                .subscribe((response) => {
                                    if (response) {
                                        // this._offersService.acceptOffer(this.subOffer.value, offer._id).subscribe((data: any) => {
                                        //   this.update.setValue('update');
                                        // });
                                    }
                                });
                        }
                    }
                });
            }
            this._offersService.getById(this.offers._id).subscribe((offerData: any) => {
                this.offers = offerData.body;
            });
        });
        this.counter = false;
        this.subOffer.reset();
        this.setForm();
    }

    downloadPdf(id: any, status: any, index: any): void {
        let offerType: any;
        if (status === 'Accepted') {
            offerType = 'accepted';
        }
        if (status === 'Rejected') {
            offerType = 'rejected';
        }
        if (status === 'Countered') {
            offerType = 'generated';
        }
        if (typeof status === 'undefined') {
            offerType = 'generated';
        }
        window.open(`${environment.yiiUrl}pdf-offer/genrate-pdf?offer_id=${id}&offer_type=${offerType}&index=${index}`);
        // this.http.get(`${environment.yiiUrl}pdf-offer/genrate-pdf?offer_id=${id}&offer_type=${offerType}&index=${index}`);

    }

}
