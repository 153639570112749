import {
  Directive,
  Input,
  ElementRef,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { forEach } from 'lodash';
import { AuthenticationService } from 'app/services';

@Directive({
  selector: '[disableUser]'
})
export class DisableUserDirective {

  flag = false as boolean;
  user: any;

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private _authenticationService: AuthenticationService
  ) {
    this.user = this._authenticationService.currentUserValue;
  }

  @Input()
  set disableUser(val) {
    forEach(val, (value) => {
      if (this.user && this.user.user_role && value === this.user.user_role) {
        this.flag = true;
      }
    });

    if (this.flag) {
      this.viewContainer.clear();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
