import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { RenewSubscriptionComponent } from './renew-subscription.component';

@NgModule({
    declarations: [RenewSubscriptionComponent],
    imports: [
        CommonModule,
        FuseSharedModule,
        MatDialogModule,
        MatToolbarModule,
        MatButtonModule,
        TranslateModule,
    ],
    exports: [RenewSubscriptionComponent]
})
export class RenewSubscriptionModule { }
