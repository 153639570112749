import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OffersService {

  constructor(
    private http: HttpClient,
  ) { }

  getData(filter: any, page: any, perPage?: any): Observable<object> {
    return this.http.post<object>(`${environment.yiiUrl}property-offers/search?page=` + page +
      `&expand=property_data,account_data,all_offers,offers,c_offers&per-page=${perPage}`, filter, { observe: 'response' as 'body' })
      .pipe(map(data => {
        return data;
      }));
  }

  getById(id: any): Observable<object> {
    // tslint:disable-next-line: max-line-length
    return this.http.get<object>(`${environment.yiiUrl}property-offers/${id}?expand=property_data,property_documents,account_data,all_offers,offers,c_offers,offer_documents`, { observe: 'response' as 'body' });
  }

  create(data: any): Observable<object> {
    return this.http.post(`${environment.yiiUrl}property-offers`, data);
  }

  update(data: any, id: any): Observable<object> {
    return this.http.put(`${environment.yiiUrl}property-offers/${id}`, data);
  }

  delete(id: any): Observable<any> {
    return this.http.delete(`${environment.yiiUrl}property-offers/${id}`);
  }
  
  createSubOffer(data: any): Observable<object> {
    return this.http.post(`${environment.yiiUrl}offers`, data);
  }

  acceptOffer(data: any, id: any): Observable<object> {
    return this.http.put(`${environment.yiiUrl}offers/${id}`, data);
  }
  getByPid(id: any): Observable<object> {
    return this.http.get<object>(`${environment.yiiUrl}property-offers?property_id=${id}`, { observe: 'response' as 'body' });
  }
}
