import { Component, OnInit, ViewEncapsulation, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { MeetingCreateModalComponent } from 'app/main/activities/meetings/meeting-create-modal/meeting-create-modal.component';
import { TasksCreateModalComponent } from 'app/main/activities/tasks/tasks-create-modal/tasks-create-modal.component';
import { ViewingToursModalComponent } from 'app/main/activities/viewingTours/viewing-tours-modal/viewing-tours-modal.component';
import { round, forEach } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { SequencesService } from 'app/services/sequences.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { TimeLineDataService } from 'app/components/time-line/time-line-data.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-owners-modal',
  templateUrl: './owners-modal.component.html',
  styleUrls: ['./owners-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class OwnersModalComponent implements OnInit, OnDestroy {

  searchDialogRef: any;
  sequenceData: any;
  displayedColumns: string[] = ['expand', 'created_at', 'title', 'owner_name', 'owner_status', 'status', 'actions'];
  private _unsubscribeAll: Subject<any>;
  expandedAll: boolean;
  expandedRow = {} as any;

  constructor(
    public matDialogRef: MatDialogRef<OwnersModalComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _matDialog: MatDialog,
    private _sequencesService: SequencesService,
    private _fuseSidebarService: FuseSidebarService,
    private _translateService: TranslateService,
    private _timeLineData: TimeLineDataService,
  ) {
    this._unsubscribeAll = new Subject();
    if (this._data) {
      this.sequenceData = this._data.genrated_sequence;
    }
  }

  ngOnInit(): void {
  }

  sequenceStatus(event: any): any {
    const tottal = event.count_tottal;
    const open = event.count_open;
    const closed = event.count_closed;
    const cancelled = event.count_cancelled ? event.count_cancelled : 0;
    if (tottal > 0 && closed > 0) {
      return round((closed / (tottal - cancelled)) * 100, 2);
    }
    else {
      return 0;
    }
  }

  deleteSequence(event: any): any {
    const dialog = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });
    dialog.componentInstance.confirmMessage = this._translateService.instant('Are you sure you want to delete?');
    dialog.afterClosed().subscribe(result => {
      if (result) {
        this._sequencesService.deleteSequence(event)
          .subscribe(() => {
            // this.reloadData.next(true);
          });
      }
    });
  }

  deletePendingEvents(event: any): any {
    const dialog = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });
    dialog.componentInstance.confirmMessage = this._translateService.instant('Are you sure you want to delete?');
    dialog.afterClosed().subscribe(result => {
      if (result) {
        this._sequencesService.deletePendingEvents(event)
          .subscribe(() => {
            // this.reloadData.next(true);
          });
      }
    });
  }

  sequenceTimeline(sequence: any): void {
    this._timeLineData.setModelDataValue(sequence);
    this._timeLineData.setRelatedToValue('sequence');
    this._fuseSidebarService.getSidebar('timeline').toggleOpen(1000000);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  expandAll(): void {
    this.expandedAll = !this.expandedAll;
    this.expandedRow = {};
    if (this.expandedAll && this.sequenceData) {
      forEach(this.sequenceData, (event: any) => {
        this.expandedTbl(event);
      });
    }
  }

  expandedTbl(event: any): void {
    this.expandedRow[event._id] = !this.expandedRow[event._id];
  }

  addActivities(activity: any): void {

    if (activity === 'Meeting') {
      this.searchDialogRef = this._matDialog.open(MeetingCreateModalComponent, {
        panelClass: 'event-form-dialog',
        data: 'Model'
      });
    } else if (activity === 'Task') {
      this.searchDialogRef = this._matDialog.open(TasksCreateModalComponent, {
        panelClass: 'event-form-dialog',
        data: 'Model'
      });
    } else if (activity === 'Viewing') {
      this.searchDialogRef = this._matDialog.open(ViewingToursModalComponent, {
        panelClass: 'event-form-dialog',
        data: 'Model'
      });
    }
    this.searchDialogRef.afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((response: any) => {
        if (response) {
        }
      });
  }

  onRefreshData(event: any): void {
    this.ngOnInit();
  }

}
