// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.folded:not(.unfolded) :host .nav-link > .nav-link-title,
.folded:not(.unfolded) :host .nav-link > .nav-link-badge {
  opacity: 0;
  transition: opacity 200ms ease;
}`, "",{"version":3,"sources":["webpack://./src/@fuse/components/navigation/vertical/item/item.component.scss"],"names":[],"mappings":"AAMY;;EAEI,UAAA;EACA,8BAAA;AALhB","sourcesContent":[":host {\n\n    .folded:not(.unfolded) & {\n\n        .nav-link {\n\n            > .nav-link-title,\n            > .nav-link-badge {\n                opacity: 0;\n                transition: opacity 200ms ease;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
