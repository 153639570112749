import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResetPasswordComponent } from './reset-password.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CustomMatPasswordStrengthModule } from 'app/components/custom-mat-password-strength-info/mat-password-strength-info.module';

@NgModule({
    declarations: [ResetPasswordComponent],
    imports: [
        CommonModule,
        FuseSharedModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatInputModule,
        MatPasswordStrengthModule,
        CustomMatPasswordStrengthModule
    ],
    exports: [ResetPasswordComponent]
})
export class ResetPasswordModule { }
