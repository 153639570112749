import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationService } from 'app/services';

@Pipe({
  name: 'getPropertyReference'
})
export class GetPropertyReferencePipe implements PipeTransform {

  constructor(
    private _authenticationService: AuthenticationService,
  ) { }

  transform(property: any, args?: any): any {
    const agency = this._authenticationService.currentAgencyValue;
    let reference = null;
    if (property) {
      reference = property.reference;
      if (agency.pdf_external_ref === 'agency_reference' && typeof property.external_reference !== 'undefined' && property.external_reference) {
        reference = property.external_reference;
      }
      if (agency.pdf_external_ref === 'external_reference' && typeof property.agency_reference !== 'undefined' && property.agency_reference) {
        reference = property.agency_reference;
      }
    }
    return reference;
  }

}
