import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BookingsService {
  private subject = new Subject<any>();

  constructor(
    private http: HttpClient,
  ) { }

  getAll(data: any, page: any, perPage?: any): Observable<object> {
    page = page ? page : 1;
    perPage = perPage ? perPage : 10;
    // tslint:disable-next-line:max-line-length
    return this.http.post<object>(`${environment.yiiUrl}bookings/filter-bookings?expand=account_data,p_data&page=${page}&status=&sort[]=undefined&sort[]=undefined&per-page=${perPage}`, data, { observe: 'response' as 'body' })
      .pipe(map(bookings => {
        return bookings;
      }));
  }

  collectionSearch(filter: any, page: any, perPage?: any): Observable<object> {
    page = page ? page : 1;
    perPage = perPage ? perPage : 10;
    return this.http.post<object>(`${environment.yiiUrl}bookings/collection-search?page=${page}&perPage=${perPage}`, filter);
  }



  getById(id: any): Observable<object> {
    return this.http.get<object>(`${environment.yiiUrl}bookings/${id}?expand=p_data,user_data,account_data`, { observe: 'response' as 'body' });
  }

  create(data: any): Observable<object> {
    return this.http.post<object>(`${environment.yiiUrl}bookings`, data);
  }

  update(data: any, id: any): Observable<object> {
    return this.http.put<object>(`${environment.yiiUrl}bookings/${id.oid ? id.oid : id}`, data);
  }

  delete(id: any): Observable<object> {
    return this.http.delete<object>(`${environment.yiiUrl}bookings/${id}`);
  }

  calculate(data: any): Observable<object> {
    return this.http.post<object>(`${environment.apiUrl}yovendo/commercial_rental_calculations`, data);
  }

  calculateBilling(data: any): Observable<object> {
    return this.http.post<object>(`${environment.apiUrl}yovendo/commercial_billing_calculations`, data);
  }

  getExtras(id?: any, isCommercial = false): Observable<object> {
    return this.http.get<object>(`${environment.yiiUrl}bookings-extras?page_size=false&p_id=${id}&isCommercial=${isCommercial}`, { observe: 'response' as 'body' });
  }

  getCleanings(id: any, isCommercial = false): Observable<object> {
    return this.http.get<object>(`${environment.yiiUrl}cleaning?page_size=false&p_id=${id}&isCommercial=${isCommercial}`, { observe: 'response' as 'body' });
  }

  sendPropertyData(data: any) {
    this.subject.next(data);
  }

  clearPropertyData() {
    this.subject.next();
  }

  getPropertyData(): Observable<any> {
    return this.subject.asObservable();
  }

}

