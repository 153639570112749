import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy, ElementRef, Input } from '@angular/core';
import { AccountsService } from 'app/services/accounts.service';
import { fuseAnimations } from '@fuse/animations';
import { UntypedFormControl } from '@angular/forms';
import { Subject, merge, combineLatest } from 'rxjs';
import { startWith, tap, takeUntil, switchMap, filter, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { forEach, find, includes, isEmpty, merge as _loMerge } from 'lodash';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { AdvanceSearchComponent as AccountsAdvanceSearchComponent } from 'app/main/master-data/accounts/advance-search/advance-search.component';
import { AuthenticationService } from 'app/services';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { GlobalFuntions } from 'app/_helpers';
import { ArchiveSearchComponent } from 'app/main/master-data/accounts/archive-search/archive-search.component';
import { MeetingCreateModalComponent } from 'app/main/activities/meetings/meeting-create-modal/meeting-create-modal.component';
import { TasksCreateModalComponent } from 'app/main/activities/tasks/tasks-create-modal/tasks-create-modal.component';
import { ViewingToursModalComponent } from 'app/main/activities/viewingTours/viewing-tours-modal/viewing-tours-modal.component';
import { AccountMassUpdateComponent } from '../account-mass-update/account-mass-update.component';
import { ActivatedRoute, Router } from '@angular/router';
import { TimeLineDataService } from 'app/components/time-line/time-line-data.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CrmMenuService } from 'app/services/crm-menu.service';
import { CommonDialogComponent } from 'app/components/common-dialog/common-dialog.component';

@Component({
    selector: 'accounts-list',
    templateUrl: './accounts-list.component.html',
    styleUrls: ['./accounts-list.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class AccountsListComponent implements OnInit, OnDestroy {

    @Input() owner: any;
    loadingResults: boolean;
    displayedColumns: string[] = ['checkbox', 'status', 'type', 'full_name', 'email', 'mobile_phone', 'updated_at', 'assigned_to', 'language', 'action'];
    accountsSearch: UntypedFormControl = new UntypedFormControl('');
    archiveSearch: UntypedFormControl = new UntypedFormControl('');
    dataReload: UntypedFormControl = new UntypedFormControl(null);
    accounts: any = {};
    filter: any = {};
    filterArchive: any = {};
    searchDialogRef: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
    currentUser: any;
    selectedItems = [];
    assignedEmails = [];
    emails = [];
    selectAll = false;
    selectAllRecords = false;
    attachments = [] as any;
    private _unsubscribeAll: Subject<any>;
    inactive: boolean;
    systemLang: any;
    quickSearch = false;
    relatedTo = 'account';
    isDisabled = false as boolean;
    currentAgency: any;
    advanceSearchSave: any;
    quickSearchSave: any;
    isInvoiceModule: any;
    showPhone = false;
    rpaId: any;
    previousUrl: any;
    
    constructor(
        private _accountsService: AccountsService,
        private mediaObserver: MediaObserver,
        private _matDialog: MatDialog,
        private _translateService: TranslateService,
        private _authenticationService: AuthenticationService,
        private _timeLineData: TimeLineDataService,
        private _fuseSidebarService: FuseSidebarService,
        public _globalFuntions: GlobalFuntions,
        private route: ActivatedRoute,
        public router: Router,
        private _crmMenuService: CrmMenuService,
    ) {
        this.systemLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
        this.filter = { grid: false, status_not: 'inactive' };
        this.filterArchive = {};
        this._unsubscribeAll = new Subject();
        this.currentUser = this._authenticationService.currentUserValue;
        this.currentAgency = this._authenticationService.currentAgencyValue;
        this.route.data
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(v => this.relatedTo = v.relatedTo);
        this.isInvoiceModule = this._crmMenuService.isInvoiceModule;
        if (this.currentUser.user_account_view === 'grid' && this.router.url.includes('list')) {
            this.previousUrl = history?.state?.data ? history?.state?.data : ''; 
            if (this.previousUrl === 'accounts/grid') {
                this.listView();
            } else {
                this.gridView();
            }
        }
        if (this.currentUser.user_account_view === 'grid' && this.router.url.includes('list')) {
            this.previousUrl = history?.state?.data ? history?.state?.data : ''; 
            if (this.previousUrl === 'accounts/grid') {
                this.listView();
            } else {
                this.gridView();
            }
        }
    }

    ngOnInit(): void {
        if (this.currentUser.user_role === 'agent' || this.currentUser.user_role === 'agent_medium' || this.currentUser.user_role === 'admin_agent_light') {
            this.isDisabled = true;
        }
        this.mediaObserver.asObservable()
            .pipe(
                takeUntil(this._unsubscribeAll),
                filter((changes: MediaChange[]) => changes.length > 0),
                map((changes: MediaChange[]) => changes[0])
            )
            .subscribe((change: MediaChange) => {
                if (change.mqAlias === 'xs') {
                    this.displayedColumns = ['checkbox', 'type', 'full_name', 'action'];
                } else {
                    if (this.currentAgency && this.currentAgency.mls_agency) {
                        this.displayedColumns = ['checkbox', 'status', 'type', 'full_name', 'email', 'mobile_phone', 'source', 'language', 'action'];
                    } else {
                        this.displayedColumns = ['checkbox', 'status', 'type', 'full_name', 'email', 'mobile_phone', 'updated_at', 'assigned_to', 'language', 'action'];
                    }
                }
            });
        this._accountsService.accountSearchData
            .subscribe((data: any) => {
                if (data && data.type && includes(this.router.url, data.type)) {
                    this.paginator.pageSize = data.pageSize;
                    this.paginator.pageIndex = data.pageIndex;
                    this.filter = data.data;
                }
            });

        setTimeout(() => {
            this._accountsService.setaccountSearchFilter(null);
        }, 1000);

        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        merge(this.sort.sortChange, this.paginator.page,
            this.accountsSearch.valueChanges.pipe(debounceTime(700), distinctUntilChanged()),
            this.dataReload.valueChanges, this.archiveSearch.valueChanges.pipe(debounceTime(700), distinctUntilChanged()))
            .pipe(
                startWith(''),
                tap(() => this.loadingResults = true),
                takeUntil(this._unsubscribeAll),
                switchMap(() => {
                    // this.selectAll = false;
                    // this.selectedItems = [];
                    // this.emails = [];
                    if (this.dataReload.value === 'refresh') {
                        this.quickSearchSave = {};
                        this.advanceSearchSave = {};
                        this.filter = { grid: false, status_not: 'inactive' };
                        this.filterArchive = {};
                        this.paginator.pageIndex = 0;
                        this.sort.active = null;
                        this.sort.direction = '';
                        this.accountsSearch.setValue('', { emitEvent: false });
                        this.archiveSearch.setValue('', { emitEvent: false });
                        this.selectedItems = [];
                        this.assignedEmails = [];
                        this.selectAll = false;
                        this.selectAllRecords = false;
                    }
                    if (this.sort.active && this.sort.direction) {
                        this.filter.sort = [this.sort.active, (this.sort.direction === 'asc' ? 'SORT_ASC' : 'SORT_DESC')];
                    }
                    if (this.accountsSearch.value) {
                        this.filter.search = ['name', this.accountsSearch.value];
                    }
                    if (this.archiveSearch.value) {
                        this.filterArchive.search = this.archiveSearch.value;
                    }
                    if (this.owner) {
                        this.filter.owner = this.owner;
                    }
                    this.dataReload.setValue(null, { emitEvent: false });
                    let perPage = 30;
                    if (this.paginator.pageSize) {
                        perPage = this.paginator.pageSize * 1;
                    }
                    if (this.relatedTo === 'archive') {
                        if (this.sort.active && this.sort.direction) {
                            this.filterArchive.sort = [this.sort.active, (this.sort.direction === 'asc' ? 'SORT_ASC' : 'SORT_DESC')];
                        }
                        const filterArchiv = this._globalFuntions.clearObject(this.filterArchive);
                        return this._accountsService.getArchiveAccounts(filterArchiv, this.paginator.pageIndex + 1, perPage);
                    }
                    return this._accountsService.getAll(this.filter, this.paginator.pageIndex + 1, perPage);
                })
            )
            .subscribe((data: any) => {
                this.loadingResults = false;
                this.accounts.data = data.body;
                this.accounts.total = data.headers.get('x-pagination-total-count');
                this.accounts.limit = data.headers.get('x-pagination-per-page');
                if (!this.selectAllRecords) {
                    this.selectAll = true;
                    if (!isEmpty(this.accounts['data'])) {
                        this.accounts['data'].forEach(element => {
                            if (element._id.oid) {
                                element._id = element._id.oid;
                            }
                            if (element.assigned_to || element.created_by) {
                                if (includes(element.assigned_to, this.currentUser.user_name) || element.created_by === this.currentUser._id) {
                                    this.showPhone = true;
                                }
                            }
                            if (this.selectedItems.indexOf(element._id) === -1) {
                                this.selectAll = false;
                            }
                        });
                    }
                }
            }, () => this.loadingResults = false);

    }

    delete(_id: any, createdby: any, agency: any): any {
        const dialog = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });
        dialog.componentInstance.confirmMessage = this._translateService.instant('Are you sure you want to delete?');
        dialog.afterClosed().subscribe(result => {
            if (result) {
                if (this.relatedTo !== 'archive') {
                    this._accountsService.archiveAccount(_id)
                        .subscribe(() => {
                            this.dataReload.setValue('refresh');
                        });
                }
                else {
                    this._accountsService.deleteArchiveAccount(_id, createdby, agency)
                        .subscribe(() => {
                            this.dataReload.setValue('refresh');
                        });
                }
            }
        });
    }

    restore(_id?: any): void {
        const dialog = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });
        dialog.componentInstance.confirmMessage = this._translateService.instant('Are you sure you want to restore?');
        dialog.afterClosed().subscribe(result => {
            if (result) {
                this._accountsService.restoreArchiveAccount(_id)
                    .pipe(
                        tap(() => this.loadingResults = true),
                        takeUntil(this._unsubscribeAll)
                    )
                    .subscribe(() => {
                        this.loadingResults = false;
                        this.dataReload.setValue('refresh');
                    }, () => this.loadingResults = false);
            }
        });
    }

    restoreSelected(): void {
        const dialog = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });
        dialog.componentInstance.confirmMessage = this._translateService.instant('Are you sure you want to restore?');
        dialog.afterClosed().subscribe(result => {
            if (result) {
                const restoreFilter = this.selectedItems.reduce((acc, elem) => {
                    acc[elem] = true;
                    return acc;
                }, {});
                this._accountsService.restoreSelectedAccounts(restoreFilter)
                    .pipe(
                        tap(() => this.loadingResults = true),
                        takeUntil(this._unsubscribeAll)
                    )
                    .subscribe(() => {
                        this.loadingResults = false;
                        this.dataReload.setValue('refresh');
                    }, () => this.loadingResults = false);
            }
        });
    }

    deletedSelected(): void {
        const dialog = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });
        dialog.componentInstance.confirmMessage = this._translateService.instant('Are you sure you want to delete permanently?');
        dialog.afterClosed().subscribe(result => {
            if (result) {
                const restoreFilter = this.selectedItems.reduce((acc, elem) => {
                    acc[elem] = true;
                    return acc;
                }, {});
                this._accountsService.deleteSeletedArchiveAccounts(restoreFilter)
                    .pipe(
                        tap(() => this.loadingResults = true),
                        takeUntil(this._unsubscribeAll)
                    )
                    .subscribe(() => {
                        this.loadingResults = false;
                        this.dataReload.setValue('refresh');
                    }, () => this.loadingResults = false);
            }
        });
    }

    restoreAll(): void {
        const dialog = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });
        dialog.componentInstance.confirmMessage = this._translateService.instant('Are you sure you want to restore?');
        dialog.afterClosed().subscribe(result => {
            if (result) {
                this._accountsService.restoreAll(this._authenticationService.currentAgencyValue._id)
                    .pipe(
                        tap(() => this.loadingResults = true),
                        takeUntil(this._unsubscribeAll)
                    )
                    .subscribe(() => {
                        this.loadingResults = false;
                        this.dataReload.setValue('refresh');
                    }, () => this.loadingResults = false);
            }
        });
    }
    deleteAll(): void {
        const dialog = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });
        dialog.componentInstance.confirmMessage = this._translateService.instant('Are you sure you want to delete permanently?');
        dialog.afterClosed().subscribe(result => {
            if (result) {
                this._accountsService.deleteAll(this._authenticationService.currentAgencyValue._id)
                    .pipe(
                        tap(() => this.loadingResults = true),
                        takeUntil(this._unsubscribeAll)
                    )
                    .subscribe(() => {
                        this.loadingResults = false;
                        this.dataReload.setValue('refresh');
                    }, () => this.loadingResults = false);
            }
        });
    }

    searchData(): void {
        this.searchDialogRef = this._matDialog.open(AccountsAdvanceSearchComponent, {
            // panelClass: 'event-form-dialog',
            panelClass: 'form-dialog',
            data: this.advanceSearchSave ? this.advanceSearchSave : {}
        });
        this.searchDialogRef.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response: any) => {
                if (response) {
                    this.advanceSearchSave = this._globalFuntions.cleanObject(response.value);
                    if (this.quickSearchSave) {
                        this.filter = _loMerge(this.quickSearchSave, this.advanceSearchSave);
                    } else {
                        this.filter = this._globalFuntions.cleanObject(response.value);
                    }
                    this.filter.grid = false;
                    if (this.filter.status && this.filter.status !== 'inactive') {
                        this.filter.status_not = 'inactive';
                    }
                    this.dataReload.setValue(null);
                }
            });
    }

    searchArchive(): void {
        this.searchDialogRef = this._matDialog.open(ArchiveSearchComponent, {
            panelClass: 'event-form-dialog',
            data: {}
        });
        this.searchDialogRef.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response: any) => {
                if (response) {
                    this.filterArchive = this._globalFuntions.cleanObject(response.value);
                    this.archiveSearch.setValue('', { emitEvent: false });
                    this.dataReload.setValue(null);
                }
            });
    }

    massUpdate(): void {
        this.searchDialogRef = this._matDialog.open(AccountMassUpdateComponent, {
            panelClass: 'mass-dialog',
            data: { accounts: this.selectedItems }
        });
        this.searchDialogRef.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response: any) => {
                // if (response) {
                //     this.loadingResults = true;
                //     let updateRequests = [];
                //     forEach(this.selectedItems, (selectedAccount) => {
                //         const account: any = find(this.accounts.data, { _id: selectedAccount });
                //         account.csvimport = 'true';
                //         if (response.office_append && response.offices && response.offices.length && account.offices) {
                //             forEach(response.offices, (ov) => {
                //                 if (account.offices.indexOf(ov) < 0) {
                //                     account.offices.push(ov);
                //                 }
                //             });
                //         } else if (response.offices && response.offices.length) {
                //             account.offices = response.offices;
                //         }
                //         if (response.assigne_append && response.assigned_to && response.assigned_to.length && account.assigned_to) {
                //             forEach(response.assigned_to, (at) => {
                //                 if (account.assigned_to.indexOf(at) < 0) {
                //                     account.assigned_to.push(at);
                //                 }
                //             });
                //         } else if (response.assigned_to && response.assigned_to.length) {
                //             account.assigned_to = response.assigned_to;
                //         }
                //         if (response.gdpr_status) {
                //             account.gdpr_status = response.gdpr_status;
                //         }
                //         if (response.status) {
                //             account.status = response.status;
                //         }
                //         account.delete_flag = response.delete_flag;
                //         updateRequests = [...updateRequests, this._accountsService.update(account)];
                //     });
                //     combineLatest(updateRequests)
                //         .pipe(takeUntil(this._unsubscribeAll))
                //         .subscribe(() => this.dataReload.setValue('refresh'), () => this.dataReload.setValue('refresh'));
                // }
            });
    }

    activeInaciveAcounts(): void {
        this.inactive = !this.inactive;
        if (this.inactive) {
            this.filter.status = 'inactive';
            delete this.filter.status_not;
            this.dataReload.setValue(null);
        }
        if (!this.inactive) {
            this.filter.status_not = 'inactive';
            delete this.filter.status;
            this.dataReload.setValue(null);
        }
    }
    SelectAllRecords(event: MatCheckboxChange): any {
        this.selectedItems = [];
        this.assignedEmails = [];
        this.emails = [];
        if (event.checked === true) {
            this.selectAll = true;
            this.selectAllRecords = true;
            const accountFilter = {} as any;
            if(this.relatedTo === 'archive'){
                const filterArchiv = this._globalFuntions.clearObject(this.filterArchive);
                filterArchiv['select_all'] = true;
                this._accountsService.getArchiveAccounts(filterArchiv).subscribe((response: any) => {
                    forEach(response?.body, (account: any) => {
                        console.log(account);
                        if (account._id.oid) {
                            account._id = account._id.oid;
                        }
                        this.selectedItems = [...this.selectedItems, account._id];
                    });
                });
            }else{
                accountFilter.status_not = 'inactive';
                accountFilter.grid = false;
                this._accountsService.getAllAccounts('', this.filter, false).subscribe((response: any) => {
                    forEach(response, (account: any) => {
                        if (account._id.oid) {
                            account._id = account._id.oid;
                        }
                        if (account.assigned_to || account.created_by) {
                            if (includes(account.assigned_to, this.currentUser.user_name) || account.created_by === this.currentUser._id) {
                                this.assignedEmails = [...this.assignedEmails, account.email];
                            }
                        }
                        this.selectedItems = [...this.selectedItems, account._id];
                        this.emails = [...this.emails, account.email];
                    });
                });
            }
        }else {
            this.selectedItems = [];
            this.emails = [];
            this.selectAll = false;
            this.selectAllRecords = false;
        }

    }

    selectAllAccounts(event: MatCheckboxChange): void {
        if (event.checked === true) {
            forEach(this.accounts.data, (account) => {
                this.selectedItems = [...this.selectedItems, account._id];
                this.emails = [...this.emails, account.email];
                if (account.assigned_to || account.created_by) {
                    if (includes(account.assigned_to, this.currentUser.user_name) || account.created_by === this.currentUser._id) {
                        this.assignedEmails = [...this.assignedEmails, account.email];
                    }
                }
            });
        }
        else {
            this.selectedItems = [];
            this.emails = [];
            this.assignedEmails = [];
        }
        if (this.selectedItems.length === this.accounts.data.length) {
            this.selectAll = true;
        }
    }

    selectAccount(event: MatCheckboxChange, account: any): void {
        const index: number = this.selectedItems.indexOf(account._id);
        if (index !== -1) {
            this.selectedItems.splice(index, 1);
            this.selectedItems = [...this.selectedItems];
            this.emails.splice(index, 1);
            this.emails = [...this.emails];
            this.assignedEmails.splice(index, 1);
            if (account.assigned_to || account.created_by) {
                if (includes(account.assigned_to, this.currentUser.user_name) || account.created_by === this.currentUser._id) {
                    this.assignedEmails = [...this.assignedEmails, account.email];
                }
            }
        }
        if (event.checked === true) {
            this.selectedItems = [...this.selectedItems, account._id];
            this.emails = [...this.emails, account.email];
            if (account.assigned_to || account.created_by) {
                if (includes(account.assigned_to, this.currentUser.user_name) || account.created_by === this.currentUser._id) {
                    this.assignedEmails = [...this.assignedEmails, account.email];
                }
            }
            if(this.accounts.data.length == this.selectedItems.length){
                this.selectAll = true;
            }
        }
    }

    toggleSidebar(name: string): void {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }

    openProperties(event: any): void {
        this.rpaId = event;
        this._fuseSidebarService.getSidebar('properties').toggleOpen();
    }

    // onOpenedChanged(event: any): void {
    //     if (!event) {
    //         delete this.rpaId;
    //     }
    // }

    gridView(): void {
        this.router.navigateByUrl('/master-data/accounts/grid');
    }
    listView(): void {
        this.router.navigateByUrl('/master-data/accounts/list');
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    openTimeline(event: any): void {
        this._timeLineData.setModelDataValue(event);
        this._timeLineData.setRelatedToValue('account');
        this._fuseSidebarService.getSidebar('timeline').toggleOpen();
    }
    editAccount(id?: any): any {
        const searchFilter = {
            type: 'list',
            data: this.filter,
            pageIndex: this.paginator.pageIndex,
            pageSize: this.paginator.pageSize
        };
        this.router.navigateByUrl('/master-data/accounts/update/' + id, { state: { list: true } });
        this._accountsService.setaccountSearchFilter(searchFilter);
    }

    addActivities(activity: any, id?: string, name?: string): void {

        if (activity === 'Meeting') {
            this.searchDialogRef = this._matDialog.open(MeetingCreateModalComponent, {
                panelClass: 'event-form-dialog',
                data: {
                    relatedTo: 'accounts',
                    accountId: id
                }
            });
        } else if (activity === 'Task') {
            this.searchDialogRef = this._matDialog.open(TasksCreateModalComponent, {
                panelClass: 'event-form-dialog',
                data: {
                    relatedTo: 'accounts',
                    accountId: id
                }
            });
        } else if (activity === 'Viewing') {
            this.searchDialogRef = this._matDialog.open(ViewingToursModalComponent, {
                panelClass: 'event-form-dialog',
                data: {
                    relatedTo: 'accounts',
                    accountId: id,
                    accountName: name
                }
            });
        }

        this.searchDialogRef.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response: any) => {
                if (response) {
                    // this.filter = pickBy(response.value, identity);
                    // this.value.setValue(null);
                }
            });

    }

    onQuickSearch(event: any): void {
        this.quickSearchSave = this._globalFuntions.cleanObject(event.value);
        if (this.advanceSearchSave) {
            this.filter = _loMerge(this.quickSearchSave, this.advanceSearchSave);
        } else {
            this.filter = this._globalFuntions.cleanObject(event.value);
        }
        this.filter.grid = false;
        this.filter.status_not = 'inactive';
        this.dataReload.setValue(null);
        setTimeout(() => {
            this.quickSearch = false;
        });
    }

    sendEmail(email: any): void {
        if (email && typeof email !== 'undefined') {
            window.location.href = 'mailto:' + email;
        }
    }
    openCommonDialog(data: any, type: string): any {
        if (((data.work_phone) && data.work_phone !== '') ||
            ((data.home_phone) && data.home_phone !== '') ||
            ((data.mobile_phone) && data.mobile_phone !== '')) {
            const mobileData = {
                type: 'account',
                _id: data._id,
                mobile: data.mobile_phone,
                homePhone: data.home_phone,
                workPhone: data.work_phone,
            };
            const dialog = this._matDialog.open(CommonDialogComponent, {
                disableClose: false,
                panelClass: 'common-dialog',
                data: mobileData
            });
            dialog.afterClosed()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((response: any) => {
                    if (response) {
                        data.whatsapp = true;
                      //  this.openTimeline.next(data);
                    }
                });
        }
    }
    bgColor(account): any {
        if (account && account.status && account.status == 'lead') {
            return 'bg-lead';
        } else if (account && account.status && account.status == 'prospect') {
            return 'bg-prospect';
        } else if (account && account.status && account.status == 'client') {
            return 'bg-qualified';
        } else if (account && account.status && account.status == 'previous client') {
            return 'bg-client';
        } else if (account && account.status && account.status == 'inactive') {
            return 'bg-inactive';
        } else {
            return 'bg-no-status';
        }
    }
}
