import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemMessagesComponent } from './system-messages.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { MatDividerModule } from '@angular/material/divider';



@NgModule({
    declarations: [SystemMessagesComponent],
    imports: [
        CommonModule,
        FuseSharedModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatToolbarModule,
        MatTooltipModule,
        MatDividerModule,
        FroalaEditorModule,
        FroalaViewModule,
    ]
})
export class SystemMessagesModule { }
