import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class CronJobsService {
    constructor(private http: HttpClient) { }

    // getData(filter: any): Observable<any[]> {
    //     return this.http.post<any[]>(`${environment.apiUrl}cron-jobs`, filter);
    // }
    getAdminJobs(filter: any, page: any = 1, perPage: any = 'false'): Observable<any[]> {
        let query = `expand=agency_users,agency_data&page=${page}`;
        if (perPage === 'false') {
            query += `&pagination=false`;
        } else if (perPage > 0) {
            query += `&per-page=${perPage}`;
        }
        return this.http.post<any[]>(
            `${environment.yiiUrl}cron-jobs/search?${query}`,
            filter, { observe: 'response' as 'body' }
        );
    }
    getUserJobs(filter: any, page: any = 1, perPage: any = 'false'): Observable<any[]> {
        let query = `expand=agency_users&page=${page}`;
        if (perPage === 'false') {
            query += `&pagination=false`;
        } else if (perPage > 0) {
            query += `&per-page=${perPage}`;
        }
        return this.http.post<any[]>(
            `${environment.yiiUrl}cron-jobs/user-cron-search?${query}`,
            filter, { observe: 'response' as 'body' }
        );
    }
    createData(data: any): Observable<object> {
        return this.http.post(`${environment.yiiUrl}cron-jobs`, data);
    }

    // Create or Update Multiple Crons
    createMultiCrons(data: any): Observable<object> {
        return this.http.post(
            `${environment.yiiUrl}cron-jobs/multiple-crons`,
            data
        );
    }
    getJobAgencies(): Observable<object> {
        return this.http.get(
            `${environment.yiiUrl}agencies?select_data=true&page_size=false`
        );
    }
    updateData(data: any): Observable<object> {
        return this.http.put(
            `${environment.yiiUrl}cron-jobs/${data._id}`,
            data
        );
    }
    getById(_id: string): Observable<object> {
        return this.http.get(`${environment.yiiUrl}cron-jobs/${_id}`);
    }
    deleteData(data: any): Observable<any> {
        return this.http.delete(
            `${environment.yiiUrl}cron-jobs/${data._id}`,
            data
        );
    }

    getListData(filter: any): Observable<object> {
        return this.http.post<any[]>(`${environment.apiUrl}cron-jobs`, filter);
    }
}
