import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'account-delete',
  templateUrl: './account-delete.component.html',
  styleUrls: ['./account-delete.component.scss']
})
export class AccountDeleteComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AccountDeleteComponent>
  ) { }

  ngOnInit(): void {
  }

}
