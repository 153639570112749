import { Component, OnInit, OnDestroy, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { AttachmentsService } from 'app/services/attachments.service';
import { Subject } from 'rxjs';
import { forEach, map } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'attachment-list-dialog',
    templateUrl: './attachment-list-dialog.component.html',
    styleUrls: ['./attachment-list-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class AttachmentListDialogComponent implements OnInit, OnDestroy {

    currentLang: string;
    modelAttachments = [] as any;
    selectedAttachments = {};
    selectedAttachmentsIds: any[] = [];
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _attachmentsService: AttachmentsService,
        private _translateService: TranslateService,
        public matDialogRef: MatDialogRef<AttachmentListDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
    ) {
        this.currentLang = this._translateService.currentLang;
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        if (this._data) {
            this._attachmentsService.getModelAttachments(this._data.modelId, this._data.modelName)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((data: any) => {
                        const modelAttachments = map(data, (attachment) => {
                            if (!attachment.description) {
                                attachment.description = {};
                            }
                            if (!attachment.alt_description) {
                                attachment.alt_description = {};
                            }
                            return attachment;
                        });
                        this.modelAttachments = modelAttachments;
                    });
        }
    }

    singleDownload(data: any): void {
        window.open(`${environment.yiiUrl}attachments/single-download?url=https://images.optima-crm.com/${this._data.modelName}/${data.model_id}/${data.file_md5_name}`, '_blank');
    }

    onSelectAttachment(): void {
        let selectedAttachmentsIds = [];
        forEach(this.selectedAttachments, (value, key) => {
            if (value) {
                selectedAttachmentsIds = [...selectedAttachmentsIds, key];
            }
        });
        this.selectedAttachmentsIds = selectedAttachmentsIds;
    }

    downloadSelectedAttachments(): void {
        window.open(`${environment.yiiUrl}attachments/download?ids=${this.selectedAttachmentsIds}&name=selected_images&modelName=${this._data.modelName}`, '_blank');
    }

    downloadAttachments(): void {
        let selectedAttachmentsIds = [];
        forEach(this.modelAttachments, (value, key) => {
            if (value) {
                selectedAttachmentsIds = [...selectedAttachmentsIds, value._id];
            }
        });
        window.open(`${environment.yiiUrl}attachments/download?ids=${selectedAttachmentsIds}&name=all_images&modelName=${this._data.modelName}`, '_blank');
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
