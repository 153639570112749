import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'app/services';
import { environment } from 'environments/environment';
import { of, Subject } from 'rxjs';
import { catchError, map, startWith, takeUntil } from 'rxjs/operators';
import { GLOBALS } from '../../../../../config/globals';
import { BaseService } from '../../../../../_helpers/base/base.service';
import { WebsocketService } from '../../../../../services/websocket.service';

@Component({
    selector: 'app-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class TimelineComponent implements OnInit, OnDestroy {

    public timeline: any;
    public activities: any;

    public boardData;
    public board;

    public loaders = { ...GLOBALS.loaders }; // To display/hide loading spinner
    public animations = { ...GLOBALS.animations }; // To use centralized animations
    public pageActions = GLOBALS.pageActions; // To use same naming conventions for opening one form for multiple purposes

    public currentLang: string;

    public env = environment;

    // use getter setter to define the property
    @Input()
    set search(val: any) {
        this.loaders.box = true;
        this._gService.post('mp/board/message/search', { board: this.board, search: val }, 'nodejs')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(res => {
                this.timeline = res.messages;
                this.loaders.box = false;
            });
    }

    @Input()
    set subscribers(val: any) {
        this.activities = val;
    }

    @Input()
    set _board(val: any) {
        this.board = val;
        this.ngOnInit();
    }

    //
    @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;

    //
    public files = [];
    public inProgress = false;


    private _unsubscribeAll: Subject<any> = new Subject();

    constructor(
        private router: Router,
        private actRoute: ActivatedRoute,
        private _gService: BaseService,
        private _formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService,
        private _authenticationService: AuthenticationService,
        private socket: WebsocketService) {
        this._translateService.onLangChange
            .pipe(startWith(''))
            .subscribe(() => {
                this.currentLang = this._translateService.currentLang;
            });
    }

    public post = this._formBuilder.group({
        message: this._formBuilder.control('', Validators.required),
        photo: this._formBuilder.control('')
    });

    ngOnInit(): void {
        // this.timeline = {
        //     activities: [
        //         {
        //             user   : {
        //                 name  : 'Alice Freeman',
        //                 avatar: 'assets/images/avatars/alice.jpg'
        //             },
        //             message: 'started following you.',
        //             time   : '13 mins. ago'
        //         },
        //         {
        //             user   : {
        //                 name  : 'Andrew Green',
        //                 avatar: 'assets/images/avatars/andrew.jpg'
        //             },
        //             message: 'sent you a message.',
        //             time   : 'June 10,2018'
        //         },
        //         {
        //             user   : {
        //                 name  : 'Garry Newman',
        //                 avatar: 'assets/images/avatars/garry.jpg'
        //             },
        //             message: 'shared a public post with your group.',
        //             time   : 'June 9,2018'
        //         },
        //         {
        //             user   : {
        //                 name  : 'Carl Henderson',
        //                 avatar: 'assets/images/avatars/carl.jpg'
        //             },
        //             message: 'wants to play Fallout Shelter with you.',
        //             time   : 'June 8,2018'
        //         },
        //         {
        //             user   : {
        //                 name  : 'Jane Dean',
        //                 avatar: 'assets/images/avatars/jane.jpg'
        //             },
        //             message: 'started following you.',
        //             time   : 'June 7,2018'
        //         },
        //         {
        //             user   : {
        //                 name  : 'Juan Carpenter',
        //                 avatar: 'assets/images/avatars/james.jpg'
        //             },
        //             message: 'sent you a message.',
        //             time   : 'June 6,2018'
        //         },
        //         {
        //             user   : {
        //                 name  : 'Judith Burton',
        //                 avatar: 'assets/images/avatars/joyce.jpg'
        //             },
        //             message: 'shared a photo with you.',
        //             time   : 'June 5,2018'
        //         },
        //         {
        //             user   : {
        //                 name  : 'Vincent Munoz',
        //                 avatar: 'assets/images/avatars/vincent.jpg'
        //             },
        //             message: 'shared a photo with you.',
        //             time   : 'June 4,2018'
        //         }
        //     ],
        //     posts     : [
        //         {
        //             user    : {
        //                 name  : 'Garry Newman',
        //                 avatar: 'assets/images/avatars/garry.jpg'
        //             },
        //             message : 'Modern complex met gemengd gebruik bestaande uit 7 verdiepingen waarvan het 1e verdiep commerciële lokalen zijn. \
        //              Fase 1 van het project is van start gegaan op 15/7 met opleveringsdatum op 1/9/2021. Contacteer me voor meer details en reservatie.',
        //             time    : '32 minutes ago',
        //             type    : 'post',
        //             like    : 5,
        //             share   : 21,
        //             media   : {
        //                 type   : 'image',
        //                 preview: 'assets/images/profile/NC-1.jpg'
        //             },
        //             comments: [
        //                 {
        //                     user   : {
        //                         name  : 'Alice Freeman',
        //                         avatar: 'assets/images/avatars/alice.jpg'
        //                     },
        //                     time   : 'June 10, 2018',
        //                     message: 'That’s a wonderful place. Lorem ipsum dolor sit amet, consectetur adipiscing elit. \
        //                     Fusce et eleifend ligula. Fusce posuere in sapien ac facilisis. Etiam sit amet justo non felis ornare feugiat.'
        //                 }
        //             ]
        //         },
        //         {
        //             user    : {
        //                 name  : 'Andrew Green',
        //                 avatar: 'assets/images/avatars/andrew.jpg'
        //             },
        //             message : 'Verkaveling nabij Gent centrum bestaande uit 5 blokken appartementen gecombineerd met commerciële lokalen en een recreatiezone.\
        //              Dit project wordt opgeleverd in maart 2021 en is nu in finale constructiefase. Reservatie voor fase 3 is nu open: www.kouter-project.be',
        //             time    : 'June 12, 2018',
        //             type    : 'article',
        //             like    : 98,
        //             share   : 6,
        //             article : {
        //                 title   : 'Never stop changing!',
        //                 subtitle: 'John Westrock',
        //                 excerpt : 'John Westrock\'s new photo album called \'Never stop changing\' is published! It features more than 200 photos that will take you right in.',
        //                 media   : {
        //                     type   : 'image',
        //                     preview: 'assets/images/profile/NC-2.jpg'
        //                 }
        //             },
        //             comments: [
        //                 {
        //                     user   : {
        //                         name  : 'Alice Freeman',
        //                         avatar: 'assets/images/avatars/alice.jpg'
        //                     },
        //                     time   : 'June 10, 2018',
        //                     message: 'That’s a wonderful place. Lorem ipsum dolor sit amet, consectetur adipiscing elit. \
        //                      Fusce et eleifend ligula. Fusce posuere in sapien ac facilisis. Etiam sit amet justo non felis ornare feugiat.'
        //                 }
        //             ]
        //         },
        //         {
        //             user   : {
        //                 name  : 'Carl Henderson',
        //                 avatar: 'assets/images/avatars/carl.jpg'
        //             },
        //             message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. \
        //              Fusce et eleifend ligula. Fusce posuere in sapien ac facilisis. Etiam sit amet justo non felis ornare feugiat. \
        //               Aenean lorem ex, ultrices sit amet ligula sed...',
        //             time   : 'June 10, 2018',
        //             type   : 'something',
        //             like   : 4,
        //             share  : 1
        //         }
        //     ]
        // };

        this.loaders.box = true;
        this._gService.post('mp/board/messages/get/all/' + this.board, {}, 'nodejs')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(res => {
                if (res.status === true) {
                    this.timeline = res.data;
                    this.loaders.box = false;
                }
            });
        //  Demo Data Start
        // this.activities = 
        // [
        //         {
        //             user   : {
        //                 name  : 'Alice Freeman',
        //                 avatar: 'assets/images/avatars/alice.jpg'
        //             },
        //             message: 'started following you.',
        //             time   : '13 mins. ago'
        //         },
        //         {
        //             user   : {
        //                 name  : 'Andrew Green',
        //                 avatar: 'assets/images/avatars/andrew.jpg'
        //             },
        //             message: 'sent you a message.',
        //             time   : 'June 10,2018'
        //         },
        //         {
        //             user   : {
        //                 name  : 'Garry Newman',
        //                 avatar: 'assets/images/avatars/garry.jpg'
        //             },
        //             message: 'shared a public post with your group.',
        //             time   : 'June 9,2018'
        //         },
        //         {
        //             user   : {
        //                 name  : 'Carl Henderson',
        //                 avatar: 'assets/images/avatars/carl.jpg'
        //             },
        //             message: 'wants to play Fallout Shelter with you.',
        //             time   : 'June 8,2018'
        //         },
        //         {
        //             user   : {
        //                 name  : 'Jane Dean',
        //                 avatar: 'assets/images/avatars/jane.jpg'
        //             },
        //             message: 'started following you.',
        //             time   : 'June 7,2018'
        //         },
        //         {
        //             user   : {
        //                 name  : 'Juan Carpenter',
        //                 avatar: 'assets/images/avatars/james.jpg'
        //             },
        //             message: 'sent you a message.',
        //             time   : 'June 6,2018'
        //         },
        //         {
        //             user   : {
        //                 name  : 'Judith Burton',
        //                 avatar: 'assets/images/avatars/joyce.jpg'
        //             },
        //             message: 'shared a photo with you.',
        //             time   : 'June 5,2018'
        //         },
        //         {
        //             user   : {
        //                 name  : 'Vincent Munoz',
        //                 avatar: 'assets/images/avatars/vincent.jpg'
        //             },
        //             message: 'shared a photo with you.',
        //             time   : 'June 4,2018'
        //         }
        //     ];
        // Demo Data End
    }
    public AddPost(): void {
        this.post.value.board = this.board;
        this.loaders.box = true;
        this._gService.post('mp/board/message/create', this.post.value, 'nodejs')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(res => {
                if (res.status === true) {
                    this.post.reset();
                    this.files = [];
                    this.loaders.box = false;
                    this.ngOnInit();
                    this.socket.emit('postCreate', {
                        created_by: this._authenticationService.currentUserValue._id, updated_by: this._authenticationService.currentUserValue._id,
                        agency: this._authenticationService.currentUserValue.agency, board: this.board, created_name: this._authenticationService.currentUserValue.full_name,
                        model_id: res.messageId
                    });
                    // setTimeout(() => {
                    //     this.socket.emit('unReadNotificationCount', { userId: this._authenticationService.currentUserValue._id });
                    // }, 2000);
                }
            });
    }

    public reloadData(event): void {
        if (event === true) {
            this.ngOnInit();
        }
    }

    private uploadFile(file): void {
        this.inProgress = true;
        file.inProgress = true;
        const data = {
            id: this.board,
            model: 'mp_board_messages'
        };

        this._gService.uploadImage('attachments/single-upload/' + data.model + '/' + data.id, {}, file.data, 'nodejs')
            // this._gService.uploadImage('attachments/single-upload', data, file.data, 'nodejs')
            .pipe(takeUntil(this._unsubscribeAll))
            .pipe(
                map(event => {
                    switch (event.type) {
                        case HttpEventType.UploadProgress:
                            file.progress = Math.round(event.loaded * 100 / event.total);
                            break;
                        case HttpEventType.Response:
                            return event;
                    }
                }),
                catchError((error: HttpErrorResponse) => {
                    file.inProgress = false;
                    return of(`${file.data.name} upload failed.`);
                }))
            .subscribe((event: any) => {
                if (typeof (event) === 'object') {
                    // this.post.addControl('photo', new FormControl(''));
                    this.post.get('photo').setValue(event.body.file_name);
                    this._gService.succussMessage('Image Uploaded', 'success');
                    this.inProgress = false;
                }
            });
    }

    /**
     * 
     */
    public uploadFiles(): void {
        this.fileUpload.nativeElement.value = '';
        this.files.forEach(file => {
            this.uploadFile(file);
        });
    }
    public onClick(): void {
        const fileUpload = this.fileUpload.nativeElement;
        fileUpload.onchange = () => {
            this.files = [];
            for (let index = 0; index < fileUpload.files.length; index++) {
                const file = fileUpload.files[index];
                this.files.push({ data: file, inProgress: false, progress: 0 });
            }
            this.uploadFiles();
        };
        fileUpload.click();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

}
