import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'getCommercialPropertyUrl'
})
export class GetCommercialPropertyUrlPipe implements PipeTransform {

    constructor(
        private _translateService: TranslateService,
    ) { }

    transform(property: any,locale?: any): any {
        let currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
        currentLang = currentLang ? currentLang.trim().toUpperCase() : 'EN';
        let title: any, websiteUrl: any, propertyWebsiteUrl: any, propertyUrl: any, location: string, propertyLocation: string, type: string, propertyType: string, language: string, agencyData: any;
        
        propertyLocation = property?.property_location?.value[currentLang.toLowerCase()];
        propertyType = property?.property_type_one?.value[currentLang.toLowerCase()];
        propertyWebsiteUrl = property.agency_data?.property_url_website_cp;
        agencyData = property?.agency_data;


        if (propertyWebsiteUrl && typeof(propertyWebsiteUrl) == 'object' && Object.keys(propertyWebsiteUrl).length) {
            websiteUrl = propertyWebsiteUrl;
        }
        if (property.lt_rental || property.st_rental) {
            title = property.rental_title;
            propertyUrl = (websiteUrl && typeof(websiteUrl) == 'object' && Object.keys(websiteUrl).length && websiteUrl['rent']) ? websiteUrl['rent'] : '';
        } else {
            title = property.title;
            propertyUrl = (websiteUrl && typeof(websiteUrl) == 'object' &&  Object.keys(websiteUrl).length && websiteUrl['sale']) ? websiteUrl['sale'] : '';
        }
        if (!['es_AR', 'ES', 'EN'].includes(currentLang)) {
            if (currentLang == 'NL') {
                if ((propertyLocation[currentLang.toLowerCase()]) && (propertyLocation[currentLang.toLowerCase()] != '')) {
                    location = propertyLocation[currentLang.toLowerCase()];
                } else if (propertyLocation['ned'] && propertyLocation['ned'] != '') {
                    location = propertyLocation['ned'];
                }

                if (propertyType[currentLang.toLowerCase()] && propertyType[currentLang.toLowerCase()] != '') {
                    type = propertyType[currentLang.toLowerCase()];
                } else if (propertyType['ned'] && propertyType['ned'] != '') {
                    type = propertyType['ned'];
                }

                if (title[currentLang] && title[currentLang] != '') {
                    title = title[currentLang];
                } else if (title['NED'] && title['NED'] != '') {
                    title = title['NED'];
                } else if (title['Dutch'] && title['Dutch'] != '') {
                    title = title['Dutch'];
                }

                if (typeof(propertyUrl) == 'object' && Object.keys(propertyUrl).length) {
                    language = currentLang.toLowerCase();
                    if (propertyUrl[language] && propertyUrl[language] != '') {
                        propertyUrl = propertyUrl[language];
                    } else if (propertyUrl['ned'] && propertyUrl['ned'] != '') {
                        propertyUrl = propertyUrl['ned'];
                    } else if (propertyUrl['dutch'] && propertyUrl['dutch'] != '') {
                        propertyUrl = propertyUrl['dutch'];
                    } else if (propertyUrl['dui'] && propertyUrl['dui'] != '') {
                        propertyUrl = propertyUrl['dui'];
                    }
                }
            } else {
                if (propertyLocation[currentLang.toLowerCase()] && propertyLocation[currentLang.toLowerCase()] != '') {
                    location = propertyLocation[currentLang.toLowerCase()];
                }

                if (propertyType[currentLang.toLowerCase()] && propertyType[currentLang.toLowerCase()] != '') {
                    type = propertyType[currentLang.toLowerCase()];
                }

                if (currentLang == 'FR' || currentLang == "RU") {
                    language = currentLang + '1';
                    if (title[language] && title[language] != '') {
                        title = title[language];
                    } else if (title[currentLang] && title[currentLang] != '') {
                        title = title[currentLang];
                    }
                    if (typeof(propertyUrl) == 'object' && Object.keys(propertyUrl).length) {
                        if (propertyUrl[currentLang.toLowerCase()] && propertyUrl[currentLang.toLowerCase()] != '') {
                            propertyUrl = propertyUrl[currentLang.toLowerCase()];
                        } else if (propertyUrl[language.toLowerCase()] && propertyUrl[language.toLowerCase()] != '') {
                            propertyUrl = propertyUrl[language.toLowerCase()];
                        }
                    }
                } else {
                    if (title[currentLang] && title[currentLang] != '') {
                        title = title[currentLang];
                    }
                    if (
                        typeof(propertyUrl) == 'object' &&
                        Object.keys(propertyUrl).length &&
                        propertyUrl[currentLang.toLowerCase()]
                    ) {
                        propertyUrl = propertyUrl[currentLang.toLowerCase()];
                    }
                }
            }
        } else {
            if(title) {
                if (title[currentLang] && title[currentLang] != '') {
                    title = title[currentLang];
                } else {
                    title = '-';
                }
            }else {
                title = '-';
            }
            if (propertyType[currentLang.toLowerCase()] && propertyType[currentLang.toLowerCase()]) {
                type = propertyType[currentLang.toLowerCase()];
            }else{
                type = '-';
            }
            if (
                typeof(propertyUrl) == 'object' &&
                Object.keys(propertyUrl).length &&
                propertyUrl[currentLang.toLowerCase()]
            ) {
                propertyUrl = propertyUrl[currentLang.toLowerCase()];
            }
        }
        if (typeof(title) == 'object' && Object.keys(title).length) { // at this level `title` must be a string value if not it's mean title is an empty array and empty `title` array msut be converted to '-'
            title = '-';
        }
        if (typeof(propertyUrl) == 'object' && Object.keys(propertyUrl).length) {
            propertyUrl = propertyUrl['en'];
        }
        return propertyUrl;
    }
}
