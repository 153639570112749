import { Directive, HostListener, ElementRef, Renderer2, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SendinblueListComponent } from './sendinblue-list/sendinblue-list.component';

@Directive({
  selector: '[sendinblue]'
})
export class SendinblueDirective {

  @Input() AccountIds = [];
  @Input() owner = false;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    public _matDialog: MatDialog
  ) { 
    this.renderer.setStyle(this.el.nativeElement, 'cursor', 'pointer');
  }

  @HostListener('click') onClick(): void {
    const dialogRef = this._matDialog.open(SendinblueListComponent, {
        panelClass: 'sendlinblue-list-dialog',
        data: {
            ids: this.AccountIds,
            owner: this.owner
        },
    });
    dialogRef.afterClosed()
        .subscribe(response => {
        });
  }

}
