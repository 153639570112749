import { Component, OnInit, ViewEncapsulation, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { DistributionListService } from 'app/services/distribution-list.service';
import { tap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'view-list',
    templateUrl: './view-list.component.html',
    styleUrls: ['./view-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ViewListComponent implements OnInit, OnDestroy {


    private _unsubscribeAll: Subject<any>;
    loadingResults: boolean;
    displayedColumns: string[] = ['full_name', 'email', 'created_at', 'actions'];
    subscribers: any;

    constructor(
        public matDialogRef: MatDialogRef<ViewListComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _distributionListService: DistributionListService,
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.getSubscribers();
    }

    getSubscribers(): void {
        this._distributionListService.getSubscribersList({ list_id: this._data.list_data._id }, this._data.relatedTo)
            .pipe(
                tap(() => this.loadingResults = true),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe((data: any) => {
                this.loadingResults = false;
                this.subscribers = data;
            }, () => this.loadingResults = false);
    }

    deleteSubscriber(data: any): void {
        this._distributionListService.deleteSubscriberFromList(data)
            .pipe(
                tap(() => this.loadingResults = true),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => this.getSubscribers(), () => this.loadingResults = false);
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

}
