// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `quick-panel {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 350px;
  min-width: 350px;
  max-width: 350px;
  z-index: 99;
}
quick-panel .mat-mdc-slide-toggle-content {
  flex: 1;
}
quick-panel .mat-mdc-tab {
  min-width: 20px !important;
  flex: 1 !important;
  padding: 0 !important;
  height: 81px !important;
  margin-bottom: -13px !important;
}
quick-panel .timeline-sidebar {
  padding-left: 10px;
  padding-right: 15px;
}
quick-panel .timeline-sidebar .latest-activity .content .activities .activity {
  padding: 16px 0;
  cursor: pointer;
}
quick-panel .timeline-sidebar .latest-activity .content .activities .activity .avatar {
  margin-right: 16px;
}
quick-panel .timeline-sidebar .latest-activity .content .activities .activity .username {
  font-weight: 600;
}
quick-panel .timeline-sidebar .latest-activity .content .activities .activity .message {
  font-weight: 200;
}`, "",{"version":3,"sources":["webpack://./src/app/layout/components/quick-panel/quick-panel.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,cAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,WAAA;AACF;AAAE;EACE,OAAA;AAEJ;AAAE;EACE,0BAAA;EACA,kBAAA;EACA,qBAAA;EACA,uBAAA;EACA,+BAAA;AAEJ;AACA;EAEI,kBAAA;EACA,mBAAA;AAAJ;AAMgB;EACI,eAAA;EACA,eAAA;AAJpB;AAMoB;EACI,kBAAA;AAJxB;AAOoB;EACI,gBAAA;AALxB;AAQoB;EACI,gBAAA;AANxB","sourcesContent":["quick-panel {\n  display: flex;\n  flex-direction: column;\n  flex: 1 1 auto;\n  width: 350px;\n  min-width: 350px;\n  max-width: 350px;\n  z-index: 99;\n  .mat-mdc-slide-toggle-content {\n    flex: 1;\n  }\n  .mat-mdc-tab {\n    min-width: 20px !important;\n    flex: 1 !important;\n    padding: 0 !important;\n    height: 81px !important;\n    margin-bottom: -13px !important;\n  }\n\n.timeline-sidebar {\n\n    padding-left: 10px;\n    padding-right: 15px;\n    .latest-activity {\n\n        .content {\n\n            .activities {\n                .activity {\n                    padding: 16px 0;\n                    cursor: pointer;\n\n                    .avatar {\n                        margin-right: 16px;\n                    }\n\n                    .username {\n                        font-weight: 600;\n                    }\n\n                    .message {\n                        font-weight: 200;\n                    }\n                }\n            }\n        }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
