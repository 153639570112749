import { Component, OnInit, ViewEncapsulation, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { DropdownsService } from 'app/services';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'mls-request',
    templateUrl: './mls-request.component.html',
    styleUrls: ['./mls-request.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MlsRequestComponent implements OnInit, OnDestroy {

    request: any;
    form: UntypedFormGroup;
    private _unsubscribeAll: Subject<any> = new Subject();
    sharingLevels: any;

    constructor(
        public matDialogRef: MatDialogRef<MlsRequestComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: UntypedFormBuilder,
        private _dropdownsService: DropdownsService,
    ) {
        this.sharingLevels = [];
        
        this.request = this._data;

        this.form = this._formBuilder.group({
            sharing_level: [''],
            share_what: [''],
            agency_to_obj: [''],
            message: [''],
        });
    }

    ngOnInit(): void {
        this._dropdownsService.getSharingLevels()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data: any) => {
                this.sharingLevels = data.body;
            });

    }
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

}
