import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UserService, AuthenticationService } from 'app/services';
import { TranslateService } from '@ngx-translate/core';
import { includes } from 'lodash';

@Component({
    selector: 'search-panel',
    templateUrl: './search-panel.component.html',
    styleUrls: ['./search-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SearchPanelComponent implements OnInit {

    searchItems = [];
    totalSearched = 0;
    currentLang: any;
    currentAgency: any;
    currentUser: any;
    show: boolean;

    constructor(
        private _userService: UserService,
        public _translateService: TranslateService,
        public _authenticationService: AuthenticationService
    ) {
        this.currentLang =  this._translateService.currentLang;
        this.currentAgency = this._authenticationService.currentAgencyValue
        this.currentUser = this._authenticationService.currentUserValue;
     }

    ngOnInit(): void {
        this._userService.appSearchData.subscribe((data) => {
            this.searchItems = data;
            this.totalSearched = data.length;
        });
    }

    isAssignedUser(item) {
        if (this.currentUser.user_role == 'agent' || this.currentUser.user_role == 'agent_medium' || this.currentUser.user_role == 'admin_agent_light' ) {
            if (item.model =='accounts' || item.model =='owners') {
                if(Array.from(item.assigned_to).indexOf(this.currentUser.user_name) >= 0) {
                    return true;
                } else {
                    return false;
                }
            }
        } else {
            return false;
        }
        
    }
    checkTime(time: any): boolean {
        if (includes(time, 'T')) {
            return true;
        } else {
            return false;
        }
    }
}
