import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { Offices } from './../models/offices';

@Injectable({
  providedIn: 'root'
})
export class OfficesService {

  constructor(
    private http: HttpClient,
  ) { }

  getAll(data: any, page: any, perPage?: any): Observable<Offices[]> {
    perPage = perPage ? perPage : '';
    return this.http.post<Offices[]>(`${environment.yiiUrl}offices/search?page=${page}&per-page=${perPage}`, data, { observe: 'response' as 'body' })
      .pipe(map(offices => {
        return offices;
      }));
  }

  getById(_id: string): Observable<object> {
    return this.http.get(`${environment.yiiUrl}offices/${_id}`);
  }

  getByIds(_ids: any): Observable<Offices[]> {
    return this.http.post<Offices[]>(`${environment.yiiUrl}offices/offices-by-ids`, _ids, { observe: 'response' as 'body' });
  }

  create(offices: Offices): Observable<object> {
    return this.http.post(`${environment.yiiUrl}offices`, offices);
  }

  update(offices: Offices): Observable<object> {
    return this.http.put(`${environment.yiiUrl}offices/${offices._id}`, offices);
  }

  delete(_id: number, createdBy: any): Observable<object> {
    return this.http.delete(`${environment.yiiUrl}offices/${_id}?created_by=${createdBy}`);
  }

  officesSearch(search: string): Observable<Offices[]> {
    return this.http.get<Offices[]>(`${environment.yiiUrl}offices?search=` + search + `&dd=true&per-page=false`);
  }

  uploadOfficeLogo(id: string, data: any, model: any): Observable<any> {
    const formData: any = new FormData();
    formData.append('id', id);
    formData.append('model', model);
    formData.append('file', data);
    return this.http.post(`${environment.yiiUrl}users/upload-single`, formData);
  }

  getOfficeName(filter: any): Observable<object> {
    return this.http.post(`${environment.yiiUrl}offices/names`, filter);
  }
}
