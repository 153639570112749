import { Component, OnInit, ViewEncapsulation, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { StripeElements, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeService } from 'ngx-stripe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'app/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
    selector: 'app-subscribtion',
    templateUrl: './subscribtion.component.html',
    styleUrls: ['./subscribtion.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SubscribtionComponent implements OnInit, OnDestroy {

    elements: StripeElements;
    card: any;        //should be cardElement type but it is not defined in ngx-stripe package so we use any
    elementsOptions: StripeElementsOptions = {
        locale: 'auto'
    };
    private _unsubscribeAll: Subject<any>;
    loader: boolean;

    constructor(
        public dialogRef: MatDialogRef<SubscribtionComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        private stripeService: StripeService,
        private _translateService: TranslateService,
        private _snackBar: MatSnackBar,
        private _userService: UserService,
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {

        this.stripeService.elements(this.elementsOptions)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(elements => {
                this.elements = elements;
                // Only mount the element the first time
                if (!this.card) {
                    this.card = this.elements.create('card', {      //creating card element
                        style: {
                            base: {
                                'iconColor': '#666EE8',
                                'color': '#31325F',
                                'lineHeight': '40px',
                                'fontWeight': 300,
                                'fontFamily': '"Helvetica Neue", Helvetica, sans-serif',
                                'fontSize': '18px',
                                '::placeholder': {
                                    color: '#CFD7E0'
                                }
                            }
                        }
                    });
                    this.card.mount('#card-element');
                }
            });

    }

    buy(): void {
        this.loader = true;
        this.stripeService.createToken(this.card, {})
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                if (result.token) {
                    this.data.token = result.token;
                    this._userService.subscribeUser(this.data)
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe((data: any) => {
                            this.loader = false;
                            this.dialogRef.close(data);
                        });
                } else if (result.error) {
                    this.loader = false;
                    this._snackBar.open(this._translateService.instant(result.error.message), this._translateService.instant('Close'), {
                        duration: 2000,
                    });
                }
            });
    }


    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

}
