import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Applications',
        translate: 'Applications',
        type: 'group',
        children: [
            // {
            //     id: 'sample',
            //     title: 'Sample',
            //     translate: 'Sample',
            //     type: 'item',
            //     icon: 'email',
            //     url: '/sample',
            //     badge: {
            //         title: '25',
            //         translate: '25',
            //         bg: '#F44336',
            //         fg: '#FFFFFF'
            //     }
            // },
            // {
            //     id: 'dashboard',
            //     title: 'Dashboard',
            //     translate: 'Dashboard',
            //     type: 'item',
            //     icon: 'dashboard',
            //     url: '/dashboard',
            // },
            // {
            //     id: 'activities',
            //     title: 'Activities',
            //     translate: 'Activities',
            //     type: 'collapsable',
            //     icon: 'assignment',
            //     children: [
            //         {
            //             id: 'meetings',
            //             title: 'Meetings',
            //             translate: 'Meetings',
            //             type: 'item',
            //             url: '/meetings',
            //         },
            //         {
            //             id: 'tasks',
            //             title: 'Tasks',
            //             translate: 'Tasks',
            //             type: 'item',
            //             url: '/tasks',
            //         },
            //         {
            //             id: 'viewingTours',
            //             title: 'Viewing tours',
            //             translate: 'Viewing tours',
            //             type: 'item',
            //             url: '/viewings',
            //         },
            //         {
            //             id: 'offers',
            //             title: 'Offers',
            //             translate: 'Offers',
            //             type: 'item',
            //             url: '/offers',
            //         },
            //         {
            //             id: 'sales',
            //             title: 'Sales',
            //             translate: 'Sales',
            //             type: 'item',
            //             url: '/sales',
            //         },
            //     ]
            // },
            // {
            //     id: 'invoices',
            //     title: 'Invoices',
            //     translate: 'Invoices',
            //     type: 'item',
            //     icon: 'shopping_basket',
            //     url: '/invoices',
            // },
            // {
            //     id: 'master-data',
            //     title: 'Master data',
            //     translate: 'Master data',
            //     type: 'collapsable',
            //     icon: 'apps',
            //     children: [
            //         {
            //             id: 'accounts',
            //             title: 'Accounts',
            //             translate: 'Accounts',
            //             type: 'item',
            //             url: '/accounts',
            //         },
            //     ]
            // },
            // {
            //     id: 'system-configuration',
            //     title: 'System configuration',
            //     type: 'collapsable',
            //     icon: 'import_contacts',
            //     children: [
            //         {
            //             id: 'agencies',
            //             title: 'Agencies',
            //             translate: 'Agencies',
            //             type: 'item',
            //             url: '/agencies',
            //         },
            //         {
            //             id: 'users',
            //             title: 'Agency users',
            //             translate: 'Agency users',
            //             type: 'item',
            //             url: '/users',
            //         },
            //         {
            //             id: 'offices',
            //             title: 'Offices',
            //             translate: 'Offices',
            //             type: 'item',
            //             url: '/offices',
            //         },
            //         {
            //             id: 'languages',
            //             title: 'Languages management',
            //             translate: 'Languages management',
            //             type: 'item',
            //             url: '/languages',
            //         },
            //         {
            //             id: 'nationalities',
            //             title: 'Nationalities',
            //             type: 'item',
            //             url: '/nationalities',
            //         },
            //         {
            //             id: 'countries',
            //             title: 'Countries',
            //             type: 'item',
            //             url: '/countries',
            //         },
            //         {
            //             id: 'regions',
            //             title: 'Regions',
            //             type: 'item',
            //             url: '/regions',
            //         },
            //         {
            //             id: 'provinces',
            //             title: 'Provinces',
            //             type: 'item',
            //             url: '/provinces',
            //         },
            //         {
            //             id: 'cities',
            //             title: 'Cities',
            //             type: 'item',
            //             url: '/cities',
            //         },
            //         {
            //             id: 'locations',
            //             title: 'Location management',
            //             type: 'item',
            //             url: '/locations',
            //         },
            //         {
            //             id: 'locationgroups',
            //             title: 'Location groups',
            //             type: 'item',
            //             url: '/locationgroups',
            //         },
            //         {
            //             id: 'property-types',
            //             title: 'Residential types',
            //             type: 'item',
            //             url: '/property-types',
            //         },
            //         {
            //             id: 'commercial_types',
            //             title: 'Commercial types',
            //             type: 'item',
            //             url: '/commercial_types',
            //         },
            //         {
            //             id: 'room-types',
            //             title: 'Room types',
            //             type: 'item',
            //             url: '/room-types',
            //         },
            //         {
            //             id: 'property_categories',
            //             title: 'Property categories',
            //             type: 'item',
            //             url: '/pcategories',
            //         },
            //         {
            //             id: 'building-styles',
            //             title: 'Building styles',
            //             type: 'item',
            //             url: '/buildingstyles',
            //         },
            //         {
            //             id: 'sharing-levels',
            //             title: 'MLS sharing levels',
            //             type: 'item',
            //             url: '/sharing-levels',
            //         },
            //         {
            //             id: 'project-license-types',
            //             title: 'Project license types',
            //             type: 'item',
            //             url: '/license_types',
            //         },
            //         {
            //             id: 'property-models',
            //             title: 'Property models',
            //             type: 'item',
            //             url: '/property_models',
            //         },
            //         {
            //             id: 'master-data-types',
            //             title: 'Master data types',
            //             type: 'item',
            //             url: '/mdtypes',
            //         },
            //         {
            //             id: 'accleadstatuses',
            //             title: 'Account lead statuses',
            //             type: 'item',
            //             url: '/leadstatuses',
            //         },
            //         {
            //             id: 'ownleadstatuses',
            //             title: 'Owner lead statuses',
            //             type: 'item',
            //             url: '/owner-lead-statuses',
            //         },
            //         {
            //             id: 'document-types',
            //             title: 'Document types',
            //             type: 'item',
            //             url: '/doctypes',
            //         },
            //         {
            //             id: 'meeting-types',
            //             title: 'Meeting types',
            //             type: 'item',
            //             url: '/meeting-types',
            //         },
            //         {
            //             id: 'task_types',
            //             title: 'Activity categories',
            //             type: 'item',
            //             url: '/task_types',
            //         },
            //         {
            //             id: 'task_groups',
            //             title: 'Activity groups',
            //             type: 'item',
            //             url: '/task_groups',
            //         },
            //         {
            //             id: 'sources',
            //             title: 'Sources management',
            //             type: 'item',
            //             url: '/sources',
            //         },
            //         {
            //             id: 'encumbrances',
            //             title: 'Encumbrances',
            //             type: 'item',
            //             url: '/encumbrances',
            //         },
            //         {
            //             id: 'extras',
            //             title: 'Common extras rentals',
            //             type: 'item',
            //             url: '/extras',
            //         },
            //         {
            //             id: 'cleaning',
            //             title: 'Cleaning management',
            //             type: 'item',
            //             url: '/cleaning',
            //         },
            //         {
            //             id: 'wo_types',
            //             title: 'Work order types',
            //             type: 'item',
            //             url: '/wo_types',
            //         },
            //         {
            //             id: 'tourist_categories',
            //             title: 'Tourist categories',
            //             type: 'item',
            //             url: '/tourist_categories',
            //         },
            //         {
            //             id: 'payment_methods',
            //             title: 'Payment methods',
            //             type: 'item',
            //             url: '/payment_methods',
            //         },
            //         {
            //             id: 'system-messages',
            //             title: 'System messages',
            //             type: 'item',
            //             url: '/system-messages',
            //         },
            //         {
            //             id: 'faqs',
            //             title: 'FAQ\'s',
            //             type: 'item',
            //             url: '/faqs',
            //         },
            //         {
            //             id: 'translations',
            //             title: 'Translations',
            //             type: 'item',
            //             url: '/translations',
            //         },
            //         {
            //             id: 'crm_menu',
            //             title: 'Crm menu',
            //             type: 'item',
            //             url: '/crm-menu',
            //         },
            //         {
            //             id: 'crm_languages',
            //             title: 'Crm languages',
            //             type: 'item',
            //             url: '/crm-languages',
            //         },
            //         {
            //             id: 'documentations',
            //             title: 'Technical documentation',
            //             type: 'item',
            //             url: '/documentations',
            //         }
            //     ]
            // },
        ]
    }
];
