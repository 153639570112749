import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'getPropertyTitle'
})
export class GetPropertyTitlePipe implements PipeTransform {

  transform(property: any, locale?: any): any {
    const lang = locale === 'es_AR' ? 'ES' : 'EN';
    let title = '';
    if (property.title && property.title[lang]) {
      title = property.title[lang];
    }
    if (!title && property.title && property.title.EN) {
      title = property.title.EN;
    }
    return title;
  }

}
