import { Directive, HostListener, Input } from '@angular/core';
import { JsonViewerComponent } from './json-viewer/json-viewer.component';
import { MatDialog } from '@angular/material/dialog';

@Directive({
  selector: '[appJsonViewer]'
})
export class JsonViewerDirective {

  @Input() data: any;
  @Input() current_data: any;
  constructor(
    public _matDialog: MatDialog
  ) { }

  @HostListener('click') onClick(): void {
    const dialogRef = this._matDialog.open(JsonViewerComponent, {
      disableClose: true,
      panelClass: 'json-compose-dialog',
      data: {
        curr_data: this.current_data ? this.current_data : '',
        pre_data: this.data
      }
    });
    dialogRef.afterClosed()
      .subscribe(response => {
      });
  }

}
