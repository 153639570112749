import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-renew-subscription',
  templateUrl: './renew-subscription.component.html',
  styleUrls: ['./renew-subscription.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RenewSubscriptionComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: any,
  ) { }

  ngOnInit(): void { }

}
