import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class LanguagesService implements Resolve<any[]>{

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    return this.http.get<any[]>(`${environment.yiiUrl}languages/for-system`);
  }

  getData(filter: any, page: number, perPage?: number): Observable<any[]> {
    return this.http.post<any[]>(`${environment.yiiUrl}languages/search?get_all=true&page=` + page + `&per-page=${perPage}`, filter, { observe: 'response' as 'body' })
      .pipe(map(data => {
        return data;
      }));
  }

  createData(data: any): Observable<object> {
    return this.http.post(`${environment.yiiUrl}languages`, data);
  }

  updateData(data: any): Observable<object> {
    return this.http.put(`${environment.yiiUrl}languages/${data._id}`, data);
  }

  deleteData(_id: any, createdBy: any): Observable<any> {
    return this.http.delete(`${environment.yiiUrl}languages/${_id}?created_by=${createdBy}`);
  }

  getListData(filter: any): Observable<object> {
    return this.http.post<any[]>(`${environment.apiUrl}languages`, filter);
  }

  getLanguages(): Observable<object> {
    return this.http.get<any[]>(`${environment.yiiUrl}languages/for-user`, { observe: 'response' as 'body' });
  }

  getLanguagesForSystem(): Observable<object> {
    return this.http.get<any[]>(`${environment.yiiUrl}languages/for-system`);
  }

  getAgencyLanguages(id: any): Observable<object> {
    return this.http.get<any[]>(`${environment.yiiUrl}languages/get-agency-languages?agency=${id}`);
  }

  getModel(): Observable<object> {
    return this.http.get<any>(`${environment.yiiUrl}agencies/get-exclusions?model=languages`);
  }

  updateModel(data: any): Observable<object> {
    return this.http.post<any>(`${environment.yiiUrl}agencies/model-exclusions`, data);
  }

  getAllModel(): Observable<object> {
    return this.http.get<any>(`${environment.yiiUrl}agencies/is-all-check?model=languages`);
  }

  updateAllModel(data: any): Observable<object> {
    return this.http.post<any>(`${environment.yiiUrl}agencies/model-all-exclusions`, data);
  }
}
