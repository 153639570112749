import { Component, OnInit, ViewEncapsulation, Input, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { Subject, merge } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';;
import { startWith, tap, takeUntil, switchMap } from 'rxjs/operators';
import { HistoryService } from 'app/services';

@Component({
  selector: 'app-offers-history',
  templateUrl: './offers-history.component.html',
  styleUrls: ['./offers-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class OffersHistoryComponent implements OnInit, OnDestroy {

  @Input() id: any;
  @Input() relatedTo: any;
  displayedColumns = ['property', 'account', 'listing_agent', 'listing_agency', 'completion_date', 'created_at', 'status', 'action'];
  offers = [] as any;
  loadingResults: boolean;

  private _unsubscribeAll: Subject<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private _historyService: HistoryService,
    private router: Router,
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith(''),
        tap(() => this.loadingResults = true),
        takeUntil(this._unsubscribeAll),
        switchMap(() => {
          let active: any;
          let direction: any;
          if (this.sort.active && this.sort.direction) {
            active = this.sort.active;
            direction = (this.sort.direction === 'asc' ? 'SORT_ASC' : 'SORT_DESC');
          }
          return this._historyService.getOffers(this.id, this.paginator.pageIndex + 1, active, direction);
        })
      )
      .subscribe((data: any) => {
        this.loadingResults = false;
        this.offers.data = data.body;
        this.offers.total = data.headers.get('x-pagination-total-count');
        this.offers.limit = data.headers.get('x-pagination-per-page');
      }, () => this.loadingResults = false);
  }

  getStatus(offer: any, cOffer: any): any {

    if (offer.length === 0) {
      return 'Open';
    } else {
      if (offer[offer.length - 1].status === undefined || offer[offer.length - 1].status === '') {
        return 'Offer open';
      }
      if (offer[offer.length - 1].status === 'Rejected') {
        return 'Offer rejected';
      }
      if (offer[offer.length - 1].status === 'Accepted') {
        return 'Offer accepted';
      }
      if (offer[offer.length - 1].status === 'Countered') {
        if (cOffer.length === 0) {
          return 'Counter open';
        } else {
          if (cOffer[cOffer.length - 1].status === undefined || cOffer[cOffer.length - 1].status === '') {
            return 'Counter open';
          }
          if (cOffer[cOffer.length - 1].status === 'Rejected') {
            return 'Counter rejected';
          }
        }
      }
    }
  }

  viewOffer(_id: any): void {
    this.router.navigate(['/activities/offers/view/' + _id]);
  }

  editOffer(_id: any): void {
    this.router.navigate(['/activities/offers/update/' + _id]);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }


}
