import { Component, OnInit, ViewEncapsulation, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { isEmpty, remove, map, forEach, concat, split, last, pullAt, find, cloneDeep } from 'lodash';
import { ViewingToursService } from 'app/services/viewing-tours.service';
import { AuthenticationService, DropdownsService, CountriesService, ProvincesService, CitiesService, LanguagesService } from 'app/services';
// import { SignaturePad } from 'ngx-signaturepad/signature-pad';
import { NgxSignaturePadComponent, NgxSignatureOptions } from '@eve-sama/ngx-signature-pad';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Observable, Subject, forkJoin, combineLatest } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';
import { ComposeEmailsService } from 'app/services/compose-emails.service';
import { TranslateService } from '@ngx-translate/core';
import { AttachmentsService } from 'app/services/attachments.service';
import { DatePipe } from '@angular/common';
// import * as moment from 'moment';
import moment from 'moment-timezone';
import { environment } from 'environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BaseService } from 'app/_helpers/base/base.service';
import { GLOBALS } from 'app/config/globals';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-viewing-sheet',
    templateUrl: './viewing-sheet.component.html',
    styleUrls: ['./viewing-sheet.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [DatePipe]
})
export class ViewingSheetComponent implements OnInit, AfterViewInit {

    name = 'Hello angular inline input';
    cost = 'Click to type';
    val = 100;
    viewing: any;
    property: any;
    windowCard: any;
    userAgencyData: any;
    user: any;

    separatorKeysCodes: number[] = [ENTER, COMMA];
    pdf = false as boolean;
    mail: UntypedFormGroup;
    emails: string[] = [];
    emailsCtrl = new UntypedFormControl('');
    templatesCtrl = new UntypedFormControl('');
    filteredEmials: Observable<string[]>;
    filteredTemplates: Observable<any>;
    attachments: string[] = [];

    CardTextEN: any;
    CardTextES: any;
    CardFooterTextEN: any;
    CardFooterTextES: any;

    editAddress = {};
    editTime = {};
    editAssigneeUser = false;
    editTextEn = false;
    editTextEs = false;
    editFooterTextEn = false;
    editFooterTextEs = false;
    editFooterText = {} as any;
    editText = {} as any;
    public tinyMce = GLOBALS.tinyMce; // To use centralized tinyMce
    public loaders = { ...GLOBALS.loaders }; // To display/hide loading spinner
    loadingResults: boolean;


    // @ViewChild('signaturePadOne', { static: true }) signaturePadOne: SignaturePad;
    // @ViewChild('signaturePadTwo', { static: true }) signaturePadTwo: SignaturePad;
    @ViewChild('signature') signature: NgxSignaturePadComponent;


    private _unsubscribeAll: Subject<any>;

    public signaturePadOptions : NgxSignatureOptions = {
        minWidth: 0.5,
        dotSize: 0.5,
        velocityFilterWeight: 0.5,
        minDistance: 0.5,
        throttle: 0.5,
        penColor: 'rgb(0, 0, 0)',
        backgroundColor: 'wheat',
        // canvasWidth: 300,
        // canvasHeight: 200,
        width: 300,
        height: 200,
    };
    systemLanguages: any;
    currentLang: string;

    constructor(
        public matDialogRef: MatDialogRef<ViewingSheetComponent>,
        private _viewingService: ViewingToursService,
        private _authenticationService: AuthenticationService,
        private _dropdownsService: DropdownsService,
        private formBuilder: UntypedFormBuilder,
        private datePipe: DatePipe,
        private _attachmentsService: AttachmentsService,
        public _composeEmailsService: ComposeEmailsService,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService,
        private _countriesService: CountriesService,
        private _provincesService: ProvincesService,
        private _citiesService: CitiesService,
        private _languagesService: LanguagesService,
        private _gService: BaseService,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private router: Router,
    ) {
        this._unsubscribeAll = new Subject();
        this.user = this._authenticationService.currentUserValue;
        this.userAgencyData = this._authenticationService.currentAgencyValue;
        this.viewing = _data;
        this.currentLang = this._translateService.currentLang === 'es_AR' ? 'es' : this._translateService.currentLang;

        this._languagesService.getLanguagesForSystem()
            .pipe(
                takeUntil(this._unsubscribeAll)
            )
            .subscribe((data: any) => {
                this.systemLanguages = data;
            });
        if (this.viewing.list.length && this.viewing.list[0].property) {
            this.property = this.viewing.list[0].property;
        }
    }

    ngOnInit(): void {
        if (this.user.user_office_data) {
            if (this.user.user_office_data.country) {
                this.getCountry(this.user.user_office_data.country);
            }
            if (this.user.user_office_data.province) {
                this.getProvince(this.user.user_office_data.province);
            }
            if (this.user.user_office_data.city) {
                this.getCity(this.user.user_office_data.city);
            }
        }
        if (!isEmpty(this.viewing)) {
            this.opnList();
        }

        this.mail = this.formBuilder.group({
            to: (''),
            files: (''),
            from: { value: this.user.user_email, disabled: true },
            zip_attachment: (false),
            subject: (''),
            content: (''),
            cc: (''),
            bcc: ('')
        });

        this.filteredEmials = this.emailsCtrl.valueChanges.pipe(
            startWith(''),
            switchMap(() => {
                return this._dropdownsService.getUserEmails(this.emailsCtrl.value);
            }),
        );

        this.filteredTemplates = this.templatesCtrl.valueChanges.pipe(
            startWith(''),
            switchMap(() => {
                return this._dropdownsService.getEmailsTemplates(this.templatesCtrl.value, undefined);
            }),
        );
    }

    getCountry(key): any {
        const filter = {
            options: {
                limit: 30,
                page: 1
            },
            query: {
                key: key
            }
        };
        this._countriesService.getData(filter)
            .subscribe((data: any) => {
                this.user.user_office_data.country_name = data.docs[0].value[this._translateService.currentLang];
            });
    }

    getProvince(key): any {
        this._provincesService.getData({ query: { key: key } })
            .subscribe((data: any) => {
                this.user.user_office_data.province_name = data.docs[0].value[this._translateService.currentLang];
            });
    }

    getCity(key): any {
        const filter = {
            options: {
                limit: 30,
                page: 1
            },
            query: {
                key: key
            }
        };
        this._citiesService.getData(filter)
            .subscribe((data: any) => {
                this.user.user_office_data.city_name = data.docs[0].value[this._translateService.currentLang];
            });
    }

    ngAfterViewInit(): void {
        // this.signaturePadOne.fromData(this.windowCard.signature_client);
        // this.signaturePadTwo.fromData(this.windowCard.signature_agent);
    }

    drawComplete(signaturePad: NgxSignaturePadComponent, type: any, index?: any): void {
        if (type === 'client') {
            this.windowCard.signature_client = signaturePad.toDataURL();
        }
        if (type === 'agent') {
            this.windowCard.signature_agent = signaturePad.toDataURL();
        }
        if (type === 'list') {
            this.windowCard.list[index].signature = signaturePad.toDataURL();
        }
    }

    clear(signaturePad: NgxSignaturePadComponent): void {
        signaturePad.clear();
    }

    opnList(): void {
        this.windowCard = this.viewing;
        let requests = [];
        if (this.viewing.agency) {
            requests = [...requests, this._viewingService.getViewingAgencies(this.viewing.agency)];
        }
        if (this.viewing.offices) {
            requests = [...requests, this._viewingService.getViewingOffices(this.viewing.offices)];
        }

        if (this.windowCard.list) {
            forEach(this.windowCard.list, (list: any) => {
                if (list.property) {
                    if(this.windowCard.viewing_for == 'Moorings' || this.windowCard.viewing_for == 'Boats'){
                        const filter: any = { param: { boat: this.router.url.split('/').includes("boats") ? true : false } };
                        requests = [...requests, this._gService.post(`mooring_properties/view/${list.property}`,filter,'nodejs')];
                    }else{
                        requests = [...requests, this._viewingService.getOnlyProperty(list.property)];
                    }
                }
            });
        }

        combineLatest(requests)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data: any) => {
                const agencyData = data[0];
                const officeData = data[1];
                this.windowCard.agency_data = agencyData.body;
                if (this.windowCard.agency_data && !this.windowCard.agency_data.logo){
                    this.windowCard.agency_data = this.userAgencyData;
                }
                this.windowCard.office_data = officeData.body;
                if (!this.windowCard.office_data || this.windowCard.office_data === 'undefined' && this.user.user_office_data){
                    this.windowCard.office_data = this.user.user_office_data;
                }
                pullAt(data, [0, 1]);
                const wcList = cloneDeep(this.windowCard.list);
                forEach(wcList, (list: any, key) => {
                    if (list.property) {
                        const property = find(data, (d: any) => d._id === list.property);
                        if (property) {
                            list.reference = property.reference;
                            list.external_reference = property.external_reference;
                            list.agency_reference = property.agency_reference;
                            if (moment(list.time).isValid()) {
                                list.time = this.datePipe.transform(list.time, 'H:mm');
                            }
                            if (property.private_info_object && property.private_info_object[this.userAgencyData._id]) {
                                const privateInfoObject = property.private_info_object[this.userAgencyData._id];
                                list.property_address = privateInfoObject.address ? privateInfoObject.address.formatted_address : '';
                                list.lat = privateInfoObject.latitude;
                                list.lng = privateInfoObject.longitude;
                            }
                        }
                    }
                });
                this.windowCard.list = wcList;
                this.initializeLanguages();
            });
    }

    setCardData(): void {

        const assigneeUser = this.windowCard.assignee_user ? this.windowCard.assignee_user : '';
        const officeName = this.windowCard.office_data ? this.windowCard.office_data.name : '';
        const agencyAddress = this.windowCard.agency_data.complete_address ? this.windowCard.agency_data.complete_address.formatted_address : '';
        // tslint:disable-next-line: max-line-length
        this.CardTextEN = `I have asked for information about the properties listed above and I agree that ${assigneeUser} as a representative of ${officeName} will accompany us to visit the said properties without any commitment. I hereby confirm that this is the first time that I have visited the said properties listed above. In case I am interested in purchasing or renting any of these properties, I agree to do so using the services of ${officeName}. The validity period for this document is one year from the date shown above.`;
        // tslint:disable-next-line: max-line-length
        this.CardTextES = `He solicitado información sobre las propiedades mencionadas anteriormente y estoy de acuerdo que ${assigneeUser} como representante de ${officeName} me acompañe a ver dichas propiedades sin ningún compromiso. Confirmo que esta es la primera vez que he visto dichas propiedades señaladas anteriormente. En el caso de que alguna de ellas me interesara, renuncio expresamente a realizar la operación de compraventa o alquiler de forma personal y sin la intervención de ${officeName} durante el periodo de un año  a partir de la fecha mencionada anteriormente, que también es el periodo de vigencia para la presente nota.`;
        // tslint:disable-next-line: max-line-length
        this.CardFooterTextEN = `In accordance with Article 5 of the Organic Law 15/1999 regarding Personal Data Protection (LOPD), ${officeName} informs you that all personal data that you supply will be registered in an automated file with the purpose of managing the buying, selling and/or renting of real estate properties, billing, creating customer loyalty and sending you advertising, including via email. Additionally, your details may be sent to other estate agents with the sole purpose of managing the buying, selling, and/or renting of your property. You can exert your right of access to, rectification, cancellation or opposition of your personal data by writing to ${officeName}, ${agencyAddress}.`;
        // tslint:disable-next-line: max-line-length
        this.CardFooterTextES = `En cumplimiento de la Ley Orgánica de Protección de Datos 15/1999 le informamos que sus datos personales serán incorporados a un fichero automatizado de ${officeName} con la finalidad de gestionarle la compra, la venta y/o alquiler de propiedades inmobiliarias, facturarle, fidalizarle y enviarle publicidad inclusa por medios electrónicos. Adicionalmente sus datos podrán ser enviados a otras agencias inmobiliarias colaboradoras con el fin de afrontar la compra, venta y/o alquiler de inmuebles. Los derechos de acceso, rectificación, cancelación y oposición podrán ser ejercidos dirigiéndose por escrito a  ${officeName}, ${agencyAddress}.`;

        if (typeof this.userAgencyData !== 'undefined' && this.userAgencyData !== '') {
            if (typeof this.userAgencyData.viewing !== 'undefined' && this.userAgencyData.viewing !== '') {
                const viewingData = this.userAgencyData.viewing;
                if (typeof viewingData.text_en !== 'undefined' && viewingData.text_en !== '') {
                    const filterData = {
                        content: viewingData.text_en
                    };
                    // tslint:disable-next-line: max-line-length
                    this._gService.post(`accounts/preview-email?activity_id=${this.viewing._id}&account=${this.viewing.account}&property_id=${this.property}`, filterData, 'Yii').subscribe((data: any) => {
                        this.windowCard.text_en = data;
                    });
                } else {
                    this.windowCard.text_en = this.CardTextEN;
                }
                if (typeof viewingData.text_es !== 'undefined' && viewingData.text_es !== '') {
                    const filterData = {
                        content: viewingData.text_es
                    };
                    // tslint:disable-next-line: max-line-length
                    this._gService.post(`accounts/preview-email?activity_id=${this.viewing._id}&account=${this.viewing.account}&property_id=${this.property}`, filterData, 'Yii').subscribe((data: any) => {
                        this.windowCard.text_es = data;
                    });
                } else {
                    this.windowCard.text_es = this.CardTextES;
                }
                if (typeof viewingData.footer_text_en !== 'undefined' && viewingData.footer_text_en !== '') {
                    const filterData = {
                        content: viewingData.footer_text_en
                    };
                    // tslint:disable-next-line: max-line-length
                    this._gService.post(`accounts/preview-email?activity_id=${this.viewing._id}&account=${this.viewing.account}&property_id=${this.property}`, filterData, 'Yii').subscribe((data: any) => {
                        this.windowCard.footer_text_en = data;
                    });
                } else {
                    this.windowCard.footer_text_en = this.CardFooterTextEN;
                }
                if (typeof viewingData.footer_text_es !== 'undefined' && viewingData.footer_text_es !== '') {
                    const filterData = {
                        content: viewingData.footer_text_es
                    };
                    // tslint:disable-next-line: max-line-length
                    this._gService.post(`accounts/preview-email?activity_id=${this.viewing._id}&account=${this.viewing.account}&property_id=${this.property}`, filterData, 'Yii').subscribe((data: any) => {
                        this.windowCard.footer_text_es = data;
                    });
                } else {
                    this.windowCard.footer_text_es = this.CardFooterTextES;
                }
            }
        }
    }

    initializeLanguages(): void {
        let viewingData: any;
        if (typeof this.userAgencyData !== 'undefined' && this.userAgencyData !== '') {
            if (typeof this.userAgencyData.viewing !== 'undefined' && this.userAgencyData.viewing !== '') {
                viewingData = this.userAgencyData.viewing;
            }
        }
        forEach(this.systemLanguages, (lang) => {
            this.windowCard['text_' + lang.key.toLowerCase()] = '';
            this.windowCard['footer_text_' + lang.key.toLowerCase()] = '';
            this.editFooterText[lang.key.toLowerCase()] = false;
            this.editText[lang.key.toLowerCase()] = false;
            if (viewingData) {
                if (typeof viewingData['text_' + lang.key.toLowerCase()] !== 'undefined' && viewingData['text_' + lang.key.toLowerCase()] !== '') {
                    this.windowCard['text_' + lang.key.toLowerCase()] = viewingData['text_' + lang.key.toLowerCase()];
                }
                if (typeof viewingData['footer_text_' + lang.key.toLowerCase()] !== 'undefined' && viewingData['footer_text_' + lang.key.toLowerCase()] !== '') {
                    this.windowCard['footer_text_' + lang.key.toLowerCase()] = viewingData['footer_text_' + lang.key.toLowerCase()];
                }
            }
        });
        this.setCardData();
    }

    saveCost(value: any): any {
        if (value[1] === 'CardTextEN') {
            this.windowCard.text_en = value[0];
        } else if (value[1] === 'CardTextES') {
            this.windowCard.text_es = value[0];
        } else if (value[1] === 'CardFooterTextEN') {
            this.windowCard.footer_text_en = value[0];
        } else if (value[1] === 'CardFooterTextES') {
            this.windowCard.footer_text_es = value[0];
        }
    }

    downloadSheet(pdfData: any, sheet: any): void {
        this._viewingService.downloadSheet(pdfData, sheet, this.currentLang).subscribe((data: any) => {
            let popup;
            if (pdfData && pdfData._id && pdfData._id !== 'undefined') {
                popup = window.open(`${environment.cdnUrl}viewing_docs/${pdfData._id}/${data.file_name}`, '_blank');
            } else {
                popup = window.open(`${environment.cdnUrl}viewing_docs/${data.file_name}`, '_blank');
            }
            if (popup == null) {
                window.alert(this._translateService.instant('viewing_sheet_error'));
                return;
            } else {
                return popup;
            }
        });
    }

    addReference(prop: any): void {
        if(!prop?.reference){
            prop.reference = prop?.commercial_reference;
        }
    }

    downloadAndAttach(pdfData: any, sheet: any): void {
        this.loaders.box = true;
        this._viewingService.downloadSheet(pdfData, sheet, this.currentLang, true).subscribe((data: any) => {
            this.pdf = true;
            this.attachments = [data.file_name];
            this.loaders.box = false;
        });
    }

    add(event: MatChipInputEvent): void {
        const value = event.value;
        if ((value || '').trim()) {
            this.emails.push(value.trim());
        }
        
        // Clear the input value
        event.chipInput!.clear();
        
        this.emailsCtrl.setValue('');
    }

    remove(fruit: string): void {
        const index = this.emails.indexOf(fruit);
        if (index >= 0) {
            this.emails.splice(index, 1);
        }
    }

    onSelectEmail(event: MatAutocompleteSelectedEvent): void {
        this.emails.push(event.option.viewValue);
        // this.emailInput.nativeElement.value = '';
        this.emailsCtrl.setValue('');
    }

    onSelectTemplate(event: any): void {
        this.mail.get('subject').setValue(event.subject);
        this.mail.get('content').setValue(event.content);
    }

    removeAttachment(file: any): void {
        remove(this.attachments, (el: any) => {
            return el === file;
        });
    }

    onSelectFile(files: FileList): void {
        const uploader = [];
        forEach(files, (file: File) => {
            uploader.push(this._attachmentsService.mailAttachment(file));
        });
        forkJoin(uploader)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data: any) => {
                const attachments = map(data, (url) => last(split(url, '/')));
                this.attachments = concat(this.attachments, attachments);
            }, () => { });
    }

    closeDialog(action: string): void {
        const emails = map(this.emails, (e) => ({ email: e }));
        this.mail.get('to').setValue(emails);
        this.mail.get('files').setValue(this.attachments);
        // this.matDialogRef.close([action, this.composeForm]);
        if (action === 'send') {
            const data = {mail: this.mail.getRawValue()};
            this._composeEmailsService.sendEmail(data)
                .subscribe((result) => {
                    if (result){
                        this._snackBar.open(this._translateService.instant('Send successfully'), this._translateService.instant('Close'), {
                            duration: 2000,
                        });
                    }
                });

        }
    }

    focusOutFunction(): void {
        if (this.emailsCtrl.value) {
            // this.emails.push(this.emailsCtrl.value);
            const obj = {} as any;
            obj.org_email = this.emailsCtrl.value;
            obj.masked_email = this.emailsCtrl.value;
            this.emails.push(obj);
            // this.emailInput.nativeElement.value = '';
            this.emailsCtrl.setValue('');
        }
    }

}
