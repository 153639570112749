import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isEmpty } from 'lodash';

@Component({
    selector: 'app-account-confirm-modal',
    templateUrl: './account-confirm-modal.component.html',
    styleUrls: ['./account-confirm-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AccountConfirmModalComponent implements OnInit {

    constructor(
        public matDialogRef: MatDialogRef<AccountConfirmModalComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
    ) { }

    ngOnInit(): void {
    }

}
