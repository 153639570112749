import { Component, OnInit, Input, ViewChild, OnDestroy, ViewEncapsulation, EventEmitter } from '@angular/core';
import { Subject, merge } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { HistoryService } from 'app/services';
import { Router } from '@angular/router';
import { startWith, tap, takeUntil, switchMap } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { BookingsCreateModalComponent } from 'app/main/holiday-rentals/bookings/bookings-create-modal/bookings-create-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-bookings-history',
  templateUrl: './bookings-history.component.html',
  styleUrls: ['./bookings-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class BookingsHistoryComponent implements OnInit, OnDestroy {

  @Input() id: any;
  @Input() relatedTo: any;
  displayedColumns = ['property', 'date_from', 'date_until', 'created_at', 'status', 'action'];
  bookings = [] as any;
  loadingResults: boolean;
  reload: EventEmitter<any> = new EventEmitter();

  private _unsubscribeAll: Subject<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private _historyService: HistoryService,
    private _matDialog: MatDialog,
    private router: Router,
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    merge(this.paginator.page, this.reload)
      .pipe(
        startWith(''),
        tap(() => this.loadingResults = true),
        takeUntil(this._unsubscribeAll),
        switchMap(() => {
          return this._historyService.getAccountBookings(this.id, this.paginator.pageIndex + 1);
        })
      )
      .subscribe((data: any) => {
        this.loadingResults = false;
        this.bookings.data = data.body;
        this.bookings.total = data.headers.get('x-pagination-total-count');
        this.bookings.limit = data.headers.get('x-pagination-per-page');
      }, () => this.loadingResults = false);
  }

  viewOffer(_id: any): void {
    this.router.navigate(['/activities/booking/view/' + _id]);
  }
  editOffer(_id: any): void {
    this.router.navigate(['/activities/booking/update/' + _id]);
  }

  updateBooking(id: any): void {
    const dialog = this._matDialog.open(BookingsCreateModalComponent, {
      panelClass: 'booking-form-dialog',
      data: {
        bookingId: id.oid
      }
    });
    dialog.afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((response: any) => {
        if (response) {
          this.reload.next(true);
        }
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
