import { Directive, Input, OnInit, HostListener } from '@angular/core';
import { FormDialogComponent } from './form-dialog/form-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Directive({
    selector: '[customFormly]'
})
export class CustomFormlyDirective implements OnInit {

    @Input() title: string;
    @Input() formFields: any;
    @Input() apiCreate: string;
    @Input() apiUpdate: string;
    @Input() apiGet: string;

    constructor(
        public _matDialog: MatDialog,
    ) {
    }
    ngOnInit(): void {
    }
    @HostListener('click') onClick(): void {
        const dialogRef = this._matDialog.open(FormDialogComponent, {
            disableClose: true,
            panelClass: 'custom-formly-dialog',
            data: {
                title: this.title,
                formFields: this.formFields,
                apiUpdate: this.apiUpdate,
                apiCreate: this.apiCreate,
                apiGet: this.apiGet
            },
        });
    }
}
