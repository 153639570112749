import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { SendgridService } from './../../../services/sendgrid.service';

@Component({
  selector: 'sendgrid-list',
  templateUrl: './sendgrid-list.component.html',
  styleUrls: ['./sendgrid-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SendgridListComponent implements OnInit {

  ids: any = [];
  displayedColumns: string[] = ['name', 'actions'];
  private _unsubscribeAll: Subject<any>;
  loadingResults: boolean;
  owner: boolean;
  sendgridList: any;

  constructor(
    public matDialogRef: MatDialogRef<SendgridListComponent>,
      @Inject(MAT_DIALOG_DATA) public _data: any,
      public _sendgridService: SendgridService,
      private _translateService: TranslateService,
      private _snackBar: MatSnackBar,
      private _toaster: ToastrService
  ) { 
    this.ids = this._data.ids;
    this.owner = this._data.owner;
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.getList();
  }

  getList(): void {
    this.loadingResults = true;
    this._sendgridService.getList(this.ids)
      .pipe(tap(() => this.loadingResults = true),takeUntil(this._unsubscribeAll)).subscribe((data) => {
        this.loadingResults = false;
        if (data && data?.result) {
          this.sendgridList = data.result;
        }
      }, () => this.loadingResults = false);
  }

  addToList(contact: any): void {
    this.loadingResults = true;
    this._sendgridService.addToList(contact.id, this.ids, this.owner).pipe(takeUntil(this._unsubscribeAll)).subscribe((data) => {
      if(data == true) {
        this._snackBar.open(this._translateService.instant('Added successfully'), this._translateService.instant('Close'), {
          duration: 2000,
        });
        this.matDialogRef.close();
      }else {
        this._toaster.error(this._translateService.instant('invalid input or someting went wrong'),this._translateService.instant('Error'));
        this.matDialogRef.close();
      }
    }, () => this.matDialogRef.close());
  }

  removeFromList(contact: any): void {
    this.loadingResults = true;
    this._sendgridService.removeFromList(contact.id, this.ids, this.owner).pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      this._snackBar.open(this._translateService.instant('Removed successfully'), this._translateService.instant('Close'), {
          duration: 2000,
      });
      this.matDialogRef.close();
    }, () => this.matDialogRef.close());
  }

}
