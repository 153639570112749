import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationService } from 'app/services';
import { environment } from 'environments/environment';

@Pipe({
    name: 'getImageSrc'
})
export class GetImageSrcPipe implements PipeTransform {
    currentAgency: any;

    constructor(
        private _authenticationService: AuthenticationService,
    ) {
        this.currentAgency = this._authenticationService.currentAgencyValue;
    }

    transform(model: any, size?: any, showWm?: any, from?: any): any {
        showWm = showWm ? showWm : false;
        let wm = false;
        let agency =  this.currentAgency._id;
        let showOnPdf = false;
        let wmSize = false;
        if (this.currentAgency.watermark_image) {
            if (this.currentAgency.watermark_image.show) {
                wm = true;
                showWm = true;
            }
            if (this.currentAgency.watermark_image.show_onpfd) {
                showOnPdf = true;
            }
            if(this.currentAgency.wm_size && this.currentAgency.wm_size != ''){
                wmSize = this.currentAgency.wm_size;
            }
        }
        if (typeof model === 'string') {
            return environment.cdnUrl + model + '/';
        }
        if(model && model.agency){
            agency = model.agency;
        }
        if (model && model.file_md5_name) {
            let modelID = model.model_id;
            if (typeof model.model_id === 'object') {
                modelID = model.model_id.$oid;
            }
            if (size === 'pdf' && showOnPdf) {
                return environment.cdnUrl + model.model_name + '/' + agency + '/' + modelID + '/2000/' + model.file_md5_name;
            } else if (size === 'pdf' || !size) {
                return environment.cdnUrl + model.model_name + '/' + modelID + '/' + model.file_md5_name;
            } else if (model.document) {
                return environment.cdnUrl + model.model_name + '/' + modelID + '/' + model.file_md5_name;
            } else {
                if (wm && showWm && wmSize && from && from == 'wc') {
                    return environment.cdnUrl + model.model_name + '/' + agency + '/' + wmSize + '/' + modelID + '/' + size + '/' + model.file_md5_name;
                } 
                else if (wm && size > 350 && showWm) {
                    return environment.cdnUrl + model.model_name + '/' + agency + '/' + modelID + '/' + size + '/' + model.file_md5_name;
                } else {
                    if (environment.production) {
                        return environment.cdnUrl + 'resize/' + model.model_name + '/' + modelID + '/' + size + '/' + model.file_md5_name;
                    }
                    else {
                        return environment.cdnUrl + model.model_name + '/' + modelID + '/' + model.file_md5_name;
                    }
                }
            }
        } else {
            return environment.appUrl + 'assets/images/placeholder-image.png'
        }
    }

}
