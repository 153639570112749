import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { BaseService } from 'app/_helpers/base/base.service';

@Component({
  selector: 'app-generate-register-token',
  templateUrl: './generate-register-token.component.html',
  styleUrls: ['./generate-register-token.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GenerateRegisterTokenComponent implements OnInit {

  url: string;
  constructor(
    private _translateService: TranslateService,
    private baseService: BaseService,
    private _snackBar: MatSnackBar,
    public matDialogRef: MatDialogRef<GenerateRegisterTokenComponent>,
  ) { }

  ngOnInit(): void {
  }

  generateURL() {
    this.baseService.get('generate-register-token', 'nodejs')
      .subscribe(resp => {
        this.url = `${environment.appUrl}register?token=${resp.token}`;
      })
  }

  copy() {
    navigator.clipboard.writeText(this.url);
    this._snackBar.open(this._translateService.instant('copy_to_clipboard'), this._translateService.instant('Close'), {
      duration: 2000,
    });
  }

}
