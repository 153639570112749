import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SendgridListComponent } from './sendgrid-list/sendgrid-list.component';

@Directive({
  selector: '[sendgrid]'
})
export class SendgridDirective {

  @Input() AccountIds = [];
  @Input() owner = false;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    public _matDialog: MatDialog
  ) { 
    this.renderer.setStyle(this.el.nativeElement, 'cursor', 'pointer');
  }

  @HostListener('click') onClick(): void {
    const dialogRef = this._matDialog.open(SendgridListComponent, {
      panelClass: 'sendgrid-list-dialog',
      data: {
          ids: this.AccountIds,
          owner: this.owner
      },
  });
  dialogRef.afterClosed()
      .subscribe(response => {
      });
  }
}
