import { Directive, Input, HostListener, ElementRef, EventEmitter, Renderer2, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
// import { MapsAPILoader } from '@agm/core';
import { find, forEach } from 'lodash';
import { CountriesService, ProvincesService, DropdownsService } from 'app/services';

declare var google: any;
@Directive({
  selector: '[appReloadAddress]'
})
export class ReloadAddressDirective implements OnInit {
  @Input() address: UntypedFormControl = new UntypedFormControl('');
  @Input() country: UntypedFormControl;
  @Input() region: UntypedFormControl;
  @Input() city: UntypedFormControl;
  @Input() province: UntypedFormControl;
  @Input() postcode: UntypedFormControl;
  @Input() street: UntypedFormControl;
  // tslint:disable-next-line: variable-name
  @Input() street_number: UntypedFormControl;
  @Input() latitude: UntypedFormControl;
  @Input() longitude: UntypedFormControl;

  @Output() selectAddress: EventEmitter<any> = new EventEmitter();
  private element: HTMLInputElement;

  filter: any;
  countryData: any;
  provinceData: any;
  cityData: any;
  regionData: any;
  private geoCoder: any;
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    // private mapsAPILoader: MapsAPILoader,
    private _countriesService: CountriesService,
    private _dropdownService: DropdownsService,
    private _provincesService: ProvincesService,
  ) {
    this.renderer.setStyle(this.el.nativeElement, 'cursor', 'pointer');
    this.filter = {
      options: { page: 1, limit: 30, sort: {} }
    };
  }
  ngOnInit(): void {
    // this.mapsAPILoader.load().then(() => {
    //   this.geoCoder = new google.maps.Geocoder();
    // });
  }
  @HostListener('click') onClick(): void {
    if (this.latitude.value && this.longitude.value) {
      this.getAddress(this.latitude.value, this.longitude.value);
    }
  }
  getAddress(latitude: any, longitude: any): void {
    this.geoCoder.geocode({ location: { lat: parseFloat(latitude), lng: parseFloat(longitude) } }, (results: any, status: any) => {
      if (status === 'OK') {
        if (results[0]) {
          const address = results[0];
          let countryName: any;
          let cityName: any;
          let ProvinceName: any;
          let postalCode: any;
          let streetName: any;
          let streetNumber: any;
          let regionName: any;

          if (this.country) { this.country.setValue(null); }
          if (this.region) { this.region.setValue(null); }
          if (this.city) { this.city.setValue(null); }
          if (this.province) { this.province.setValue(null); }
          if (this.postcode) { this.postcode.setValue(null); }
          if (this.street) { this.street.setValue(null); }
          if (this.street_number) { this.street_number.setValue(null); }

          postalCode = find(address.address_components, (component: any) => component.types.indexOf('postal_code') > -1);
          streetName = find(address.address_components, (component: any) => component.types.indexOf('route') > -1);
          streetNumber = find(address.address_components, (component: any) => component.types.indexOf('street_number') > -1);
          if (postalCode && this.postcode) { this.postcode.setValue(postalCode.long_name); }
          if (streetName && this.street) { this.street.setValue(streetName.long_name); }
          if (streetNumber && this.street_number) { this.street_number.setValue(streetNumber.long_name); }
          if (address.formatted_address && this.address) { this.address.setValue(address.formatted_address); }
          countryName = find(address.address_components, (component: any) => component.types.indexOf('country') > -1);
          cityName = find(address.address_components, (component: any) => component.types.indexOf('locality') > -1);
          ProvinceName = find(address.address_components, (component: any) => component.types.indexOf('administrative_area_level_2') > -1);
          regionName = find(address.address_components, (component: any) => component.types.indexOf('administrative_area_level_1') > -1);

          /** Calling APi's */
          if (countryName && this.country) {
            this.filter.query = { 'value.en': { $regex: '.*' + countryName.long_name + '.*', $options: 'i' } };
            this._countriesService.getData(this.filter)
              .subscribe((data: any) => {
                if (data.docs[0]) {
                  this.countryData = data.docs[0];
                  this.country.setValue(this.countryData.key);
                }
              });
          }

          if (ProvinceName && this.province) {
            this.filter.query = { 'value.en': { $regex: '.*' + ProvinceName.long_name + '.*', $options: 'i' } };
            this._provincesService.getData(this.filter)
              .subscribe((data: any) => {
                if (data.docs[0]) {
                  this.provinceData = data.docs[0];
                  this.province.setValue(this.provinceData.key);
                }
              });
          }

          if (regionName && this.region) {
            // this.filter.query = { 'value.en': { $regex: '.*' + regionName.long_name + '.*', $options: 'i' } };
            this._dropdownService.getRegions(regionName.long_name)
              .subscribe((data: any) => {
                if (data.docs[0] && this.region) {
                  this.regionData = data.docs[0];
                  this.region.setValue(this.regionData.key);
                }
              });
          }

          if (cityName && this.city) {
            // this.filter.query = { 'value.en': { $regex: '.*' + cityName.long_name + '.*', $options: 'i' } };
            // this.filter.query.country = this.countryData.key;
            this._dropdownService.getCities(cityName.long_name)
              .subscribe((data: any) => {
                if (data.docs[0]) {
                  this.cityData = data.docs[0];
                  if (cityName.long_name === 'Ronda') {
                    forEach(data.docs, (content: any) => {
                      if ((content.value.en === cityName.long_name || content.value.es_AR === cityName.long_name)
                        && content.country === this.country.value) {
                        this.city.setValue(content.key);
                      }
                    });

                  }
                  else {
                    this.city.setValue(this.cityData.key);
                  }
                  if (!this.region.value) {
                    this.region.setValue(this.cityData.region);
                  }
                  if (!this.province.value) {
                    this.province.setValue(this.cityData.province);
                  }
                }
              });
          }



        } else {
          return;
        }
      } else {
        return;
      }

    });
  }

}
