// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex: 0 0 auto;
  z-index: 3;
}
:host .mat-toolbar {
  background: inherit;
  color: inherit;
  box-shadow: 0px -1px 1px -1px rgba(0, 0, 0, 0.2), 0px 0px 1px 0px rgba(0, 0, 0, 0.14), 0px -1px 3px 0px rgba(0, 0, 0, 0.12);
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
}
:host.above {
  position: relative;
  z-index: 99;
}
:host a:hover {
  text-decoration: none !important;
  font-weight: bold;
  color: inherit;
}`, "",{"version":3,"sources":["webpack://./src/app/layout/components/footer/footer.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,cAAA;EACA,UAAA;AACJ;AACI;EACI,mBAAA;EACA,cAAA;EACA,2HAAA;EACA,wDAAA;AACR;AAEI;EACI,kBAAA;EACA,WAAA;AAAR;AAEI;EACI,gCAAA;EACA,iBAAA;EACA,cAAA;AAAR","sourcesContent":[":host {\n    display: flex;\n    flex: 0 0 auto;\n    z-index: 3;\n\n    .mat-toolbar {\n        background: inherit;\n        color: inherit;\n        box-shadow: 0px -1px 1px -1px rgba(0, 0, 0, 0.2), 0px 0px 1px 0px rgba(0, 0, 0, 0.14), 0px -1px 3px 0px rgba(0, 0, 0, 0.12);\n        font: 500 20px/32px Roboto, \"Helvetica Neue\", sans-serif;\n    }\n\n    &.above {\n        position: relative;\n        z-index: 99;\n    }\n    a:hover {\n        text-decoration: none !important;\n        font-weight: bold;\n        color: inherit;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
