import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppProviders } from 'app/_helpers';

@Injectable({
  providedIn: 'root'
})
export class TranslationsService {

  constructor(
    private http: HttpClient,
    private _appProviders: AppProviders
  ) { }

  getData(postData: any, page: any, perPage?: number): Observable<any[]> {
    return this.http.post<any[]>(`${environment.yiiUrl}translations/search?page=` + page + `&per-page=${perPage}`, postData, { observe: 'response' as 'body' })
      .pipe(map(data => {
        return data;
      }));
  }

  createData(data: any): Observable<object> {
    return this.http.post(`${environment.yiiUrl}translations`, data);
  }

  updateData(data: any): Observable<object> {
    let id = '';
    if (data._id.oid) {
      id = data._id.oid;
    } else {
      id = data._id;
    }
    return this.http.put(`${environment.yiiUrl}translations/${id}`, data);
  }

  deleteData(_id: any): Observable<any> {
    let id = '';
    if (_id.oid) {
      id = _id.oid;
    } else {
      id = _id;
    }
    return this.http.delete(`${environment.yiiUrl}translations/${id}`);
  }

  getMissing(missingTranslations: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}translations/missing`, { siteLang: this._appProviders.siteLangIds, missingTranslations: missingTranslations });
  }
}
