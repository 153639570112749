import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { CrmMenuService } from 'app/services/crm-menu.service';

import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';

import { FuseNavigation } from '@fuse/types';
import * as _ from 'lodash';
import { AuthenticationService } from 'app/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'menu-access',
    templateUrl: './menu-access.component.html',
    styleUrls: ['./menu-access.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MenuAccessComponent implements OnInit {

    treeControl = new NestedTreeControl<FuseNavigation>(node => node.children);
    dataSource = new MatTreeNestedDataSource<FuseNavigation>();

    @Input() viewRecord = false;
    @Input() accessLevel: string;
    @Input() id: string;
    crmMenu: any = {};
    itemChecked: any = {};
    currentAgency: any;

    constructor(
        private _crmMenuService: CrmMenuService,
        private _authenticationService: AuthenticationService,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService,
    ) {
        this.currentAgency = this._authenticationService.currentAgencyValue;
    }

    // tslint:disable-next-line: no-shadowed-variable
    hasChild = (_: number, node: FuseNavigation) => !!node.children && node.children.length > 0;

    ngOnInit(): void {
        const postData: any = {
            access_level: this.accessLevel,
        };
        if (this.accessLevel === 'agency') {
            postData.agency = this.id;
            postData.office = null;
        }
        if (this.accessLevel === 'office') {
            postData.agency = this.currentAgency._id;
            postData.office = this.id;
        }
        this._crmMenuService.getMenuStructure('?all=true').then((menu: any) => {
            this.crmMenu = menu;
            this.dataSource.data = menu;
            if (this.dataSource?.data) {
                this._crmMenuService.getMenuAccess(postData).subscribe((data: any) => {
                    if (data && data?.menu_ids && data?.menu_ids.length > 0) {
                        _.forEach(data.menu_ids, (id) => {
                            _.forEach(this.dataSource.data, (menu_data: any) => {
                                this.getMenuIds(menu_data, id);
                            });
                        });
                    }
                });
            }
        });
    }

    getMenuIds(menu_data, id): void {
        if (menu_data?.id === id) {
            this.itemChecked[menu_data.id] = true
        }
        if (menu_data?.children && menu_data?.children.length > 0) {
            _.forEach (menu_data.children, (child_menu) => {
                this.getMenuIds(child_menu, id);
            });
        }
    }

    leafItemSelectionToggle(node: any): void {
        this.setParents(node, this.crmMenu);
    }
    setParents(node: any, crmMenu: any): void {
        let item = _.find(crmMenu, _.flow(
            _.property('children'),
            _.partialRight(_.some, { id: node.id })
        ));
        if (item) {
            let checkParent = false;
            _.map(item.children, (child) => {
                if (this.itemChecked[child.id]) {
                    checkParent = true;
                }
            });
            this.itemChecked[item.id] = checkParent;
            this.leafItemSelectionToggle(item);
        } else {
            _.forEach(crmMenu, (child) => {
                item = this.setParents(node, child.children);
            });
        }
    }
    itemSelectionToggle(node: any): void {
        this.setParents(node, this.crmMenu);
        this.setChild(node);
    }
    setChild(node: any): void {
        _.forEach(node.children, (child) => {
            this.itemChecked[child.id] = node.id ? this.itemChecked[node.id] : this.itemChecked[node._id];
            if (child.children && child.children.length) {
                this.setChild(child);
            }
        });
    }
    saveMenu(): void {
        const ids = [];
        _.forEach(this.itemChecked, (value: boolean, key: number) => {
            if (value) {
                ids.push(key * 1);
            }
        });
        const postData: any = {
            access_level: this.accessLevel,
            menu_ids: ids,
        };
        if (this.accessLevel === 'agency') {
            postData.agency = this.id;
            postData.office = null;
        }
        if (this.accessLevel === 'office') {
            postData.agency = this.currentAgency._id;
            postData.office = this.id;
        }

        this._crmMenuService.setMenuAccess(postData).subscribe((data: any) => {
            this._snackBar.open(this._translateService.instant('Menu saved successfully'), this._translateService.instant('Close'), {
                duration: 2000,
            });
        });
    }
}
