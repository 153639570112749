import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationService } from 'app/services';
import { CurrencyPipe } from '@angular/common';
import * as _ from 'lodash';

@Pipe({
  name: 'getPropertyPrice'
})
export class GetPropertyPricePipe implements PipeTransform {

  constructor(
    private _authenticationService: AuthenticationService,
    private _currencyPipe: CurrencyPipe
  ) { }

  transform(property: any, args?: any): any {
    let cPrice = '';
    let stPrice = '';
    let ltPrice = '';
    let purchaseOption = '';
    let ltPriceObject = {};
    let stPriceObject = {};
    let dnp = 0;
    const user = this._authenticationService.currentUserValue;
    const currencyCode = property?.currency ? property.currency : 'EUR';
    const symbol = currencyCode === 'AED' ? 'د.إ' : 'symbol-narrow';
    const defaultSeparators = user.default_separators ? user.default_separators : 'es_AR';
    if (property && (property.sale || property.transfer || property.nude_sale) && property.currentprice) {
      cPrice = this._currencyPipe.transform(property.currentprice ? property.currentprice : 0, currencyCode, symbol, '1.0-2', defaultSeparators);
    }
    if (property && (property.sale || property.transfer || property.nude_sale) && !property.currentprice) {
      cPrice = this._currencyPipe.transform(0, currencyCode, symbol, '1.0-2', defaultSeparators);
    }
    if (property && property.rent && property.st_rental && property.rental_seasons) {
      let rentalSeasons = _.find(property.rental_seasons, (o) => this.todayExist(o.period_from, o.period_to) && o.new_price);
      if (!rentalSeasons) {
        dnp = 0;
        rentalSeasons = _.find(property.rental_seasons, (o) => {
          dnp = _.clone(o.new_price);
          return this.futureExist(o.period_from, o.period_to) && (o.new_price < dnp || dnp === 0);
        });
      }
      if (rentalSeasons) {
        stPrice = ((!args && cPrice) ? '/' : '') + this._currencyPipe.transform(rentalSeasons.new_price, currencyCode, symbol, '1.0-2', defaultSeparators);
        stPriceObject = rentalSeasons;
      }
    }
    if (property && property.rent && property.lt_rental && property.period_seasons) {
      let periodSeasons = _.find(property.period_seasons, (o) => this.todayExist(o.period_from, o.period_to) && o.new_price);
      if (!periodSeasons) {
        dnp = 0;
        periodSeasons = _.find(property.period_seasons, (o) => {
          dnp = _.clone(o.new_price);
          return this.futureExist(o.period_from, o.period_to) && (o.new_price < dnp || dnp === 0);
        });
      }
      if (periodSeasons) {
        ltPrice = ((!args && (cPrice || stPrice)) ? '/' : '') + this._currencyPipe.transform(periodSeasons.new_price, currencyCode, symbol, '1.0-2', defaultSeparators);
        ltPriceObject = periodSeasons;
      }
    }
    if (property && property.rent && property.purchase_option) {
      let optionPrice = 0;
      let purchasePrice = 0;
      if (property.purchase_options && property.purchase_options.option_price) {
        optionPrice = parseFloat(property.purchase_options.option_price);
      }
      if (property.purchase_options && property.purchase_options.purchase_price) {
        purchasePrice = parseFloat(property.purchase_options.purchase_price);
      }
      if (property.lt_rental && ltPrice && ltPrice.length) {
        const result = parseFloat(ltPriceObject['new_price']) + optionPrice + purchasePrice;
        purchaseOption = this._currencyPipe.transform(result, currencyCode, symbol, '1.0-2', defaultSeparators);
      }
      else if (property.st_rental && stPrice && stPrice.length) {
        const result = parseFloat(stPriceObject['new_price']) + optionPrice + purchasePrice;
        purchaseOption = this._currencyPipe.transform(result, currencyCode, symbol, '1.0-2', defaultSeparators);
      } else {
        const result = optionPrice + purchasePrice;
        purchaseOption = this._currencyPipe.transform(result, currencyCode, symbol, '1.0-2', defaultSeparators);
      }
    }
    if (args === 'currency') {
      let currencySign = '';
      if (property.currency === 'GBP') {
        currencySign = ' £';
      } else if (property.currency === 'USD') {
        currencySign = ' $';
      } else {
        currencySign = ' €';
      }
      return currencySign;
    } else if (args === 'cPrice') {
      return cPrice;
    } else if (args === 'cPriceSymbolBefore') {
      let sign = cPrice.slice(cPrice.length - 1)
      return sign + " " + cPrice.slice(0, -2);
    } else if (args === 'stPrice') {
      return stPrice;
    } else if (args === 'ltPrice') {
      return ltPrice;
    } else if (args === 'ltPriceObject') {
      return ltPriceObject;
    } else if (args === 'stPriceObject') {
      // If current date will not exists in the season then we will pick nearest furture season
      if (_.isEmpty(stPriceObject)) {
        let rentalSeasons = _.find(property.rental_seasons, (o) => this.nearFurtureExist(o.period_from) && o.new_price);
        return rentalSeasons;
      } else {
        return stPriceObject;
      }
    } else if (args === 'purchase_option') {
      return purchaseOption;
    } else {
      return cPrice + stPrice + ltPrice;
    }
  }

  futureExist(dFrom: string | number | Date, dTo: string | number | Date): boolean {
    if (typeof dFrom === 'number') {
      dFrom = _.size(_.toString(dFrom)) === 10 ? dFrom * 1000 : dFrom;
    }
    if (typeof dTo === 'number') {
      dTo = _.size(_.toString(dTo)) === 10 ? dTo * 1000 : dTo;
    }
    const currentDate = new Date();
    const minDate = new Date(dFrom);
    const maxDate = new Date(dTo);
    return minDate > currentDate || maxDate > currentDate;
  }

  todayExist(dFrom: string | number | Date, dTo: string | number | Date): boolean {
    if (typeof dFrom === 'number') {
      dFrom = _.size(_.toString(dFrom)) === 10 ? dFrom * 1000 : dFrom;
    }
    if (typeof dTo === 'number') {
      dTo = _.size(_.toString(dTo)) === 10 ? dTo * 1000 : dTo;
    }
    const currentDate = new Date();
    const minDate = new Date(dFrom);
    const maxDate = new Date(dTo);
    return currentDate > minDate && currentDate < maxDate;
  }

  nearFurtureExist(dFrom: string | number | Date): boolean {
    if (typeof dFrom === 'number') {
      dFrom = _.size(_.toString(dFrom)) === 10 ? dFrom * 1000 : dFrom;
    }
    const currentDate = new Date();
    const minDate = new Date(dFrom);
    return minDate > currentDate;
  }

}
