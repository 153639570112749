import { Component, OnInit, ViewChild, ViewEncapsulation, AfterViewChecked, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { SalesService, AuthenticationService, CitiesService, LocationsService, DropdownsService, UserService } from 'app/services';
import { UntypedFormGroup, UntypedFormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { GlobalFuntions } from 'app/_helpers';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subject, merge } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { startWith, tap, takeUntil, switchMap, filter, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { environment } from 'environments/environment';
import { AttachmentsService } from 'app/services/attachments.service';
import { TranslateService } from '@ngx-translate/core';
import { PropertiesService } from 'app/services/properties.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { SequencesService } from 'app/services/sequences.service';
import { MeetingCreateModalComponent } from '../../meetings/meeting-create-modal/meeting-create-modal.component';
import { TasksCreateModalComponent } from '../../tasks/tasks-create-modal/tasks-create-modal.component';
import { ViewingToursModalComponent } from '../../viewingTours/viewing-tours-modal/viewing-tours-modal.component';
import { TimeLineDataService } from 'app/components/time-line/time-line-data.service';
import { SequencesComponent } from 'app/main/dashboard/sequences/sequences.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { includes } from 'lodash';
import { CrmMenuService } from 'app/services/crm-menu.service';
import { GetCommercialReferencePipe } from 'app/pipes/get-commercial-reference.pipe';
@Component({
    selector: 'app-sales-create',
    templateUrl: './sales-create.component.html',
    styleUrls: ['./sales-create.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
    providers: [SequencesComponent, GetCommercialReferencePipe]
})
export class SalesCreateComponent implements OnInit, AfterViewChecked {


    private _unsubscribeAll: Subject<any>;
    currentLang: string;
    currentUser: any;
    currentAgency: any;
    id: string;
    sale: UntypedFormGroup;
    viewRecord: boolean;
    user: any;
    currentOffice: any;
    title: any;
    isInvoiceModule: any;

    constructor(
        private _salesService: SalesService,
        private route: ActivatedRoute,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private _snackBar: MatSnackBar,
        private _globalFunctions: GlobalFuntions,
        private _authenticationService: AuthenticationService,
        private _attachmentsService: AttachmentsService,
        private mediaObserver: MediaObserver,
        private _citiesService: CitiesService,
        private _locationService: LocationsService,
        private _translateService: TranslateService,
        private _dropDownsService: DropdownsService,
        private cdRef: ChangeDetectorRef,
        private _propertiesService: PropertiesService,
        private _timeLineData: TimeLineDataService,
        private _fuseSidebarService: FuseSidebarService,
        private _matDialog: MatDialog,
        private _sequenceService: SequencesService,
        public _userService: UserService,
        private _crmMenuService: CrmMenuService,
        private cp_reference: GetCommercialReferencePipe,
    ) {
        this.viewRecord = false;
        this.currentLang = this._translateService.currentLang;
        this.currentUser = this._authenticationService.currentUserValue;
        this.currentAgency = this._authenticationService.currentAgencyValue;
        this.currentOffice = _authenticationService.currentOfficeValue;

        this._unsubscribeAll = new Subject();
        this.isInvoiceModule = this._crmMenuService.isInvoiceModule;
    }

    ngOnInit(): void {

        this._translateService.onLangChange.subscribe(() => this.currentLang = this._translateService.currentLang);
        this.id = this.route.snapshot.paramMap.get('id');

        this.viewRecord = false;
        let officeRecord = false;

        if (includes(this.router.url, 'view/')) {
            this.viewRecord = true;
            officeRecord = true;
        }
        if (this._globalFunctions.getUserRole(this.currentUser.user_role)) {
            officeRecord = true;
        }

        this.sale = this.formBuilder.group({
            related_to: { value: 'residental_properties', disabled: this.viewRecord },
            property_reference: { value: '', disabled: this.viewRecord },
            offer_id: { value: '', disabled: this.viewRecord },
            // listing_agency_id: { value: this.currentAgency._id, disabled: this.viewRecord },
            listing_agency_id: { value: this.currentAgency._id, disabled: this.viewRecord },
            listing_agent_id: { value: '', disabled: this.viewRecord },
            own_property: { value: '', disabled: this.viewRecord },
            exclusive_property: { value: '', disabled: this.viewRecord },
            // company_agency_id: { value: this.currentAgency._id, disabled: this.viewRecord },
            company_agency_id: { value: '', disabled: this.viewRecord },
            sales_price: { value: '', disabled: this.viewRecord },
            listed_price: { value: '', disabled: this.viewRecord },
            currency: { value: '', disabled: this.viewRecord },
            offices: [{ value: [this.currentOffice._id], disabled: officeRecord }, Validators.required],
            owner: { value: '', disabled: this.viewRecord },
            account: { value: '', disabled: this.viewRecord },
            vat: { value: 21, disabled: this.viewRecord },
            status: { value: 'sale_initiated', disabled: this.viewRecord },
            expected_closing_date: { value: '', disabled: this.viewRecord },
            companies: this.formBuilder.array([this.addCompanies()]),
            tottal_commission_value: { value: '', disabled: this.viewRecord },
            tottal_commission_amount: { value: '', disabled: this.viewRecord },
            tottal_vat_on_commission: { value: '', disabled: this.viewRecord },
            tottal_commission_payout: { value: '', disabled: this.viewRecord },
            commission: { value: '', disabled: this.viewRecord },
            commission_in_euro: { value: '', disabled: this.viewRecord },
            commission_in_iva: { value: '', disabled: this.viewRecord },
            commission_payout: { value: '', disabled: this.viewRecord },
            commission_value_one: { value: '', disabled: this.viewRecord },
            commission_out_one_euro: { value: '', disabled: this.viewRecord },
            commission_out_one_iva: { value: '', disabled: this.viewRecord },
            commission_payout_one: { value: '', disabled: this.viewRecord },
            commission_value_two: { value: '', disabled: this.viewRecord },
            commission_out_two_euro: { value: '', disabled: this.viewRecord },
            commission_out_two_iva: { value: '', disabled: this.viewRecord },
            commission_payout_two: { value: '', disabled: this.viewRecord },
            commission_out_selling_percent: { value: '', disabled: this.viewRecord },
            commission_out_selling_agent_net: { value: '', disabled: this.viewRecord },
            commission_out_selling_agent_vat: { value: '', disabled: this.viewRecord },
            commission_out_selling_agent_total: { value: '', disabled: this.viewRecord },
            commission_out_three_euro: { value: '', disabled: this.viewRecord },
            commission_out_three_iva: { value: '', disabled: this.viewRecord },
            commission_payout_three: { value: '', disabled: this.viewRecord },
            commission_net: { value: '', disabled: this.viewRecord },
            commission_net_iva: { value: '', disabled: this.viewRecord },
            commission_total_vat: { value: '', disabled: this.viewRecord },
            comments: { value: '', disabled: this.viewRecord },
            created_by: (''),
            created_at: (''),
            _id: (''),
            sale_id: (''),
            commission_one_payable: { value: '', disabled: this.viewRecord },
            commission_two_payable: { value: '', disabled: this.viewRecord },
            commission_three_payable: { value: '', disabled: this.viewRecord },
            selling_agent_commissions: this.formBuilder.array([this.addAgentCommision()]),
            listing_agent: { value: '', disabled: this.viewRecord },
            listing_agent_payable: { value: '', disabled: this.viewRecord },
            commission_out_listing_percent: { value: '', disabled: this.viewRecord },
            commission_out_listing_agent_net: { value: '', disabled: this.viewRecord },
            commission_out_listing_agent_vat: { value: '', disabled: this.viewRecord },
            commission_out_listing_agent_total: { value: '', disabled: this.viewRecord }
        });

    }

    addAgentCommision(): UntypedFormGroup {
        return this.formBuilder.group({
            payable_to: { value: '', disabled: this.viewRecord },
            commission_percent: { value: '', disabled: this.viewRecord },
            net_commission: { value: '', disabled: this.viewRecord },
            vat_commission: { value: '', disabled: this.viewRecord },
            total_commission: { value: '', disabled: this.viewRecord }
        });
    }

    addCompanies(): UntypedFormGroup {
        return this.formBuilder.group({
            type: { value: '', disabled: this.viewRecord },
            company: { value: '', disabled: this.viewRecord },
            contact: { value: '', disabled: this.viewRecord },
            phone: { value: '', disabled: this.viewRecord },
            email: { value: '', disabled: this.viewRecord }
        });
    }

    downloadProforma(): void {
        window.open(`${environment.yiiUrl}` + 'pdf/download-proforma?sale_id=' + this.sale.get('_id').value + '&user=' + this.user.user_id);
        this._attachmentsService.getModelAttachments(this.sale.get('_id').value, 'sales_images').subscribe(() => {

        });
    }

    submitForm(): any {
        if (this.id) {
            this._salesService.save(this._globalFunctions.cleanObject(this.sale.value), this.id)
                .subscribe((data: any) => {
                    this._snackBar.open(this._translateService.instant('Updated successfully'), this._translateService.instant('Close'), {
                        duration: 2000,
                    });
                });
        } else {
            this._salesService.create(this._globalFunctions.cleanObject(this.sale.value))
                .subscribe((data: any) => {
                    this.router.navigate(['/activities/sales/update', data._id]);
                });
        }

    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    getTitle(data: any): any {
        const sale = data;
        if(data?.related_to === 'non_residental_properties') {
            this.title = 
            (sale.sale_id ? sale.sale_id + ': ' : '') + (sale.account_name ? sale.account_name + ' - ' : '') + 
            (sale.property_data ? this.cp_reference.transform(sale.property_data) + ' - ' : '') +
            (sale.property_data && sale.property_data.type_one_obj && sale.property_data.type_one_obj[this.currentLang] ? sale.property_data.type_one_obj[this.currentLang] + ' ' : '') +
            this._translateService.instant('in') + ' ' +
            (sale.property_data && sale.property_data.location_obj && sale.property_data.location_obj[this.currentLang] ? sale.property_data.location_obj[this.currentLang] : '');
        }else {
            let defaultRef = data?.property_data?.reference;
            if (this.currentAgency.pdf_external_ref === 'external_reference') {
                defaultRef = data?.property_data?.agency_reference
            } else if (this.currentAgency.pdf_external_ref === 'agency_reference') {
                defaultRef = data?.property_data?.external_reference
            } 
            this.title = (sale.sale_id ? sale.sale_id : '') + ': ' + (sale.account_name ? sale.account_name : '') + ' - ' + (sale.property_data ? defaultRef : '');
            if(data?.related_to === 'Moorings' || data?.related_to === 'Boats') {
                this.title += ' - ' + (sale?.property_data?.type_one ? sale.property_data.type_one : '');
            }else {
                this.title += ' - ' + (sale.type_one_obj ? (sale?.type_one_obj?.en ? sale.type_one_obj?.en : '') : '');
            }
            this.title += ' ' + this._translateService.instant('in');
            this.title += ' ' + (sale.property_data ? sale.property_data.location_name : '');
        }
    }

}
