import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'app/_guards';
import { AccountsListComponent } from './accounts-list/accounts-list.component';
import { AccountsCreateComponent } from './accounts-create/accounts-create.component';
import { GridBoardsComponent } from '../grid-boards/grid-boards.component';
import { GridDataComponent } from '../grid-boards/grid-data/grid-data.component';
import { QuickAddComponent } from '../accounts/quick-add/quick-add.component';
import { TabIdComponent } from './accounts-create/tabs/tab-id/tab-id.component';
import { TabProfileComponent } from './accounts-create/tabs/tab-profile/tab-profile.component';
import { TabProfileNonresidentialComponent } from './accounts-create/tabs/tab-profile-nonresidential/tab-profile-nonresidential.component';
import { TabNotesComponent } from './accounts-create/tabs/tab-notes/tab-notes.component';
import { TabDocumentsComponent } from './accounts-create/tabs/tab-documents/tab-documents.component';
import { TabAgencyComponent } from './accounts-create/tabs/tab-agency/tab-agency.component';
import { TabFinancialsComponent } from './accounts-create/tabs/tab-financials/tab-financials.component';
import { TabHistoryComponent } from './accounts-create/tabs/tab-history/tab-history.component';
import { AuditComponent } from 'app/components/audit/audit.component';
import { TabContactComponent } from './accounts-create/tabs/tab-contact/tab-contact.component';
import { MooringProfileComponent } from './accounts-create/tabs/mooring-profile/mooring-profile.component';
import { BoatProfileComponent } from './accounts-create/tabs/boat-profile/boat-profile.component';
const routes: Routes = [
    {
        path: 'accounts',
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                redirectTo: 'list',
                pathMatch: 'full'
            },
            {
                path: 'list',
                component: AccountsListComponent,
                data: { relatedTo: 'account' }
            },
            {
                path: 'archive',
                component: AccountsListComponent,
                data: { relatedTo: 'archive' }
            },
            {
                path: 'grid',
                component: GridBoardsComponent,
                data: { relatedTo: 'account' }
            },
            {
                path: 'grid/:id',
                component: GridDataComponent,
                data: { relatedTo: 'account' }
            },
            {
                path: 'create',
                component: AccountsCreateComponent,
                children: [{
                    path: '',
                    redirectTo: 'id',
                    pathMatch: 'full'
                }, {
                    path: 'id',
                    component: TabIdComponent,
                }, {
                    path: 'contact',
                    component: TabContactComponent,
                }, {
                    path: 'profile',
                    component: TabProfileComponent,
                },{
                    path: 'mooring-profile',
                    component: MooringProfileComponent,
                },{
                    path: 'boat-profile',
                    component: BoatProfileComponent,
                }, {
                    path: 'profile-non-residential',
                    component: TabProfileNonresidentialComponent,
                }, {
                    path: 'notes',
                    component: TabNotesComponent,
                }, {
                    path: 'documents',
                    component: TabDocumentsComponent,
                }, {
                    path: 'agency',
                    component: TabAgencyComponent,
                }, {
                    path: 'financials',
                    component: TabFinancialsComponent,
                }, {
                    path: 'history',
                    component: TabHistoryComponent,
                }, {
                    path: 'audit',
                    component: AuditComponent,
                    data: { title: 'account' }
                }]
            },
            {
                path: 'update/:id',
                component: AccountsCreateComponent,
                children: [{
                    path: '',
                    redirectTo: 'id',
                    pathMatch: 'full'
                }, {
                    path: 'id',
                    component: TabIdComponent,
                }, {
                    path: 'contact',
                    component: TabContactComponent,
                }, {
                    path: 'profile',
                    component: TabProfileComponent,
                },{
                    path: 'mooring-profile',
                    component: MooringProfileComponent,
                },{
                    path: 'boat-profile',
                    component: BoatProfileComponent,
                }, {
                    path: 'profile-non-residential',
                    component: TabProfileNonresidentialComponent,
                }, {
                    path: 'notes',
                    component: TabNotesComponent,
                }, {
                    path: 'documents',
                    component: TabDocumentsComponent,
                }, {
                    path: 'agency',
                    component: TabAgencyComponent,
                }, {
                    path: 'financials',
                    component: TabFinancialsComponent,
                }, {
                    path: 'history',
                    component: TabHistoryComponent,
                }, {
                    path: 'audit',
                    component: AuditComponent,
                    data: { title: 'account' }
                }]
            },
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AccountsRoutingModule { }
