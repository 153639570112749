// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.example-radio-group {
  display: flex;
  flex-direction: row;
  margin: 15px 0;
}

.example-radio-button {
  margin: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/main/master-data/accounts/accounts-create/tabs/tab-profile-nonresidential/tab-profile-nonresidential.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,cAAA;AACF;;AAEA;EACE,WAAA;AACF","sourcesContent":[".example-radio-group {\n  display: flex;\n  flex-direction: row;\n  margin: 15px 0;\n}\n\n.example-radio-button {\n  margin: 5px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
