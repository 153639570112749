import { Pipe, PipeTransform } from '@angular/core';
import { isArray, isString, isNumber } from 'lodash';

@Pipe({
    name: 'limitTo'
})
export class LimitToPipe implements PipeTransform {

    transform(input, limit, begin): any {
        if (Math.abs(Number(limit)) === Infinity) {
            limit = Number(limit);
        } else {
            limit = parseInt(limit, 10);
        }
        if (isNaN(limit)) { return input; }

        if (isNumber(input)) { input = input.toString(); }
        if (!isArray(input) && !isString(input)) { return input; }

        begin = (!begin || isNaN(begin)) ? 0 : parseInt(begin, 10);
        begin = (begin < 0 && begin >= -input.length) ? input.length + begin : begin;

        if (limit >= 0) {
            return input.slice(begin, begin + limit);
        } else {
            if (begin === 0) {
                return input.slice(limit, input.length);
            } else {
                return input.slice(Math.max(0, begin + limit), begin);
            }
        }
    }
}
