import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { BaseService } from 'app/_helpers/base/base.service';
import { environment } from 'environments/environment';
import { LanguagesService } from 'app/services';
import { pickBy, toUpper, isObject, isArray, isEmpty, toLower } from 'lodash';

@Component({
    selector: 'windowcards-view-dialog',
    templateUrl: './windowcards-view-dialog.component.html',
    styleUrls: ['./windowcards-view-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class WindowcardsViewDialogComponent implements OnInit {

    @ViewChild('wc', { static: false }) wc: any;
    template: string;
    card: any = {};
    pdfAsEmail = false;
    property: any;
    tooltip: any = this._translateService.instant('Download SD PDF');
    selectedLang: any;
    languages: [];
    currentLang: any;
    selectedLanguage: any;
    requestFrom: any;
    constructor(
        public matDialogRef: MatDialogRef<WindowcardsViewDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _gService: BaseService,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService,
        private _languagesService: LanguagesService,
    ) {
        this.currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
        this.selectedLang = this._translateService.currentLang === 'es_AR' ? 'ES' : toUpper(this._translateService.currentLang);
    }

    ngOnInit(): void {

        this.template = this._data.template_id;
        this.requestFrom = this._data?.requestFrom;
        if (this._data.type && (this._data.type == 'boat' || this._data.type == 'mooring')) {
            let modelType = this._data.type == 'boat' ? 'boats' : 'mooring_properties';
            let filter = { param: { boat: this._data.type == 'boat' ? true : false } };
            this._gService.post(`${modelType}/view/${this._data.property_id}`, filter, 'nodejs')
                .subscribe((data: any) => {
                    this.property = data;                 
                    this.card.type = this.selectedProperty();                   
                });
        } else {
            if(this.requestFrom !== undefined && this.requestFrom == "commercials") {
                this._gService.get(`commercial-properties/${this._data.property_id}?expand=property_region,property_province,property_city,property_location,property_urbanisation,agency_logo,images,owner_data,property_type,website_url`, 'Yii').subscribe((data: any) => {
                    this.property = data;
                    this.card.type = this.selectedProperty();
                });
            }else {
                this._gService.get(`properties/${this._data.property_id}?expand=property_location,property_urbanisation,property_city,property_region,property_province,agency_logo,images,owner_data,property_type,url`, 'Yii').subscribe((data: any) => {
                    this.property = data;
                    this.card.type = this.selectedProperty();
                });
            }
        }

        this._languagesService.getLanguagesForSystem()
            .subscribe((langs: any) => {
                this.languages = langs;
                this.languages.forEach((language: any) => {
                    if (language.key == this.selectedLang) {
                        this.selectedLanguage = language.key;
                    }
                });
            });
    }

    domPdfDownload(template_id): void {
        const pdfTemplate = this.wc.pdfTemplate as ElementRef;
        this._gService.post(`pdf/html-to-pdf?template_id=${template_id}`, pdfTemplate.nativeElement.innerHTML, 'Yii')
            .subscribe(() => {
                window.open(`${environment.yiiUrl}pdf/download-html-pdf?name=${template_id}`, '_blank');
            });
    }

    onSendEmail(event: any): void {
        const pdfTemplate = this.wc.pdfTemplate as ElementRef;
        this._gService.post(`pdf/html-to-pdf?template_id=${this._data.property_id}`, pdfTemplate.nativeElement.innerHTML, 'Yii')
            .subscribe(() => {
                this._gService.post(`pdf/send-pdf-mail?property_id=${this._data.property_id}`, event, 'Yii')
                    .subscribe(() => {
                        this._snackBar.open(this._translateService.instant('Send successfully'), this._translateService.instant('Close'), {
                            duration: 2000,
                        });
                        this.pdfAsEmail = false;
                    });
            });
    }

    selectedProperty(): any {
        if(this.property.type  && (this.property.type === 'boat' || this.property.type === 'mooring')){
            if(this.property.sale === true || this.property.rent === true){
                if(this.property.rent === true && this.property.sale === false){
                    return 'STR';
                }else{
                    return 'Sale';
                }
            }
        }else{
            if (this.property?.sale === true || (this.property.sale === true && this.property.lt_rental === false
                && this.property.st_rental === false)) {
                return 'Sale';
    
            }
            else if (this.property.sale === false && this.property.lt_rental === true
                && this.property.st_rental === false) {
                return 'STR';
            }
    
            else if (this.property.sale === false && this.property.lt_rental === false
                && this.property.st_rental === true) {
                return 'LTR';
            }
            else if (this.property.rent === true && this.property.sale === true) {
                return 'Sale';
            }
    
            else if (this.property.sale === false && this.property.rent === true) {
                return 'STR';
            }
    
            else if (this.property.rent === true && this.property.sale === false
                && this.property.lt_rental === true) {
                return 'LTR';
            }
    
            else if (this.property.rent === true && this.property.sale === false
                && this.property.st_rental === true) {
                return 'STR';
            }
        }
    }
    changeToolTip(): any {
        if (this.card.size) {
            this.tooltip = this._translateService.instant('Download HD PDF');
        } else if (this.card.white_labeled) {
            this.tooltip = this._translateService.instant('Download SD White Label PDF');
        } else {
            this.tooltip = this._translateService.instant('Download SD PDF');
        }
    }

    setLanguage(lang): void {
        this.languages.forEach((language: any) => {
            if (language.key == lang) {
                this.selectedLanguage = language.key;
                lang = language.internal_key;
            }
        });
        this.selectedLang = lang;
        let langKey = toLower(lang);
        if (langKey === 'es') {
            langKey = 'es_AR';
        }
        localStorage.setItem('lang', langKey);
        this._translateService.use(langKey);
    }

    reSetLanguageAndClose(): void {
        localStorage.setItem('lang', this.currentLang);
        this._translateService.use(this.currentLang);
        this.matDialogRef.close();
    }
}
